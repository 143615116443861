import { CalendarEvent } from '@karve.it/interfaces/calendarEvents';

import { BaseLocationFragment, EstimatesJobFragment, FullJobFragment } from '../../generated/graphql.generated';

export function remainingBalance<T extends { total: number; paidTotal: number }>(balance: T, dollars = true){

    const remainingBalanceCents = balance ? (balance.total - balance.paidTotal) : 0;

    return dollars ? remainingBalanceCents / 100 : remainingBalanceCents;
}

export function hasUncompletedEvents(job: EstimatesJobFragment) {
    return job?.events?.some((e) => e.status !== 'completed' && e.status !== 'required' && e.status !== 'cancelled');
}

export function getJobLocation(job: EstimatesJobFragment, type: string){
    if (!job?.locations) { return; }

    const location = job.locations.find((l) => l.locationType === type);

    return location?.location;
}

//when lead comes from OBE addressLineOne is not always filled correct
//if it's not filled, display area code instead
export function formatLocationToDisplay(location: Partial<BaseLocationFragment>) {
    const addressLineOne = location?.addressLineOne?.trim();
    const addressLineTwo = location?.addressLineTwo?.trim();
    const areaCode = location?.areaCode;
    const city = location?.city;

    if (addressLineOne) {
        return (addressLineTwo ? addressLineTwo + ' - ' : '') + addressLineOne;
    } else {
        return (areaCode || '') + ' ' + (city || '');
    }
}

export function getEventCompareFn<T extends keyof CalendarEvent>(property: T) {
  return function(a, b) {
      if (!Boolean(a[property])) {
          return 1;
      }

      if (!Boolean(b[property])) {
          return -1;
      }

      if (a[property] < b[property]) {
          return -1;
      } else if (a[property] > b[property]) {
          return 1;
      } else {
          return 0;
      }
  };
}

type JobWithSharedZones = Pick<FullJobFragment, 'sharedZones'>;

export function isJobSharedToZone(
  job?: JobWithSharedZones,
  zone?: string,
) {
  if (!job || !zone) {
    return false;
  }

  return Boolean(job?.sharedZones?.includes(zone));



}
