import { Injectable } from '@angular/core';

import { EmailExistsInput, EmailExistsOutput, emailExistsQuery } from '@karve.it/interfaces/auth';
import { } from '@karve.it/interfaces/registration';
import * as DMI from '@karve.it/interfaces/tokens';
import { map } from 'rxjs/operators';

import { PlusApollo } from '../auth/graphql.module';

@Injectable({
  providedIn: 'root'
})
export class DomoreRegistrationService {

  constructor(private apollo: PlusApollo) { }

  /**
   * Create a new invite token and if sendToken is true then email that invite token to a user.
   *
   * @param input Input for the new token
   * @returns The token that is created.
   */
  createInviteToken(input: DMI.CreateInviteTokenInput){
    return this.apollo.mutate<DMI.CreateInviteTokenOutput>({
      mutation: DMI.createInviteTokenMutation,
      variables: {
        ...input
      }
    });
  }


  /**
   *
   * @param input The Token and user information to be used.
   * @returns QueryRef, the id of the created user.
   */
  redeemInviteToken(input: DMI.RedeemInviteTokenInput){
    return this.apollo.mutate<DMI.RedeemInviteTokenOutput>({
      mutation: DMI.redeemInviteToken,
      variables: {
        ...input
      },
    });
  }

  /**
   * Check the system for an email to make sure it doesn't already exist.
   *
   * @param email Email to check the system for.
   * @param cache Whether to use cahed results.
   * @returns
   */
  emailExists(email: string, cache = true) {
    return this.apollo.query<EmailExistsOutput>({
      query: emailExistsQuery,
      fetchPolicy: cache ? 'cache-first' : 'no-cache',
      variables: {
        email,
      } as EmailExistsInput,
    }).pipe(map((res) => res.data.tokens.emailExists));
  }
}
