import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { dayjs } from '@karve.it/core';
import { MutationResult } from 'apollo-angular';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { firstValueFrom } from 'rxjs';

import { Asset, CreateCalendarEventGQL, CreateCalendarEventMutation, CreateCalendarEventMutationVariables } from '../../../generated/graphql.generated';
import { CalendarHelperService } from '../../calendar-helper.service';
import { BOOK_OFF_EVENT_TYPE } from '../../global.constants';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { TimezoneHelperService } from '../../services/timezone-helper.service';
import { dateToDateString } from '../../time';

export interface BookOffInput{
  asset: Asset;
  today: boolean;
  date?: Date;
}

@Component({
  selector: 'app-book-off',
  templateUrl: './book-off.component.html',
  styleUrls: ['./book-off.component.scss']
})
export class BookOffComponent implements OnInit {

  @Input() input: BookOffInput;

  bookOffOptions = [
    {
      label: 'All Day',
        start: 0,
        end: 86340
    },
    {
      label: 'Morning',
        start: 0,
        end: 43140,
    },
    {
      label: 'Afternoon',
        start: 43200,
        end: 86340,
    },
    {
      label: 'Custom',
      custom: true,
    }
  ];

  bookOffForm = new UntypedFormGroup({
    date: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', []),
    timeBlock: new UntypedFormControl(this.bookOffOptions[0], [Validators.required]),
    assets: new UntypedFormControl([], [Validators.required]),
  });

  customTimeForm = new UntypedFormGroup({
    start: new UntypedFormControl('', [Validators.required]),
    end: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private createCalendarEventGQL: CreateCalendarEventGQL,
    private localNotify: FreyaNotificationsService,
    private detailsHelper: DetailsHelperService,
    private calendarHelper: CalendarHelperService,
    private timeZoneHelper: TimezoneHelperService,
  ) { }

  ngOnInit(): void {
    this.input = this.config?.data.input;
    if (this.input.asset) {
      this.bookOffForm.patchValue({
        assets: [this.input.asset],
      });
    }
    if (!this.input.date){
      this.input.date = new Date(this.calendarHelper.displayedDate);
    }

    this.bookOffForm.patchValue({
      date: this.input.date,
    });

  }

  closeDialog(){
    this.ref.close();
  }

  bookOff(){
    this.closeDialog();

    const formVal = this.bookOffForm.value;

    let start: number;
    let end: number;

    if (formVal.timeBlock.custom){
      const timeFormVal = this.customTimeForm.value;

      const startSeconds = (timeFormVal.start.getHours() * 3600) + (timeFormVal.start.getMinutes() * 60);
      start = dayjs(dateToDateString(formVal.date), 'YYYY/MM/DD')
        .tz(this.timeZoneHelper.getCurrentTimezone(), true)
        .startOf('day')
        .add(startSeconds, 'seconds')
        .unix();

      const endSeconds = (timeFormVal.end.getHours() * 3600) + (timeFormVal.end.getMinutes() * 60);
      end = dayjs(dateToDateString(formVal.date), 'YYYY/MM/DD')
        .tz(this.timeZoneHelper.getCurrentTimezone(), true)
        .startOf('day')
        .add(endSeconds, 'seconds')
        .unix();
    } else {
      start = dayjs(dateToDateString(formVal.date), 'YYYY/MM/DD')
        .tz(this.timeZoneHelper.getCurrentTimezone(), true)
        .startOf('day')
        .add(formVal.timeBlock.start, 'seconds')
        .unix();
      end = dayjs(dateToDateString(formVal.date), 'YYYY/MM/DD')
        .tz(this.timeZoneHelper.getCurrentTimezone(), true)
        .startOf('day')
        .add(formVal.timeBlock.end, 'seconds')
        .unix();
    }

    const createPromises: Promise<MutationResult<CreateCalendarEventMutation>>[] = [];

    for (const asset of formVal.assets){
      const createEventInput: CreateCalendarEventMutationVariables = {
        calendarEvents: [{
          title: 'Booked Off',
          description: formVal.description,
          start,
          end,
          type: BOOK_OFF_EVENT_TYPE,
          status: 'confirmed',
          assetIds: [ asset.id ],
          attributes: ['disable-notifications']
        }]
      }

      const promise = firstValueFrom(this.createCalendarEventGQL.mutate(createEventInput));

      createPromises.push(promise);
    }

    Promise.all(createPromises).then((results) => {

      let createdAssets = 0;

      let firstAssetName;

      for (const res of results) {

        const [ createdEvent ] = res.data.createCalendarEvent.events;

        const [ asset ] = createdEvent.assets;

        if (!asset) { continue; }

        if (!firstAssetName) {
          firstAssetName = asset.name;
        }

        createdAssets++;

        this.detailsHelper.pushUpdate({
          id: asset.id,
          type: 'Events',
          action: 'create',
          update: {
            type: 'book-off'
          }
        });
      }

      let successMessage = `Successfully booked off ${createdAssets} assets`;

      if (createdAssets === 1){
        successMessage = `Successfully booked off ${firstAssetName}`;
      }

      this.localNotify.success(successMessage);
    }).catch((err) => {
      this.localNotify.apolloError('Failed to book off assets',err);
    });
  }

  setCustomEnabled(event){
    console.log(event);
  }

}
