<div class="card">
  <div class="header-with-button">
    <h5>"How Did You Hear About Us?" Options</h5>
      <div *ngIf="canEdit">
            <button
              pButton
              icon="pi pi-trash"
              class="p-button-danger"
              (click)="howHeardList.openDeleteDialog()"
              [disabled]="!howHeardList.selectedItems.length"
              ></button>
            <button
              pButton
              icon="pi pi-plus"
              (click)="howHeardList.openAddDialog()"
              class="p-ml-1"
              ></button>
      </div>
  </div>
  <app-editable-list
    #howHeardList
    [items]="howHeardOptions"
    editWarning="This will not affect any jobs with this value"
    deleteWarning="This will not affect any jobs with this value or values"
    [editable]="canEdit"
    (itemsEdited)="mutateHowHeard($event)"
    ></app-editable-list>
</div>

