import { EventEmitter, Injectable } from '@angular/core';
import { checkRules, Rule } from '@karve.it/rule-checker';
import { merge } from 'lodash';
import { Subscription } from 'rxjs';

import { RulePartsFragment, Trigger } from '../../generated/graphql.generated';
import { RuleService } from '../features/Rules/rule.service';

@Injectable({
  providedIn: 'root'
})
export class RuleCheckerService {

  form: string;
  loading = false;
  rules: RulePartsFragment[] = [];
  ruleTotal: number;
  ruleLimit = 50;

  triggersUpdated = new EventEmitter<Rule[]>();

  sub: Subscription;

  globalTrigger?: Trigger;
  triggers: Partial<{
    [x: string]: Trigger;
  }> = {};
  rulesTriggered: Rule[] = [];
  rulesOverriden: Rule[] = [];
  calcs: {
    [ on: string ]: {
      rules: Rule[];
      result?: number;
    };
  } = {};

  /**
   * Set by Mutate Rule Component, whether rules have changed recently
   * and we need to invalidate the cache when loading rules.
   */
  rulesRecentlyUpdated = false;

  private data: any = {};

  public get ready() {
    return Boolean(this.form) && !this.loading;
  }

  constructor(
    private ruleService: RuleService,
  ) { }

  reset() {
    this.form = undefined;
    this.loading = false;
    this.rules = [];
    this.ruleTotal = undefined;

    this.triggers = {};
    this.globalTrigger = undefined;
    this.rulesTriggered = [];

    if (this.sub) {
      this.sub.unsubscribe();
      delete this.sub;
    }

    this.triggersUpdated.next(this.rulesTriggered);
  }

  loadRules() {
    if (!this.form) { return; }
    this.loading = true;

    this.sub = this.ruleService.listRules({
      skip: this.rules.length,
      limit: this.ruleLimit,
      filter: {
        form: this.form,
      },
    }).subscribe((res) => {
      delete this.sub;
      const { rules, total } = res?.data?.rules;
      if (!rules || !total) { return; }
      this.rules.push(...rules);
      this.ruleTotal = total;
      if (this.rules.length >= this.ruleTotal) {
        // ready
        this.loading = false;
        console.log(`${ this.rules.length } rules ready.`, this.rules);
        this.checkData();
      } else {
        // load more
        this.loadRules();
      }
    });

  }

  updateData(data: any) {
    this.data = data;
    return this.checkData();
  }

  patchData(delta: any) {
    this.data = merge(this.data, delta);
    return this.updateData(this.data);
  }

  checkData(data?: any) {
    data = data || this.data;
    if (!this.ready) { return; }

    const result = checkRules({
      data,
      rules: this.rules as any,
    });

    this.rulesTriggered = result.rulesTriggered;
    this.rulesOverriden = result.rulesOverriden;
    this.calcs = result.calcs;
    this.triggersUpdated.next(this.rulesTriggered);

    return result;
  }
}
