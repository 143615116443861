/* eslint-disable @typescript-eslint/naming-convention */

import { Component, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EstimatingUpdateJobGQL } from 'graphql.generated';
import { cloneDeep, pick } from 'lodash';
import { ADDABLE_LOCATION_TYPES, JOB_LOCATION_ORDER } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { EstimateHelperService } from 'src/app/services/estimate-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { GoogleHelperService } from 'src/app/services/google-helper.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

import { BaseLocationFragment, CreateLocationsGQL, CreateLocationsMutationVariables, EditLocationsGQL, EditLocationsMutationVariables, EstimatesJobFragment, RemoveLocationsGQL } from '../../../generated/graphql.generated';
import { AppMainComponent } from '../../app.main.component';

import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { AddJobLocationComponent } from '../add-job-location/add-job-location.component';

import { DistanceService } from '../distance.service';
import { EstimateUpdated, EstimateUpdatedEvent, Extra, extraIsGarage, extraIsShed, extraIsStorageUnit, ExtraType, GarageInfo, ShedInfo, StorageUnitInfo } from '../estimate.interfaces';

export interface JobLocation{
  location?: BaseLocationFragment;
  locationType?: string;
  locationId?: string;
  fieldNamespace?: string;
  enableAdditionalFields?: boolean;
  changed?: boolean;
  removable?: boolean;
}

@Component({
  selector: 'app-job-info',
  templateUrl: './job-info.component.html',
  styleUrls: ['./job-info.component.scss']
})
export class JobInfoComponent implements OnInit, OnDestroy, EstimateUpdated {

  // @ViewChild('startAutocomplete') startAutocompleteRef: GooglePlaceDirective;
  // @ViewChild('endAutocomplete') endAutocompleteRef: GooglePlaceDirective;
  // @ViewChild('addLocation') addLocationRef: AddLocationComponent;

  // @ViewChild('startLocationRef') startLocationRef: AddJobLocationComponent;
  // @ViewChild('endLocationRef') endLocationRef: AddJobLocationComponent;

  @ViewChildren('locationComponent') locationRefs: QueryList<AddJobLocationComponent>;

  @Output() jobInfo = new EventEmitter();

  @Output() startLocationSet = new EventEmitter();

  @Output() endLocationSet = new EventEmitter();

  @Output() locationAdded = new EventEmitter(); // Deprecated

  @Output() estimateUpdated = new EventEmitter<EstimateUpdatedEvent>();

  subs = new SubSink();

  // Subsink specifically for the form values
  formFieldSubs = new SubSink();

  job: EstimatesJobFragment;

  // LOCATIONS

  jobLocations: JobLocation[] = [];
  // The types of locations that show even if not present on the job
  defaultLocationTypes = ['start', 'end'];
  activeLocationIndex = 0;


  addableLocationTypes = ADDABLE_LOCATION_TYPES;
  showAdditionalLocationDialog = false;

  addExtraActions = [{
    label: 'Add Extra',
    items: [
      {
        label: 'Shed',
        icon: 'pi pi-plus',
        command: () => {
          this.openShedModal();
        }
      },
      {
        label: 'Garage',
        icon: 'pi pi-plus',
        command: () => {
          this.openGarageModal();
        }
      },
      {
        label: 'Storage Unit',
        icon: 'pi pi-plus',
        command: () => {
          this.openStorageUnitModal();
        }
      }
    ]
  }];

  defaultGarageInfo: GarageInfo = {
    sqft: undefined,
    xCarGarage: undefined,
  };

  garageInfo: GarageInfo = cloneDeep(this.defaultGarageInfo);

  defaultShedInfo: ShedInfo = {
    sqft: undefined,
  };

  shedInfo: ShedInfo = cloneDeep(this.defaultShedInfo);

  defaultStorageUnitInfo: StorageUnitInfo = {
    sqft: undefined,
    indoor: false,
    private: false,
    accessAndSecurityInfo: '',
  };

  storageUnitInfo: StorageUnitInfo = cloneDeep(this.defaultStorageUnitInfo);

  showGarageModal = false;
  showShedModal = false;
  showStorageUnitModal = false;

  extrasEditIndex: number;

  // MANUAL LOCATIONS
  attemptedAutocomplete = false;

  // ADDITIONAL FIELDS

  additionalFieldsForm = new UntypedFormGroup({
    partialMove: new UntypedFormControl(false, []),
    moreThan10Items: new UntypedFormControl(false, []),
    // extras: new FormControl([], []),
  });
  additionalFieldsFormValues = this.additionalFieldsForm.value;

  extras: Extra[] = [];

  activeTab: 'start' | 'end';

  constructor(
    public estimateHelper: EstimateHelperService,
    public googleHelper: GoogleHelperService,
    private createLocationsGQL: CreateLocationsGQL,
    private removeLocationsGQL: RemoveLocationsGQL,
    private editLocationsGQL: EditLocationsGQL,
    private distanceService: DistanceService,
    private freyaHelper: FreyaHelperService,
    private detailsHelper: DetailsHelperService,
    private updateJobsGQL: EstimatingUpdateJobGQL,
    private localNotify: FreyaNotificationsService,
    private appMain: AppMainComponent,
  ) { }

  ngOnInit(): void {
    this.assignFieldHandlers();
    this.setJob(null);
    this.handleLocationsTabOpened();

    this.estimateHelper.permissionsAndJobLoading.subscribe((res) => {
      if (!res.jobLoading && res.setFieldValues) {
        this.additionalFieldsForm.enable();
      } else {
        this.additionalFieldsForm.disable();
      }
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.formFieldSubs.unsubscribe();
  }

  reset() {
    this.subs.unsubscribe();
    this.job = undefined;
    this.garageInfo = cloneDeep(this.defaultGarageInfo);
    this.shedInfo = cloneDeep(this.defaultShedInfo);
    this.storageUnitInfo = cloneDeep(this.defaultStorageUnitInfo);
    this.showGarageModal = false;
    this.showShedModal = false;
    this.showStorageUnitModal = false;
    this.activeTab = 'start';
    this.additionalFieldsForm.reset();
    this.extras = [];
  }

  assignFieldHandlers() {
    const additionalFields = ['partialMove', 'moreThan10Items'];

    // Assign Additional Field Handlers
    this.estimateHelper.assignFieldEventHandlers(
      this.formFieldSubs,
      additionalFields,
      'startLocation',
      this.additionalFieldsForm,
      // this.fieldUpdated
    );
  }

  setJob(job: EstimatesJobFragment){
    this.job = job;

    this.jobLocations = [];


    if (job) {
      for (const location of this.job.locations){
        this.jobLocations.push(
          {
            ...location,
            enableAdditionalFields: location.locationType === 'start' || location.locationType === 'end',
            removable: !(location.locationType === 'start' || location.locationType === 'end'),
            fieldNamespace: `${location.locationType}Location`,
          }
        );
      }
    }

    // Call detect changes on appMain to prevent an `ExpressionChangedAfterItHasBeenCheckedError`
    this.appMain.cd.detectChanges();

    for (const locationType of this.defaultLocationTypes){
      if (this.jobLocations.find((l) => l.locationType === locationType)) { continue; }

      this.jobLocations.push({
        locationType,
        enableAdditionalFields: locationType === 'start' || locationType === 'end',
        removable: !(locationType === 'start' || locationType === 'end'),
        fieldNamespace: `${locationType}Location`,
      });
    }

    this.setAddableLocationTypes();

    this.jobLocations.sort((a, b) => {
      const aIndex = JOB_LOCATION_ORDER.indexOf(a.locationType);
      const bIndex = JOB_LOCATION_ORDER.indexOf(b.locationType);

      return aIndex > bIndex ? -1 : 1;
    });
  }

  /**
   * Pass the estiamte notes info to each location
   *
   * @param jobInfo
   */
  syncLocationsFromNotes(jobInfo){

    if (jobInfo?.start?.address){
      const locationRef = this.locationRefs.find((lr) => lr.type === 'start');

      locationRef.syncLocationFromNotes(jobInfo);
    }
  }

  hasChanges() {
    return this.locationRefs.map((lr) => lr.locationChanged || lr.baseLocationForm.controls.unit.dirty).includes(true);
  }

  // Create Locations if necessary and assign the id's to the update job input
  async saveJobInfo() {
    // @ts-ignore
    const saveInfo: EstimatingSaveInfo = { input: {}, promises: [] };
    saveInfo.input.addLocations = [];

    const locationPromises = [];

    for (const ref of this.locationRefs){
      locationPromises.push(ref.getSaveValue());
    }

    const addLocationInput = await (await Promise.all(locationPromises)).filter((res) => res !== undefined);

    saveInfo.input.addLocations = addLocationInput;

    if (!saveInfo.input.addLocations?.length) {
      saveInfo.input.addLocations = undefined;
    }

    return saveInfo;
  }


  async createLocation(value, type: 'start' | 'end') {

    // if country is not set (eg, in estimate notes with just an area code)
    // then determine the country from the area code
    if (!value.country && value.areaCode) {
      if (value.areaCode.match(/^[0-9]/)) {
        value.country = 'USA';
      } else if (value.areaCode.match(/^[a-zA-Z]/)) {
        value.country = 'Canada';
      }
    }

    const createLocationInput: CreateLocationsMutationVariables['input'] = {
      locations: [{
        public: false,
        addressLineOne: value.address,
        addressLineTwo: value.unit,

        areaCode: value.areaCode,
        country: value.country,
        city: value.city,
        countryJurisdiction: value.jurisdiction || undefined,
        coordinates: value.coordinates?.latitude ? value.coordinates : undefined,
      }],
      owner: this.job?.users?.length ? this.freyaHelper.getJobCustomerId(this.job) : undefined
    };

    const res = await this.createLocationsGQL.mutate({
      input: createLocationInput,
    }).toPromise();

    return res?.data?.createLocations?.locations[0]?.id;
  }

  // Update the location on a Job
  async updateLocation(value, type: 'start' | 'end') {

    if (value?.id) {
      await this.removeLocationsGQL.mutate({
        ids: [ value.id ],
      }).toPromise();
    }

    return this.createLocation(value, type);

    // WHY: In the future if we change the update logic it is good to go
    // const updateLocationInput = {
    //   ids: [value.id],
    //   edit: {
    //     addressLineOne: value.address,
    //     addressLineTwo: value.unit,

    //     areaCode: value.areaCode,
    //     country: value.country,
    //     city: value.city,
    //     countryJurisdiction: value.jurisdiction,
    //   }
    // } as EditLocationsInput;

    // this.subs.sink = this.locationService.EditLocations(updateLocationInput).subscribe((res) => {
    //   this.estimateHelper.saveFunctionComplete.next(`${type}Location`);
    // }, (err) => {
    //   this.localNotify.addToast.next({severity: 'error', summary: 'Failed to update location'});
    // });
  }

  /**
   * Update Unit field of the location.
   *
   * @param form The formGroup to validate
   */
  updateUnit(form: UntypedFormGroup) {
    const value = form.getRawValue();

    const updateLocationInput: EditLocationsMutationVariables = {
      ids: [value.id],
      edit: {
        addressLineTwo: value.unit,
      }
    };

    this.subs.sink = this.editLocationsGQL.mutate(updateLocationInput).subscribe((res) => {
      this.detailsHelper.pushUpdate({
        id: value.id,
        type: 'Locations',
        action: 'update'
      });
    });
  }

  /**
   * Add an extra (shed, garage, storage) to a job
   */
  addExtra(type: ExtraType) {
    const current = this.extras ? this.extras : [];

    this.extras = [...current, this.getExtraValue(type)];

    this.updateExtrasField();
  }

  /**
   * Get the value of the extra based on it's type
   */
  getExtraValue(type: ExtraType): Extra {
    let extraValue;

    if (type === 'garage') {
      extraValue = this.garageInfo;
      if (!extraValue.sqft) {
        // Approximate the Sqft of the garage
        extraValue.sqft = (+extraValue.xCarGarage) * environment.approximateSqftPerCarGarage;
      }
      this.showGarageModal = false;
    } else if (type === 'shed') {
      extraValue = this.shedInfo;
      this.showShedModal = false;
    } else if (type === 'storage') {
      extraValue = this.storageUnitInfo;
      this.showStorageUnitModal = false;
    }

    return {
      type,
      details: extraValue,
    } as Extra;
  }

  /**
   * Initialize the correct form and then open the dialog based on extraType
   */
  openEditExtra(extra, type: ExtraType) {
    const index = this.extras.indexOf(extra);

    if (type === 'shed') {
      this.shedInfo = extra.details;
      this.showShedModal = true;
    } else if (type === 'garage') {
      this.garageInfo = extra.details;
      this.showGarageModal = true;
    } else if (type === 'storage') {
      this.storageUnitInfo = extra.details;
      this.showStorageUnitModal = true;
    }

    this.extrasEditIndex = index;
  }

  /**
   * Edit the value of an existing extra on a job
   */
  editExtra(type: ExtraType) {
    if (!(this.extrasEditIndex >= 0)) { return; }

    const value = this.extras;
    value.splice(this.extrasEditIndex, 1, this.getExtraValue(type));
    this.extras = value;

    this.extrasEditIndex = undefined;
    this.updateExtrasField();
  }

  /**
   * Remove an extra from the job
   */
  removeExtra(extra) {
    const index = this.extras.indexOf(extra);
    const value = this.extras;

    value.splice(index, 1);

    this.extras = value;

    this.updateExtrasField();
  }

  /**
   * Notify the parent component of changes to the extras
   */
  updateExtrasField() {
    this.estimateHelper.fieldModified.next(
      {
        name: 'startLocation.extras',
        value: JSON.stringify(this.extras)
      }
    );
  }

  openGarageModal() {
    this.showGarageModal = true;
    this.garageInfo = cloneDeep(this.defaultGarageInfo);
  }

  openShedModal() {
    this.showShedModal = true;
    this.shedInfo = cloneDeep(this.defaultShedInfo);
  }

  openStorageUnitModal() {
    this.showStorageUnitModal = true;
    this.storageUnitInfo = cloneDeep(this.defaultStorageUnitInfo);
  }

  getRuleData() {

    const formToData = (form: UntypedFormGroup) => {
      const data = { ...form.getRawValue() };
      delete data.extras;
      return data;
    };

    let totalSqftWithExtras = 0;
    const extraInfo = {
      extras: {
        types: [],
        count: 0,
        totalSqft: 0,
      },
      shed: {
        totalSqft: 0,
        count: 0,
      },
      garage: {
        totalSqft: 0,
        count: 0,
        totalVehicleCapacity: 0,
      },
      storageUnit: {
        totalSqft: 0,
        count: 0,
        indoors: undefined,
      },
    };

    const extras: Extra[] = this.extras;

    if (extras && Array.isArray(extras)) {
      for (const extra of extras) {
        extraInfo.extras.types.push(extra.type);
        if (extra.details.sqft) {
          totalSqftWithExtras += extra.details.sqft;
          extraInfo.extras.totalSqft += extra.details.sqft;
          extraInfo.extras.count++;
        }

        if (extraIsGarage(extra)) {
          extraInfo.garage.totalSqft += extra.details.sqft;
          extraInfo.garage.count++;
          extraInfo.garage.totalVehicleCapacity += extra.details.xCarGarage;
        }

        if (extraIsShed(extra)) {
          extraInfo.shed.totalSqft += extra.details.sqft;
          extraInfo.shed.count++;
        }

        if (extraIsStorageUnit(extra)) {
          extraInfo.storageUnit.totalSqft += extra.details.sqft;
          extraInfo.storageUnit.count++;
          extraInfo.storageUnit.indoors = extraInfo.storageUnit.indoors || extra.details.indoor;
        }
      }
    }

    const distances: any = {
      units: this.distanceService.units,
    };

    for (const [key, distance] of Object.entries(this.distanceService.distances)) {
      distances[key] = pick(distance, ['estimatedTime', 'totalDistance', 'distanceUnits']);
    }

    return {
      starting_location: {
        // ...formToData(this.startLocationForm),
        totalSqftWithExtras,
      },
      // ending_location: formToData(this.endLocationForm),
      ...extraInfo,
      distances,
    };
  }

  /**
   * Adds a location of the specified type to the list of locations for the job. Location is only saved if a value is entered.
   *
   * @param value
   */
  addLocationType(value: string){
    this.jobLocations.push({
      locationType: value,
      enableAdditionalFields: false,
      removable: true,
    });

    this.setAddableLocationTypes();

    this.showAdditionalLocationDialog = false;
  }

  /**
   * Update the list of addable location types based on the existing location types. NO DUPLICATES
   */
  setAddableLocationTypes(){
    this.addableLocationTypes = ADDABLE_LOCATION_TYPES
      .filter((lt) => !this.jobLocations.find((jl) => jl.locationType === lt));
  }

  removeLocation(type: string){
    const location = this.jobLocations?.find((jl) => jl.locationType === type);

    this.jobLocations = this.jobLocations.filter((jl) => jl.locationType !== type);

    this.setAddableLocationTypes();

    if (!location?.locationId){
      return;
    }

    this.subs.sink = this.updateJobsGQL.mutate({updateJobs: [{
      jobId: this.job.id,
      removeLocations: [location.locationId],
    }]}).subscribe((res) => {
      this.localNotify.success('Location Removed');
    }, (err) => {
      this.localNotify.apolloError('Failed to remove location', err);
    });
  }

  handleLocationsTabOpened() {
    this.subs.sink = this.estimateHelper.locationTabOpened$
      .subscribe((locationType) => {

        const locations = this.locationRefs.toArray();

        const locationIndex = locations.findIndex((l) => l.type === locationType);

        if (locationIndex < 0) { return; }

        this.activeLocationIndex = locationIndex;

        const location = locations[locationIndex];

        // Set timeout so input has a chance to render before trying to set focus
        setTimeout(() => {
          location.setFocusOnLocationInput();
        }, 50);;
      });

  }
}

