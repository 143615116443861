<div [formGroup]="areaForm">
    <ng-template #name>
        <div *appVar="areaForm.get('name') as control">
            <label>Name</label>
            <br />
            <input
                type="text"
                pInputText
                placeholder="Name"
                formControlName="name"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <br>
    <ng-template #color>
        <div class="p-grid">
            <div
                *appVar="areaForm.get('color') as control"
                class="p-flex p-ai-center p-col"
            >
                <label>Color</label>
                <br />
                <p-colorPicker
                    appendTo="body"
                    class="p-mr-2"
                    formControlName="color"
                    (onHide)="changeTagColors()"
                ></p-colorPicker>
                <span>Area Color </span>
            </div>
            <div class="tag-container p-col" *ngIf="showColorTags">
                <br>
                <p-tag
                    styleClass="white-text"
                    [style]="{'background-color': areaForm.controls.color.value}"
                >
                    White Text
                </p-tag>
                <p-tag
                    styleClass="grey-text"
                    [style]="{'background-color': areaForm.controls.color.value}"
                >
                    Grey Text
                </p-tag>
                <p-tag
                    styleClass="black-text"
                    [style]="{'background-color': areaForm.controls.color.value}"
                >
                    Black Text
                </p-tag>
                <br>
                <small>Use this to gauge how the color will look in the system.</small>
            </div>
        </div>
        <br>
    </ng-template>
    <br>
    <ng-template #country>
        <div *appVar="areaForm.get('country') as control">
            <label>Country</label>
            <br />
            <p-dropdown
                [options]="countries"
                formControlName="country"
                appendTo="body"
            ></p-dropdown>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <br>
    <ng-template #description>
        <div *appVar="areaForm.get('description') as control">
            <label>Description</label>
            <br />
            <input
                type="text"
                pInputText
                placeholder="Description"
                formControlName="description"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <br>
    <ng-template #areaCodes>
        <div *appVar="areaForm.get('areaCodes') as control">
            <div>
                <label>Press ENTER to validate code</label>
                <br />
                <p-chips
                    formControlName="areaCodes"
                    placeholder="Enter Zip/Postal Codes"
                    separator=","
                    [style]="{'width': '100%'}"
                    [allowDuplicate]="false"
                    (onAdd)="validateAreaCode($event); "
                    (keyup.enter)="$event.stopPropagation()"
                    (keyup)="capitalizeText($event.target)"
                    (onBlur)="clearAreaInput($event)"
                    [addOnTab]="true"
                    [allowDuplicate]="false"

                    [class.p-invalid-input]="!control.valid && control.touched"
                >
                    <ng-template
                        let-item
                        pTemplate="item"
                    >
                        <div [class.invalid-chip]="true">
                            {{item}}
                        </div>
                    </ng-template>
                </p-chips>
                <br>
                <app-validation-messages [control]="control" [requireTouched]="false">
                </app-validation-messages>
                <br>
                <br>
                <small>Must match the format for a Postal Code Eg. "A1A 1A1" or the format of a Zipcode Eg. "99501". </small>
            </div>
        </div>
    </ng-template>
</div>

<app-mutate-object
    #mutate
    [fg]="areaForm"
    objectType="Area"
    [mutateType]="mutateType"
    [steps]="steps"
    (objectMutated)="mutateObject()"
></app-mutate-object>