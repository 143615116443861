<div
    [formGroup]="businessUnitForm"
    >
    <ng-template #name>
        <div *appVar="businessUnitForm.get('name') as control">
            <label>Name</label>
            <br />
            <input
                type="text"
                pInputText
                formControlName="name"
                placeholder="Name"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #unitType>
        <div *appVar="businessUnitForm.get('unitType') as control">
            <label>Unit Type</label>
            <br />
            <p-dropdown
                [options]="unitTypes"
                formControlName="unitType"
                appendTo="body"
                optionLabel="label"
                optionValue="value"
                data-cy="type-drop-down-field"
            ></p-dropdown>
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #id>
        <div *appVar="businessUnitForm.get('id') as control">
            <label>Business Unit ID</label>
            <br />
            <input
                type="text"
                pInputText
                formControlName="id"
                placeholder="Business Unit ID"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #zoneCode>
        <div *appVar="businessUnitForm.get('zoneCode') as control">
            <label>Zone Code</label>
            <br />
            <input
                type="text"
                pInputText
                formControlName="zoneCode"
                placeholder="Zone Code"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #timezone>
        <div *appVar="businessUnitForm.get('timezone') as control">
            <label>Timezone</label>
            <br />
            <input
                type="text"
                pInputText
                formControlName="timezone"
                placeholder="Timezone"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #language>
        <div *appVar="businessUnitForm.get('language') as control">
            <label>Language</label>
            <br />
            <input
                type="text"
                pInputText
                formControlName="language"
                placeholder="Language"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #country>
        <div *appVar="businessUnitForm.get('country') as control">
            <label>Country</label>
            <br />
            <input
                type="text"
                pInputText
                formControlName="country"
                placeholder="Country"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #hqAddress>
        <div *appVar="businessUnitForm.get('hqAddress') as control">
            <label>HQ Address</label>
            <br />
            <input
                type="text"
                pInputText
                formControlName="hqAddress"
                placeholder="HQ Address"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #email>
        <div *appVar="businessUnitForm.get('email') as control">
            <label>Email</label>
            <br />
            <input
                type="email"
                pInputText
                formControlName="email"
                placeholder="Email"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
    <ng-template #phone>
        <div *appVar="businessUnitForm.get('phone') as control">
            <label>Phone</label>
            <br />
            <input
                type="text"
                pInputText
                formControlName="phone"
                placeholder="Phone"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages
                [control]="control"
                [requireTouched]="true"
            ></app-validation-messages>
        </div>
    </ng-template>
</div>
<app-mutate-object
    #mutate
    [fg]="businessUnitForm"
    objectType="Business Unit"
    [mutateType]="mutateType"
    [steps]="steps"
    (objectMutated)="mutateObject()"
></app-mutate-object>
