<p-listbox
  #listbox
  *ngIf="!listboxRefreshing"
  [(ngModel)]="selectedItems"
  [options]="indexedItems"
  [filter]="true"
  [readonly]="!editable"
  [checkbox]="editable"
  [multiple]="editable"
  [listStyle]="{ height }"
  optionLabel="item"
  dataKey="index"
  >
  <ng-template let-item pTemplate="item">
    <span style="flex: 1" class="p-d-flex p-ai-center">
      <span style="flex: 1">
        {{ item.item }}
      </span>
      <button
        *ngIf="editable"
        pButton
        type="button"
        icon="pi pi-ellipsis-v"
        class="p-button-icon-only p-button-text ellipsis-button"
        (click)="$event.stopPropagation(); setActions(item); actionsMenu.toggle($event)"
        ></button>
    </span>
  </ng-template>
  <ng-template pTemplate="empty">
    <li class="p-listbox-item" *ngIf="listbox._filterValue">
      <span *ngIf="editable; else notFound">Add
        <button
          (click)="addItemInput.value = listbox._filterValue; openAddDialog()"
          class="freya-link-button">
          {{ listbox._filterValue }}
        </button>
      </span>
        <ng-template #notFound>
          {{ listbox._filterValue }} not found
        </ng-template>
    </li>
  </ng-template>
</p-listbox>

<p-menu
	#actionsMenu
	appendTo="body"
	[popup]="true"
	[model]="actions"
	></p-menu>

<p-dialog
  [(visible)]="addDialogVisible"
  header="Add new item?"
  [dismissableMask]="true"
  [style]="{width: '50vw'}"
  [breakpoints]="{'992px': '62vw', '510px': '93vw'}" 
  appendTo="body"
  [modal]="true"
  (onHide)="addItemInput.value = ''"
  >
  <label>Item</label>
  <br>
  <input
    #addItemInput
    type="text"
    placeholder="Enter new item"
    autofocus
    pInputText>
  <ng-template pTemplate="footer">
    <button
        pButton
        label="Cancel"
        icon="pi pi-times"
        (click)="closeAddDialog()"
    ></button>
    <button
      pButton
      label="Add"
      icon="pi pi-plus"
      (click)="addItem(addItemInput.value)"
      class="p-button-success"
      ></button>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="editDialogVisible"
  header="Edit item?"
  [dismissableMask]="true"
  [style]="{width: '50vw'}"
  [breakpoints]="{'992px': '62vw', '510px': '93vw'}" 
  appendTo="body"
  [modal]="true"
  (onHide)="activeItem = undefined"
  >
  <label>Item</label>
  <br>
  <input
    *ngIf="activeItem"
    pInputText
    [(ngModel)]="activeItem.item"
    type="text"
    placeholder="Enter new item"
    autofocus
    #editItemInput>
  <br>
  <p-message
    *ngIf="editWarning"
    severity="warn"
    [text]="editWarning"
    styleClass="p-mt-2">
  </p-message>
  <ng-template pTemplate="footer">
    <button
        pButton
        label="Cancel"
        icon="pi pi-times"
        (click)="closeEditDialog()"
    ></button>
    <button
      pButton
      label="Edit"
      icon="pi pi-check"
      class="p-button-success"
      (click)="editItem()"
      ></button>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="deleteDialogVisible"
  header="Delete {{ selectedItems.length }} items?"
  [dismissableMask]="true"
  [style]="{width: '50vw'}"
  [breakpoints]="{'992px': '62vw', '510px': '93vw'}" 
  appendTo="body"
  [modal]="true"
  >
  <ng-template pTemplate="content">
    This will delete the following items:
    <ul>
      <li *ngFor="let item of selectedItems" class="items-to-delete">
        <span class="error-text">{{ item.item }}</span>
      </li>
    </ul>
    <p-message
      *ngIf="deleteWarning"
      severity="warn"
      [text]="deleteWarning">
    </p-message>
  </ng-template>
  <ng-template pTemplate="footer">
    <button
        pButton
        label="No, Exit Window"
        icon="pi pi-times"
        (click)="closeDeleteDialog()"
    ></button>
    <button
      pButton
      label="Delete"
      class="p-button-danger"
      icon="pi pi-trash"
      (click)="deleteSelectedItems()"
      ></button>
  </ng-template>

</p-dialog>
