/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApolloError, NetworkStatus } from '@apollo/client/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar, CalendarOptions, EventContentArg, EventDropArg, EventInput } from '@fullcalendar/core';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { EventResizeDoneArg, ThirdPartyDraggable } from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'; // a plugin!
import { dayJsFullCalendarTimeZonePlugin, dayjs } from '@karve.it/core';
import { AssetService } from '@karve.it/features';
import { ListAssetsOutput } from '@karve.it/interfaces/assets';
import { ZoneDir } from '@karve.it/interfaces/common.gql';
import { QueryRef } from 'apollo-angular';

import { capitalize, cloneDeep, intersection } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { BehaviorSubject, Subject, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

import { AvailabilityGQL, AvailabilityOutput, AvailabilityQuery, AvailabilityQueryVariables, CalendarEventForScheduleFragment, EditCalendarEventGQL, EditCalendarEventInput, FullCalendarEventFragment, ScheduleEventsGQL, ScheduleEventsQuery, ScheduleEventsQueryVariables } from '../../generated/graphql.generated';
import { AppMainComponent } from '../app.main.component';

import { AssetWithConfig } from '../availability/availability.interfaces';
import { CalendarHelperService } from '../calendar-helper.service';
import { BOOK_OFF_EVENT_TYPE, JOB_EVENT_TYPES, MIN_EVENT_RENDER_TIME, ADDITIONAL_EVENT_TYPES as SUPPORTING_EVENT_TYPES, eventTypeInfoMap } from '../global.constants';
import { isFinalizedInvoice } from '../invoices/invoices.utils';
import { BrandingService } from '../services/branding.service';
import { DetailsHelperService } from '../services/details-helper.service';
import { FreyaHelperService } from '../services/freya-helper.service';
import { FreyaNotificationsService } from '../services/freya-notifications.service';
import { FullCalendarHelperService } from '../services/full-calendar-helper.service';
import { ResponsiveHelperService } from '../services/responsive-helper.service';
import { TimezoneHelperService } from '../services/timezone-helper.service';
import { assetTypesDropdown } from '../shared/assets/assets';
import { FCEventHolder, getEventCustomer, getEventCustomerName } from '../shared/event-location/calendarevent.util';
import { dateToDateString, ensureUnixSeconds } from '../time';
import { firstInitialLastName } from '../users/users.utils';
import { DataError, parseGraphqlErrors } from '../utilities/errors.util';
import { WatchQueryHelper } from '../utilities/watchQueryHelper';

import { RESOURCE_AREA_HEADER } from './schedule.constants';

import { sortAssetsForSchedule } from './schedule.util';

export type EventChange = (EventResizeDoneArg | EventDropArg) & {
  notifyAttendees: boolean;
  status: 'Pending' | 'Saving' | 'Saved' | 'Failed';
  hasConflict: boolean;
};

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss', '../availability/calendar.styles.scss']
})
export class SchedulesComponent implements OnInit, OnDestroy, AfterViewInit {

  // Calendar
  @ViewChild('fc', { static: false }) calendarComponent: FullCalendarComponent;
  @ViewChild('scheduleOverlay') scheduleOverlay: OverlayPanel;

  @Output() initialized = new EventEmitter();

  @Input() showHeader = true;
  @Input() showFilters = true;
  @Input() enableAvailability = true; // Whether or not this page wants to get the availability of assets
  @Input() height = '85vh';
  // Change the height based on the number of assets
  @Input() dynamicHeight = true;
  // True if we can/are editing the events on the schedule
  @Input() editModeEnabled = false;

  // The area whose events/availability should be shown, defaults to all
  @Input() area: string;

  //to track weather component is being used in separate Schedule page or
  //in one of the Estimator steps and if it already received jobId
  @Input() scheduleForEstimator = false;
  @Input() currentJobId: null | string = null;

  // If false shows the totals for day, If true shows all events for day
  showMonthEvents = false;

  // This subject is fired when the user changes dates/view, the subscription uses a debounce to filter unecessary backend requests
  scheduleDebounce = new Subject<void>();

  calendar: Calendar;

  calendarTitle$ = new BehaviorSubject('');

  subs = new SubSink();
  timeOuts: NodeJS.Timeout[] = [];

  // Assets
  assets: AssetWithConfig[];
  assetsQueryRef: QueryRef<ListAssetsOutput>;
  noAvailableAssetsWarning = '';

  // Calendar Events
  calendarEvents: CalendarEventForScheduleFragment[] = [];
  calendarEventQueryRef: QueryRef<ScheduleEventsQuery, ScheduleEventsQueryVariables>;
  calendarEventDataErrors: DataError[];

  networkLoading = true;
  scheduleQH: WatchQueryHelper = {
    loading: true,
  };

  // Availability
  availabilities: AvailabilityOutput[];
  availabilityQueryRef: QueryRef<AvailabilityQuery, AvailabilityQueryVariables>;

  startSeconds: number; // The Starting Time for the calendar in unix seconds
  endSeconds: number; // The Ending Time for the calenar in unix seconds

  // Editing
  // List of all changes applied to events
  eventEdits: EventChange[] = [];
  // List of edits that can be redone
  undoneEventEdits: EventChange[] = [];
  // List of only the most recent changes for any given event
  latestEventEdits: EventChange[] = [];
  // Max number of events that can be edited concurrently, WILL BE VARIABLE IN THE FUTURE
  maximumEditableEvents = 5;
  // The events that are conflicting after rechecking the schedule
  eventsWithConflicts: string[] = [];

  notifyMasterToggle = true;

  // DIALOGS
  maxEventsDialogVisible = false;
  discardEditsDialogVisible = false;
  saveEditsDialogVisible = false;

  // SAVING
  scheduleSaving = false;
  scheduleSaved = false;
  checkingForConflicts = false;
  reloadClicked = false;

  // TODO: Break this out into a service value or a seperate file
  calendarOptions: CalendarOptions = {
    headerToolbar: false,
    plugins: [
      resourceTimelinePlugin,
      interactionPlugin,
      dayGridPlugin,
      dayJsFullCalendarTimeZonePlugin
    ],
    timeZone: this.timeZoneHelper.getCurrentTimezone(),
    eventMinWidth: 0.001,
    initialView: 'resourceTimelineDay',
    schedulerLicenseKey: environment.fullCalendarKey,
    eventDisplay: 'block', // Without this dayGrid events don't have backgrounds
    resourceAreaHeaderContent: RESOURCE_AREA_HEADER,
    resourceAreaWidth: this.fcHelper.getResourceAreaWidth(),
    stickyFooterScrollbar: true,
    titleFormat: { // will produce something like "Tuesday, September 18, 2018"
      month: 'long',
      year: 'numeric',
      day: 'numeric',
      weekday: 'short'
    },
    moreLinkDidMount: (arg) => {
      // @ts-ignore
      const date = arg.el.closest('.fc-day').dataset.date;
      const eventsForDay = this.calendarEvents.filter((ev) => dayjs(ev.start * 1000).format('YYYY-MM-DD') === date);

      const content = document.createElement('div');
      content.classList.add('content');

     const assetTypes = this.scheduleFilters.get('assetTypes').value;
     const filteredEventsForDay = eventsForDay.filter(event => !assetTypes.length || event.assets.some((asset)=>assetTypes.includes(asset.type)));
      const totalEl = document.createElement('div');
      if (filteredEventsForDay.length > 0) {
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        totalEl.classList.add('total');
        totalEl.style.color = 'var(--text-color)';
        totalEl.title = `Discounted Subtotal for ${formattedDate}`;
        const totalAmount = (filteredEventsForDay.reduce((total, current) => total + current.discountedSubTotal, 0) / 100).toFixed(2);
        totalEl.innerText = `$${totalAmount}`;
      }

      const eventBadgesEl = document.createElement('div');
      eventBadgesEl.classList.add('badges-container');
      const eventTypesToDisplay = {};

      for (const event of filteredEventsForDay) {
        if (event.type === 'book-off') {
          continue;
        }
        if(eventTypesToDisplay[event.type]){
          eventTypesToDisplay[event.type].push(event);
          continue;
        }
        eventTypesToDisplay[event.type] = [event];
      }
      // Create a badge for every event type present on the day
      for (const eventType of Object.keys(eventTypesToDisplay)){
        const badge = document.createElement('div');
        badge.title = `${eventTypesToDisplay[eventType]?.length} ${capitalize(eventType)} events`;
        badge.innerText = `${eventTypesToDisplay[eventType]?.length}`;

        const backgroundColor = eventTypeInfoMap[eventType]?.backgroundColor || 'primary-color';

        badge.style.backgroundColor = `var(--${backgroundColor})`;
        eventBadgesEl.append(badge);
     }

      content.append(totalEl, eventBadgesEl);

      arg.el.append(content);
    },
    moreLinkClick: (info) => {
      const tzDate = dayjs(dateToDateString(info.date), 'YYYY/MM/DD').add(1, 'day').toDate();
      this.calendar.gotoDate(tzDate);
      this.calendar.changeView('resourceTimelineDay');
    },
    moreLinkContent: (args) => ({domNodes: [document.createElement('div')]}),
    dayMaxEvents: 0,
    views: {
      dayGridMonth: { // name of view
        titleFormat: { month: 'long', year: 'numeric' },
      },
      resourceTimelineWeek: {
        titleFormat: { month: 'long', day: 'numeric', year: 'numeric' }
      }
    },
    resourceLabelContent: (info) => (
      this.fcHelper.generateResourceLabel(info, this.assets as any)
    ),
    resources: [
      {
        id: 'loading',
        title: 'Loading ...',
      }
    ],
    resourceOrder: 'order',
    eventMouseEnter: (info) => {
      if (this.editModeEnabled) { return; }
      const ce: FullCalendarEventFragment = info.event?.extendedProps?.event;

      if (ce?.type === BOOK_OFF_EVENT_TYPE) {
        return;
      }

      return this.fcHelper.createEventHover(info, ce);
    },
    eventMouseLeave: this.clearOverlays,
    eventContent: (event) => {
      const hidden = this.resolveEventHidden(event);

      const calendarEvent = this.calendarEvents.find((ce) => ce.id === event.event._def.groupId);

      let eventEl;

      if (event.event.extendedProps?.type === 'availability') {
        eventEl = this.fcHelper.createFreyaAvailability(hidden);
      } else if (calendarEvent?.type === 'book-off') {
        eventEl = this.fcHelper.createBookedOffEvent(calendarEvent, this.calendar.view.type);
      } else {
        const hasBeenEdited = Boolean(this.editModeEnabled && this.latestEventEdits.find((edit) => edit.event.id === event.event.id));

        let disabled = false;

        if (this.freyaHelper.lockDate > calendarEvent.end) {
          disabled = true;
        }

        if (calendarEvent.invoices?.some(isFinalizedInvoice)) {
          disabled = true;
        }

        // Do not style any events as disabled unless edit mode is enabled
        if (!this.editModeEnabled) {
          disabled = false;
        }

        eventEl = this.fcHelper.createFreyaEvent(event, calendarEvent, this.calendar, hidden, this.editModeEnabled, hasBeenEdited, disabled);
      }

      if (!eventEl) { return; } // Event was removed

      return { domNodes: [eventEl] };
    },
    eventResizableFromStart: false,
    eventDurationEditable: false,
    // Sets the granularity for moving events
    snapDuration: '00:05:00',
    // Sets the frequency of the lines
    slotDuration: '01:00:00',
    slotLabelInterval: '02:00:00',
    // Sets the start and end times for the day
    slotMinTime: '06:00:00',
    slotMaxTime: '22:00:00',
    navLinks: false,
    datesSet: (info) => {
      this.calendarHelper.displayedDateChanged(info.startStr, info.view.type);
      if (!this.eventsInitialized) { return; }
      this.setCalendarTitle();
      this.refetchData();

      const [blank, currentPage] = this.router.url.split('/');

      if (currentPage.includes('schedule')) {
        // Set the date parameter in the url
        this.cacheDateAndView(dayjs(this.calendar.getDate()).format('YYYY-MM-DD'), info.view.type);
      }
    },
    dateClick: (info) => {
      if (info.view.type === 'dayGridMonth') {
        this.calendar.gotoDate(info.dateStr);
        this.calendar.changeView('resourceTimelineDay');
      }
    },
    navLinkWeekClick: (date, event) => {
      event.preventDefault();
    },
    eventClick: (info) => {
      const eventType = info.event?.extendedProps?.type;

      if (eventType === 'availability') {
        // DISABLED, reworked version will be implemented later
        // this.selectAvailability(info.jsEvent.offsetX, info.el.offsetWidth, info.event._def.resourceIds[0], info.event.extendedProps.availability);
        return; // No event to show for availability
      }

      let detailsType: string;
      let detailsItem: any;

      if (info.event.extendedProps.eventType === BOOK_OFF_EVENT_TYPE) {
        detailsType = 'book-off-event';
        const event = info.event?.extendedProps?.event;
        if (!event) { return; }
        detailsItem = event;
      } else if (['travel-time', 'calendar-event'].includes(eventType)) {
        detailsType = 'calendar-event';
        const event = info.event?.extendedProps?.event;
        if (!event) { return; }
        detailsItem = event;
      }

      if (detailsType && detailsItem) {
        // If showing event details will cause right panel to open
        // clear overlays as otherwise mouse might leave event without firing eventMouseLeave
        if (!this.detailsHelper.rightPanelOpen) {
          this.clearOverlays();
        };
        this.detailsHelper.open(detailsType, {id: detailsItem.id});
      }


    },
    events: [],
    eventOrder: 'start',
    eventResize: (info: EventResizeDoneArg) => {
      // Pending events do not send notifications
      const isPending = info.event?._def.extendedProps?.event?.status === 'pending';

      this.logNewEdit({ ...info, notifyAttendees: !isPending, status: 'Pending', hasConflict: false });
    },
    eventDrop: (info: EventDropArg) => {
      // Pending events do not send notifications
      const isPending = info.event?._def.extendedProps?.event?.status === 'pending';

      this.logNewEdit({ ...info, notifyAttendees: !isPending, status: 'Pending', hasConflict: false });
    },
    // Do not automatically scroll the calendar when dragging an event on mobile (makes dragging awkward)
    dragScroll: !this.responsiveHelper.isSmallScreen,
    longPressDelay: this.responsiveHelper.isSmallScreen ? this.responsiveHelper.dragDelay : undefined
  };

  // True if the events have been loaded
  eventsInitialized = false;

  // Constants
  assetTypes = assetTypesDropdown;
  systemEventTypes = [...JOB_EVENT_TYPES];
  supportingEventTypes = SUPPORTING_EVENT_TYPES;

  // Filters
  scheduleFilters: UntypedFormGroup = new UntypedFormGroup({
    assetTypes: new UntypedFormControl([]),
    eventTypes: new UntypedFormControl([]),
    products: new UntypedFormControl([]),
    // supportingEventTypes: new FormControl([]),
  });

  filtersCollapsed = true;

  constructor(
    // Angular
    private router: Router,
    private route: ActivatedRoute,
    // Helpers
    public responsiveHelper: ResponsiveHelperService,
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private freyaHelper: FreyaHelperService,
    private fcHelper: FullCalendarHelperService,
    private confirmationService: ConfirmationService,
    private calendarHelper: CalendarHelperService,
    private brandingService: BrandingService,
    private timeZoneHelper: TimezoneHelperService,
    // Components
    private appMain: AppMainComponent,
    // GQL
    private assetService: AssetService,
    private editCalendarEventGQL: EditCalendarEventGQL,
    private scheduleEventsGQL: ScheduleEventsGQL,
    private availabilityGQL: AvailabilityGQL,
  ) { }

  @HostListener('document:keydown.control.z')
  onUndo() {
    if (!this.editModeEnabled) { return; }

    this.undoLastEdit();
  };

  @HostListener('document:keydown.control.y')
  onRedo() {
    if (!this.editModeEnabled) { return; }

    this.redoEdit();
  };

  ngOnInit(): void {
    this.subs.sink = this.detailsHelper.getObjectUpdates('Events').subscribe(() => {
      this.refetchData();
    });

    this.subs.sink = this.calendarHelper.refreshScheduleData.subscribe(() => {
      this.reloadClicked = true;
      this.refetchData();
    });

    // Set the calenar date from other components
    this.subs.sink = this.fcHelper.changeCalendarDate.subscribe((date) => {
      if (!this.calendar) { return; }
      const tzDate = dayjs(date).format('YYYY-MM-DD');
      this.calendar.gotoDate(tzDate);
    });

    // This ensures that the calendar is rerendered if the container size changes to match that size.
    this.subs.sink = this.freyaHelper.containerSizeChanged.subscribe(() => {
      this.renderAfterDelay();
    });

    // Update the calendar to reflect any changes based on authentication status
    this.subs.sink = this.brandingService.currentZone().subscribe(() => {
      // skip initial behavioursubject callback
      if (!this.calendar) { return; }
      this.calendar.removeAllEvents();
      this.scheduleQH.loading = true;
      this.calendarEvents = [];
      this.refetchData();
    });

    this.subs.sink = this.timeZoneHelper.timezoneChanged.subscribe(() => {
      this.calendar.setOption('timeZone', this.timeZoneHelper.getCurrentTimezone());
    });

    // Refetch after 250ms without the user changing the date/view
    this.subs.sink = this.scheduleDebounce.pipe(debounceTime(250)).subscribe((res) => {

      //when click Create New Job in Estimator we run this queries right away and then rerun after job created
      //it doesn't make sense to run them when job doesn't exist yet, so here we add additional check
      if(this.scheduleForEstimator && this.currentJobId) {
        this.retrieveAvailability();
        this.retrieveEvents();
      } else if (!this.scheduleForEstimator) {
        this.retrieveAvailability();
        this.retrieveEvents();
      }
    });
  }

  ngAfterViewInit() {
    this.calendar = this.calendarComponent.getApi();
    this.setCalendarTitle();

    // Try to pull the date out of the query params, if none try to restore last date
    this.subs.sink = this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (params.date) {
        this.calendar.gotoDate(params.date);
      } else {
        const cachedDate = sessionStorage.getItem(environment.lskeys.scheduleDate);
        if (cachedDate) {
          this.calendar.gotoDate(cachedDate);
        }
      }

      if (params.view) {
        this.changeView(params.view);
      } else {
        const cachedView = sessionStorage.getItem(environment.lskeys.scheduleView);
        if (cachedView) {
          this.changeView(cachedView);
        }
      }

      this.setCalendarTitle();

      // We may be setting the title after change detection has completed,
      // call this to prevent an `ExpressionCheckedAfterItHasBeenCheckedError`
      this.appMain.cd.detectChanges();
      this.renderAfterDelay();
    });

    this.retrieveAssets();

    // Fire event when the component is initialized, timeout allows references in parent compoennts to update
    const timeout = setTimeout(() => {
      this.initialized.emit(true);
      this.setCalendarHeight(this.height);
    }, 25);

    this.timeOuts.push(timeout);

    const container = document.getElementById('droppable-area');

    new ThirdPartyDraggable(container);
  }

  ngOnDestroy() {
    this.clearOverlays();
    this.timeOuts.forEach((t) => clearTimeout(t));
    this.subs.unsubscribe();
    this.freyaHelper.scheduleEditModeEnabled.next(false);
  }

  // CALENDAR HELPERS

  setCalendarDate(date: Date) {
    this.fcHelper.changeCalendarDate.next(date);
  }

  isMonthView() {
    return this.calendar.view.type === 'dayGridMonth';
  }

  /**
   * Changes the view based on the view type, throws a warning in edit mode
   *
   * @param viewType The type of you you want to render
   */
  changeView(viewType: string) {
    this.calendar.changeView(viewType);
    this.setCalendarHeight();
  }

  changeDate(dateType: 'prev' | 'next' | 'today') {
    if (dateType === 'prev') {
      this.calendar.prev();
    }

    if (dateType === 'next') {
      this.calendar.next();
    }

    if (dateType === 'today') {
      this.calendar.today();
    }
  }

  setCalendarHeight(height?: string) {
    if (this.calendar.view.type === 'dayGridMonth') {
      height = 'auto';
    }


    if (!height) { return; }
    this.calendar.setOption('contentHeight', height);

  }

  clearOverlays() {
    const overlays = document.querySelectorAll('.freya-fc-hover');

    for (let i = 0; i < overlays.length; i++) {
      overlays.item(i).remove();
    }
  }

  setCalendarTitle() {
    this.calendarTitle$.next(this.calendar.getCurrentData().viewTitle);
  }

  /**
   * Determines if an event should be shown based on the filters
   *
   * @param event Full calendar event to render
   * @returns True if the event should be hidden, false if it should be shown
   */
  resolveEventHidden(event: EventContentArg): boolean {
    const props = event.event.extendedProps || {};

    const filters = this.scheduleFilters.value;

    if (filters.eventTypes?.length && (props.eventType && !filters.eventTypes?.includes(props.eventType))) {
      return true;
    }

    if (filters.supportingEventTypes?.length && (props.type && !filters.supportingEventTypes?.includes(props.type))) {
      return true;
    }

    if (filters?.products?.length && props.event && !props.event.charges.find((c) => filters?.products.find((fp) => fp.id === c?.product?.id))) {
      return true;
    }

    if (filters?.assetTypes?.length && props.event?.assets?.find((a) => !filters.assetTypes.includes(a.type))) {
      return true;
    }

    return false;
  }

  /**
   * Caches the current date of the schedule in session storage and in the url
   *
   * @param date The date being cached, format: 'YYYY-MM-DD'
   */
  cacheDateAndView(date: string, view: string) {
    this.router.navigate(['/schedule'], {
      queryParamsHandling: 'merge',
      queryParams: {
        date,
        view,
      },
      replaceUrl: true,
    });

    sessionStorage.setItem(environment.lskeys.scheduleDate, date);
    sessionStorage.setItem(environment.lskeys.scheduleView, view);
  }

  /**
   * Remove events of a certain type from the calendar
   *
   * @param eventTypes The types to remove
   */
  removeEventsOfTypes(eventTypes: string[]) {
    const eventsToRemove = this.calendar.getEvents().filter((e) => eventTypes.includes(e?.extendedProps?.type));

    for (const event of eventsToRemove) {
      event.remove();
    }

  }

  // Calendar is drawn not dynamic, as such for events not watched by FC (container size) we must call this.
  renderAfterDelay() {
    this.calendar.setOption('resourceAreaWidth', this.fcHelper.getResourceAreaWidth());
    const timeout = setTimeout(() => { // Timeout ensures that the DOM has been updated before we try to redraw
      this.calendar.render();

      // This logic handles setting the top property for the times to never overlap with the dates
      const sourceElement = document.getElementsByClassName('calendar-header-wrapper').item(0);
      const targetElement = document.getElementsByClassName('fc-scrollgrid').item(0).getElementsByTagName('thead')[0];

      // Get the height of the source element
      const sourceElementHeight = sourceElement.clientHeight;

      // Set the top property of the target element based on the height of the source element
      targetElement.style.top = sourceElementHeight + 'px';
    }, 225);

    this.timeOuts.push(timeout);
  }

  // QUERY HELPERS
  getScheduleVariables(): { availabilty: AvailabilityQueryVariables; events: ScheduleEventsQueryVariables } {
    const start = dayjs(this.calendar.view.currentStart || new Date()).startOf('day');
    const end = dayjs(this.calendar.view.currentEnd || new Date()).endOf('day');

    // add / subtract one period so previous/next loads quickly
    // TODO: REDO this logic to run as supporting requests
    const duration = 0; // end.diff(start, 'day');
    const min = dayjs(start).startOf('day').subtract(duration, 'day').unix();
    const max = dayjs(end).endOf('day').add(duration, 'day').unix();

    return {
      availabilty: {
        objectIds: this.getDisplayedAssets().map((a) => a.id),
        startDate: start.format('YYYY-MM-DD'),
        endDate: end.format('YYYY-MM-DD'),
        zone: this.area || undefined,
      },
      events: {
        filter: {
          min,
          max,
        },
        limit: 2000,
      }
    };
  }

  // Updates the event query variables to reflect the calendar date
  async refetchData() {
    this.scheduleQH.loading = true;
    this.scheduleDebounce.next();
  }

  // ASSETS
  retrieveAssets() {
    if (this.assetsQueryRef) { return; }

    this.assetsQueryRef = this.assetService.watchAssets(
      this.generateAssetInput(),
      { zones: true },
      'cache-and-network' as any,
    );

    this.subs.sink = this.assetsQueryRef.valueChanges.subscribe((res) => {
      const assets = cloneDeep(res?.data?.assets?.assets);
      this.assets = assets || [];

      this.calendar.batchRendering(() => {
        this.convertAssetsIntoResources();
      });

      if (assets && res.networkStatus === 7) {
        this.refetchData();
      }

      if (!this.assets.length) {
        this.noAvailableAssetsWarning = 'There are no available assets in the area where current job is being placed.';
      }
    });
  }

  generateAssetInput() {
    return {
      filter: {
        types: this.scheduleFilters.get('assetTypes').value.length ? this.scheduleFilters.get('assetTypes').value : undefined,
        zoneDir: ZoneDir.any,
      },
      limit: -1
    };
  }

  updateAssets() {
    // If the assets have not been set yet return
    if (!this.assets) { return; }
    this.assetsQueryRef.refetch(this.generateAssetInput());
    //console.log(this.assets);
    //console.log(this.generateAssetInput);
  }

  getDisplayedAssets() {
    let assetsToDisplay = [];
    if (this.scheduleFilters.get('assetTypes').value?.length) {
      assetsToDisplay = this.assets.filter((a) => this.scheduleFilters.get('assetTypes').value.includes(a.type));
    } else {
      assetsToDisplay = this.assets;
    }

    return assetsToDisplay;
  }

  // EVENTS
  retrieveEvents() {
    const { events: variables } = this.getScheduleVariables();
    if (this.calendarEventQueryRef) {
      this.calendarEventQueryRef.refetch(variables);
      return;
    }
    this.calendarEventQueryRef = this.scheduleEventsGQL.watch(variables, {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    });

    this.subs.sink = this.calendarEventQueryRef.valueChanges.subscribe((res) => {
      const events = cloneDeep(res.data?.calendarEvents?.events);
      this.scheduleQH.loading = cloneDeep(res.loading);

      // If our cached result equals our current result then skip
      // Apollo wants to send the result of the previous query when we
      // call refetch for some reason...
      if (
        events?.length &&
        events === this.calendarEvents
        && res.networkStatus === NetworkStatus.setVariables
      ) {
        return;
      };

      if (this.reloadClicked && !this.scheduleQH.loading) {
        this.reloadClicked = false;
        this.localNotify.success('Schedule Reloaded');
      }

      // Handle Errors
      if (!res.loading){
        this.calendarEventDataErrors = parseGraphqlErrors(res.errors, 'calendarEvents');
        this.calendarEvents.forEach((event, index) => {
          const errors = this.calendarEventDataErrors.filter((err) => err.listIndex === index);
          if (errors?.length) {
            this.localNotify.error(
              `Error Occurred on: ${capitalize(event.type)}-${firstInitialLastName(getEventCustomer(event as any)?.user)}`,
              errors.map((err) => err.property).join(', '),
              10000
            );
          }
        });
      }
      this.calendarEvents = cloneDeep(events) || [];

      this.networkLoading = res.loading;

      this.calendar.batchRendering(() => {
        // Prevent Duplicates
        this.removeEventsOfTypes(['calendar-event']);

        if (this.calendarEvents) {
          this.eventsInitialized = true;
          this.setCalendarTitle();
          this.convertCalendarEventsIntoEvents(this.calendarEvents);
        }
      });
    });
  }

  // AVAILABILITY
  retrieveAvailability() {
    const { availabilty: variables } = this.getScheduleVariables();

    if (this.isMonthView()) { return; }

    if (this.availabilityQueryRef) {
      this.availabilityQueryRef.refetch(variables);
      return;
    }

    this.availabilityQueryRef = this.availabilityGQL.watch(variables, {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all'
    });

    this.subs.sink = this.availabilityQueryRef.valueChanges.subscribe((res) => {
      this.availabilities = res?.data?.availabilities || [];

      this.calendar.batchRendering(() => {
        this.removeEventsOfTypes(['availability']);
        if (this.availabilities && this.enableAvailability) {
          this.convertAvailabilityIntoEvents();
        } else {
          this.availabilities = [];
        }
      });

    });
  }

  // PARSE OBJECTS TO Full Calendar

  convertCalendarEventsIntoEvents(events: CalendarEventForScheduleFragment[]) {
    this.eventsWithConflicts = [];

    // this.removeEventsOfTypes(['event', 'travel-time']);

    const eventsAsHolders: FCEventHolder[] = events.map((e) => (
      {
        id: e.id,
        assetIds: e.assets.map((a) => a.id),
        start: e.start,
        end: e.end,
      }
    ));

    const editsAsHolders: FCEventHolder[] = this.latestEventEdits.map((edit) => (
      {
        id: edit.event.id,
        assetIds: edit.event.getResources().map((r) => r.id),
        start: ensureUnixSeconds(edit.event.start.getTime()),
        end: ensureUnixSeconds(edit.event.end.getTime()),
      }
    ));

    for (const ev of events) {
      let start = ev.start;
      let end = ev.end;

      // This may contain the id of deleted assets...
      const allAssetIds = ev.assets.map((a) => a.id);

      // ...therefore, make sure allAssetIds contains the id of at least one existing asset...
      const hasExistingAssets = ev?.assets?.length
        && intersection(allAssetIds, this.assets.map((a) => a.id)).length;

      // ...otherwise assign 'none' so the event is assigned to the 'Unassigned' resource
      let assetIds = hasExistingAssets ? allAssetIds : ['none'];

      // If we have edits then update the matching events and check for conflicst
      if (this.latestEventEdits?.length) {
        // Handle events that have been edited on the schedule
        const editedEvent = editsAsHolders.find((edit) => edit.id === ev.id);

        if (editedEvent) {
          // Render the edited start instead of the actual start
          start = editedEvent.start;
          end = editedEvent.end;
          assetIds = editedEvent.assetIds;

          // Check for conflicts
          // @ts-ignore
          if (this.fcHelper.conflictsWithEvent(eventsAsHolders, { id: ev.id, start, end, assetIds }, editsAsHolders)) {
            this.eventsWithConflicts.push(ev.id);
            this.latestEventEdits.find((edit) => edit.event.id === ev.id).hasConflict = true;
          } else {
            this.latestEventEdits.find((edit) => edit.event.id === ev.id).hasConflict = false;
          }
        }
      }

      let endForRendering = end;

      if ((end - start) < MIN_EVENT_RENDER_TIME) {
        endForRendering += MIN_EVENT_RENDER_TIME - (end - start);
      }

      const {
        backgroundColor,
        textColor,
      } = this.fcHelper.determineColorsFromEvent(ev);

      let editable = this.editModeEnabled;

      if (this.freyaHelper.lockDate > ev.end) {
        editable = false;
      }

      if (ev.invoices?.some(isFinalizedInvoice)) {
        editable = false;
      }

      // Create Actual Event
      const event: EventInput = {
        title: ev.title,
        groupId: ev.id,
        start: start * 1000,
        end: endForRendering * 1000,
        id: ev.id,
        extendedProps: {
          type: 'calendar-event',
          eventId: ev.id,
          event: ev,
          eventType: ev.type,
        },
        resourceIds: assetIds,
        color: backgroundColor,
        textColor,
        editable,
        resourceEditable: editable,
      };
      this.calendar.addEvent(event);

    }

    // Add any events that were edited here but didn't originate here
    for (const edit of this.latestEventEdits) {
      // Convert the dates to the correct timezone
      const calendarDateInTZ = dayjs(this.calendar.getDate());

      const {
        backgroundColor,
        textColor,
      } = this.fcHelper.determineColorsFromEvent(edit.event.extendedProps?.event);

      if (calendarDateInTZ.date() === edit.event.start.getDate() && !this.calendar.getEventById(edit.event.id)) {
        const event = {
          title: edit.event.title,
          groupId: edit.event.id,
          start: ensureUnixSeconds(edit.event.start.getTime() / 1000) * 1000,
          end: ensureUnixSeconds(edit.event.end.getTime() / 1000) * 1000,
          id: edit.event.id,
          extendedProps: {
            type: 'calendar-event',
            eventId: edit.event.id,
            event: edit.event.extendedProps?.event,
            eventType: edit.event.extendedProps?.type,
            loaded: false,
          },
          resourceIds: edit.event.extendedProps?.event.assets.map((r) => r.id),
          color: backgroundColor,
          textColor,
        };

        this.calendar.addEvent(event);
      }
    }


    // After all events have been looped the conflicts list will be up to date
    this.checkingForConflicts = false;
    this.renderAfterDelay();
  }

  // Turn System Assets into Calendar Resources
  convertAssetsIntoResources() {
    this.calendar.refetchResources();
    if (!this.assets) { return; }

    let assetsToDisplay = this.getDisplayedAssets();

    if (this.scheduleFilters.get('assetTypes').value?.length) {
      assetsToDisplay = this.assets.filter((a) => this.scheduleFilters.get('assetTypes').value.includes(a.type));
    } else {
      assetsToDisplay = this.assets;
    }

    // Sort asset array by type, then name
    sortAssetsForSchedule(this.assets);

    let order = 0;


    this.calendar.addResource({ // Add a resource for events that don't have any assets
      id: 'none',
      title: 'Unassigned',
      eventColor: 'red',
      type: 'Unassigned',
      order,
    });
    order++;

    for (const asset of assetsToDisplay) {
      this.calendar.addResource({
        id: asset.id,
        title: `${asset.name} (${asset.type})`,
        eventColor: asset.zones[0]?.color,
        extendedProps: {
          id: asset.id,
          type: asset.type,
          name: asset.name,
        },
        order,
      });

      // increment order
      order++;
    }

    const loadingResource = this.calendar.getResourceById('loading');

    loadingResource?.remove();

    if (this.dynamicHeight) {
      this.setCalendarHeight(`${80 + ((this.calendar.getResources().length) * 52)}px`);
      // this.calendar.setOption('contentHeight', ``);
    }
  }

  convertAvailabilityIntoEvents() {
    for (const object of this.availabilities) {

      for (const day of object.dayAvailability) {

        // If the asset has no availability, we need to put a dummy availability to show the inverse of
        if (!day.availability?.length) {
          this.calendar.addEvent({
            title: 'Unavailable',
            groupId: `${object.objectId}-availability`,
            start: `${day.date}T00:00:00`,
            end: `${day.date}T00:00:1`,
            display: 'inverse-background',
            // The Asset that this availability belongs to
            resourceIds: [object.objectId],
            color: '#666666',
            extendedProps: {
              type: 'availability',
            },
          });

          continue;
        }

        for (const avail of day.availability) {
          const start = avail.start * 1000;
          const end = avail.end * 1000;

          this.calendar.addEvent({
            title: 'Unavailable',
            groupId: `${object.objectId}-availability`,
            start,
            end,
            display: 'inverse-background',
            // The Asset that this availability belongs to
            resourceIds: [object.objectId],
            color: '#666666',
            extendedProps: {
              type: 'availability',
            },
          });
        }
      }
    }
  }

  // NOT CURRENTLY IN USE, REVISED VERSION TODO
  // selectAvailability(offsetX: number, width: number, resourceId: string, availability: Availability[]) {
  //   const numberOfAvails = availability.length;

  //   // Convert the Conainer width/offset to a position in the array
  //   let index = Math.round(offsetX / width * numberOfAvails);
  //   if (index >= numberOfAvails) {
  //     index -= 1;
  //   }

  //   // Notify any components listening that we clicked on an availability
  //   this.fcHelper.availabilityClicked.next({
  //     assetId: resourceId,
  //     date: this.calendar.getDate(),
  //     time: availability[index].start,
  //   } as BookingInformation);
  // }


  // EDITING
  /**
   * Enables edited on the FullCalendar scheduler and sets the values used by other functions to modify functionality while in edit mode.
   *
   * @param enabled Sets values to match, If true we are enabling, if false we are disabling
   */
  enableEditMode(enabled = true) {
    // If there is no change to editModeEnabled
    if (this.editModeEnabled === enabled) { return; }

    this.eventEdits = [];
    this.latestEventEdits = [];

    this.editModeEnabled = enabled;

    this.freyaHelper.scheduleEditModeEnabled.next(enabled);

    if (!enabled) {
      this.discardEditsDialogVisible = false;
      this.saveEditsDialogVisible = false;
      this.maxEventsDialogVisible = false;
    }

    // Reload events
    this.calendar.batchRendering(() => {
      this.calendar.setOption('editable', enabled);
      this.calendar.setOption('droppable', enabled);
      this.calendar.setOption('eventDurationEditable', enabled);

      this.calendar.removeAllEvents();
      this.convertAvailabilityIntoEvents();
      this.convertCalendarEventsIntoEvents(this.calendarEvents);
    });
  }

  /**
   * Tracks a change that the user makes while editing
   */
  logNewEdit(edit: EventChange) {
    if (this.latestEventEdits?.length >= this.maximumEditableEvents && !this.latestEventEdits.find((le) => le.event.id === edit.event.id)) {
      edit.revert();
      this.maxEventsDialogVisible = true;
    }
    // Redo history is removed after adding a new change
    this.undoneEventEdits = [];

    // Push the latest edit
    this.eventEdits.push(edit);
    this.setLatestEventEdits();
  }

  /**
   * Undoes the last edit in the list
   */
  undoLastEdit() {
    if (!this.eventEdits.length) { return; }

    // Revert the edit
    const lastEdit = this.eventEdits[this.eventEdits.length - 1];

    const fullCalendarEvent = this.calendar.getEventById(lastEdit.event.id);

    fullCalendarEvent.setStart(lastEdit.oldEvent.start);
    fullCalendarEvent.setEnd(lastEdit.oldEvent.end);
    fullCalendarEvent.setResources(lastEdit.oldEvent.getResources());

    // Remove the edit from the list
    this.eventEdits.splice(this.eventEdits.length - 1, 1);

    // Add the edit to list of undone edits
    this.undoneEventEdits.push(lastEdit);

    this.setLatestEventEdits();
  }

  /**
   * Redo the latest edit that was undone
   */
  redoEdit() {
    if (!this.undoneEventEdits?.length) { return; }

    const editToRedo = this.undoneEventEdits[this.undoneEventEdits.length - 1];

    const fullCalendarEvent = this.calendar.getEventById(editToRedo.event.id);

    fullCalendarEvent.setStart(editToRedo.event.start);
    fullCalendarEvent.setEnd(editToRedo.event.end);
    fullCalendarEvent.setResources(editToRedo.event.getResources());

    // Remove the edit from the list
    this.undoneEventEdits.splice(this.undoneEventEdits.length - 1, 1);

    // Relog this edit without clearing the edit list
    this.eventEdits.push(editToRedo);
    this.setLatestEventEdits();
  }

  /**
   * Updates the list of the most recent event
   */
  setLatestEventEdits() {
    const finalEdits: EventChange[] = [];
    // Loop through the list of changes backwards to get the most recent change first
    for (let index = this.eventEdits.length - 1; index >= 0; index--) {
      // We already have a more up to date version of this event
      if (finalEdits.find((fe) => fe.event.id === this.eventEdits[index].event.id)) {
        continue;
      }

      finalEdits.push(this.eventEdits[index]);
    }

    this.latestEventEdits = finalEdits;

    // Updates the calendar so newly edited items have and outline
    this.calendar.render();
  }

  showDiscardDialog() {
    // Just disable edit mode if there are no outstanding changes
    if (!this.latestEventEdits?.filter((e) => e.status !== 'Saved')?.length) {
      this.enableEditMode(false);
      return;
    }
    this.discardEditsDialogVisible = true;

    // Hide the other dialogs
    this.maxEventsDialogVisible = false;
    this.saveEditsDialogVisible = false;
  }

  discardEdit(edit: EventChange) {
    this.latestEventEdits = this.latestEventEdits.filter((e) => e.event.id !== edit.event.id);
    this.eventEdits = this.eventEdits.filter((e) => e.event.id !== edit.event.id);
  }

  /**
   * Discard any existing edits and exit edit mode
   */
  discardEdits() {
    this.enableEditMode(false);
    this.refetchData();
    this.discardEditsDialogVisible = false;
    this.saveEditsDialogVisible = false;
    this.localNotify.success('Event edits discarded');
  }

  showSaveDialog() {
    this.saveEditsDialogVisible = true;
    this.checkingForConflicts = true;
    this.scheduleSaved = false;
    this.refetchData();

    // Hide the other dialogs
    this.maxEventsDialogVisible = false;
    this.discardEditsDialogVisible = false;
  }

  /**
   * Save if there are no conflicts, prompt the user if there are
   */
  tryToSave() {
    if (!this.latestEventEdits.filter((e) => e.hasConflict)?.length) {
      this.saveAllEdits();
      return;
    }

    this.confirmationService.confirm({
      header: 'Conflicts found',
      message: 'One or more of the events you are trying to save have a conflict, are you sure you want to continue?',
      acceptLabel: 'Continue',
      accept: () => {
        this.saveAllEdits();
      }
    });
  }

  /**
   * Save a single edit, return the observable if batching otherwise return nothing
   *
   * @param edit The edit to save
   * @param batch If true we are batching multiple edits so we will return the observable instead of subscribing
   * @returns Observable of the edit query or undefined
   */
  saveEdit(edit: EventChange, batch = false) {
    const { added, removed } = this.freyaHelper.getAddedAndRemoved(
      edit.event?.extendedProps?.event.assets.map((a) => a.id),
      edit.event.getResources().map((r) => r.id),
      false
    );

    const editInput: EditCalendarEventInput = {};

    let changed = false;
    if (edit.event.start.getTime() !== edit.oldEvent.start.getTime()) {
      editInput.start = ensureUnixSeconds(edit.event.start.getTime());
      changed = true;
    }

    if (edit.event.end.getTime() !== edit.oldEvent.end.getTime()) {
      editInput.end = ensureUnixSeconds(edit.event.end.getTime());
      changed = true;
    }

    if (added?.length) {
      editInput.setAssets = editInput.setAssets || {};
      editInput.setAssets.addAssets = added;
      changed = true;
    }

    if (removed?.length) {
      editInput.setAssets = editInput.setAssets || {};
      editInput.setAssets.removeAssets = removed;
      changed = true;
    }

    edit.status = 'Saving';

    const eventTitle = `${capitalize(edit.event.extendedProps?.event.type)} for ${getEventCustomerName(edit.event.extendedProps?.event)}`;
    const editQuery = this.editCalendarEventGQL.mutate({ edit: editInput, ids: [edit.event.id], notifyAttendees: edit.notifyAttendees });

    if (batch) {
      return editQuery;
    }

    editQuery.subscribe((res) => {
      edit.status = 'Saved';
      this.localNotify.success(`${eventTitle} saved`);
      this.setScheduleSaved();
    }, (err: ApolloError) => {
      edit.status = 'Failed';
      this.localNotify.apolloError(`${eventTitle} failed to save`, err);
    });
  }

  /**
   * Loops through and saves all edits that have not already been saved
   */
  saveAllEdits() {
    this.scheduleSaving = true;
    // Find the most up to date change for each event and then save that version
    const editQueries = {};

    // Loop through and save the final version of each event
    for (const edit of this.latestEventEdits.filter((e) => e.status !== 'Saved')) {
      const editQuery = this.saveEdit(edit, true);

      editQueries[edit.event.id] = editQuery.pipe(catchError(error => {
        this.localNotify.apolloError('Failed to save changes', error);
        return of(error);
      }));
    }

    // If there were no changes to save
    if (!Object.keys(editQueries)?.length) {
      this.scheduleSaving = false;
      return;
    }

    this.subs.sink = forkJoin(editQueries).subscribe((res) => {
      for (const [key, value] of Object.entries(res)) {
        // Find the edit that matches the mutate result
        const matchingEdit = this.latestEventEdits.find((edit) => edit.event.id === key);

        // Check if the query had an error
        // @ts-ignore
        if (value?.message) {
          matchingEdit.status = 'Failed';
          continue;
        }

        matchingEdit.status = 'Saved';
      }

      this.scheduleSaving = false;

      this.setScheduleSaved();
    });
  }

  /**
   * If there are are no events that are still in need of saving set saved to true
   */
  setScheduleSaved() {
    if (!this.latestEventEdits.filter((edit) => edit.status !== 'Saved')?.length) {
      this.scheduleSaved = true;
    }
  }

  /**
   * Toggles all notify attendees checkboxes to match the master toggle
   */
  toggleAllNotifyAttendees(event) {
    for (const edit of this.latestEventEdits) {
      if (edit.event?.extendedProps?.event?.status === 'pending') { continue; }
      edit.notifyAttendees = event.checked;
    }
  }

  /**
   * When one of the notify attendees checkboxes are toggled, check if the master state needs to be updated
   */
  checkNotifyAttendees() {
    const listOfChecked = this.latestEventEdits.filter((edit) => edit.notifyAttendees === true);

    if (listOfChecked?.length === this.latestEventEdits?.length) {
      this.notifyMasterToggle = true;
    }

    if (listOfChecked?.length === 0) {
      this.notifyMasterToggle = false;
    }
  }

  /**
   * When the save dialog is hidden
   */
  backToEditing() {
    const editsBeforeFiltering = this.latestEventEdits?.length;

    this.latestEventEdits = this.latestEventEdits.filter((e) => e.status !== 'Saved');

    // Filter out the edits for events that have been saved and removed from the list
    if (editsBeforeFiltering !== this.latestEventEdits?.length) {
      this.eventEdits = this.eventEdits.filter((e) => this.latestEventEdits.find((le) => le.event.id === e.event.id));
      this.refetchData();
    }

    this.saveEditsDialogVisible = false;
  }

  setMaxEvents(showAllEvents){
    if(showAllEvents){
      this.calendar.setOption('dayMaxEvents', false);
    } else {
      this.calendar.setOption('dayMaxEvents', 0);
    }
  }
}

