import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { SubSink } from 'subsink';

import { BaseDynamicReportFragment, BaseReportTypeFragment, BaseZoneFragment, CreateScheduledReportGQL, ListRolesGQL, RoleWithZonesFragment, ScheduledReportEssentialsFragment, UpdateScheduledReportsGQL, ZoneDir } from '../../../generated/graphql.generated';
import { PeriodService } from '../../dashboard/period.service';
import { regex } from '../../global.constants';
import { BrandingService } from '../../services/branding.service';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { TimezoneHelperService } from '../../services/timezone-helper.service';
import { MutateObjectComponent, MutateObjectElement } from '../../shared/mutate-object/mutate-object.component';
import { attributeToggle } from '../../utilities/attributes.util';
import { cronValidator, periodToString } from '../report.utils';
import { AdvancedPeriodOptions } from '../reports.constants';

@Component({
  selector: 'app-mutate-scheduled-report',
  templateUrl: './mutate-scheduled-report.component.html',
  styleUrls: ['./mutate-scheduled-report.component.scss']
})
export class MutateScheduledReportComponent implements OnInit, OnDestroy {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  @Input() mutateType: 'create' = 'create' as const;
  @Input() scheduledReport: ScheduledReportEssentialsFragment;
  @Input() reportType: BaseReportTypeFragment;
  @Input() dynamicReport: BaseDynamicReportFragment;
  @Input() navigate = true;

  // Template Refs
  @ViewChild('period') periodRef: TemplateRef<any>;
  @ViewChild('time') timeRef: TemplateRef<any>;
  @ViewChild('name') nameRef: TemplateRef<any>;
  @ViewChild('notifications') notificationsRef: TemplateRef<any>;

  variables: any = {};
  form = new UntypedFormGroup({
    name: new UntypedFormControl('', [ Validators.maxLength(200) ]),

    notifyRoles: new UntypedFormControl([], []),
    notifyUsers: new UntypedFormControl([], []),
    webhooks: new UntypedFormControl([], []),

    period: new UntypedFormControl('Since Last Run', []),
    variables: new UntypedFormControl({}, []),

    start: new UntypedFormControl(undefined, []),
    end: new UntypedFormControl(undefined, []),

    saveData: new UntypedFormControl(true, []),
    time: new UntypedFormControl('0 1 * * *', [ cronValidator ]),
    dontSendIfEmpty: new UntypedFormControl(true, []),

    // period: new FormControl('Today', []),
    zone: new UntypedFormControl('No Zone', []),
  });

  initialFormState = cloneDeep(this.form.value);
  steps: MutateObjectElement[];

  currentZone: BaseZoneFragment;

  periods = this.periodService.getPeriodNameValues({
    includeCustom: false,
    includeSinceLastRun: true,
    defaultPeriod: this.initialFormState.period,
  });

  subs = new SubSink();

  listRolesWatchQuery: ReturnType<typeof this.listRolesGQL.watch>;
  roles: RoleWithZonesFragment[] = [];

  parsedCronTime = 'Unknown';

  reportName: string;
  reportDescription: string;

  constructor(
    private createScheduledReportGQL: CreateScheduledReportGQL,
    private updateScheduledReportGQL: UpdateScheduledReportsGQL,
    public freyaHelper: FreyaHelperService,
    private brandingService: BrandingService,
    private localNotify: FreyaNotificationsService,
    private periodService: PeriodService,
    private detailsHelper: DetailsHelperService,
    private listRolesGQL: ListRolesGQL,
    private timeZoneHelper: TimezoneHelperService,
  ) { }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subs.unsubscribe();
    delete this.listRolesWatchQuery;
  }

  loadRoles() {
    if (this.listRolesWatchQuery) { return; }

    this.listRolesWatchQuery = this.listRolesGQL.watch({
      search: {
        limit: -1,
        zoneDir: ZoneDir.Any,
      }
    }, {
      fetchPolicy: 'cache-first',
    });

    this.subs.sink = this.listRolesWatchQuery.valueChanges.subscribe((res) => {
      this.roles = res.data.roles;
    });
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      this.create();
      this.reset();
    } else if (this.mutateType === 'update') {
      this.update();
      this.reset();
    }
  }

  reset() {
    this.form.reset(this.initialFormState);
    this.variables = {};
  }

  /**
   * Set the current zone to the currently contexted zone
   */
  setZone() {
    // TODO: this can be improved for working on scheduled reports in sub zones
    const timezone = this.timeZoneHelper.getCurrentTimezone();
    if (this.scheduledReport) {
      const [ zone ] = this.scheduledReport.zones.nodes;
      this.currentZone = zone;
    } else {
      this.currentZone = this.brandingService.currentZone().value;
    }
    this.form.get('zone').setValue(`${ this.currentZone.name } (${ timezone })`);
  }

  /**
   * Set the placeholder name of the report type to match the name that will be set on
   * the backend
   */
  getNamePlaceholder() {
    const zoneName = this.currentZone?.name || 'Current Zone';
    const name = `Scheduled ${ this.reportName } ${ this.parsedCronTime }`
      + ` [${ this.periodToString(this.form.value.period) }] (${ zoneName })`;
    return name;
  }

  getName() {
    return this.form.value.name || this.getNamePlaceholder();
  }


  periodToString(period: AdvancedPeriodOptions) {
    return periodToString(period, this.variables);
  }

  parseReportTypePeriod() {
    if (!this.reportDescription) { return undefined; }

    for (const line of this.reportDescription.split('\n')) {
      const parsedLine = line.replace(/[*]/g, '').toLowerCase().trim();
      if (parsedLine.startsWith('period:')) {
        return line;
      }
    }

    return undefined;
  }

  updatePeriodLocalStorage() {
    const period = this.form.value.period || 'Today';
    this.periodService.updatePeriodLocalStorage(period);
  }

  checkWebhooks() {
    let webhooks: string[] = this.form.value?.webhooks || [];
    webhooks = webhooks.filter((w) => w.match(regex.secureURL));
    this.form.get('webhooks').setValue(webhooks);
    return webhooks;
  }

  openDialog() {
    this.setNameAndDescription();
    this.setZone();
    this.loadRoles();

    const steps: MutateObjectElement[] = [
      {
        name: 'Interval',
        ref: this.timeRef,
        control: 'time',
        type: 'func',
        reviewFunc: () => this.freyaHelper.parseCrontime(this.form.value.time),
      },
      {
        name: 'Period',
        ref: this.periodRef,
        control: 'period',
        type: 'func',
        reviewFunc: () => this.periodToString(this.form.value.period),
        removed: Boolean(!this.scheduledReport && this.dynamicReport),
      },
      {
        name: 'Name',
        ref: this.nameRef,
        control: 'name',
        type: 'func',
        reviewFunc: () => this.getName(),
      },
      {
        name: 'Notifications',
        ref: this.notificationsRef,
        control: 'webhooks',
        type: 'func',
        reviewFunc: () => {
          const { webhooks, notifyUsers, notifyRoles } = this.form.value;

          const vals: string[] = [];
          if (webhooks?.length) {
            vals.push(`${ webhooks.length } webhook(s)`);
          }
          if (notifyUsers?.length) {
            vals.push(`${ notifyUsers.length } users(s)`);
          }
          if (notifyRoles?.length) {
            vals.push(`${ notifyRoles.length } roles(s)`);
          }

          if (!vals.length) {
            return 'None';
          }

          return vals.join(', ');
        },
      },
      {
        name: 'Zone (Timezone)',
        type: 'text',
        control: 'zone',
      },
    ];

    this.steps = steps;

    this.mutateRef.steps = this.steps;

    if (this.mutateType === 'create') {
      this.mutateRef.titleText = `Create Scheduled Report: ${ this.reportName }`;
    } else if (this.mutateType === 'update') {
      this.mutateRef.titleText = `Updating ${ this.scheduledReport.name }`;
      const variables = JSON.parse(this.scheduledReport.variables || '{}');
      const baseVariables = {...variables};
      delete baseVariables.period;

      this.form.patchValue({
        name: this.scheduledReport.name,

        notifyRoles: this.scheduledReport.notifyRoles,
        notifyUsers: this.scheduledReport.notifyUsers,
        webhooks: this.scheduledReport.webhooks,

        period: variables.period || this.initialFormState.period,
        variables: baseVariables,
        dontSendIfEmpty: this.scheduledReport.attributes?.includes('dontSendIfEmpty'),

        // these aren't implemented on the frontend
        // start:
        // end:

        saveData: this.scheduledReport.saveData,
        time: this.scheduledReport.time,
        // zone: is set in setZone
      });

      if (this.scheduledReport.name === this.getNamePlaceholder()) {
        // delete existing name if it matches the generated name
        // so any modified properties can also update the name
        this.form.get('name').setValue('');
      }
    }

    this.mutateRef.openDialog();
  }

  getAttributes() {
    let attributes: string[] = [];
    if (this.scheduledReport) {
      attributes = this.scheduledReport.attributes || [];
    }

    const value = this.form.value;
    attributes = attributeToggle('dontSendIfEmpty', attributes, value.dontSendIfEmpty);

    return attributes;
  }

  /**
   * Create the report and immediately open the report in the details sidebar
   *
   * The report will have the generating status, so the details sidebar
   * will poll until the report is complete or failed
   */
  private async create() {
    if (!this.reportType && !this.dynamicReport) { return; }

    const value = this.form.getRawValue();
    const period = value.period as AdvancedPeriodOptions;

    const variables = {
      period,
    };

    this.subs.sink = this.createScheduledReportGQL.mutate({
      input: {
        reportType: this.reportType?.id,
        dynamicReportId: this.dynamicReport?.id,
        notifyRoles: value.notifyRoles || [],
        notifyUsers: value.notifyUsers || [],
        scheduledReport: {
          start: undefined,
          end: undefined,
          name: this.getName(),
          saveData: value.saveData,
          variables: JSON.stringify(variables),
          time: value.time,
          webhooks: value.webhooks || [],
          attributes: this.getAttributes(),
        },
      },
    }, {
      refetchQueries: [ 'ListScheduledReports' ],
    }).subscribe((res) => {
      const report = res.data.createScheduledReport;
      this.localNotify.success(`Scheduled report created`, report.name);
      this.detailsHelper.pushUpdate({
        id: report.id,
        action: 'create',
        type: 'ScheduledReport',
        source: 'local',
        update: report,
      });
      this.mutateRef.closeDialog();
    }, (err) => {
      console.error(`Error creating scheduled report`, err);
      this.localNotify.error(`Error creating scheduled report`, err.message);
      this.mutateRef.loading = false;
    });
  }

  private async update() {
    if (!this.scheduledReport) { return; }
    if (!this.reportType) { return; }

    const value = this.form.getRawValue();
    const period = value.period as AdvancedPeriodOptions;
    const variables = {
      period,
    };

    this.subs.sink = this.updateScheduledReportGQL.mutate({
      input: {
        ids: [ this.scheduledReport.id ],
        rels: {
          notifyRoles: value.notifyRoles || [],
          notifyUsers: value.notifyUsers || [],
        },
        update: {
          start: undefined,
          end: undefined,
          name: this.getName(),
          saveData: value.saveData,
          variables: JSON.stringify(variables),
          time: value.time,
          webhooks: value.webhooks || [],
          attributes: this.getAttributes(),
        },
      },
    }, {
      refetchQueries: [ 'ListScheduledReports' ],
    }).subscribe((res) => {
      const [sr] = res.data.updateScheduledReports;
      if (!sr) {
        this.localNotify.warning(`Scheduled report not updated`);
        return;
      }
      this.localNotify.success(`Scheduled report updated`, sr.name);
      this.detailsHelper.pushUpdate({
        id: sr.id,
        action: 'update',
        type: 'ScheduledReport',
        source: 'local',
        update: sr,
      });
    }, (err) => {
      console.error(`Error updating scheduled report`, err);
      this.localNotify.error(`Error updating scheduled report`, err.message);
    });

  }

  setNameAndDescription() {
    if (this.reportType) {
      this.reportName = this.reportType.name;
      this.reportDescription = this.reportType.description;
    } else if (this.dynamicReport) {
      this.reportName = this.dynamicReport.name;
      this.reportDescription = this.dynamicReport.description
    }
  }
}
