import { Injectable, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';

import { Comment, GetConfigValuesGQL } from '../../generated/graphql.generated';
import { ArtifactService } from '../core/artifact/artifact.service';
import { Inventory } from '../estimates/estimating-inventory/estimating-inventory.component';
import { safeParseJSON } from '../js';
@Injectable({
  providedIn: 'root'
})
export class YemboHelperService implements OnDestroy{

  yemboEnabled = false;

  subs = new SubSink();

  constructor(
    private artifactService: ArtifactService,
    private configGQL: GetConfigValuesGQL,
  ) {
    this.checkYemboEnabled();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  checkYemboEnabled(){
    this.subs.sink = this.configGQL.watch({keys: ['yembo-integration.enabled']}).valueChanges.subscribe((res) => {
      if (!res.data?.getConfigValues?.length) { return; }
      this.yemboEnabled = res.data.getConfigValues[0].value === 'true';
    });
  }

  getYemboDocument(jobId: string, docType: 'inventory-pdf' | 'cub-sheet'){
    this.artifactService.listArtifacts({
      filter: {
        relatedObjectIds: [jobId],
        relatedObjectLabels: [ 'Job' ],
        attributes: [docType],
      }
    });
  }

  getInventoryComments(comments: Comment[]){
    return {
      manual: comments.find((c) => c.attributes.includes('inventory'))?.text || '',
      generated: safeParseJSON(comments.find((c) => c.attributes.includes('generated-inventory'))?.text) as Inventory,
    };
  }

  getCombinedInventory(manualInventory: string, generatedInventory: Inventory){
    let generatedAsString = '';
    if (!generatedInventory) { return manualInventory; }

    for (const room of generatedInventory.rooms){
      generatedAsString += `\n${room.name}\n\n`;
      for (const item of room.inventory){
        generatedAsString += `${item.name} (${item.quantity})\n`;
      }
    }

    if (generatedInventory?.specialtyItems?.length) {
      generatedAsString += `\nSpecialty Items\n\n`;
      for (const specialtyItem of generatedInventory.specialtyItems) {
        generatedAsString += `${specialtyItem}\n`;
      }
    }

    return (manualInventory || '') + '\n' + generatedAsString;
  }
}
