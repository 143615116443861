import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { Artifact, ArtifactFilter } from '@karve.it/interfaces/artifacts';
import {QueryRef} from 'apollo-angular';

import { isEqual } from 'lodash';
import { LazyLoadEvent } from 'primeng/api';
import { Tag } from 'primeng/tag';
import { SubSink } from 'subsink';

import { BaseArtifactFragment, DocumentSignedGQL, ListArtifactsGQL, ListArtifactsQuery, ListArtifactsQueryVariables } from '../../generated/graphql.generated';
import { pagination } from '../global.constants';
import { DetailsHelperService } from '../services/details-helper.service';
import { DocumentHelperService } from '../services/document-helper.service';
import { FreyaHelperService } from '../services/freya-helper.service';
import { FreyaNotificationsService } from '../services/freya-notifications.service';
import { UploadFileComponent } from '../shared/upload-file/upload-file.component';
import { WatchQueryHelper } from '../utilities/watchQueryHelper';


@Component({
  selector: 'app-artifacts',
  templateUrl: './artifacts.component.html',
  styleUrls: ['./artifacts.component.scss']
})
export class ArtifactsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @ViewChild('upload', {static: false}) uploadRef: UploadFileComponent;

  // Filter Variables
  @Input() filter: ArtifactFilter = {};
  @Input() pagination = pagination;

  // Mutation Variables
  @Input() overrideUploadObjects: string[]; // If this is defined then it will be used for uploading instead of the filter

  // Modify Component Layout
  @Input() showHeaderCard = true;
  @Input() jobConfirmationView = false;
  @Input() stackOnMobile = false;

  // If true mutate functionality is disabled
  @Input() readonly = false;
  // Tooltip to show when readonly is set to true
  @Input() readonlyTooltip: string;
  // If true shows the send documents button
  // @Input() showSendDocuments = false;
  // If true shows the upload button
  @Input() showUpload = true;

  @Input() emptyMessage = pagination.emptyMessage;

  showUploadDialog = false;

  subs = new SubSink();

  tags: Tag[] = [];

  // Component Variables
  artifacts: BaseArtifactFragment[] = [];

  // Artifact Query Variables
  artifactsQueryRef!: QueryRef<ListArtifactsQuery>;
  artifactsQH: WatchQueryHelper = {
    limit: 10,
    skip: 0,
    total: 0,
    loading: true,
    search: ''
  };

  constructor(
    private detailsHelper: DetailsHelperService,
    private ref: ChangeDetectorRef,
    private listArtifactsGQL: ListArtifactsGQL,
    public freyaHelper: FreyaHelperService,
    private documentHelper: DocumentHelperService,
    private documentSignedGQL: DocumentSignedGQL,
    private notify: FreyaNotificationsService,
  ) { }

  ngOnInit(): void {
    this.artifacts = [];

    this.subs.sink = this.detailsHelper.getObjectUpdates(['Artifacts','Transactions']).subscribe(() => {
      this.artifactsQueryRef.refetch();
    });

    this.subs.sink = this.documentSignedGQL.subscribe().subscribe((res) => {

      const { documentSigned: { artifactId, signedByRole } } = res.data;

      const artifact = this.artifacts.find((a) => a.id === artifactId);

      if (!artifact) { return; }

      this.detailsHelper.pushUpdate({
        type: 'Artifacts',
        id: artifactId,
        action: 'update',
      });

      this.notify.success('Document Signed', `Document has been signed by ${signedByRole.toLowerCase()}`);

    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter && this.relatedObjectIdsHaveChanged(changes.filter)) {
      this.searchForArtifacts();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterViewInit() {
    this.ref.detectChanges();
  }

  // Pagination
  retrieveMoreArtifacts(event: LazyLoadEvent) {

    this.artifactsQH.limit = event.rows;
    this.artifactsQH.skip = event.first;

    this.searchForArtifacts();
  }

  // Search For Artifacts based on filter values
  searchForArtifacts() {
    this.artifactsQH.loading = true;

    if (this.artifactsQueryRef) {
      this.artifactsQueryRef.resetLastResults();
      this.artifactsQueryRef.refetch(this.generateArtifactInput());
    } else {
      this.artifactsQueryRef = this.listArtifactsGQL.watch(this.generateArtifactInput());

      this.subs.sink = this.artifactsQueryRef.valueChanges.subscribe((res) => {
        if (res.networkStatus === 7) {
          this.artifacts = res.data.artifacts.artifacts;
          this.artifactsQH.total = res.data.artifacts.total;
          this.artifactsQH.loading = false;
        }
      });
    }
  }

  generateArtifactInput(){
    return {
      filter: {
        ...this.filter,
        zoneDir: 'any',
      },
      getPrivate: true,
      limit: this.artifactsQH.limit,
      skip: this.artifactsQH.skip,
      sortBy: 'createdAt:desc'
    } as ListArtifactsQueryVariables;
  }

  selectArtifact(artifact: Artifact) {

    const invoiceId = artifact?.metadata?.invoiceId;

    if (invoiceId) {
      this.detailsHelper.detailsItem.next({ type: 'invoice', item: { id: invoiceId } });
    } else {
      this.detailsHelper.detailsItem.next({ type: 'artifact', item: { id: artifact.id } });
    }
  }

  resendDocument(artifact: Artifact) {

    const isInvoice = artifact?.attributes?.some((a) => a === 'Invoice');

    const invoiceId = artifact?.metadata?.invoiceId;

    if (isInvoice && invoiceId) {
      this.documentHelper.showSendInvoiceConfirmation(invoiceId);
    } else {
      this.documentHelper.resendDocument(artifact);
    }

  }

  // Needs JOB ID
  // openSendDocuments(){
  //   this.dialogService.open(SendDocumentsComponent, {
  //     header: 'Send Documents',
  //     data: {
  //       input: {
  //         jobId: this.job.id,
  //       }
  //     },
  //     width: '50vw',
  //   });
  // }

  private relatedObjectIdsHaveChanged(filter: SimpleChange): boolean {
    const currentIds = filter?.currentValue?.relatedObjectIds.sort();
    const previousIds = filter?.previousValue?.relatedObjectIds.sort();
    return !isEqual(currentIds, previousIds);
  }
}
