import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { SubSink } from 'subsink';

import { advancedConditions, conditionalConditionOptions, conditionalInfo, conditionalTypeOptions, ConditionArray, ConditionInfo, FormDetail, propTypeOptions } from '../../../franchise/rules/rules.constants';


interface ConditionMenuItem extends MenuItem {
  block: string;
}

const EMPTY_CONDITION: ConditionInfo = {
  property: '',
  condition: '',
  type: 'string',
  value: '',
};

@Component({
  selector: 'app-mutate-rule-condition-block',
  templateUrl: './mutate-rule-condition-block.component.html',
  styleUrls: ['./mutate-rule-condition-block.component.scss']
})
export class MutateRuleConditionBlockComponent implements OnInit, OnDestroy {

  @Input() form: FormDetail;
  @Input() conditions: ConditionArray;
  @Input() blockLevel = 0;

  @Output() blockUpdated = new EventEmitter<void>();

  /**
   * Lets the parent block know that
   * this block needs to be removed.
   */
  @Output() removeBlock = new EventEmitter();

  conditionalInfo = conditionalInfo;

  subs = new SubSink();

  booleanOptions = [
    { label: 'True', value: true },
    { label: 'False', value: false },
  ];

  addConditionItems: ConditionMenuItem[] = [
    this.generateConditionBlockItem('AND'),
    this.generateConditionBlockItem('OR'),
    this.generateConditionBlockItem('XOR'),
    this.generateConditionBlockItem('NOT'),
  ];

  propOptions;

  constructor() { }

  ngOnInit(): void {
    this.checkConditionItems();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  generateConditionBlockItem(name: string) {
    return {
      block: name,
      label: `Add ${ name } block`,
      disabled: true,
      command: () => {
        this.addCondition(name);
      },
    } as ConditionMenuItem;
  }

  checkConditionItems() {
    for (const item of this.addConditionItems) {
      if (!item.block) { continue; }
      item.disabled = this.hasBlockCondition(item.block);
    }
  }

  /**
   * Removes a condition
   */
  removeCondition(con: ConditionInfo) {
    const index = this.conditions.indexOf(con);
    if (index >= 0) {
      this.conditions.splice(index, 1);
    }
    this.onBlockUpdated();
  }

  /**
   * If advancedBlockType is not set, creates a basic, empty
   * condition.
   *
   * If advancedBlockType is set then it creates an advanced block type.
   */
  addCondition(advancedBlockType?: string): void {

    if (advancedBlockType) {

      this.conditions.push({
        condition: advancedBlockType,
        value: [{...EMPTY_CONDITION}] as ConditionArray,
      });

      this.onBlockUpdated();
      return;
    }

    if (this.hasEmptyCondition()) { return; }

    this.conditions.push({...EMPTY_CONDITION});
    this.onBlockUpdated();
  }

  onBlockUpdated() {

    this.checkConditionItems();
    this.blockUpdated.next();
  }

  conditionInfoUpdated(ev: any, prop: ConditionInfo, changed: string) {
    // const newValue = ev.value;
    // const conditions = this.ruleForm.get('conditions').value as ConditionDetails;

    // conditions.find()

    prop[changed] = typeof ev === 'string' ? ev : ev.value;
    this.checkCondition(prop);
    this.onBlockUpdated();
  }

  /**
   * Checks the conditional info to make sure that its
   * "condition" and "type" is set correctly.
   *
   * If value is of a different
   * type then this function will update "type"
   *
   * If the type is not in the list of allowed types,
   * then the type will be set to the first allowed type.
   *
   * If the condition is not in the list of allowed conditions
   * for that property then the condition will reset to the first
   * allowed condition.
   */
  checkCondition(prop: ConditionInfo) {
    // todo: determine "type" from value

    // todo: determine "conditions" from "property" allowed conditions

    const allowedTypes = this.getAllowedTypesForCondition(prop);
    if (!allowedTypes.includes(prop.type)) {
      prop.type = allowedTypes[0];
    }

    if (prop.type.startsWith('array-') && !Array.isArray(prop.value)) {
      prop.value = [ prop.value ];
    }

    if (!prop.type.startsWith('array-') && Array.isArray(prop.value)) {
      prop.value = prop.value[0];
    }
  }

  arrTrackBy(index: number) {
    return index;
  }

  getConditionalPropertyOptions() {
    this.propOptions = this.propOptions || this.form.properties.map((p) => ({
      label: p.label || p.name,
      value: p.name,
    }));

    return this.propOptions;
  }

  checkIfConditionIsBasic(con: ConditionInfo) {
    return !advancedConditions.includes(con.condition);
  }

  getAllowedConditionsForProperty(prop: ConditionInfo) {

    const conditions = conditionalConditionOptions;

    const propDetails = this.form.properties.find((p) => p.name === prop.property);
    if (!propDetails) { return conditions; }

    const conditionalTypeInfo = conditionalTypeOptions.find((to) => to.value === propDetails.type);
    if (!conditionalTypeInfo) { return conditions; }

    return conditions.filter((c) => {
      if (conditionalTypeInfo.disabledConditions && conditionalTypeInfo.disabledConditions.find((ac) => ac === c.value)) {
        return false;

      }

      if (conditionalTypeInfo.allowedConditions && !conditionalTypeInfo.allowedConditions.find((ac) => ac === c.value)) {
        return false;
      }

      return true;

    });
  }

  getAllowedTypesForCondition(prop: ConditionInfo) {

    const info = conditionalInfo[prop.condition];

    if (!info || !info.typeOptions) { return propTypeOptions; }

    return info.typeOptions;
  }

  getAllowedTypesForConditionOptions(prop: ConditionInfo) {
    const allowedTypes: any = this.getAllowedTypesForCondition(prop);

    const res = allowedTypes.map((t: any) => conditionalTypeOptions.find((opt: any) => opt.value === t));

    return res;
  }

  hasEmptyCondition() {
    const basicConditions = this.conditions.filter((c) => this.checkIfConditionIsBasic(c));

    const emptyCondition = basicConditions.find((c) => c.property === '' || !c.type);
    return basicConditions.length > 0 && Boolean(emptyCondition);
  }

  checkIfConditionIsDuplicate(prop: ConditionInfo) {
    const existing = this.conditions.find((c) =>
      c.property === prop.property &&
      c.condition === prop.condition &&
      c !== prop
    );
    return Boolean(existing);
  }

  isValid() {
    for (const c of this.conditions) {
      // todo: check if block is empty
      // todo: check if a condition is complete (not empty)
      // todo: check if a condition is duplicate
    }
  }

  hasBlockCondition(blockType: string) {
    const block = this.conditions.find((c) => c.condition === blockType);
    return Boolean(block);
  }

}
