import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {QueryRef} from 'apollo-angular';

import { SubSink } from 'subsink';

import {
    DeleteInvoicesGQL,
  ExportInvoiceToQuickbooksGQL,
  FullInvoiceFragment,
  GetInvoiceLinksGQL,
  InvoiceDetailsGQL,
  InvoiceDetailsQuery,
  InvoiceDetailsQueryVariables,
  UpdateInvoicesGQL,
  UpdateInvoicesMutationVariables,
} from '../../../generated/graphql.generated';
import { remainingBalance } from '../../jobs/jobs.util';
import { DetailsHelperService } from '../../services/details-helper.service';
import { DocumentHelperService } from '../../services/document-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { PermissionService } from '../../services/permission.service';
import { DisabledWhen, DisabledWhenTooltips, KarveMenuItem, setMenuItemDisabled } from '../../utilities/menu-item.util';
import { isDraftInvoice, isFinalizedInvoice } from '../invoices.utils';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss']
})
export class InvoiceDetailsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() invoiceId: string;

  invoice: FullInvoiceFragment;

  invoiceQueryRef: QueryRef<InvoiceDetailsQuery, InvoiceDetailsQueryVariables>;

  documentUpdating = false;

  subs = new SubSink();

  showDownloadInvoiceLink = false;

  showSendInvoiceLink = false;

  showOpenInvoiceLink = false;

  viewOnQuickbooksUrl = 'https://app.qbo.intuit.com/app/invoice?txnId=';

  permissions = {
    pay: false,
    send: false,
    update: false,
    delete: false,
    exportToQuickbooks: false,
  };

  invoiceActions: KarveMenuItem[] = [
    {
      id: 'pay',
      label: 'Pay',
      icon: 'pi pi-wallet',
      command: () => this.documentHelper.payInvoice(this.invoice),
      disabled: true,
      disabledWhen: {
        invoiceVoided: true,
        objectDeleted: true,
        invoicePaid: true,
      }
    },
    {
      id: 'preview',
      label: 'Preview',
      icon: 'pi pi-eye',
      command: () => this.viewDocument(),
      disabled: true,
      disabledWhen: {
        documentGenerating: true,
      }
    },
    {
      id: 'download',
      label: 'Download',
      icon: 'pi pi-download',
      command: () => this.downloadDocument(),
      disabled: true,
      disabledWhen: {
        documentGenerating: true,
        objectDeleted: true,
      }
    },
    {
      id: 'send',
      label: 'Send',
      icon: 'pi pi-send',
      command: () => this.sendInvoice(),
      disabled: true,
      disabledWhen: {
        documentGenerating: true,
        invoiceOutdated: true,
        invoiceVoided: true,
        objectDeleted: true,
      }
    },
    {
      id: 'employee',
      label: 'View as Employee',
      icon: 'pi pi-external-link',
      command: () => this.confirmOpenInvoice('employee'),
      disabled: true,
      disabledWhen: {
        documentGenerating: true,
        invoiceOutdated: true,
        invoiceVoided: true,
        objectDeleted: true,
      }
    },
    {
      id: 'customer',
      label: 'View as Customer',
      icon: 'pi pi-external-link',
      command: () => this.confirmOpenInvoice('customer'),
      disabled: true,
      disabledWhen: {
        documentGenerating: true,
        invoiceOutdated: true,
        invoiceVoided: true,
        objectDeleted: true,
      }
    },
    {
      id: 'void',
      label: 'Void',
      icon: 'pi pi-times',
      command: () => this.voidInvoice(),
      disabled: true,
      disabledWhen: {
        invoiceVoided: true,
      },
      visible: false,
    },
    {
      id: 'delete',
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => this.deleteInvoice(),
      visible: false,
    },
    {
      id: 'export-to-quickbooks',
      label: 'Export to Quickbooks',
      icon: 'pi pi-share-alt',
      visible: false,
      command: () => this.exportToQuickbooks(),
      disabledWhen: {
        invoiceExportedToQuickbooks: true,
        invoiceVoided: true,
        invoiceDraft: true,
        documentGenerating: true,
        objectDeleted: true,
      },
    },
    {
      id: 'force-update',
      label: 'Force Update Invoice',
      icon: 'pi pi-sync',
      command: () => this.updateDocument(),
      disabled: true,
      disabledWhen: {
        documentGenerating: true,
        invoiceVoided: true,
        objectDeleted: true,
        invoiceFinalized: true,
      }
    },
  ];

  constructor(
    private invoiceDetailsGQL: InvoiceDetailsGQL,
    private permissionHandler: PermissionService,
    private localNotify: FreyaNotificationsService,
    private documentHelper: DocumentHelperService,
    private freyaHelper: FreyaHelperService,
    private detailsHelper: DetailsHelperService,
    private updateInvoicesGQL: UpdateInvoicesGQL,
    private getInvoiceLinksGQL: GetInvoiceLinksGQL,
    private deleteInvoicesGQL: DeleteInvoicesGQL,
    private exportInvoiceToQuickbooksGQL: ExportInvoiceToQuickbooksGQL,
  ) { }

  ngOnInit(): void {

    this.setPermissions();

    this.subs.sink = this.detailsHelper.getObjectUpdates([ 'Invoice', 'Jobs' ]).subscribe((update) => {

      if (!this.invoiceId) { return; }

      const invoiceJobUpdated = update.type === 'Jobs' && update.id === this.invoice?.job?.id;

      if (update.type === 'Invoice' || invoiceJobUpdated) {
        this.retrieveInvoice();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('invoiceId') && this.invoiceId) {
      this.retrieveInvoice();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  retrieveInvoice() {
    if (this.invoiceQueryRef) {
      this.invoiceQueryRef.refetch({ invoiceId: this.invoiceId });
      return;
    }

    this.invoiceQueryRef = this.invoiceDetailsGQL.watch({ invoiceId: this.invoiceId });

    this.subs.sink = this.invoiceQueryRef.valueChanges.subscribe((res) => {

      if (res.loading) { return; }

      this.invoice = res.data.invoices.invoices?.[0];

      this.setShowLinks();

      this.setDisabledActions();

      this.setActionsVisibility();

    });
  }

  setPermissions() {
    this.subs.sink = this.permissionHandler.watchPermissions([
      'transactions.create',
      'invoice.send',
      'invoice.update',
      'invoice.delete',
      'quickbooks.createInvoice'
    ]).subscribe((res) => {

      this.permissions.pay = res[0];
      this.permissions.send = res[1];
      this.permissions.update = res[2];
      this.permissions.delete = res[3];
      this.permissions.exportToQuickbooks = res[4];

      this.setActionsVisibility();
    });
  }

  confirmOpenInvoice(role: 'customer' | 'employee') {

    const message = `Opening an invoice will finalize it. `
    + `You will not be able to make any further charges on any invoiced events. `
    + `If you just want to view the document and do not need to collect signatures, `
    + `consider using the "Preview" option instead.`;

    if (isFinalizedInvoice(this.invoice)) {
      this.openInvoice(role, false);
    } else {
      this.freyaHelper.confirmWithLoading({
        header: 'Open Invoice?',
        message,
        acceptLabel: 'Open and Finalize',
        acceptIcon: 'pi pi-external-link',
        rejectLabel: 'Preview',
        rejectIcon: 'pi pi-eye',
        reject: () => this.viewDocument(),
        acceptObservable: this.getInvoiceLinksGQL.mutate({ invoiceIds: [ this.invoiceId ], role }),
        onAcceptSuccess: (res) => {

          const [ link ] = res.data.getLinks;

          if (!link) {
            this.localNotify.error('Unable to open invoice');
          } else {
            this.freyaHelper.openInDialog(this.artifact, link);
          }

          this.localNotify.success('Invoice finalized');

          this.detailsHelper.pushUpdate({
            id: this.invoiceId,
            action: 'update',
            type: 'Invoice',
          });
        },
        onAcceptErr: (err) => {
          this.localNotify.apolloError('Unable to open invoice', err);
        },
      });
    }
  }

  openInvoice(role: 'customer' | 'employee', willFinalize: boolean) {

    this.getInvoiceLinksGQL.mutate({ invoiceIds: [ this.invoiceId ], role }).subscribe((res) => {

      const [ link ] = res.data.getLinks;

      if (!link) {
        this.localNotify.error('Unable to open invoice');
      } else {
        this.freyaHelper.openInDialog(this.artifact, link);
      }

      if (willFinalize) {
        this.localNotify.success('Invoice finalized');

        this.detailsHelper.pushUpdate({
          id: this.invoiceId,
          action: 'update',
          type: 'Invoice',
        });
      }
    }, (err) => {
      this.localNotify.apolloError('Unable to open invoice', err);
    });
  }

  get artifact() {
    return this.invoice?.artifacts?.[0];
  }

  setDisabledActions() {

    const disabledWhen: DisabledWhen = {
      documentGenerating: this.documentGenerating,
      invoiceOutdated: this.invoiceOutdated,
      invoiceVoided: this.invoiceVoided,
      objectDeleted: this.objectDeleted,
      invoicePaid: this.invoicePaid,
      invoiceExportedToQuickbooks: this.invoiceExportedToQuickbooks,
      invoiceDraft: this.invoiceDraft,
      invoiceFinalized: Boolean(this.invoice?.sentAt) || Boolean(this.invoice?.openedAt),
    };

    const disabledWhenTooltips: DisabledWhenTooltips = {
      documentGenerating: 'Invoice is stil being generated',
      invoiceOutdated: 'This invoice is outdated',
      invoiceVoided: 'This invoice was voided',
      objectDeleted: 'This invoice was deleted',
      invoiceExportedToQuickbooks: 'This invoice has already been exported to Quickbooks',
      invoiceDraft: 'This invoice is still in draft status',
      invoiceFinalized: `This invoice is finalized. If you need a document
        with the latest financials, please void this invoice and create a new one`,
    };

    for (const action of this.invoiceActions) {
      setMenuItemDisabled(action, disabledWhen, disabledWhenTooltips);
    }

  }

  setActionsVisibility() {
    const voidAction = this.invoiceActions.find((a) => a.id === 'void');

    if (!voidAction) {
      throw new Error('Could not find void action');
    }

    const deleteAction = this.invoiceActions.find((a) => a.id === 'delete');

    if (!deleteAction) {
      throw new Error('Could not find delete action');
    }

    const payAction = this.invoiceActions.find((a) => a.id === 'pay');

    if (!payAction) {
      return new Error('Could not find pay action');
    }

    const sendAction = this.invoiceActions.find((a) => a.id === 'send');

    if (!sendAction) {
      throw new Error('Could not find send action');
    }

    const openAsCustomerAction = this.invoiceActions.find((a) => a.id === 'customer');

    if (!openAsCustomerAction) {
      throw new Error('Could not find open as customer action');
    }

    const openAsEmployeeAction = this.invoiceActions.find((a) => a.id === 'employee');

    if (!openAsEmployeeAction) {
      throw new Error('Could not find open as employee action');
    }

    const exportToQuickbooksAction = this.invoiceActions.find((a) => a.id === 'export-to-quickbooks');

    if (!exportToQuickbooksAction) {
      throw new Error('Could not find export to Quickbooks action');
    }

    payAction.visible = this.permissions.pay;

    voidAction.visible = Boolean(this.invoice?.sentAt || this.invoice?.openedAt) && this.permissions.update;

    deleteAction.visible = Boolean(!this.invoice?.sentAt && !this.invoice?.deletedAt) && this.permissions.delete;

    sendAction.visible = this.permissions.send;

    openAsEmployeeAction.visible = this.permissions.update;

    openAsCustomerAction.visible = this.permissions.update;

    exportToQuickbooksAction.visible = this.permissions.exportToQuickbooks;
  }

  viewDocument() {
    this.freyaHelper.openInDialog(this.artifact);
  }

  voidInvoice() {

    const vars: UpdateInvoicesMutationVariables = {
      invoices: [{
        invoiceId: this.invoiceId,
        voided: true,
      }],
    };

    this.updateInvoicesGQL.mutate(vars).subscribe(() => {

      this.localNotify.success('Invoice voided');

      this.detailsHelper.pushUpdate({
        id: this.invoiceId,
        type: 'Invoice',
        action: 'update',
      });

    }, (err) => {

      this.localNotify.apolloError('Failed to void invoice', err);
    });
  }

  updateDocument() {

    const vars: UpdateInvoicesMutationVariables = {
      invoices: {
        invoiceId: this.invoiceId,
        updatePricingTables: true,
      },
    };

    this.documentUpdating = true;

    this.updateInvoicesGQL.mutate(vars).subscribe(() => {
      this.localNotify.success('Invoice updated');

      this.detailsHelper.pushUpdate({
        id: this.invoiceId,
        type: 'Invoice',
        action: 'update',
      });

      this.documentUpdating = false;

    }, (err) => {
      this.localNotify.apolloError('Failed to update invoice', err);
      this.documentUpdating = false;
    });

  }

  deleteInvoice() {
    this.deleteInvoicesGQL.mutate({ invoiceIds: [ this.invoiceId ]})
      .subscribe(() => {

        this.localNotify.success('Invoice deleted');

        this.detailsHelper.pushUpdate({
          type: 'Invoice',
          id: this.invoiceId,
          action: 'delete',
        });
      }, (err) => {

        this.localNotify.apolloError('Failed to delete invoice', err);

      });
  }

  downloadDocument() {
    this.documentHelper.downloadDocument(this.artifact);
  }

  sendInvoice() {
    this.documentHelper.confirmSendInvoice(this.invoice);
  }

  exportToQuickbooks() {

    const timeout = setTimeout(() => {
      this.localNotify.info('Exporting to Quickbooks', 'This may take a few seconds');
    }, 200);

    const failMsg = 'Failed to export invoice to Quickbooks';
    this.exportInvoiceToQuickbooksGQL.mutate({ invoiceId: this.invoiceId }).subscribe({
      next: (res) => {

        clearTimeout(timeout);

        if (!res.data.exportInvoiceToQuickbooks) {
          this.localNotify.error(failMsg, 'Please make sure that Quickbooks integration is connected and enabled');
          return;
        }

        this.localNotify.success('Invoice successfully exported to Quickbooks');

        this.detailsHelper.pushUpdate({
          id: this.invoiceId,
          type: 'Invoice',
          action: 'update',
        });

      },
      error: (err) => {
        this.localNotify.apolloError(failMsg, err);
        clearTimeout(timeout);
      },
    });
  }

  setShowLinks() {

    const downloadDisabled = this.documentGenerating || this.objectDeleted;

    this.showDownloadInvoiceLink = this.artifact && !downloadDisabled;

    const openInvoiceDisabled = this.documentGenerating || this.invoiceOutdated || this.invoiceVoided || this.objectDeleted;

    this.showOpenInvoiceLink = !this.invoice.openedAt && !openInvoiceDisabled;

    const sendInvoiceDisabled = this.documentGenerating || this.invoiceOutdated || this.invoiceVoided || this.objectDeleted;

    this.showSendInvoiceLink = !this.invoice.sentAt && !sendInvoiceDisabled;
  }

  get documentGenerating() {
    return !(this.artifact?.url || this.artifact?.signedUrl);
  }

  get invoiceOutdated() {
    return this.artifact?.attributes?.includes('outdated');
  }

  get objectDeleted() {
    return Boolean(this.invoice?.deletedAt);
  }

  get invoiceVoided() {
    return Boolean(this.invoice?.voidedAt);
  }

  get invoicePaid() {
    return remainingBalance(this.invoice) === 0;
  }

  get invoiceExportedToQuickbooks() {
    return Boolean(this.invoice?.metadata?.quickbooksId);
  }

  get invoiceDraft() {
    return isDraftInvoice(this.invoice);
  }
}
