import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';


import { MarkdownModule } from 'ngx-markdown';

import { AttentionItemsSummaryComponent } from '../attention-items/attention-items-summary/attention-items-summary.component';
import { FreyaCommonModule } from '../freya-common/freya-common.module';
import { SharedModule } from '../shared/shared.module';

import { ChangeComponent } from './change/change.component';
import { DashboardEventTypeSummaryComponent } from './dashboard-event-type-summary/dashboard-event-type-summary.component';
import { DashboardInfoComponent } from './dashboard-info/dashboard-info.component';
import { DashboardComponent } from './dashboard.component';

@NgModule({
  declarations: [
    DashboardComponent,
    ChangeComponent,
    DashboardInfoComponent,
    DashboardEventTypeSummaryComponent,
    AttentionItemsSummaryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FreyaCommonModule,
    SharedModule,
    MarkdownModule.forChild(),
  ],
  exports: [
    DashboardComponent,
  ],
})
export class DashboardModule { }
