
import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentService } from '@karve.it/features';
import { CommentsInput, ListCommentsOutput } from '@karve.it/interfaces/comments';
import { Field, ListFieldsOutput } from '@karve.it/interfaces/fields';
import { QueryRef } from 'apollo-angular';

import { cloneDeep } from 'lodash';
import { EstimateConfirmationComponent } from 'src/app/estimates/estimate-confirmation/estimate-confirmation.component';
import { EventTypeInfo, JOB_ROLE_MAP, JOB_STAGES } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { PageTitleService } from 'src/app/services/page-title.service';
import { RecentItemsService } from 'src/app/services/recent-items.service';
import { MutateJobComponent } from 'src/app/shared/mutate-job/mutate-job.component';
import { MutateLocationComponent } from 'src/app/shared/mutate-location/mutate-location.component';
import { SubSink } from 'subsink';

import {
  BaseLocationFragment,
  CreateCalendarEventGQL,
  FullJobWithStagesFragment,
  JobPageListJobsGQL,
  JobPageListJobsQuery,
  JobPageListJobsQueryVariables,
  JobPromotedGQL,
} from '../../../generated/graphql.generated';
import { AppMainComponent } from '../../app.main.component';
import { DistanceService } from '../../estimates/distance.service';

import { BrandingService } from '../../services/branding.service';
import { EstimateHelperService } from '../../services/estimate-helper.service';
import { PermissionService } from '../../services/permission.service';
import { YemboHelperService } from '../../services/yembo-helper.service';
import { getJobLocation, isJobSharedToZone, getEventCompareFn } from '../jobs.util';


export interface StageOption {
  name: string;
  id?: string;
}

@Component({
  selector: 'app-job-page',
  templateUrl: './job-page.component.html',
  styleUrls: ['./job-page.component.scss']
})
export class JobPageComponent implements OnInit, OnDestroy {

  @ViewChild('mutate') mutateRef: MutateJobComponent;
  @ViewChild('mutateLocation') mutateLocationRef: MutateLocationComponent;
  @ViewChild('confirmationRef') confirmationRef: EstimateConfirmationComponent;

  @HostBinding('id') hostId: 'job-page';

  showCancelDialog = false;

  subs = new SubSink();

  job: FullJobWithStagesFragment;

  activeJobIndex = 0;

  jobStages: StageOption[] = Object.values(JOB_STAGES).map((v) => ({ ...v }));

  jobStagesSet = false;

  jobConstants = JOB_ROLE_MAP;

  // True if this job has been shared with this zone
  sharedFromAnotherZone = false;

  // Query Variables
  jobsQueryRef: QueryRef<JobPageListJobsQuery, JobPageListJobsQueryVariables>;
  jobsLoading = true;
  currentStage = '';

  // FIELDS
  fields: Field[];
  fieldsQueryRef: QueryRef<ListFieldsOutput>;

  // INVENTORY
  inventoryText: string;
  commentsQueryRef: QueryRef<ListCommentsOutput>;

  readonly: boolean;
  addCommentReadOnly: boolean;

  readonlyTooltip: string;

  permissions = {
    addComments: false,
  };

  constructor(
    private route: ActivatedRoute,
    // private jobService: JobService,
    private jobService: JobPageListJobsGQL,
    private router: Router,
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private freyaHelper: FreyaHelperService,
    private commentService: CommentService,
    private titleService: PageTitleService,
    private recentItems: RecentItemsService,
    private yemboHelper: YemboHelperService,
    private distanceService: DistanceService,
    private brandingService: BrandingService,
    private jobPromotedGQL: JobPromotedGQL,
    private appMain: AppMainComponent,
    private permissionHandler: PermissionService,
    private createEventGQL: CreateCalendarEventGQL,
    private estimateHelper: EstimateHelperService,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.route.params.subscribe((params) => {
      this.retrieveJob(params.id);
      this.getFields(params.id);
      this.retrieveInventory(params.id);
    });

    this.permissionHandler.watchPermissions(['jobs.update'])
      .subscribe((res) => {
        this.permissions.addComments = res[0];
        this.setReadonly();
      });

    this.subs.sink = this.detailsHelper
    .getObjectUpdates(['Jobs', 'Events', 'Transactions', 'Invoice'])
    .subscribe(() => {
      this.reload();
    });

    this.subs.sink = this.jobPromotedGQL.subscribe().subscribe((res) => {

      const jobId = res.data?.jobPromoted?.jobId;

      if (jobId === this.job.id) {
        this.localNotify.success('Job automatically promoted to invoice', res.data?.jobPromoted?.reason);
        this.reload();
      }
    });

    this.subs.sink = this.estimateHelper.addRequiredEvent.subscribe((res) => {
      this.addRequiredEvent(res);
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    delete this.jobsQueryRef;
    this.titleService.setCustomPageTitle({});
  }

  openUpdateJob() {
    this.mutateRef.job = this.job;
    this.mutateRef.mutateType = 'update';
    this.mutateRef.openDialog();
  }

  openUpdateJobTo(stepName: string) {
    this.openUpdateJob();
    this.mutateRef.mutateRef.viewSection(stepName);
  }

  retrieveJob(id: string) {

    if (this.jobsQueryRef && this.job?.id === id) {
      return this.reload();
    }

    this.jobsQueryRef = this.jobService.watch({
      filter: { jobIds: [id], getDeleted: true },
      resolve: ['discounts', 'stages', 'users', 'locations'],
    });

    this.subs.sink = this.jobsQueryRef.valueChanges.subscribe((res) => {
      this.jobsLoading = false;
      if (JOB_ROLE_MAP && res.data.jobs.jobs.length === 0) {
        this.router.navigateByUrl('jobs');
      }

      if (res.networkStatus === 7 && res.data.jobs.jobs.length > 0) {
        this.job = cloneDeep(res.data.jobs.jobs[0]);
        this.job.events.sort(getEventCompareFn('sequentialOrder'));
        this.setReadonly();

        // Used to prevent ExpressionChangedAfterChecked errors
        this.appMain.cd.detectChanges();

        this.recentItems.addToRecentItems(this.job, 'job');
        this.titleService.setCustomPageTitle({
          jobCode: this.job.code,
        });

        this.mutateRef.job = this.job;
        this.mutateRef.setFormValues();

        this.currentStage = this.job.id;

        this.calculateDistances();

        // Assign Stage Values
        const stages = [...this.job.previousStages, this.job, ...this.job.followingStages];

        for (const stage of stages) {
          const index = this.jobStages.findIndex((js) => js.name === stage.stage);
          this.jobStages[index].id = stage.id;
          if (stage.id === this.job.id) {
            this.activeJobIndex = index;
          }
        }
        this.jobStagesSet = true;
        this.estimateHelper.jobLoading.next(false);
      } else {
        if (!this.job) {
          this.localNotify.addToast.next({ severity: 'warn', summary: 'Job could not be found' });
          this.router.navigateByUrl('jobs');
        }
      }
    }, (err) => {
      this.localNotify.addToast.next({ severity: 'warn', summary: 'Job could not be found' });
      this.router.navigateByUrl('jobs');
    });
  }

  reload() {
    console.log('reloading');
    this.jobsLoading = true;
    this.jobsQueryRef.resetLastResults();
    this.jobsQueryRef.refetch();

    this.getFields(this.job.id);
  }

  changeStageByIndex(index: number) {
    this.activeJobIndex = index;
    this.changeStage(this.jobStages[index].id);
  }

  changeStage(id: string) {
    this.jobsLoading = true;
    this.jobsQueryRef.refetch(
      {
        filter: { jobIds: [id] },
        resolve: ['discounts', 'stages', 'users', 'locations'],
      }
    );

    this.freyaHelper.setPageUrl(`job/${id}`, true);
  }

  openUpdateLocation(l: BaseLocationFragment) {
    this.mutateLocationRef.location = l;
    this.mutateLocationRef.mutateType = 'update';
    this.mutateLocationRef.openDialog();
  }

  openUser() {
    const user = this.job.users.find((u) => u.role === JOB_ROLE_MAP.customerRole);
    if (!user) { return; }
    this.detailsHelper.detailsItem.next({ item: { id: user.user.id }, type: 'users' });
  }

  navigateToEstimatingTool() {
    this.router.navigate(['/estimating', this.job.id]);
  }

  cancelJob() {
    this.showCancelDialog = false;
    this.freyaHelper.cancelJob(this.job.id);
  }

  async getFields(jobId: string) {
    const fields = await this.freyaHelper.getFieldValues(
      [
        'startLocation.dwellingType',
        'startLocation.bedrooms',
        'startLocation.sqft',
        'startLocation.elevators',
        'startLocation.stairs',
        'startLocation.parkingInformation',
        'endLocation.dwellingType',
        'endLocation.bedrooms',
        'endLocation.sqft',
        'endLocation.elevators',
        'endLocation.stairs',
        'endLocation.parkingInformation',
        'misc.signedAt',
        'misc.signatureRequired',
        'misc.needsFinance',
        'customer.howDidTheyHearAboutUs'
      ],
      [jobId],
      [ 'Job' ],
    );
    this.confirmationRef.setDisplayValues(fields);
  }

  retrieveInventory(jobId: string) {
    if (this.commentsQueryRef) { // If we are refetching
      this.commentsQueryRef.refetch();
      return;
    }

    const watchCommentsInput = {
      filter: {
        hasAllAttributes: ['inventory', 'generated-inventory'],
        objectIds: [jobId],
      },
    } as CommentsInput;

    this.commentsQueryRef = this.commentService.watchComments(watchCommentsInput);

    this.subs.sink = this.commentsQueryRef.valueChanges.subscribe((res) => {
      if (res.networkStatus === 7 && res.data.comments.comments?.length) {
        if (!res?.data?.comments?.comments) { return; }

        const {manual, generated} = this.yemboHelper.getInventoryComments(res.data.comments.comments as any[]);

        this.inventoryText = this.yemboHelper.getCombinedInventory(manual, generated);
      }
    });
  }

  setReadonly() {
    const isJobDeleted = Boolean(this.job?.deletedAt);
    const isJobArchived = Boolean(this.job?.archivedAt);
    const isJobShared = isJobSharedToZone(this.job, this.brandingService.currentZone().value.id);

    this.readonly = !this.permissions.addComments || isJobDeleted || isJobArchived || isJobShared;
    this.addCommentReadOnly = !this.permissions.addComments || isJobDeleted || isJobArchived;

    if (!this.permissions.addComments) {
      this.readonlyTooltip = 'You do not have permission to modify jobs';
    } else if (isJobDeleted) {
      this.readonlyTooltip = 'This job has been deleted';
    } else if (isJobArchived) {
      this.readonlyTooltip = 'This job has been archived';
    } else if (isJobShared) {
      this.readonlyTooltip = 'This job is shared, it can only be edited from its original zone';
    }
  }

  async calculateDistances() {
    if (!this.job) { return; }

    this.distanceService.setLocations({
      dock: this.distanceService.convertLocationToDistanceLocation(getJobLocation(this.job, 'dock')),
      start: this.distanceService.convertLocationToDistanceLocation(getJobLocation(this.job, 'start')),
      end: this.distanceService.convertLocationToDistanceLocation(getJobLocation(this.job, 'end')),
    });

    this.distanceService.calculateDistances().then((sub) => this.subs.add(sub));
  }


  addRequiredEvent(eventType: EventTypeInfo){
    this.createEventGQL.mutate({
      calendarEvents: [{
        title: eventType.name,
        type: eventType.value,
        jobId: this.job.id,
        status: 'required'
      }]
    }).subscribe((res) => {
      this.localNotify.success(`${eventType.name} event added`);
      const [event] = res.data.createCalendarEvent.events;
      this.job.events.push(event);
      this.detailsHelper.pushUpdate({
        action: 'create',
        type: 'Events',
        id: this.job.id,
      });
    }, (err) => {
      console.error(err);
      this.localNotify.error(`Failed to add event ${eventType.name}`, err.message);
    });
  }

}
