/* eslint-disable @typescript-eslint/naming-convention */
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxGpAutocompleteDirective } from "@angular-magic/ngx-gp-autocomplete";
import { isEqual, omit } from 'lodash';
// import { Address } from 'ngx-google-places-autocomplete/objects/address';
// import { LatLngBounds } from 'ngx-google-places-autocomplete/objects/latLngBounds';
// import { Options } from 'ngx-google-places-autocomplete/objects/options/options';

import { InputText } from 'primeng/inputtext';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { BaseLocationFragment, CoordinateInput, Coordinates, CreateLocationsGQL, CreateLocationsMutationVariables, EstimatesJobFragment, LocationCreateBase } from '../../../generated/graphql.generated';
import { getFieldValue, hasValue, splitName } from '../../fields/fields.utils';
import { estimating } from '../../global.constants';
import { getJobLocation } from '../../jobs/jobs.util';
import { EstimateHelperService } from '../../services/estimate-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { GoogleHelperService } from '../../services/google-helper.service';
import { AddLocationComponent, AddLocationFormType } from '../../shared/add-location/add-location.component';
import { requiresAddress } from '../../shared/location-validators';
import { DistanceService } from '../distance.service';
import { googleLocationOptions } from '../estimate.interfaces';

export const LOCATION_FIELDS = [
  'unit',
  'dwellingType',
  'sqft',
  'elevators',
  'elevatorsBookable',
  'stairs',
  'bedrooms',
  'parkingInformation',
];

@Component({
  selector: 'app-add-job-location',
  templateUrl: './add-job-location.component.html',
  styleUrls: ['./add-job-location.component.scss']
})
export class AddJobLocationComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @ViewChild('googleAutocomplete') autocompleteRef: NgxGpAutocompleteDirective;
  @ViewChild(InputText) locationInputRef: InputText;
  @ViewChild('addLocation') addLocationRef: AddLocationComponent;

  @Output() locationRemoved = new EventEmitter();

  @Input() job: EstimatesJobFragment;
  @Input() type: AddLocationFormType;
  @Input() removable = false;

  // If this is false additional fields won't appear
  @Input() enableAdditionalFields = true;
  @Input() fieldNamespace: string;

  formFieldSubs = new SubSink();

  subs = new SubSink();

  // Base
  baseLocationForm: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(undefined, []),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    place_id: new UntypedFormControl(undefined, []),
    coordinates: new UntypedFormControl({}),

    address: new UntypedFormControl('', [Validators.required]),
    areaCode: new UntypedFormControl(undefined),
    unit: new UntypedFormControl(undefined, [requiresAddress(`Won't save without address`)]),
    // bathrooms: new FormControl('', [Validators.required]),

    // Set Based on Google
    country: new UntypedFormControl('', []),
    city: new UntypedFormControl(undefined, []),
    jurisdiction: new UntypedFormControl(undefined, []),
  });

  additionalFieldsForm: UntypedFormGroup = new UntypedFormGroup({
    sqft: new UntypedFormControl(undefined, []),
    bedrooms: new UntypedFormControl(0, []),
    dwellingType: new UntypedFormControl('House', []),
    stairs: new UntypedFormControl(0, []),
    elevators: new UntypedFormControl(undefined, []),
    elevatorsBookable: new UntypedFormControl(false, []),
    parkingInformation: new UntypedFormControl(undefined, []),
  });

  // The last valid address to be entered in the input
  lastGoodAddress: LocationCreateBase;

  // Whether we have attempted to autocomplete the address or not
  attemptedAutocomplete = false;

  // Manually track whether we hae changed the location
  // (does not check whether the input number has changed, that has to be checked separately)
  locationChanged = false;

  // CONSTANTS
  BEDROOM_DROPDOWN_OPTIONS = estimating.bedroomDropdownOptions;
  BATHROOM_DROPDOWN_OPTIONS = estimating.bedroomDropdownOptions;
  DWELLING_DROPDOWN_OPTIONS = estimating.dwellingTypes;
  STAIRS_DROPDOWN_OPTIONS = estimating.stairsDropDownOptions;
  ELEVATOR_DROPDOWN_OPTIONS = estimating.elevatorDropDownOptions;

  GOOGLE_OPTIONS = googleLocationOptions;

  disabled$ = this.estimateHelper.permissionsAndJobLoading
    .pipe(map((res) => res.jobLoading || !res.updateJob));

  constructor(
    private estimateHelper: EstimateHelperService,
    public googleHelper: GoogleHelperService,
    public distanceService: DistanceService,
    public freyaHelper: FreyaHelperService,
    // GQL
    private createLocationsGQL: CreateLocationsGQL,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.distanceService.dockUpdated
    .pipe(distinctUntilChanged(isEqual))
    .subscribe((dock) => {
      this.setGoogleOptions(dock);
    });

    if (this.enableAdditionalFields) {
      // Assign Field Event Handlers
      this.estimateHelper.assignFieldEventHandlers(
        this.formFieldSubs,
        LOCATION_FIELDS,
        this.fieldNamespace,
        this.additionalFieldsForm,
      );

      this.setAdditionalValues();
    }

    this.estimateHelper.permissionsAndJobLoading.subscribe((res) => {

      if (res.jobLoading) {
        this.baseLocationForm.disable();
        this.additionalFieldsForm.disable();
        return;
      }

      if (res.updateJob) {
        this.baseLocationForm.enable();
      } else {
        this.baseLocationForm.disable();
      }

      if (res.setFieldValues) {
        this.additionalFieldsForm.enable();
      } else {
        this.additionalFieldsForm.disable();
      }
    });
  }

  ngAfterViewInit(): void {
    // Call detect changes to prevent an `ExpressionChangedAfterItHasBeenCheckedError`
    this.cd.detectChanges();
  }

  ngOnChanges() {
    this.setBaseValues(this.job);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.formFieldSubs.unsubscribe();
  }

  /**
   * Set the values of the Base Location form, from the locations on the job
   *
   * @param job
   */
  setBaseValues(job: EstimatesJobFragment) {
    this.job = job;

    const location = getJobLocation(this.job, this.type);
    if (location) {
      const startFormValue = this.convertLocationToForm(location);
      this.baseLocationForm.patchValue(startFormValue);
      this.lastGoodAddress = location;

      // Pass the location value to set distances
      const setLocationsInput = {};
      setLocationsInput[this.type] = this.distanceService.convertFormToDistanceLocation(this.baseLocationForm);
      this.distanceService.setLocations(setLocationsInput);
      this.distanceService.calculateDistances();
    } else {
      this.baseLocationForm.reset();
    }
  }

  /**
   * Set the values of the additionalFieldForm with values from the job fields
   */
  setAdditionalValues() {
    this.subs.sink = this.estimateHelper.fieldValues.subscribe((fields) => {
      if (!fields) {
        return;
      }

      for (const field of fields) {

        // Skip fields that don't have a value set
        if (!hasValue(field)) { continue; }

        const { namespace, key } = splitName(field);

        if (namespace !== this.fieldNamespace) { continue; }

        // Verify there is a form control matching the field
        if (!this.additionalFieldsForm.controls[key]) { continue; }

        this.additionalFieldsForm.controls[key].setValue(getFieldValue(field));
      }

    });
  }

  /**
   * Set the values for the forms based on data being synced from the estimating notes
   *
   * @param jobInfo
   */
  syncLocationFromNotes(jobInfo: any){
    const info = jobInfo[this.type];

    if (info.address){
      this.baseLocationForm.patchValue({
        place_id: info.place_id,
        address: info.address,
        areaCode: info.areaCode,
        city: info.city,
        country: info.country,
        coordinates: undefined
      });

      this.locationChanged = true;
      this.estimateHelper.locationSet.next(info.address);
    }

    for (const field of LOCATION_FIELDS){
      // Skip fields that don't have a value set
      if (!info[field]) { continue; }

      this.additionalFieldsForm.controls[field].setValue(info[field]);
      this.estimateHelper.fieldModified.next({name: `${this.type}Location.${field}`, value: info[field]});
    }
  }

  /**
   * Take the input of the location autocomplete and assign the values for our location forms accordingly
   *
   * @param address Google Address https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
   * @param formType The location form to use
   */
  public handleAddressChange(address: google.maps.places.AutocompletePrediction) {
    const form = this.baseLocationForm;
    const controls = form.controls;

    if (!this.googleHelper.isValidGooglePlacesAddress(address)) {
      this.attemptedAutocomplete = true;
      this.addLocationRef.formType = this.type;
      this.addLocationRef.open(address);
      return;
    }

    this.estimateHelper.addressChanged.next();

    const location = this.googleHelper.convertGoogleLocationToCreateLocationInput(address);
    const areaCode = location.areaCode.replace(' ', '');

    form.patchValue({
      place_id: address.place_id || true,
      coordinates: location.coordinates,
      address: location.addressLineOne,
      areaCode,
      country: location.country,
      jurisdiction: location.countryJurisdiction,
      city: location.city,
    });

    form.markAsDirty();

    form.controls.unit.updateValueAndValidity();

    const formattedAddress = this.getFormattedAddress();

    this.lastGoodAddress = location;
    // Pass the postal code to estimate so it can resolve the area for this job.
    // Estimate component will call update distances after it does its thing
    // Set the relevant flag to true so we know we need to create a new location.
    this.locationChanged = true;

    if (this.type === 'start') {
      this.estimateHelper.locationSet.next({
        address: formattedAddress,
        areaCode,
      });
    }

    const setLocationsInput = {};
    setLocationsInput[this.type] = this.distanceService.convertFormToDistanceLocation(this.baseLocationForm);

    this.distanceService.setLocations(setLocationsInput);
    this.distanceService.calculateDistances();
  }


  getFormattedAddress() {
    const form = this.baseLocationForm.getRawValue();

    const formattedAddress = `${form.address || ''} ${form.city || ''} `
      + `${form.jurisdiction || ''} ${form.areaCode || ''} ${form.country || ''}`;
    return formattedAddress;
  }

  openManualLocation() {
    this.addLocationRef.open();
    this.addLocationRef.formType = this.type;
  }

  setGoogleOptions<T extends { coordinates?: Coordinates }>(hqLocation: T) {
    this.GOOGLE_OPTIONS = {
      componentRestrictions: {
        country: ['CA', 'US'],
      },
      bounds: !hqLocation.coordinates ? undefined :
        {
          north: hqLocation.coordinates.latitude + 0.25,
          south: hqLocation.coordinates.latitude - 0.25,
          east: hqLocation.coordinates.longitude + 0.25,
          west: hqLocation.coordinates.longitude - 0.25,
        } as unknown as google.maps.LatLngBounds,
      strictBounds: false,
    };

    this.autocompleteRef.options = this.GOOGLE_OPTIONS;
    // this.autocompleteRef.reset()
    // this.autocompleteRef.autocomplete.setOptions(this.GOOGLE_OPTIONS);
  }

  /**
   * Clear the form value if no google value is selected
   * TODO: Grab the highest value for the searchbox, retrieve the google address for it, and populate the values
   *
   * @param formType Which form we are using
   */
  async onLocationInputBlur() {

    if (!this.lastGoodAddress?.addressLineOne) {
      this.baseLocationForm.controls.address.setValue(undefined);
    } else {
      const lastGoodLocation = this.convertLocationToForm(this.lastGoodAddress);

      if (lastGoodLocation.address !== this.baseLocationForm.getRawValue().address) {
        this.baseLocationForm.patchValue(lastGoodLocation);
      }

    }

    return;
  }

  convertLocationToForm(loc: Partial<BaseLocationFragment>) {
    return {
      id: loc.id,
      place_id: loc.id,
      name: loc.name || loc.addressLineOne,
      address: loc.addressLineOne,
      unit: loc.addressLineTwo,
      areaCode: loc.areaCode,
      country: loc.country,
      jurisdiction: loc.countryJurisdiction,
      city: loc.city,
      coordinates: loc.coordinates,
    };
  }


  /**
   * Update the value for the form based on the manual location values
   */
  setValueEqualToManual(values: AddLocationComponent['manualLocationForm']['value']) {
    const address = `${values.streetNumber} ${values.streetName}, ${values.city}, `
      + `${values.jurisdiction} ${values.areaCode}, ${values.country}`;

    this.baseLocationForm.patchValue({
      place_id: false,
      // eslint-disable-next-line max-len
      address,
      areaCode: values.areaCode,
      country: values.country,
      jurisdiction: values.countryJurisdiction,
      city: values.city,
      coordinates: undefined,
    });

    this.locationChanged = true;

    this.addLocationRef.close();

    if (this.addLocationRef.formType === 'start') {
      // this.estimateHelper.locationSet.next(`MANUAL: ${address}`);
      this.estimateHelper.locationSet.next({
        address: `MANUAL: ${ address }`,
        areaCode: values.areaCode,
      });
    }
  }


  /// SAVING

  async getSaveValue() {

    const placeId = this.baseLocationForm.value.place_id;

    // Check if user has selected an address from the autocomplete menu or entered an address manually
    const hasAddress = placeId !== null && placeId !== undefined;

    const hasLocationChanges = this.locationChanged || this.baseLocationForm.controls.unit.dirty;

    // Do not attempt to create a location if user hasn't entered an address
    if (hasLocationChanges && hasAddress) {
      this.baseLocationForm.markAsPristine();
      return {
        locationId: await this.createLocation(),
        locationType: this.type
      };
    }

    this.locationChanged = false;
    return;
  }

  async createLocation() {
    const value = this.baseLocationForm.getRawValue();

    // if country is not set (eg, in estimate notes with just an area code)
    // then determine the country from the area code
    if (!value.country && value.areaCode) {
      if (value.areaCode.match(/^[0-9]/)) {
        value.country = 'USA';
      } else if (value.areaCode.match(/^[a-zA-Z]/)) {
        value.country = 'Canada';
      }
    }

    const createLocationInput: CreateLocationsMutationVariables['input'] = {
      locations: [{
        public: false,
        addressLineOne: value.address,
        addressLineTwo: value.unit,

        areaCode: value.areaCode,
        country: value.country,
        city: value.city,
        countryJurisdiction: value.jurisdiction || undefined,
        // TO DO: Tell Apollo Link to strip '__typename' from any mutations
        // https://github.com/apollographql/apollo-client/issues/2160
        coordinates: value.coordinates?.latitude ? omit(value.coordinates, ['__typename']) as CoordinateInput : undefined,
      }],
      owner: this.job?.users?.length ? this.freyaHelper.getJobCustomerId(this.job) : undefined
    };

    const res = await this.createLocationsGQL.mutate({
      input: createLocationInput,
    }).toPromise();

    return res?.data?.createLocations?.locations[0]?.id;
  }

  removeLocation(){
    this.locationRemoved.emit(this.type);
  }

  setFocusOnLocationInput() {
    this.locationInputRef.el.nativeElement.focus();
  }
}
