<div [formGroup]="ruleForm">
    <ng-template #given>
        <div>
            <label>Given</label>
            <p-dropdown
                appendTo="body"
                placeholder="Select Form"
                [options]="formOptions"
                formControlName="form"
                (onChange)="formUpdated()"
            ></p-dropdown>

            <ng-container *ngIf="showFormProperties">
                on
                <p-dropdown
                    appendTo="body"
                    placeholder="Select Property"
                    [options]="formPropertyOptions"
                    formControlName="property"
                ></p-dropdown>
            </ng-container>
            <app-validation-messages [control]="ruleForm.get('form')">
            </app-validation-messages>
            <app-validation-messages [control]="ruleForm.get('property')">
            </app-validation-messages>
        </div>
    </ng-template>
    <br>
    <ng-template #when>
        <div *appVar="ruleForm.get('conditions') as control">
            <app-mutate-rule-condition-block
                [form]="getFormDetails()"
                [conditions]="control.value"
                (blockUpdated)="onConditionBlockUpdated()"
            ></app-mutate-rule-condition-block>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #then>
        <div *appVar="ruleForm.get('trigger') as control">
            <p-dropdown
                appendTo="body"
                placeholder="Select Trigger"
                [options]="triggerOptions"
                formControlName="trigger"
            ></p-dropdown>
            <br>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #overridePriority *appVar="ruleForm.get('overridePriority') as control">
        <div 
            class="overridePriority"
        >
            <p-dropdown  #overridePriorityType
                appendTo="body"
                formControlName="overridePriorityType"
                placeholder="Select Type"
                [options]="[ 'Assorted', 'Ordered' ]"
            ></p-dropdown>
            <p-inputNumber
                formControlName="overridePriority"
                mode="decimal"
                [minFractionDigits]="1"
                [maxFractionDigits]="4"
            ></p-inputNumber>
        </div>
        <small> * Override Priority number does not apply to assorted rules</small>
        <br />
        <app-validation-messages [control]="control">
        </app-validation-messages>
    </ng-template>
    <ng-template #attributes>
        <label>Attributes (comma seperated)</label>
        <div *appVar="ruleForm.get('attributes') as control">
            <p-chips
                formControlName="attributes"
                separator=","
                addOnBlur="addOnBlur"
                [allowDuplicate]="false"
            ></p-chips>
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <ng-template #metadata>
        <div *appVar="ruleForm.get('metadata') as control">
            <table>
                <thead>
                    <th>Key</th>
                    <th>Value</th>
                </thead>
                <tbody>
                    <tr *ngFor="let meta of ruleForm.value.metadata | keyvalue">
                        <td>
                            <input type="text" pInputText
                                [(ngModel)]="meta.key"
                                [disabled]="true"
                                [ngModelOptions]="{standalone: true}"
                                class="meta-input"
                            />
                        </td>
                        <td>
                            <input type="text" pInputText
                                [ngModel]="ruleForm.value.metadata[meta.key]"
                                (blur)="control.value[meta.key] = $event.target.value"
                                [ngModelOptions]="{standalone: true}"
                                class="meta-input"
                            />
                        </td>
                    </tr>
                    <td>
                        <input 
                        type="text" 
                        pInputText
                        class="meta-input"
                        placeholder="Property Key"
                        #newKey
                    />
                    </td>
                    <td>
                        <button pButton label="Add Key" (click)="addProperty(newKey.value); newKey.value = ''"></button>
                    </td>
                </tbody>
            </table>

            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
</div>

<app-mutate-object
    #mutate
    [fg]="ruleForm"
    objectType="Rule"
    mutateType="create"
    [steps]="steps"
    (objectMutated)="mutateObject()"
></app-mutate-object>

<app-delete-object #delete
    name="this rule"
    type="Rule"
    (deleted)="deleteRule()"
>
</app-delete-object>