import { createPlugin, PluginDef } from '@fullcalendar/core'

import { NamedTimeZoneImpl } from '@fullcalendar/core/internal';

import * as dayjs from 'dayjs';
import * as arraySupport from 'dayjs/plugin/arraySupport';
import * as dayjsDuration from 'dayjs/plugin/duration';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as dayjsTimezone from 'dayjs/plugin/timezone';
import * as toArray from 'dayjs/plugin/toArray';
import * as updateLocale from 'dayjs/plugin/updateLocale';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(dayjsTimezone);
dayjs.extend(arraySupport);
dayjs.extend(toArray);
dayjs.extend(relativeTime);
dayjs.extend(dayjsDuration);
dayjs.extend(quarterOfYear);
dayjs.extend(updateLocale);


type IDateInputArray = [number?, number?, number?, number?, number?, number?, number?]
type IDateOutputArray = IDateOutputArrayStrict | number[]
type IDateOutputArrayStrict = [number, number, number, number, number, number, number]

class DayjsNamedTimeZone extends NamedTimeZoneImpl
{
	offsetForArray(a: IDateInputArray): number
	{
		return dayjs.tz(a, this.timeZoneName).utcOffset()
	}

	timestampToArray(ms: number)
	{
		return dayjs.tz(ms, this.timeZoneName).toArray() as IDateOutputArray
	}
}

const dayJsFullCalendarTimeZonePlugin = createPlugin({
	name: 'dayjs-fullcalendar-timezone',
	namedTimeZonedImpl: DayjsNamedTimeZone,
}) as PluginDef

export { dayjs, dayJsFullCalendarTimeZonePlugin };
