<a
    *ngIf="!disabled"
    pButton
    type="button"
    icon="pi pi-external-link"
    [pTooltip]="tooltip"
    [tooltipPosition]="tooltipPosition"
    [routerLink]="link"
></a>

<button
    *ngIf="disabled"
    pButton
    icon="pi pi-external-link"
    disabled
    ></button>
