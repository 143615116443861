import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { CONDITIONS } from '@karve.it/rule-checker';

import { RulePartsFragment } from '../../../generated/graphql.generated';

export interface FormDetailProperty {
    name: string;
    type: PropTypeOptions;
    label?: string;
}

export interface FormDetail {
    form: string;
    formLabel: string;
    properties: FormDetailProperty[];
    showFormProperties?: boolean;
}

export const estimatorDetails: FormDetail = {
    form: 'estimating',
    formLabel: 'Estimating',
    showFormProperties: true,
    properties: [
        {
            label: 'Always True',
            name: 'alwaystrue',
            type: 'boolean',
        },
        //=======> Starting Location
        {
            label: 'Starting Location Area Code',
            name: 'starting_location.areaCode',
            type: 'string',
        },
        {
            label: 'Starting Location Unit',
            name: 'starting_location.unit',
            type: 'string',
        },
        {
            label: 'Starting Location # Bedrooms',
            name: 'starting_location.bedrooms',
            type: 'number',
        },
        {
            label: 'Starting Location Sqft',
            name: 'starting_location.sqft',
            type: 'number',
        },
        {
            label: 'Partial Move',
            name: 'starting_location.partialMove',
            type: 'boolean',
        },
        {
            label: 'More than 10 items',
            name: 'starting_location.moreThan10Items',
            type: 'boolean',
        },
        {
            label: 'Starting Location Country',
            name: 'starting_location.country',
            type: 'string',
        },
        {
            label: 'Starting Location City',
            name: 'starting_location.city',
            type: 'string',
        },
        {
            label: 'Starting Location Jurisdiction',
            name: 'starting_location.jurisdiction',
            type: 'string',
        },
        {
            label: 'Starting Location Dwelling Type',
            name: 'starting_location.dwellingType',
            type: 'string',
        },
        {
            label: 'Starting Location Stairs',
            name: 'starting_location.stairs',
            type: 'number',
        },
        {
            label: 'Starting Location Elevators',
            name: 'starting_location.elevators',
            type: 'string',
        },

        {
            label: 'Starting Location Elevators Bookable',
            name: 'starting_location.elevatorsBookable',
            type: 'boolean',
        },
        {
            label: 'Starting Location Total Sqft w/extras',
            name: 'starting_location.totalSqftWithExtras',
            type: 'number',
        },

        //=======> Extras (shed, garage, storage unit)
        {
            label: 'Shed Total Sqft',
            name: 'shed.totalSqft',
            type: 'number',
        },
        {
            label: 'Shed Count',
            name: 'shed.count',
            type: 'number',
        },
        {
            label: 'Garage Total Sqft',
            name: 'garage.totalSqft',
            type: 'number',
        },
        {
            label: 'Garage Count',
            name: 'garage.count',
            type: 'number',
        },
        {
            label: 'Garage Total Vehicle Capacity',
            name: 'garage.totalVehicleCapacity',
            type: 'number',
        },
        {
            label: 'Storage Unit Total Sqft',
            name: 'storageUnit.totalSqft',
            type: 'number',
        },
        {
            label: 'Storage Unit Count',
            name: 'storageUnit.count',
            type: 'number',
        },
        {
            label: 'Storage Unit Indoors',
            name: 'storageUnit.indoors',
            type: 'boolean',
        },
        {
            label: 'Extras Total Sqft',
            name: 'extras.totalSqft',
            type: 'number',
        },
        {
            label: 'Extras Count',
            name: 'extras.count',
            type: 'number',
        },
        {
            label: 'Extras Types',
            name: 'extras.types',
            type: 'array-str',
        },


        //=======> End Location
        {
            label: 'End Location Area Code',
            name: 'ending_location.areaCode',
            type: 'string',
        },
        {
            label: 'End Location Unit',
            name: 'ending_location.unit',
            type: 'string',
        },
        {
            label: 'End Location Sqft',
            name: 'ending_location.sqft',
            type: 'number',
        },
        {
            label: 'End Location Country',
            name: 'ending_location.country',
            type: 'string',
        },
        {
            label: 'End Location City',
            name: 'ending_location.city',
            type: 'string',
        },
        {
            label: 'End Location Jurisdiction',
            name: 'ending_location.jurisdiction',
            type: 'string',
        },
        {
            label: 'End Location Dwelling Type',
            name: 'ending_location.dwellingType',
            type: 'string',
        },
        {
            label: 'End Location Stairs',
            name: 'ending_location.stairs',
            type: 'number',
        },
        {
            label: 'End Location Elevators',
            name: 'ending_location.elevators',
            type: 'string',
        },
        {
            label: 'End Location Elevators Bookable',
            name: 'ending_location.elevatorsBookable',
            type: 'boolean',
        },

        //=======> Distances
        {
            label: 'Dock -> Dock: Estimated Time (seconds)',
            name: 'distances.dock_start_end_dock.estimatedTime',
            type: 'number',
        },
        {
            label: 'Dock -> Dock: Total Distance (meters)',
            name: 'distances.dock_start_end_dock.totalDistance',
            type: 'number',
        },
        {
            label: 'Dock -> Dock: Distance Units',
            name: 'distances.dock_start_end_dock.distanceUnits',
            type: 'number',
        },

        {
            label: 'Start -> End: Estimated Time (seconds)',
            name: 'distances.start_end.estimatedTime',
            type: 'number',
        },
        {
            label: 'Start -> End: Total Distance (meters)',
            name: 'distances.start_end.totalDistance',
            type: 'number',
        },
        {
            label: 'Start -> End: Distance Units',
            name: 'distances.start_end.distanceUnits',
            type: 'number',
        },

        {
            label: 'Dock -> Start: Estimated Time (seconds)',
            name: 'distances.dock_start.estimatedTime',
            type: 'number',
        },
        {
            label: 'Dock -> Start: Total Distance (meters)',
            name: 'distances.dock_start.totalDistance',
            type: 'number',
        },
        {
            label: 'Dock -> Start: Distance Units',
            name: 'distances.dock_start.distanceUnits',
            type: 'number',
        },

        {
            label: 'End -> Dock: Estimated Time (seconds)',
            name: 'distances.end_dock.estimatedTime',
            type: 'number',
        },
        {
            label: 'End -> Dock: Total Distance (meters)',
            name: 'distances.end_dock.totalDistance',
            type: 'number',
        },
        {
            label: 'End -> Dock: Distance Units',
            name: 'distances.end_dock.distanceUnits',
            type: 'number',
        },

        //=======> Customer Info
        // {
        //     label: 'Customer Used Us Before',
        //     name: 'customer.usedUsBefore',
        //     type: 'boolean',
        // },
        // {
        //     label: 'Customer Used Other Before',
        //     name: 'customer.usedOtherBefore',
        //     type: 'boolean',
        // },

        //=======> Signature
        {
            label: 'Client Signature Required',
            name: 'signature.required',
            type: 'boolean',
        },
        {
            label: 'Client Signature Required',
            name: 'signature.signed',
            type: 'boolean',
        },
        {
            label: 'Client Signed At',
            name: 'signature.signedAt',
            type: 'boolean',
        },
        {
            label: 'Client Signed Duration',
            name: 'signature.signedDuration',
            type: 'boolean',
        },

        //=======> Financing
        {
            label: 'Financing Required',
            name: 'financing.required',
            type: 'boolean',
        },

    ],
};

const jobProperties: FormDetailProperty[] = [
    {
        label: 'Job Stage',
        name: 'job.stage',
        type: 'string',
    },
    {
        label: 'Job Closed Reason',
        name: 'job.closedReason',
        type: 'string',
    },
    {
        label: 'Zone ID',
        name: 'zone.id',
        type: 'string',
    },
];

export const jobCreatedDetails: FormDetail = {
    form: 'Job Created',
    formLabel: 'Job Created',
    properties: [
        ...jobProperties.map((p) => {
            return {
                ...p,
                name: `data.${ p.name }`,
            }
        }),
    ],
};

export const jobUpdatedDetails: FormDetail = {
    form: 'Job Updated',
    formLabel: 'Job Updated',
    properties: [
        ...jobProperties.map((p) => {
            return {
                ...p,
                name: `data.${ p.name }`,
            }
        }),
        ...jobProperties.map((p) => {
            return {
                ...p,
                name: `change.${ p.name }`,
                label: `Change: ${ p.label }`,
            }
        }),
    ],
};


export const formDetails: FormDetail[] = [
    estimatorDetails,
    jobCreatedDetails,
    jobUpdatedDetails,
];

export const forms = formDetails.map((r) => ({
    label: r.formLabel,
    value: r.form
}));

export const propTypeOptions = [ 'number', 'string', 'boolean', 'array-str', 'array-num', 'array-bool' ] as const;
export type PropTypeOptions = typeof propTypeOptions[number];

export const conditionalInfo: {
    [ key: string ]: {
        alias: string;
        block?: boolean;
        typeOptions?: PropTypeOptions[];
        enum?: string[];
    };
} = {
    XOR: {
        alias: 'XOR (either but not both)',
        block: true,
    },
    OR: {
        alias: 'OR',
        block: true,
    },
    AND: {
        alias: 'AND',
        block: true,
    },
    NOT: {
        alias: 'NOT',
        block: true,
    },
    _EQUALS: {
        alias: 'equals',
    },
    _RAWEQUALS: {
        alias: 'raw equals',
    },
    _EXISTS: {
        alias: 'exists',
        typeOptions: [ 'boolean' ],
    },
    _GT: {
        alias: 'is greater than',
        typeOptions: [ 'number' ],
    },
    _GTE: {
        alias: 'is greater than or equal to',
        typeOptions: [ 'number' ],
    },
    _LT: {
        alias: 'is lesser than',
        typeOptions: [ 'number' ],
    },
    _LTE: {
        alias: 'is lesser than or equal to',
        typeOptions: [ 'number' ],
    },
    _IN: {
        alias: 'is in',
        typeOptions: [ 'array-str', 'array-num', 'array-bool' ],
    },
    _INCLUDES: {
        alias: 'includes',
    },
    _TYPEOF: {
        alias: 'is of type',
        typeOptions: [ 'string' ],
        enum: [ 'string', 'number', 'boolean', 'null', 'undefined', 'array' ],
    },

};

export const advancedConditions = [ 'XOR', 'OR', 'AND', 'NOT' ];

export const conditionalTypeOptions: {
    label: string;
    value: PropTypeOptions;
    allowedConditions?: string[];
    disabledConditions?: string[];
}[] = [
    {
        label: 'Number',
        value: 'number',
        disabledConditions: [ '_INCLUDES' ],
    },
    {
        label: 'Text',
        value: 'string',
        disabledConditions: [ '_INCLUDES', '_LT', '_LTE', '_GT', '_GTE' ],
    },
    {
        label: 'Boolean',
        value: 'boolean',
        allowedConditions: [ '_TYPEOF', '_IN', '_EXISTS', '_EQUALS', '_RAWEQUALS' ],
    },
    {
        label: 'Array (text)',
        value: 'array-str',
        allowedConditions: [ '_TYPEOF', '_INCLUDES' ],
    },
    {
        label: 'Array (number)',
        value: 'array-num',
        allowedConditions: [ '_TYPEOF', '_INCLUDES' ],
    },
    {
        label: 'Array (boolean)',
        value: 'array-bool',
        allowedConditions: [ '_TYPEOF', '_INCLUDES' ],
    },
];

function getConditionTypeOption(valueType: string) {
    return conditionalTypeOptions.find((cto) => cto.value === valueType);
}

export function conditionArrayValidator(fc: UntypedFormControl): ValidationErrors | null {
    const arr: ConditionArray = fc.value;
    if (!Array.isArray(arr)) {
        return {
            conditions: 'must be an array'
        };
    }

    let errs: ValidationErrors | null = null;
    for (const c of arr) {
        const res = validateCondition(c);
        if (res) {
            errs = {
                ...errs,
                ...res,
            };
        }
    }

    return errs;

    function validateCondition(
        c: ConditionInfo,
        parent?: string,
    ) {
        const key = `${ parent ? parent + '.' : '' }${ c.property || '' }${ c.condition || '' }`;
        if (!c) {
            return {
                [ key ]: 'must be an object',
            };
        }

        if (advancedConditions.includes(c.condition)) {
            if (!Array.isArray(c.value)) {
                return {
                    [ key ]: 'value must be an array',
                };
            }

            let valErrs;
            for (const val of c.value) {
                const res = validateCondition(val, key);
                if (res) {
                    valErrs = {
                        ...valErrs,
                        ...res,
                    };
                }
            }

            return valErrs;
        }

        if (!c.condition) {
            return {
                [ key ]: 'must have a condition',
            };
        }

        if (!c.property) {
            return {
                [ key ]: 'must have a property',
            };
        }

        if (!c.type) {
            return {
                [ key ]: 'must have a type',
            };
        }

        return null;
    }

}

export function getPropertyInfo(formName: string, propertyName: string) {
    const form = formDetails.find((f) => f.form === formName);
    const defaultProperty = {
        label: propertyName,
        name: propertyName,
        type: 'string',
    };

    if (!form) {
        return defaultProperty;
    }

    const property = form.properties.find((p) => p.name === propertyName);
    if (!property) {
        return defaultProperty;
    }

    return property;
}

export function determineValueTypeOption(value: any) {
    const valueType = typeof value;
    const typeOption = getConditionTypeOption(valueType);
    if (typeOption) {
        return typeOption;
    }

    if (Array.isArray(value) && typeof value[0] === 'string') {
        return getConditionTypeOption('array-str');
    }

    if (Array.isArray(value) && typeof value[0] === 'number') {
        return getConditionTypeOption('array-num');
    }

    if (Array.isArray(value) && typeof value[0] === 'boolean') {
        return getConditionTypeOption('array-bool');
    }

    return undefined;
}

export const conditionalConditionOptions = Object.entries(CONDITIONS)
    .filter(([ label, value ]) => !advancedConditions.includes(label))
    .map(([ label, value ]) => {
        const info = conditionalInfo[value];

        let newLabel = label;
        if (info && info.alias) {
            newLabel = info.alias;
        }

        return {
            label: newLabel,
            value,
        };
    });


export interface ConditionInfo {
    property?: string;
    condition?: string;
    type?: PropTypeOptions;
    value?: any;
    }

export type ConditionArray = Array<ConditionInfo>;

export interface DescribedRule extends Omit<RulePartsFragment, 'condition'> {
  given: string;
  when: string;
  then: string;
  str: string;
  condition: Record<string, unknown>;
}
