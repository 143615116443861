import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PlusApollo, UserService } from '@karve.it/core';
import { describeRule } from '@karve.it/rule-checker';
import { clone } from 'lodash';
import { PermissionService } from 'src/app/services/permission.service';
import { parseMenuItemCategoriesVisible } from 'src/app/utilities/menu-item.util';
import { SubSink } from 'subsink';

import { BaseZoneFragment, GetRuleZonesDocument, GetRuleZonesQuery, GetRuleZonesQueryVariables } from '../../../generated/graphql.generated';

import { DescribedRule } from '../../franchise/rules/rules.constants';
import { safeParseJSON } from '../../js';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { MutateRuleComponent } from '../mutate-rule/mutate-rule.component';





@Component({
  selector: 'app-rule-details',
  templateUrl: './rule-details.component.html',
  styleUrls: ['./rule-details.component.scss']
})
export class RuleDetailsComponent implements OnInit, OnDestroy {

  @Input() rule: DescribedRule;
  @ViewChild('mutate') mutateRef: MutateRuleComponent;

  totalZones: number;
  zones: BaseZoneFragment[];



  updateRuleAction = {
    label: 'Edit',
    icon: 'pi pi-pencil',
    visible: false,
    command: () => {
      this.openUpdate();
    }
  };

  duplicateRuleAction = {
    label: 'Duplicate',
    icon: 'pi pi-clone',
    visible: false,
    command: () => {
      this.openDuplicate();
    }
  };

  deleteRuleAction = {
    label: 'Delete',
    icon: 'pi pi-trash',
    visible: false,
    command: () => {
      this.openDelete();
    }
  };


  ruleActions = [{
    label: 'Actions',
    items: [
      this.updateRuleAction,
      this.duplicateRuleAction,
      this.deleteRuleAction
    ],
    visible: false,
  }];

  subs = new SubSink();

  constructor(
    private localNotify: FreyaNotificationsService,
    private detailsHelper: DetailsHelperService,
    private permissionHandler: PermissionService,
    private userService: UserService,
    private apollo: PlusApollo,
  ) { }

  ngOnInit(): void {
    this.initializePermissions();
    // load zones when details is opened
    this.subs.sink = this.detailsHelper.detailsItem.subscribe((item) => {
      if (item?.type !== 'rules') { return; }
      this.zones = undefined;

      if (typeof item.item?.condition === 'string') {
        item.item = {
          ...item.item,
          ...describeRule(item.item),
          condition: safeParseJSON(item.item.condition, '{}'),
        };
      }

      // wait for end of event loop
      setTimeout(() => {
        this.loadZones();
      }, 0);
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  loadZones() {
    this.zones = undefined;
    if (!this.rule?.id) { return; }
    this.subs.sink = this.apollo.query<GetRuleZonesQuery, GetRuleZonesQueryVariables>({
      query: GetRuleZonesDocument,
      variables: {
        ruleId: this.rule.id,
      },
    }).subscribe((res) => {
      const [rule] = res.data?.rules?.rules;
      if (!rule || !rule.zones) {
        this.zones = null;
        return;
      }

      this.totalZones = rule.zones.total;

      this.zones = rule.zones.nodes;
    });

  }

  initializePermissions() {
    this.subs.sink = this.permissionHandler.watchPermissions(
      ['rules.update', 'rules.create', 'rules.delete'])
      .subscribe((res) => {
        this.updateRuleAction.visible = res[0];
        this.duplicateRuleAction.visible = res[1];
        this.deleteRuleAction.visible = res[2];

        const [ actions ] = this.ruleActions;

        actions.visible = actions.items.some((a) => a.visible);

        parseMenuItemCategoriesVisible(this.ruleActions);
        this.ruleActions = clone(this.ruleActions);
      });
  }

  openUpdate() {
    this.mutateRef.rule = this.rule;
    this.mutateRef.mutateType = 'update';
    this.mutateRef.openDialog();
  }

  openDelete() {
    this.mutateRef.rule = this.rule;
    this.mutateRef.openDelete();
  }

  openDuplicate() {
    this.mutateRef.rule = this.rule;
    this.mutateRef.mutateType = 'duplicate';
    this.mutateRef.openDialog();
    // this.mutateRef.rule = this.rule;
    // this.mutateRef.openDelete();
  }


}
