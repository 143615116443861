import { Component, OnDestroy, } from '@angular/core';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss']
})
export class MarketingComponent implements OnDestroy {

  constructor() {
  }

  ngOnDestroy(){
  }

}
