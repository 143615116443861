import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Pipe({
  name: 'canChangeZone'
})
export class CanChangeZonePipe implements PipeTransform {
  transform(route: string): unknown {
    if(route.indexOf('estimating') >= 0){
      return false;
    } else {
      return true;
    }
  }
}
