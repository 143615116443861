import { Injectable } from '@angular/core';
import { PlusApollo } from '@karve.it/core';

import { CreateRulesDocument, CreateRulesMutation, CreateRulesMutationVariables, DeleteRulesDocument, DeleteRulesMutation, DeleteRulesMutationVariables, GetRuleTriggersDocument, GetRuleTriggersQuery, ListRulesDocument, ListRulesQuery, ListRulesQueryVariables, UpdateRulesDocument, UpdateRulesMutation, UpdateRulesMutationVariables } from 'graphql.generated';

import { KarveRequestOptions, KarveWatchRequestOptions, parseRequestOptions } from '../options';

@Injectable({
  providedIn: 'root'
})
export class RuleService {

    constructor(private apollo: PlusApollo){}

    listRules(variables: ListRulesQueryVariables, opts?: KarveRequestOptions) {
        return this.apollo.query<ListRulesQuery, ListRulesQueryVariables>({
            query: ListRulesDocument,
            variables,
            ...parseRequestOptions(opts),
        });
    }

    watchRules(variables: ListRulesQueryVariables, opts?: KarveWatchRequestOptions) {
        return this.apollo.watchQuery<ListRulesQuery, ListRulesQueryVariables>({
            query: ListRulesDocument,
            variables,
            ...parseRequestOptions(opts),
        });
    }

    createRules(variables: CreateRulesMutationVariables, opts?: KarveRequestOptions) {
        return this.apollo.mutate<CreateRulesMutation, CreateRulesMutationVariables>({
            mutation: CreateRulesDocument,
            variables,
            ...parseRequestOptions(opts),
            fetchPolicy: 'network-only',
        });
    }

    updateRules(variables: UpdateRulesMutationVariables, opts?: KarveRequestOptions) {
        return this.apollo.mutate<UpdateRulesMutation, UpdateRulesMutationVariables>({
            mutation: UpdateRulesDocument,
            variables,
            ...parseRequestOptions(opts),
            fetchPolicy: 'network-only',
        });
    }

    deleteRules(variables: DeleteRulesMutationVariables, opts?: KarveRequestOptions) {
        return this.apollo.mutate<DeleteRulesMutation, DeleteRulesMutationVariables>({
            mutation: DeleteRulesDocument,
            variables,
            ...parseRequestOptions(opts),
            fetchPolicy: 'network-only',
        });
    }

    getRuleTriggers(opts?: KarveRequestOptions) {
        return this.apollo.query<GetRuleTriggersQuery>({
            query: GetRuleTriggersDocument,
            ...parseRequestOptions(opts),
        });
    }
}
