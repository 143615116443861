<div class="p-grid">
    <div
        id="left-side"
        class="p-md-6"
    >
        <h5>
            <p-radioButton
                name="contact-group"
                value="create"
                inputId="create"
                (onClick)="unselectUser()"
                [(ngModel)]="customerType"
                [disabled]="!permissions.updateJob"
                data-cy="create-customer-radio-button"
            ></p-radioButton>
            <label
                class="p-ml-1"
                for="create"
            >Create New Customer</label>
        </h5>
        <br>
        <div
            class="p-grid"
            [formGroup]="customerForm"
        >
            <div class="p-md-12">
                <span class="p-float-label">
                    <input
                        pInputText
                        formControlName="company"
                        type="text"
                        data-cy="first-name-text-field"
                        class="full-width"
                    >
                    <label for="company">Company</label>
                </span>
            </div>
            <div class="p-md-6">
                <span class="p-float-label">
                    <input
                        pInputText
                        formControlName="firstName"
                        type="text"
                        data-cy="first-name-text-field"
                        class="full-width"
                    >
                    <label for="firstName">First Name (required)</label>
                </span>
            </div>
            <div class="p-md-6">
                <span class="p-float-label">
                    <input
                        pInputText
                        formControlName="lastName"
                        type="text"
                        data-cy="last-name-text-field"
                        class="full-width"
                    >
                    <label for="lastName">Last Name (required)</label>
                </span>
                <!-- <button type="button" pButton pRipple label="Search"></button> -->
            </div>
            <div class="p-md-12" *appVar="customerForm.get('billingAddress') as control">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input
                                    *ngIf="googleHelper.loaded | async"
                                    pInputText
                                    #googleAutocomplete="ngx-places"
                                    ngx-gp-autocomplete
                                    autocomplete="off"
                                    placeholder=""
                                    formControlName="billingAddress"
                                    (onAddressChange)="handleAddressChange($event)"
                                    (input)="clearBillingAddress()"
                                    >
                                <label for="billingAddress">Billing Address</label>
                            </span>
                            <button
                                type="button"
                                pButton
                                pRipple
                                icon="pi pi-times"
                                pTooltip="Clear"
                                tooltipPosition="left"
                                [disabled]="!control.value?.length || selectedUser?.id || (estimateHelper.jobLoading | async)"
                                (click)="clearBillingAddressControl()"
                                ></button>
                        </div>
                <app-validation-messages
                    [control]="control"
                    fieldName="billingAddress"
                    >
                </app-validation-messages>
            </div>
            <div class="p-md-6">
                <span class="p-float-label">
                    <input
                        pInputText
                        formControlName="email"
                        type="email"
                        data-cy="email-text-field"
                    >
                    <label for="email">Email</label>
                </span>
            </div>
            <div class="p-md-6">
                <span class="p-float-label">
                    <p-inputMask
                        type="tel"
                        formControlName="phone"
                        [autoClear]="false"
                        [unmask]="true"
                        mask="(999) 999-9999"
                        data-cy='phone-number-field'
                        styleClass="full-width"
                    >
                    </p-inputMask>
                    <label for="phone">Phone Number</label>
                </span>
            </div>

            <hr>
            <!-- <p-checkbox
                class="p-col-12"
                formControlName="usedUsBefore"
                ruleProperty="customer.usedUsBefore"
                binary="true"
                label="Have they used our services before?"
                [hidden]="true"
            ></p-checkbox> -->
            <small
                class="p-px-3"
                *ngIf="existingInvoices?.length"
            >
                Previous jobs:
                <span
                    class="existing-invoice"
                    *ngFor="let invoice of existingInvoices; let i = index"
                >
                    <a (click)="detailsHelper.open('job', {id: invoice.id})">{{invoice.code}}</a>
                    <span *ngIf="i < existingInvoices.length - 1; else period">, </span>
                    <ng-template #period>.</ng-template>
                </span>
            </small>
            <!-- <p-checkbox
                class="p-col-12"
                formControlName="usedOtherBefore"
                ruleProperty="customer.usedOtherBefore"
                binary="true"
                label="Have they used other moving companies before?"
            ></p-checkbox> -->
            <div class="p-col-12">
                <br>
                <span class="p-float-label full-width-float-label">
                    <p-dropdown
                        [placeholder]="job?.id || !this.howHeardMandatory ? 'How did they hear about us?' : 'A source is required to create this job'"
                        formControlName="howDidTheyHearAboutUs"
                        [style]="{width: '100%'}"
                        [options]="howHeardDropdownOptions"
                    ></p-dropdown>
                    <label for="howDidTheyHearAboutUs">
                        Referral Source - How did they hear about us?
                        {{ this.howHeardMandatory ? '(required)' : '' }}
                    </label>
                </span>
            </div>

            <div class="p-col-6">
                <span class="p-float-label full-width-float-label">
                    <p-dropdown
                        [options]="customerTypes"
                        formControlName="type"
                        [style]="{width: '100%'}"
                    ></p-dropdown>
                    <label for="type">Customer Type</label>
                </span>
            </div>
            <div class="p-col-6">
                <span class="p-float-label full-width-float-label">
                    <p-dropdown
                        [options]="jobOrigins"
                        formControlName="origin"
                        [style]="{width: '100%'}"
                    ></p-dropdown>
                    <label for="origin">Job Origin</label>
                </span>
            </div>
            <div class="p-col-12">
                <label for="timeline" class="label-hack">Timeline</label>
                <p-calendar 
                    formControlName="timeline"
                    [inline]="true"
                    selectionMode="range" 
                ></p-calendar>
            </div>
        </div>
    </div>

    <div
        id="right-side"
        class="p-md-6"
    >
        <div class="p-grid p-align-center">
            <div class="p-col">
                <h5 class="p-align-center no-margin">
                    <p-radioButton
                        name="contact-group"
                        [disabled]="true"
                        [value]="customerForm.value.userId"
                        inputId="existing"
                        [(ngModel)]="customerType"
                        data-cy="existing-contact-radio-button"
                    ></p-radioButton>
                    <label
                        class="p-ml-1"
                        for="existing"
                    >Select Existing Contact</label>
                    <span *ngIf="selectedUser?.id"> - {{this.selectedUser?.givenName}}
                        {{this.selectedUser?.familyName}}</span>
                </h5>
            </div>
            <div *hasPermission="['users.edit']" class="p-col-3 p-text-right">
                <button
                    class="p-m-1 p-text-right"
                    pButton
                    *ngIf="selectedUser?.id"
                    (click)="openMutateCustomer()"
                    icon="pi pi-pencil"
                ></button>
            </div>
        </div>
        <br>
        <div class="p-grid">
            <div class="p-col">
                <button
                    type="button"
                    (click)="searchForUsers('name')"
                    pButton
                    pRipple
                    label="Search By Name"
                    data-cy="search-by-name-button"
                ></button>
            </div>
            <div class="p-col">
                <button
                    type="button"
                    (click)="searchForUsers('email')"
                    pButton
                    pRipple
                    label="Search By Email"
                ></button>
            </div>
            <div class="p-col">
                <button
                    type="button"
                    (click)="searchForUsers('phone')"
                    pButton
                    [disabled]="!customerForm.value.phone"
                    pRipple
                    label="Search By Phone"
                ></button>
            </div>
        </div>
        <div>
            <p-table [value]="userSearchResults">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Select</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-user
                >
                    <tr
                        class="selectable-row"
                        for="contact-group"
                    >
                        <td>
                            <p-radioButton
                                name="contact-group"
                                value="{{user.id}}"
                                (onClick)="selectUser(user)"
                                [(ngModel)]="customerType"
                                [disabled]="!permissions.updateJob"
                                data-cy="user-radio-buttons"
                            ></p-radioButton>
                        </td>
                        <td>{{user.givenName}} {{user.familyName}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user.phone}}</td>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="emptymessage"
                    let-car
                    let-columns="columns"
                >
                    <tr>
                        <td [attr.colspan]="'4'">
                            {{!userSearchResults ? 'Click Search to Find Users' : 'No Users Found'}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>

        </div>
        <br>
        <button
            type="button"
            class="p-button-secondary"
            (click)="unselectUser()"
            [disabled]="!customerForm.value.userId || customerType === 'create' || !permissions.updateJob"
            pButton
            pRipple
            label="Unselect User"
        ></button>
    </div>
</div>
