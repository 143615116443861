import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PlusAuthenticationService } from '@karve.it/core';
import { countries } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { MutateAreaComponent } from 'src/app/shared/mutate-area/mutate-area.component';
import { SubSink } from 'subsink';

import { BaseZoneWithAreasFragment, ListZonesWithAreasGQL, ListZonesWithChildrenQueryVariables } from '../../../generated/graphql.generated';
import { BrandingService } from '../../services/branding.service';

@Component({
  selector: 'app-zone-areas',
  templateUrl: './zone-areas.component.html',
  styleUrls: ['./zone-areas.component.scss']
})
export class ZoneAreasComponent implements OnInit, OnDestroy {

  @ViewChild('ma') mutateAreaRef: MutateAreaComponent;

  @Input() cardView = false;

  subs = new SubSink();

  zoneQueryRef: ReturnType<typeof this.listZonesWithAreasGQL['watch']>;
  zoneAreas: BaseZoneWithAreasFragment[];
  areasLoading = true;

  countries = countries;

  constructor(
    private detailHelper: DetailsHelperService,
    private brandingSvc: BrandingService,
    private listZonesWithAreasGQL: ListZonesWithAreasGQL,
  ) { }

  ngOnInit(): void {
    this.watchZones();

    this.subs.sink = this.detailHelper.getObjectUpdates('Zones').subscribe((res) => {
      this.zoneQueryRef.refetch();
    });

    this.subs.sink = this.brandingSvc.currentZone().subscribe((res) => {
      if (!this.zoneQueryRef) { return; }
      if (!this.brandingSvc.currentZone().value) { return; }

      this.watchZones();
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getVariables(): ListZonesWithChildrenQueryVariables {
    return {
      limit: -1,
      filter: {
        parent: this.brandingSvc.currentZone().value.id,
      },
    };
  }

  watchZones() {
    if (!this.brandingSvc.currentZone().value) { return; }
    if (this.zoneQueryRef) {
      this.zoneQueryRef.setVariables(this.getVariables());
      return;
    }

    this.zoneQueryRef = this.listZonesWithAreasGQL.watch(this.getVariables());

    this.subs.sink = this.zoneQueryRef.valueChanges.subscribe((res) => {
      if (res.data?.zones?.nodes) {
        this.zoneAreas = res.data.zones.nodes;
        this.areasLoading = false;
      }
    });
  }

  openCreate() {
    this.mutateAreaRef.mutateType = 'create';
    this.mutateAreaRef.openDialog();
  }

}
