<div
    class="p-grid"
    *ngIf="job"
>
    <div class="p-col-12">
        <div class="card">
            <div class="header-with-button p-py-0">
                <h5>Events</h5>
                <app-add-event-button
                    *hasPermission="['calendarEvents.create']"
                    [job]="job"
                    [disabled]="readonly || (estimateHelper.jobLoading | async)"
                    [onEstimator]="onEstimator"
                ></app-add-event-button>
            </div>
            <div class="p-d-md-none">
                <br>
                <p-dropdown
                class="full-width-dropdown"
                [options]="job.events"
                [(ngModel)]="selectedEvent"
                (onChange)="setActiveEventId(job.events.indexOf(selectedEvent), true)"
                appendTo="body"
            >
                <ng-template
                    let-e
                    pTemplate="item"
                >
                    {{ e.title }} ({{e.status | titlecase}}), {{e?.start ?  (e.start | freyaDate: 'h:mm a, MMM d') : 'Unscheduled'}}
                </ng-template>
                <ng-template pTemplate="selectedItem">
                    <span *ngIf="selectedEvent">
                        {{ selectedEvent.title }} ({{selectedEvent.status | titlecase}}), {{selectedEvent.start ? ( selectedEvent.start | freyaDate: 'h:mm a, MMM d') : 'Unscheduled'}}
                    </span>
                </ng-template>
            </p-dropdown>
            </div>
            <p-tabView 
                *ngIf="job?.events?.length" 
                [activeIndex]="job.events | getObjectById : activeEventId : true"
                >
                <p-tabPanel *ngFor="let event of job.events">
                    <ng-template pTemplate="header">
                        <div (click)="setActiveEventId(job.events.indexOf(event))">
                            <span>{{event.title}} ({{event.status | titlecase}})</span>
                            <br>
                            <small>{{event.start ? (event.start | freyaDate: 'h:mm a, MMM d') : 'Unscheduled'}}</small>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <app-event-confirmation
                            [event]="event"
                            [readonly]="readonly"
                            [readonlyTooltip]="readonlyTooltip"
                            [job]="job"
                            (printButtonClicked)="printConfirmationPage()"
                        ></app-event-confirmation>
                    </ng-template>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
    <div
        id="left-side"
        class="p-col-12 p-md-6"
    >
        <div
            class="card"
            #detailsCard
        >
        <div class="p-grid">
            <div class="p-col-12">
                <h5>Job Details</h5>
                <p>
                    {{ job?.metadata?.jobOrigin || '' }}
                    {{ job?.metadata?.jobType ? ' - ' + job.metadata.jobType : '' }}
                    {{ displayFields?.howDidTheyHearAboutUs ? ' - ' + displayFields.howDidTheyHearAboutUs : '' }}
                </p>
                <p> 
                    <b>Dock Location: </b> 
                    <a
                    *ngIf="jobLocations.dock.location; else noLocationSet" 
                    [class.disabled]="!jobLocations.dock.location"
                    target="_blank"
                    [href]="viewLocationOnGoogleMaps(jobLocations.dock.location)"
                    > 
                    {{jobLocations.dock?.addressString}}
                    </a>
                </p>
            </div>
            <div class="p-col-12 p-lg-6" *ngFor="let jobLocation of [
                jobLocations.start,
                jobLocations.end,
            ]" >
                <h5>{{jobLocation.title}}</h5>
                <div *ngIf="jobLocation?.location; else noLocationSet">
                <a
                    [class.disabled]="!jobLocation.location"
                    target="_blank"
                    [href]="viewLocationOnGoogleMaps(jobLocation.location)"
                >
                {{ jobLocation?.addressString }}
                </a>
                <p class="p-mt-4" > {{jobLocation?.details }}</p>
                <p class="p-mt-4" *ngIf="jobLocation?.parkingInformation"> <b>Parking:</b> {{jobLocation?.parkingInformation}} </p>
            </div>

            </div>
            <div class="p-col-12">
                <div class="other-locations">
                    <div *ngFor="let location of jobLocations.otherStops"> 
                        <b> {{location?.locationType | titlecase }}</b> 
                        <br>
                        <a
                            [class.disabled]="!location.location"
                            target="_blank"
                            [href]="viewLocationOnGoogleMaps(location.location)"
                        >
                        {{ location?.addressString }}
                        </a>   
                    </div>
                </div>
            </div>
            <div class="p-col-12">
                <div class="p-d-flex p-flex-column p-flex-lg-row p-ai-start p-flex-wrap" style="gap: 1rem;" >
                    <span
                    *hasPermission="['artifacts.create']"
                    [pTooltip]="(documentsJobId && documentsJobId !== job.id) ? 'Documents Dialog is being used for another job' : readonlyTooltip"
                    tooltipPosition="bottom"
                >
                    <button
                        pButton
                        label="Create Document"
                        icon="pi pi-file"
                        (click)="openDocuments(0)"
                        [disabled]="readonly || documentsJobId && documentsJobId !== job.id"
                    ></button>
                </span>
                <!-- <span
                    *hasPermission="['artifacts.send']"
                    [pTooltip]="(documentsJobId && documentsJobId !== job.id) ? 'Documents Dialog is being used for another job' : readonlyTooltip"
                    tooltipPosition="bottom"
                >
                    <button
                        pButton
                        label="Send Document"
                        icon="pi pi-send"
                        (click)="openDocuments(1)"
                        [disabled]="readonly || documentsJobId && documentsJobId !== job.id"
                    ></button>
                </span> -->
                <!-- <button
                    pButton
                    label="View History"
                    icon="pi pi-book"
                    (click)="openHistory()"
                ></button> -->
                <p-button
                    [outlined]="true"
                    icon="pi pi-directions"
                    (click)="showGoogleMapsRoute()"
                    label="View Route"
                ></p-button>
                </div>
            </div>
        </div>
            <ng-template #noLocationSet>No Location Set</ng-template>
        </div>
        <div
            class="card"
#inventoryCard
        >
            <app-estimating-inventory #estimateConfirmationInventory [job]="job" [readonly]="true" [readonlyNotesScroll]="true">
            </app-estimating-inventory>
        </div>
        <div class="card">
            <app-distances
                #distances
                *ngIf="googleHelper.loaded | async"
            ></app-distances>
        </div>

        <div #invoicesCard class="p-mb-5">
            <app-invoices
                #invoices
                layout="compact"
                [syncToQueryParams]="false"
                emptyMessage="This job has no invoices"
                [filter]="{
                    jobId: job?.id
                }"
                ></app-invoices>
        </div>

        <div #uploadsCard>
            <app-artifacts
                [readonly]="readonly"
                [readonlyTooltip]="readonlyTooltip"
                [jobConfirmationView]="true"
                [emptyMessage]="sharedFromAnotherZone ? 'Documents can not be viewed for shared jobs' : 'No Documents Found'"
                [filter]="{
                    relatedObjectIds: [this.job?.id],
                    relatedObjectLabels: ['Job'],
                    hasNoneAttributes: [ 'snapshot' ]
                }"
                [overrideUploadObjects]="this.job | jobArtifactRelatedObjects"
            ></app-artifacts>
        </div>
        <!-- <div #assetsCard>
            <app-assets [showHeaderCard]="false" [showSmallHeader]="true"></app-assets>
        </div> -->
        <div
            #usersCard
            class="card"
        >
            <div class="header-with-button">
                <h5>Users</h5>
                <span *hasPermission="['jobs.update']" [pTooltip]="readonlyTooltip">
                    <button
                        pButton
                        icon="pi pi-pencil"
                        (click)="openMutateJob('Users')"
                        [disabled]="readonly"
                    ></button>
                </span>
            </div>
            <p-table
                [value]="job?.users"
                breakpoint="620px"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Job Relation</th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-user
                >
                    <tr
                        class="selectable-row"
                        (click)="openUserDetails(user.user)"
                    >
                        <td>
                            <span class="p-column-title">Name</span>
                            {{user.user.givenName}} {{user.user.familyName}}
                        </td>
                        <td>
                            <span class="p-column-title">Email</span>
                            {{user.user.email}}
                        </td>
                        <td>
                            <span class="p-column-title">Job Relation</span>
                            {{user.role}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <div
        id="right-side"
        class="p-col-12 p-md-6"
    >
        <div class="card">
            <div class="p-grid p-align-top p-p-3">
                <div class="p-col p-align-center">
                    <h5>Line Items</h5>
                </div>
                <div class="p-col p-d-none p-d-md-block" *hasPermission="['fields.setValue']">
                    <span
                        [pTooltip]="readonlyTooltip"
                        tooltipPosition="left"
                    >
                        <span>Customer requires financing</span>
                        <br>
                        <p-inputSwitch
                            (onChange)="setFieldValues('misc.needsFinance', needsFinancing)"
                            ruleProperty="financing.required"
                            [(ngModel)]="needsFinancing"
                            [disabled]="readonly"
                        ></p-inputSwitch>
                    </span>
                </div>
            </div>
            <p-table
                [value]="eventsWithCharges"
                responsiveLayout="scroll"
                dataKey="id"
                rowExpandMode="multiple"
                [expandedRowKeys]="expandedRowKeys"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th>Line item</th>
                        <th>Unit Price</th>
                        <th>Quantity</th>
                        <th>Item Subtotal</th>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="body"
                    let-event
                    let-expanded="expanded"
                >
                    <tr>
                        <td>
                            <button
                                type="button"
                                pButton
                                pRipple
                                [pRowToggler]="event"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                            ></button>
                        </td>
                        <td>
                            <p
                                class="p-text-bold"
                                [class.freya-link-button]="event.event"
                                (click)="openEvent(event.event.id)"
                            >
                                <span>{{event.event.title | titlecase}} ({{event.event.status | titlecase}})</span>
                                <br>
                                <small>{{event.event.start ? (event.event.start | freyaDate : 'h:mm a, MMM d') : 'Unscheduled'}}</small>
                                <p-tag *ngIf="event.event | eventHasInvoice" class="p-ml-2" value="Invoiced" severity="success"></p-tag>
                            </p>
                        </td>
                        <td colspan="2"></td>
                        <td>
                            {{ event.event.discountedSubTotal | freyaCurrency : job.currency}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="rowexpansion"
                    let-event
                >
                    <tr *ngFor="let charge of event.charges | chargeDamage : true">
                        <td></td>
                        <td>
                            {{charge.product?.id ? charge.product.name : charge.productName}}
                        </td>
                        <td *ngIf="charge?.price?.priceType !== 'percentage'">
                            {{(charge.price ? charge.price.amount / 100 : charge.amount / 100) | currency :
                            job.currency}}
                        </td>
                        <td *ngIf="charge?.price?.priceType === 'percentage'">
                            {{ charge | chargeAmount }}%
                        </td>
                        <td>
                            {{charge.quantity}}
                        </td>
                        <td>
                            {{charge.chargeSubTotal / 100 | currency : job.currency}}
                        </td>
                    </tr>
                    <tr class="event-total-row">
                        <td
                            [attr.colspan]="'4'"
                            class="p-text-right"
                        >
                            Charges
                        </td>
                        <td>
                            {{((event.event.discountedSubTotalWithoutDamages) + (event.event.discountTotal)) / 100 | currency : job.currency}}
                        </td>
                    </tr>
                    <tr
                        class="short-row"
                        *ngIf="event?.event?.discounts?.length"
                    >
                        <td [attr.colspan]="'5'">
                            <b>Discounts</b>
                        </td>
                    </tr>
                    <tr *ngFor="let discount of event?.event?.discounts">
                        <td></td>
                        <td>
                            {{discount.discount.name}}
                        </td>
                        <td>
                            {{ discount | discountCustomAmount : job.currency }}
                        </td>
                        <td>
                        </td>
                        <td>
                            {{ discount.discountedAmount / 100 | currency : job.currency }}
                        </td>
                    </tr>
                    <tr class="event-total-row">
                        <td
                            [attr.colspan]="'4'"
                            class="p-text-right"
                        >
                            Discounts
                        </td>
                        <td>
                            {{-event.event.discountTotal / 100 | currency : job.currency}}
                        </td>
                    </tr>
                    <tr class="event-total-row">
                        <td
                            [attr.colspan]="'4'"
                            class="p-text-right"
                        >
                            Discounted Subtotal
                        </td>
                        <td>
                            {{ event.event.discountedSubTotalWithoutDamages / 100 | currency : job.currency }}
                        </td>
                    </tr>
                    <tr
                        class="short-row"
                        *ngIf="(event.charges | chargeDamage : false)?.length"
                    >
                        <td [attr.colspan]="'5'">
                            <b>Damages</b>
                        </td>
                    </tr>
                    <tr *ngFor="let damage of (event.charges | chargeDamage : false)">
                        <td></td>
                        <td>
                            {{damage.product?.id ? damage.product.name : damage.productName}}
                        </td>
                        <td>
                            {{(damage.price ? damage.price.amount / 100 : damage.amount / 100) | currency :
                            job.currency}}
                        </td>
                        <td>
                            {{damage.quantity}}
                        </td>
                        <td>
                            {{damage.chargeSubTotal / 100 | currency : job.currency}}
                        </td>
                    </tr>
                    <tr class="event-total-row">
                        <td
                            [attr.colspan]="'4'"
                            class="p-text-right"
                        >
                            Damages
                        </td>
                        <td>
                            {{(event.event.damageTotal) / 100 | currency : job.currency}}
                        </td>
                    </tr>
                    <tr class="event-total-row">
                        <td
                            [attr.colspan]="'4'"
                            class="p-text-right"
                        >
                            Subtotal
                        </td>
                        <td>
                            {{(event.event.discountedSubTotal || 0) / 100 | currency : job.currency}}
                        </td>
                    </tr>
                    <tr class="event-total-row">
                        <td
                            [attr.colspan]="'4'"
                            class="p-text-right"
                        >
                            Tax
                        </td>
                        <td>
                            {{event.event.taxTotal / 100 | currency : job.currency}}
                        </td>
                    </tr>
                    <tr class="event-total-row">
                        <td
                            [attr.colspan]="'4'"
                            class="p-text-right"
                        >
                            Total
                        </td>
                        <td>
                            {{event.event.total / 100 | currency : job.currency}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr>
                        <td colspan="5"></td>
                    </tr>
                    <tr class="short-row">
                        <td
                            [attr.colspan]="'4'"
                            class="p-text-right"
                        >
                            Charges
                        </td>
                        <td>
                            {{(job.subTotal - job.damageTotal) / 100 | currency : job.currency}}
                            <!-- {{job.subTotal / 100 | currency : job.currency}} -->
                        </td>
                    </tr>
                    <tr class="short-row">
                        <td
                            [attr.colspan]="'4'"
                            class="p-text-right"
                        >
                            Discounts
                        </td>
                        <td>
                            {{-job.discountTotal / 100 | currency : job.currency}}
                            <!-- {{job.discountTotal / -100 | currency : job.currency}} -->
                        </td>
                    </tr>
                    <tr class="short-row">
                        <td
                            [attr.colspan]="'4'"
                            class="p-text-right"
                        >
                            Damages
                        </td>
                        <td>
                            {{job.damageTotal / 100 | currency : job.currency}}
                            <!-- {{job.discountTotal / -100 | currency : job.currency}} -->
                        </td>
                    </tr>
                    <tr class="short-row">
                        <td
                            [attr.colspan]="'4'"
                            class="p-text-right"
                        >
                            Subtotal
                        </td>
                        <td>
                            {{job.discountedSubTotal / 100 | currency : job.currency}}
                            <!-- {{job.subTotal / 100 | currency : job.currency}} -->
                        </td>
                    </tr>
                    <tr class="short-row">
                        <td
                            [attr.colspan]="'4'"
                            class="p-text-right"
                        >Tax</td>
                        <td>
                            {{job.taxTotal / 100 | currency : job.currency}}
                            <!-- {{job.taxTotal / 100 | currency : job.currency}} -->
                        </td>
                    </tr>
                    <tr class="short-row">
                        <td
                            [attr.colspan]="'4'"
                            class="p-text-right"
                        >Total</td>
                        <td>
                            {{job.total / 100 | currency : job.currency}}
                            <!-- {{job.total / 100 | currency : job.currency}} -->
                        </td>
                    </tr>
                    <tr class="short-row">
                        <td
                            [attr.colspan]="'4'"
                            class="p-text-right"
                        >Paid</td>
                        <td>
                            {{ job.paidTotal / 100 | currency: job?.currency }}
                        </td>
                    </tr>
                    <tr class="short-row">
                        <td
                            [attr.colspan]="'4'"
                            class="p-text-right"
                        >
                            Remaining Balance
                        </td>
                        <td>
                            {{
                            (job.total - job.paidTotal) / 100
                            | currency: job?.currency
                            }}
                        </td>
                    </tr>
                </ng-template>
                <ng-template
                    pTemplate="emptymessage"
                    let-car
                    let-columns="columns"
                >
                    <tr>
                        <td [attr.colspan]="'4'">
                            This job has no line items.
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="p-d-block p-d-md-none p-d-flex p-jc-end p-ai-center p-pt-4">
                <span class="p-mr-2">Customer requires financing</span>
                <p-inputSwitch
                    (onChange)="setFieldValues('misc.needsFinance', needsFinancing)"
                    ruleProperty="financing.required"
                    [(ngModel)]="needsFinancing"
                ></p-inputSwitch>
            </div>
        </div>
        <div #transactionsCard>
            <app-transactions
                #transactions
                [filter]="{jobId: job?.id}"
                [user]="job.users | jobCustomer : false"
                [job]="job"
                [readonly]="readonly"
                [readonlyTooltip]="readonlyTooltip"
                [retrieveOnLoad]="false"
                [showReceiptButton]="false"
                [showHeaderCard]="false"
                [emptyMessage]="sharedFromAnotherZone ? 'Transactions can not be viewed for shared jobs' : 'No Transactions Found'"
            ></app-transactions>
        </div>

        <div #chargesCard>
            <app-charges
                [charges]="job?.charges | chargeDamage: true"
                [job]="job"
                [readonly]="readonly"
                [readonlyTooltip]="readonlyTooltip"
                [showHeaderCard]="false"
                tableTitle="Charges"
            ></app-charges>
        </div>

        <br />
        <br />

        <div #damagesCard>
            <app-charges
                [charges]="job?.charges | chargeDamage: false"
                [job]="job"
                [readonly]="readonly"
                [readonlyTooltip]="readonlyTooltip"
                [isDamages]="true"
                [showHeaderCard]="false"
                tableTitle="Damages"
            ></app-charges>
        </div>

        <br />
        <br />

        <div
            class="card"
            #discountsCard
        >
            <app-estimate-discounts
                [job]="job"
                [readonly]="readonly"
                [archived]="readonly"
            ></app-estimate-discounts>
        </div>
    </div>
</div>
<div *ngIf="!job && !showLoading">
    <p-message
        severity="warn"
        text="Enter Starting Address to View Job Details"
    ></p-message>
    <br />
    <br />
</div>

<div *ngIf="!job && showLoading">
    <p-skeleton
        width="100%"
        height="400px"
    ></p-skeleton>
    <br>
    <div class="p-grid">
        <div class="p-col">
            <p-skeleton
                width="100%"
                height="250px"
            ></p-skeleton>
        </div>
        <div class="p-col">
            <p-skeleton
                width="100%"
                height="250px"
            ></p-skeleton>
        </div>
    </div>
</div>

<button
    [printSectionId]="printSectionId"
    [useExistingCss]="true"
    [class.hide]="true"
    ngxPrint
    #print
    ></button>

<p-dialog
    header="Cancel"
    [(visible)]="showCancelDialog"
    [style]="{width: '50vw'}"
    [breakpoints]="{'992px': '62vw', '510px': '93vw'}"
>
    <p>Are you sure you want to cancel?</p>
    <ng-template pTemplate="footer">
        <button
            pButton
            (click)="cancelJob()"
            class="p-button-danger"
            label="Yes, Cancel"
        ></button>
        <p-button
            (click)="showCancelDialog = false"
            label="No, Don't Cancel"
        ></p-button>
    </ng-template>
</p-dialog>

<!-- (eventMutated)="detailsHelper.jobsUpdated.next(true)" -->

<app-mutate-calendar-event
    (eventMutated)="detailsHelper.pushUpdate({id:this.job.id, type:'Jobs', action:'update'})"
    #mutateEvent
></app-mutate-calendar-event>

<ng-template #nameNotSet> Name Not Set</ng-template>
<ng-template #startNotSet>Start Location Not Set</ng-template>
<ng-template #endNotSet>End Location Not Set</ng-template>
<ng-template #bedroomsNotSet>Bedrooms Not Set</ng-template>
<ng-template #bathroomsNotSet>Bathrooms Not Set</ng-template>
