<div *ngIf="rule">
    <div class="header-with-button">
        <div>
            <h4><i class="pi pi-book large-icon"> </i> Rule</h4>
            <app-object-state
                [object]="rule"
                objectType="rule"
                [colorful]="true"
            ></app-object-state>
        </div>
        <div>
            <button
                *ngIf="ruleActions[0].visible"
                pButton
                pRipple
                label="Actions"
                (click)="menu.toggle($event)"
                icon="pi pi-angle-down"
            ></button>
            <p-menu
                #menu
                [popup]="true"
                appendTo="body"
                [model]="ruleActions"
            ></p-menu>
        </div>
    </div>

    <div class="card">
        <p class="givenwhenthen">
            <strong>Given</strong> {{ rule.given }}
            <br />
            <strong>When</strong> {{ rule.when }}
            <br />
            <strong>Then</strong> {{ rule.then }}
        </p>
        <!-- <p>Name: {{artifact.name}}</p>
        <p>Size: {{artifact.fileSize | fileSize}}</p>
        <p>Public: {{artifact.public ? 'Yes' : 'No'}}</p> -->
        <p>Created: {{rule.createdAt | freyaDate}}</p>
        <p>Updated: {{rule.updatedAt | freyaDate}}</p>
        <p *ngIf="rule.deletedAt">Deleted: {{rule.deletedAt | freyaDate}}</p>
        <p>Override Priority:
            <span *ngIf="rule.overridePriority === null">Assorted</span>
            <span *ngIf="rule.overridePriority !== null">Ordered / {{ rule.overridePriority }}</span>
        </p>
        <p >Attributes: {{ rule.attributes ? rule.attributes.join(', ') : 'None'}}</p>
        <p *ngIf="rule.metadata && rule.metadata.length">
            Metadata:
            <p-table [value]="rule.metadata">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-meta>
                    <tr>
                        <td>{{meta.key}}</td>
                        <td>{{meta.value}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p>
        <p>Zones<span *ngIf="totalZones"> ({{ totalZones }})</span>: 

            <ng-container *ngIf="zones" >
                <ul *ngFor="let zone of zones">
                    <li>
                        <strong>{{ zone.type | titlecase }}:</strong>
                        {{ zone.name }}
                    </li>
                </ul>



            </ng-container>

            <ng-template *ngIf="!zones">
                <p-skeleton
                    width="100%"
                    height="40px"
                ></p-skeleton>
                <p-skeleton
                    width="75%"
                    height="10px"
                ></p-skeleton>
            </ng-template>

            

        </p>
    </div>

</div>

<ng-template #elseBlock>
    <p-skeleton
        width="100%"
        height="24px"
    ></p-skeleton>
</ng-template>

<app-mutate-rule #mutate></app-mutate-rule>
