<div [class.hide]="!showHeaderCard || jobConfirmationView">
    <div class="card">
        <div class="p-grid">
            <div class="p-col">
                <h4>Documents</h4>
            </div>
        </div>
        <app-upload-file
            [relatedObjects]="overrideUploadObjects ? overrideUploadObjects : filter.relatedObjectIds"
            #upload
        ></app-upload-file>
    </div>
    <br>
</div>


<div class="card">
    <div
        class="p-grid"
        *ngIf="jobConfirmationView"
    >
        <div class="p-col">
            <h5>Documents</h5>
        </div>
        <!-- <div class="p-col">
            <button 
                *ngIf="showSendDocuments"
                pButton
                label="Send"
                icon="pi pi-send"
                (click)="openSendDocuments()"
            ></button>
        </div> -->
        <div *hasPermission="['artifacts.create']" class="p-col p-text-right">
            <span [pTooltip]="readonly && readonlyTooltip">
                <button
                    *ngIf="showUpload"
                    pButton
                    (click)="showUploadDialog = true"
                    [disabled]="readonly"
                    icon="pi pi-upload"
                    pTooltip="Upload Documents"
                ></button>
            </span>
        </div>
    </div>
    <p-table
        [value]="artifacts"
        [paginator]="true"
        (onLazyLoad)="retrieveMoreArtifacts($event)"
        [lazy]="true"
        [totalRecords]="artifactsQH.total"
        [(rows)]="artifactsQH.limit"
        [rowsPerPageOptions]="pagination.rowPerPageOptions"
        [loading]="artifactsQH.loading"
        [showCurrentPageReport]="!artifactsQH.loading"
        [breakpoint]="stackOnMobile? '620px' : '0'"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <!-- <th>Size</th> -->
                <th>Signed By</th>
                <th *hasPermission="['artifacts.send']">Resend</th>
                <th>View</th>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="body"
            let-artifact
        >
            <tr
                class="selectable-row"
                (click)="selectArtifact(artifact)"
            >
                <td>{{artifact.name | sliceText : 60}}
                    <p-tag *ngIf="([ artifact ] | attributeFilter : { includesAny: [ 'Invoice' ] })?.length > 0 && !artifact?.metadata?.invoiceId" class="p-ml-2" value="Old Invoice Type" severity="warning"></p-tag>
                </td>
                <td>
                    <p *ngIf="([ artifact ] | attributeFilter : { includesNone: [ 'signable' ] })?.length > 0">Not Signable</p>
                    <p-tag
                        *ngIf="artifact.metadata?.signedByCustomer"
                        severity="success"
                        [pTooltip]="+(artifact.metadata?.signedByCustomer) | freyaDate"
                        >
                        Customer
                    </p-tag>
                    <p-tag
                        *ngIf="artifact.metadata?.signedByEmployee"
                        [pTooltip]="+(artifact.metadata?.signedByEmployee) | freyaDate"
                        styleClass="p-my-1"
                        >
                        Employee
                    </p-tag>
                </td>
                <!-- <td>{{artifact.fileSize | fileSize }}</td> -->
                <td *hasPermission="['artifacts.send']">
                    <span [pTooltip]="(readonly && readonlyTooltip) || (([ artifact ] | attributeFilter : { includesAny: [ 'outdated' ] })?.length > 0 && 'Cannot resend outdated invoice')">
                        <button
                            *ngIf="([artifact] | attributeFilter : {includesAny: ['document']})?.length > 0; else noResend"
                            pButton
                            class="p-button-text"
                            icon="pi pi-send"
                            (click)="$event.stopPropagation(); resendDocument(artifact)"
                            [disabled]="readonly || ([ artifact ] | attributeFilter : { includesAny: [ 'outdated' ] })?.length > 0"
                        ></button>
                    </span>
                    <ng-template #noResend> N/A </ng-template>
                </td>
                <td>
                    <button
                        pButton
                        class="p-button-text"
                        icon="pi pi-external-link"
                        (click)="freyaHelper.openInDialog(artifact); $event.stopPropagation()"
                    ></button>
                </td>
            </tr>
        </ng-template>
        <ng-template
            pTemplate="emptymessage"
            let-car
            let-columns="columns"
        >
            <tr>
                <td [attr.colspan]="'5'">
                    <span>{{emptyMessage}}</span>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog
    header="Upload Documents"
    [(visible)]="showUploadDialog"
    [modal]="true"
    [breakpoints]="{'992px': '62vw', '510px': '93vw'}"
    [style]="{width: '50vw'}"
>
    <app-upload-file
        [relatedObjects]="overrideUploadObjects ? overrideUploadObjects : filter.relatedObjectIds"
        #upload
    ></app-upload-file>
</p-dialog>

<!-- <app-mutate-artifact #mutate mutateType="create"></app-mutate-artifact> -->
