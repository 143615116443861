import { Component, OnInit } from '@angular/core';

import { QuickbooksAuthUriGQL } from '../../../generated/graphql.generated';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';


@Component({
  selector: 'app-quickbooks-login',
  templateUrl: './quickbooks-login.component.html',
  styleUrls: ['./quickbooks-login.component.scss']
})
export class QuickbooksLoginComponent implements OnInit {

  constructor(
    private quickbooksAuthUriGQL: QuickbooksAuthUriGQL,
    private notify: FreyaNotificationsService,
  ) { }

  ngOnInit(): void {
  }

  connect() {
    this.quickbooksAuthUriGQL.fetch(undefined, { fetchPolicy: 'no-cache' }).subscribe((res) => {
      this.launchPopup(res.data.quickbooksAuthUri);
    }, (err) => {
      this.notify.apolloError('Unable to connect to Quickbooks', err);
    });
  }

  launchPopup(path: string) {
    let parameters = 'location=1,width=800,height=650';
    parameters += ',left=' + (screen.width - 800) / 2 + ',top=' + (screen.height - 650) / 2;

    // Launch Popup
    window.open(path, 'connectPopup', parameters);
  }

}
