import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {QueryRef} from 'apollo-angular';

import { cloneDeep } from 'lodash';

import MiniSearch from 'minisearch';
import { ConfirmationService } from 'primeng/api';
import { AutoComplete, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { SubSink } from 'subsink';

import { CreateYemboSelfSurveyGQL, EstimatesJobFragment, GetConfigValuesGQL, Job, ListFieldsGQL, ListFieldsQuery, SetFieldValuesGQL } from '../../../generated/graphql.generated';

import { getFieldValue, getFieldValueByName } from '../../fields/fields.utils';
import { safeParseJSON } from '../../js';
import { EstimateHelperService } from '../../services/estimate-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { YemboHelperService } from '../../services/yembo-helper.service';

import { getJobCustomerUser } from '../../utilities/job-customer.util';
import { UnsavedInventoryNotesService } from 'src/app/services/unsaved-inventory-notes.service';
import { OnlineStatusService } from 'src/app/online-status.service';

export interface Inventory {
  totalVolume: number;
  totalItems: number;
  totalWeight: number;
  rooms: InventoryRoom[];
  specialtyItems: string[];
  };

export interface InventoryRoom {
    name: string;
    packing?: any;
    level?: string;
    flights?: string;
    notes?: string;
    moverNotes?: string;
    notMoving?: string;
    totalVolume: number;
    totalWeight: number;
    totalItems: number;

    inventory: InventoryItem[];
}

export interface InventoryItem {
  isBulky?: boolean;
  isValuable?: boolean;
  isSpecialEquipment?: boolean;
  is3rdPartyServicing?: boolean;
  isProGear?: boolean;
  isAssembleDisassemble?: boolean;
  isCarrierPack?: boolean;
  isoCode1?: string;
  isoCode2?: string;
  isoCode3?: string;
  name: string;
  packing?: string;
  quantity: number;
  volume?: number;
  weight?: number;

  generated?: boolean;
}

export const INVENTORY_ITEM_ATTRIBUTES = {
  isBulky: 'Bulky',
  isValuable: 'Valuable',
  isProGear: 'Pro Gear',
  isAssembleDisassemble: 'Assemble/Disassemble',
  isSpecialEquipment: 'Special Equipment',
  is3rdPartyServicing: '3rd Party Servicing',
};

interface ItemOption {
  // reviewerName: string;
  label: string;
  value: string;
}

@Component({
  selector: 'app-estimating-inventory',
  templateUrl: './estimating-inventory.component.html',
  styleUrls: ['./estimating-inventory.component.scss']
})
export class EstimatingInventoryComponent implements OnInit, OnDestroy, OnChanges {

  @Input() job: EstimatesJobFragment;
  @Input() readonly = false;
  @Input() readonlyNotesScroll = false;
  @Input() roomsExpanded = false;

  // SUBS
  subs = new SubSink();

  loading = true;

  // Values
  inventory: Inventory;
  inventoryNote: string;

  //used to keep initial value from db when user retrieve notes from localstorage
  //and decides to keep previous notes
  inventoryNotePrevVersion: string;

  intervalId: any;

  notesFromStorageRetrieved = false;
  notesFromStorage = '';

  // True if the inventory has been manually changed
  inventoryUpdated = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  DEFAULT_INVENTORY: Inventory = {
    rooms: [],
    specialtyItems: [],
    totalItems: 0,
    totalVolume: 0,
    totalWeight: 0
  };

  // ROOMS
  defaultRoomOptions = [
    'Living Room',
    'Dining Room',
    'Kitchen',
    'Office',
    'Bedroom',
    'Bathroom',
    'Garage',
    'Basement',
    'Attic',
    'Other',
  ];
  filteredRoomOptions = [];
  roomDialogVisible = false;

  roomForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
  });

  // ITEMS
  defaultItemOptions: ItemOption[] = [];
  filteredItemOptions: ItemOption[] = [];

  itemForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required])
  });

  // The possible attributes for an inventory item
  inventoryItemAttributesOptions = INVENTORY_ITEM_ATTRIBUTES;

  showItemDialog = false;

  // QUERIES
  inventoryQueryRef: QueryRef<ListFieldsQuery>;

  // YEMBO
  yemboMoveCreated = false;

  showSurveyDialog = false;
  customerEmail: string;
  customerPhone: string;

  // SEARCHING
  itemMiniSearch = new MiniSearch({
    fields: ['reviewerName', 'label', 'rooms', 'aliases'],
    storeFields: ['reviewerName', 'label', 'rooms'],
    idField: 'label',
  });

  constructor(
    // QUERIES
    private fieldsGQL: ListFieldsGQL,
    private setFieldsGQL: SetFieldValuesGQL,
    private createYemboSelfSurveyGQL: CreateYemboSelfSurveyGQL,
    private getConfigValues: GetConfigValuesGQL,
    // HELPERS
    private localNotify: FreyaNotificationsService,
    public yemboHelper: YemboHelperService,
    // UTILITIES
    private confirmationService: ConfirmationService,
    private router: Router,
    public estimateHelper: EstimateHelperService,
    public unsavedInventoryNotes: UnsavedInventoryNotesService,
    public onlineStatusService: OnlineStatusService,
  ) { }

  ngOnInit(): void {
    this.inventory = cloneDeep(this.DEFAULT_INVENTORY);

    this.fetchInventoryItems();

    this.onlineStatusService.isOnline$.subscribe(online => {
      if (!online && this.hasChanges()) {
        // Save current inventory notes each time when the app goes offline
        this.unsavedInventoryNotes.setUnsavedNotes({jobId: this.job.id, note: this.inventoryNote});

        this.checkUnsavedNotes();

        // Start an interval to save inventory notes every 10 seconds while is offline
        this.intervalId = setInterval(() => {
          this.unsavedInventoryNotes.setUnsavedNotes({jobId: this.job.id, note: this.inventoryNote});
        }, 10000);
      } else {
        // Clear the interval when the app is back online
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.job && changes.job.currentValue?.id !== changes.job.previousValue?.id){
        this.fetchInventory();
        this.checkUnsavedNotes();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  fetchInventoryItems(){
    this.subs.sink = this.getConfigValues.fetch({
      keys: ['inventory.items'],
    }).subscribe((res) => {
      const inventoryItems = res.data.getConfigValues[0];

      if (!inventoryItems) { return; }

      this.defaultItemOptions = safeParseJSON(inventoryItems?.value, []);
      this.itemMiniSearch.addAll(cloneDeep(this.defaultItemOptions));
    });
  }


  fetchInventory(){
    this.loading = true;

    const inventoryInput = {
      filter: {
        names: ['jobs.inventory', 'jobs.inventory-notes'],
        labels: ['Job'],
      },
      objectLabels: ['Job'],
      objects: [this.job.id],
    };

    // if (this.inventoryQueryRef){
    //   this.inventoryQueryRef.refetch(inventoryInput);
    //   return;
    // }

    // this.inventoryQueryRef = this.fieldsGQL.watch(inventoryInput, {fetchPolicy: 'cache-first'});

    this.fieldsGQL.fetch(inventoryInput, {fetchPolicy: 'network-only'}).subscribe((res) => {
      this.loading = res.loading;
      if(res.loading) { return; }
      const fields = res?.data?.fields?.fields;
      if (!fields) { return; }

      // Check if we are getting results from other queries being fired by the same componenet for different objects
      const objectId = fields[0]?.values[0]?.objectId;
      if (objectId !== this.job.id) { return; }

      const inventoryNotes = getFieldValueByName(fields, 'jobs.inventory-notes');

      if (inventoryNotes && this.inventoryUpdated) {
        return;
      }

      if (inventoryNotes) {
        this.inventoryNote = cloneDeep(inventoryNotes);
        //keep in two vars for the restoring from localstorage functionality
        this.inventoryNotePrevVersion = cloneDeep(inventoryNotes);
      } else {
        this.inventoryNote = '';
      }

      const strInventory = getFieldValueByName(fields, 'jobs.inventory');
      // if (strInventory === null) { return; }

      this.inventory = cloneDeep(safeParseJSON(strInventory, this.DEFAULT_INVENTORY));
    });
  }

  reset(){}

  resetInventoryValue(){
    // TODO: REMOVE OR IMPLEMNET
  }

  addRoom(){
    this.inventory.rooms.push({
      name: this.roomForm.value.name,
      inventory: [],
      totalItems: 0,
      totalVolume: 0,
      totalWeight: 0,
    });

    this.roomForm.reset();

    this.inventoryUpdated = true;

    // Autofocus the item autocomplete for the new room
    setTimeout(() => {
      this.autofocusNewRoom();
    }, 75);
  }

  duplicateRoom(room: InventoryRoom){
    this.inventory.rooms.push(cloneDeep(room));

    this.calculateRoomTotals();
  }

  removeRoom(room: InventoryRoom){
    this.inventory.rooms = this.inventory.rooms.filter((r) => r !== room);

    this.calculateRoomTotals();
  }

  autofocusNewRoom() {
    const itemSelects = document.getElementsByClassName('room-item-autocomplete');
    const itemSelect =  itemSelects[itemSelects?.length - 1];
    const itemSelectInput =  itemSelect?.getElementsByTagName('input')[0];
    itemSelectInput.focus();
  }

  searchRoomOptions(event){
    this.filteredRoomOptions = this.defaultRoomOptions.filter((ro) => ro.includes(event.query));

    if (!this.filteredRoomOptions?.length){
      this.filteredRoomOptions.push(event.query);
    }
  }

  searchItemOptions(event, room){

    // this.filteredItemOptions = this.defaultItemOptions.filter((io) => io.reviewerName.includes(event.query));
    const searchResults = this.itemMiniSearch.search(
      `${event.query}`, {
        prefix: true,
        fuzzy: 0.2,
        boost: {
          reviewerName: 2,
          label: 2,
        }
    });

    const roomSearchResults = this.itemMiniSearch.search(
      `${room.name}`, {
        prefix: true,
        fuzzy: 0.2,
    });

    const combinedResults = searchResults;


    for (const result of roomSearchResults){
      const matchingResult = combinedResults.find((cr) => cr.label === result.label);

      if (matchingResult){
        matchingResult.score += result.score;
        continue;
      }

      combinedResults.push(result);

    }

    if (!combinedResults?.length){
      this.filteredItemOptions = cloneDeep(this.defaultItemOptions);
      return;
    }

    this.filteredItemOptions = combinedResults.map((sr) => this.defaultItemOptions.find((dio) => dio.label === sr.id));

    if (event.query) {
      this.filteredItemOptions.unshift(event.query);
    }

    // used to show the text for "View Complete List"
    this.filteredItemOptions.push({
      label: 'View Complete List',
      value: 'VIEW',
    });
  }

  addItemToRoom(room: InventoryRoom, event: AutoCompleteSelectEvent, autocomplete: AutoComplete){

    const value = event.value;

    if(typeof value !== 'string' && value?.value === 'VIEW'){
      this.showItemDialog = true;
      this.itemForm.get('name').setValue('');
      return;
    }

    if (typeof value === 'string'){
      room.inventory.push({
        name: value,
        quantity: 1,
        weight: 0,
        volume: 0,

      });
    } else {
      room.inventory.push({
        name: value.reviewerName,
        quantity: 1,
        weight: +value.weight,
        volume: +value.volume,
        isBulky: value.isBulky,
        isoCode1: value.isoCode1,
        isoCode2: value.isoCode2,
        isoCode3: value.isoCode3,
        isValuable: value.isValuable,
        isAssembleDisassemble: value.isAssembleDisassemble,
        isCarrierPack: false,
        isProGear: value.isProGear,
        is3rdPartyServicing: value.is3rdPartyServicing,
        isSpecialEquipment: value.isSpecialEquipment,
      });
    }

    this.calculateRoomTotals();

    this.itemForm.reset();
    // Handle reopening the autocomplete dropdown after selecting an item
    setTimeout(() => {
      const input = autocomplete.el.nativeElement.getElementsByTagName('input')[0];
      input.blur();
      input.focus();
      // autocomplete.el.nativeElement.focus();
      // autocomplete.handleDropdownClick(event);
    }, 20);
  }

  removeItemFromRoom(room: InventoryRoom, item){
    room.inventory = room.inventory.filter((inv) => inv !== item);

    this.calculateRoomTotals();
  }

  updateInventoryItemAttributes(event){
    console.log(event);
  }

  calculateRoomTotals(){
    for (const room of this.inventory.rooms){
      room.totalItems = room.inventory.map((item) => item.quantity).reduce((total, current) => total += +current, 0);
      room.totalVolume = room.inventory.map((item) => (+item.volume * item.quantity)).reduce((total, current) => total += +current, 0);
      room.totalWeight = room.inventory.map((item) => (+item.weight * item.quantity)).reduce((total, current) => total += +current, 0);
    }

    this.inventory.totalItems = this.inventory.rooms.map((room) => room.totalItems).reduce((total, current) => total += +current, 0);
    this.inventory.totalWeight = this.inventory.rooms.map((room) => room.totalWeight).reduce((total, current) => total += +current, 0);
    this.inventory.totalVolume = this.inventory.rooms.map((room) => room.totalVolume).reduce((total, current) => total += +current, 0);

    this.inventoryUpdated = true;
  }

  hasChanges(): boolean{
    return this.inventoryUpdated;
  }

  saveInventory(){

    if (!this.hasChanges()) {
      return;
    }

    const stringInventory = JSON.stringify(this.inventory);
    return new Promise<void>((resolve, reject) => {
    this.subs.sink = this.setFieldsGQL.mutate({
      fields: [
        {
          fieldName: 'jobs.inventory',
          value: stringInventory,
        },
        {
          fieldName: 'jobs.inventory-notes',
          value: this.inventoryNote,
        }
      ],
      objectLabel: 'Job',
      objects: [this.job.id],
    }).subscribe((res) => {
      console.log('SET FIELDS RES', res);
      this.inventoryUpdated = false;
      //after notes saved we want to remove them from localstorage
      this.unsavedInventoryNotes.removeNoteFromStorage(this.job.id);
      this.notesFromStorageRetrieved = false;
      resolve(null);
    }, (err) => {
      this.unsavedInventoryNotes.setUnsavedNotes({jobId: this.job.id, note: this.inventoryNote});
      this.localNotify.apolloError(`Failed to save inventory`, err);
      reject(err);
    });
  });
  }

  // YEMBO
  openSurveyDialog(){
    const customer = (getJobCustomerUser(this.job?.users, false));

    this.customerEmail = customer?.email;
    this.customerPhone = customer?.phone;

    this.showSurveyDialog = true;
  }
  /**
   * Create a move and send the customer the self survey link
   */
  createSelfSurvey(sendTo: 'email' | 'sms' | 'both'){
    this.yemboMoveCreated = true;

    // TODO: These values just force the notification against the user preferences, right now all notifications are sent to both
    const sendEmail = (sendTo === 'email' || sendTo === 'both');
    const sendSms = (sendTo === 'sms' || sendTo === 'both');

    this.createYemboSelfSurveyGQL.mutate({jobId: this.job?.id, sendEmail, sendSms}).subscribe((res) => {
      this.localNotify.success(`Smart survey link sent`);
      this.showSurveyDialog = false;
    }, (err) => {
      this.localNotify.apolloError(`Failed to send smart survey link`, err);
    });
  }

  onYemboMoveCreatedFromMenu() {
    this.yemboMoveCreated = true;
  }

  goToSmartConsult(){
    this.router.navigate(['estimating', this.job.id], { queryParams: { step: 'booking', eventType: 'virtualEstimate' } });
  }

  checkUnsavedNotes() {
    this.notesFromStorage = this.unsavedInventoryNotes.getUnsavedNotesForJob(this.job.id);
  }

  retrieveUnsavedNotes() {
    this.inventoryNotePrevVersion = this.inventoryNote;

    if (!this.notesFromStorage) {
      this.localNotify.addToast.next({severity: 'warn', summary: 'There are no unsaved notes found for current job'});
    } else {
      this.inventoryNote = this.notesFromStorage;
      this.notesFromStorageRetrieved = true;
      this.inventoryUpdated = true;
    }
  }

  declineRestoredNotes() {
    this.inventoryNote = this.inventoryNotePrevVersion;
    this.notesFromStorageRetrieved = false;
    this.inventoryUpdated = false;
    //remove from storage if user retrieved notes and discarded them
    this.notesFromStorage = '';
    this.unsavedInventoryNotes.removeNoteFromStorage(this.job.id);
  }

  acceptRestoredNotes() {
    this.inventoryNote = this.notesFromStorage;
    //remove from storage if user retrieved notes and accepted them
    this.notesFromStorage = '';
    this.unsavedInventoryNotes.removeNoteFromStorage(this.job.id);
    this.notesFromStorageRetrieved = false;
  }
}
