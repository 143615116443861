
<div class="history">
    <table>

        <thead>
            <th
                class="datetime"
                (click)="toggleSort('createdAt')"
            >
                {{ queryHelper.sort | sortDirection: 'createdAt':'(oldest first)':'(newest first)' }}
            </th>
            <th class="text" >
                Actions
            </th>
        </thead>
        
        <tr class="history-node" *ngFor="let h of history || []; let i = index">
            <td class="datetime"
                (click)="openHistoryDetails(h)"
                [pTooltip]="h.createdAt * 1000 | appropriateTimeMeasurement"
                tooltipPosition="left"
            >
                <span class="date">{{ h.createdAt | freyaDate: 'EEE MMM dd hh:mma' }}</span>
                <!-- <span class="time">{{ h.createdAt | freyaDate: 'HH:mm' }}</span> -->
            </td>
            <td class="text"
                [innerHTML]="h.text | markdown | async"
                (click)="markdownHelper.onMarkdownClick($event)"
            >
            </td>
        </tr>

    </table>

    <span
        *ngIf="!history?.length && !queryHelper.loading"
        class="history-desc freya-link-button"
        (click)="fetch()"
    >
        This object has no history.
    </span>

    <span *ngIf="queryHelper.loading && !history?.length" class="history-loading">
        <div *ngFor="let skeleton of skeletons">
            <p-skeleton
                class="first"
                height="1em"
            ></p-skeleton>
            <p-skeleton
                [style.flexBasis]="skeleton.secondWidth"
                [height]="skeleton.secondHeight"
            ></p-skeleton>

        </div>
    </span>

    <div class="loadmore">
        <button
            pButton pRipple
            type="button"
            *ngIf="queryHelper.hasMore && history"
            [disabled]="queryHelper.loading"
            (click)="fetchMore()"
        >
            Load More
        </button>

        <span
            *ngIf="history?.length && !queryHelper.loading && !queryHelper.hasMore"
            class="history-desc"
        >
            Showing entire history for {{ name }}
            <!-- - <span
                class="freya-link-button"
                (click)="fetch()"
            >reload</span> -->
        </span>
    </div>
</div>