import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';

import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { SubSink } from 'subsink';

import { BaseReportTypeFragment, ReportTypesListGQL } from '../../../generated/graphql.generated';
import { PermissionService } from '../../services/permission.service';
import { ExportToSheetsDialogComponent } from '../export-to-sheets-dialog/export-to-sheets-dialog.component';

@Component({
  selector: 'app-report-type-details',
  templateUrl: './report-type-details.component.html',
  styleUrls: ['./report-type-details.component.scss']
})
export class ReportTypeDetailsComponent implements OnInit, OnChanges {

  @Input() rt: BaseReportTypeFragment;
  @Input() loaded = true;

  actions: MenuItem[] = [];
  loading = false;
  variables: [];

  subs = new SubSink();

  hasExportPermission = false;

  constructor(
    private reportTypeList: ReportTypesListGQL,
    private dialogSvc: DialogService,
    private permissionsHandler: PermissionService,
  ) { }

  async ngOnInit() {
    if (!this.loaded && this.rt?.id) {
      const res = await this.reportTypeList.fetch({
        filter: { ids: [ this.rt.id ] },
      }).toPromise();

      const [rt] = res.data?.reportTypes?.reportTypes;
      if (rt) {
        this.rt = rt;
      }
    }

    this.initActions();
    this.initPermissions();
  }

  initActions() {
    this.actions = [
      {
        id: 'view',
        label: 'View',
        icon: 'pi pi-external-link',
        routerLink: [ '/reports/type', this.rt?.id ],
      },
      {
        id: 'export',
        label: 'Export to Google Sheets',
        icon: 'pi pi-cloud-upload',
        command: () => {
          this.dialogSvc.open(ExportToSheetsDialogComponent, {
            data: {
              reportType: this.rt,
            },
            header: 'Semi-live Export to Google Sheets',
          });
        },
        visible: false,
      },
      // generate?
    ];
  }

  initPermissions() {
    this.subs.sink = this.permissionsHandler.watchPermissions([ 'reports.export' ])
      .subscribe((res) => {

        this.hasExportPermission = res[0];

        this.setActionVisibility();
      });
  }

  setActionVisibility() {
    const exportAction = this.actions.find((a) => a.id === 'export');

    if (exportAction) {
      exportAction.visible = this.hasExportPermission;
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initActions();
    this.setActionVisibility();
  }

  flattenVariables() {

  }

}
