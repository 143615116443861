<div class="layout-dashboard">
    <div class="p-grid p-align-stretch vertical-container">

        <div class="p-col-9 pt-0 pb-0">
            <h2 class="mb-0">Dashboard
                <span *ngIf="period && dashboardService.periodOption !== 'Custom'">
                    {{ period.start | freyaDate }}
                    <ng-container *ngIf="(period.start | freyaDate) !== (period.end | freyaDate)">
                        to
                        {{ period.end | freyaDate }}
                    </ng-container>
                </span>
                <span *ngIf="period && dashboardService.periodOption === 'Custom'">
                    <p-calendar
                        [ngModel]="visualPeriodStart"
                        (onSelect)="onDateRangeSelected('start', $event)"
                    ></p-calendar>
                    <b> To </b>
                    <ng-container *ngIf="(period.start | freyaDate)">
                        <p-calendar
                            [ngModel]="visualPeriodEnd"
                            (onSelect)="onDateRangeSelected('end', $event)"
                        ></p-calendar>
                    </ng-container>
                </span>
            </h2>
        </div>

        <div class="p-col-3 p-p-0">
            <div class="p-col periodPicker">
                <p-dropdown
                    appendTo="body"
                    class="currency-dropdown"
                    [options]="allCurrencies"
                    [(ngModel)]="dashboardService.currency"
                    (onChange)="onCurrencyUpdated()"
                    [disabled]="!data.currencies || data.currencies.allCurrencies?.length < 2"
                ></p-dropdown>
                <p-dropdown
                    appendTo="body"
                    [options]="periodOptions"
                    [(ngModel)]="dashboardService.periodOption"
                    (onChange)="onPeriodUpdated()"
                ></p-dropdown>
            
                <small *ngIf="data?.lastUpdated">
                    Last updated {{ data.lastUpdated | appropriateTimeMeasurement }}
                </small>
                <small *ngIf="!data?.lastUpdated">
                    Loading...
                </small>
            </div>
        </div>

        <div class="p-col-12">
            <div class="p-grid" style="margin: -1rem;">
                <div class="p-col dashboard-info-container">
                    <app-dashboard-info
                        key="Job Stages"
                    ></app-dashboard-info>
                    <div class="card overview-box panel-1">
                        <div class="overview-info">
                            <h6>Lead / Estimate / Booked / Invoiced / Cancelled</h6>
                            <h1 *ngIf="data?.jobPipeline">
                                {{ data.jobPipeline.lead?.count      || 0 }} /
                                {{ data.jobPipeline.estimate?.count  || 0 }} /
                                {{ data.jobPipeline.booking?.count   || 0 }} /
                                {{ data.jobPipeline.invoice?.count   || 0 }} /
                                {{ data.jobPipeline.cancelled?.count || 0 }}
                            </h1>
                            <p-skeleton *ngIf="!data?.jobPipeline" width="100%" height="3em"></p-skeleton>
                        </div>
                    </div>
                </div>
                <div class="p-col dashboard-info-container">
                    <app-dashboard-info
                        key="Average Invoice Size"
                    ></app-dashboard-info>
                    <div class="card overview-box panel-2">
                        <div class="overview-info">
                            <h6>Average Invoice Size</h6>
                            <ng-container *ngIf="data?.jobPipeline">
                                <h1>{{ (data.jobPipeline.invoice?.avgJobSize || 0) / 100 | currency : data.currencies.currency  }}</h1>
                                <app-change [value]="data.jobPipeline"></app-change>
                            </ng-container>
                            <p-skeleton *ngIf="!data?.jobPipeline"  width="100%" height="3em"></p-skeleton>
                        </div>
                    </div>
                </div>
                <div class="p-col dashboard-info-container">
                    <app-dashboard-info
                        key="OBE conversion"
                    ></app-dashboard-info>
                    <div class="card overview-box panel-3">
                        <div class="overview-info">
                            <h6>OBE Conversion</h6>
                            <h1 *ngIf="data.obeConversion">{{
                                100 * data.obeConversion.totalConvertedJobs / data.obeConversion.totalOBEJobs || 0 | number : '1.2-2'  }}%</h1>
                            <p-skeleton *ngIf="!data.obeConversion"  width="100%" height="3em"></p-skeleton>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-col-12">
            <div class="p-grid box box-stretched">
                <div class="p-col-12 p-pb-0 dashboard-info-container">
                    <app-dashboard-info
                        key="Transaction Volume"
                    ></app-dashboard-info>
                    <div class="card chart">
                        <div class="card-header">
                            <div class="card-title">
                                <h6>Transactions Paid {{ dashboardService.getPeriodStr() }}</h6>
                                <!-- <p class="subtitle">Line graph</p> -->
                            </div>
                        </div>
                        <p-chart
                            *ngIf="graphData"
                            type="line"
                            [data]="graphData" [options]="transactionVolumeChartOptions"
                            [responsive]="true"
                            style="max-height: 150px;"
                        ></p-chart>
                        <p-skeleton *ngIf="!graphData"  width="100%" height="250px"></p-skeleton>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-col-12 p-md-4 dashboard-info-container">
            <app-dashboard-info
                key="Revenue"
            ></app-dashboard-info>
            <div class="p-grid">
                <div class="p-col-12 p-pb-0">
                    <div class="card statistics">
                        <div class="card-header">
                            <div class="card-title">
                                <h6>Revenue {{ dashboardService.getPeriodStr() }}</h6>
                                <!-- <p class="subtitle">All Franchises</p> -->
                            </div>
                            <!-- <p class="subtitle">August</p> -->
                        </div>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-6">
                                <div class="statistic-item">
                                    <div class="item-title">
                                        <h5 *ngIf="data?.revenue">
                                            {{ data.revenue.realizedRevenue / 100 | currency : data.currencies?.currency }}

                                            <app-change [value]="data.revenue.realizedRevenue"></app-change>
                                            <app-dashboard-info class="block"
                                                key="Realized Revenue"
                                            ></app-dashboard-info>

                                        </h5>
                                        <p-skeleton *ngIf="!data?.revenue"  width="11ch" height="2em"></p-skeleton>
                                    </div>
                                    <h6>Realized Revenue</h6>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-6">
                                <div class="statistic-item">
                                    <div class="item-title">
                                        <h5 *ngIf="data?.revenue">{{ data.revenue.unrealizedRevenue / 100 | currency : data.currencies.currency }}

                                            <app-change [value]="data.revenue.scheduledRevenue"></app-change>
                                            <app-dashboard-info class="block"
                                                key="Scheduled Revenue"
                                            ></app-dashboard-info>
                                        </h5>
                                        <p-skeleton *ngIf="!data?.revenue"  width="11ch" height="2em"></p-skeleton>
                                    </div>
                                    <h6>Scheduled Revenue</h6>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-6">
                                <div class="statistic-item">
                                    <div class="item-title">
                                        <h5 *ngIf="data?.revenue">{{ data.revenue.expectedRevenue / 100 | currency : data.currencies.currency }}
                                            <app-change [value]="data.expectedRevenue"></app-change>
                                            <app-dashboard-info class="block"
                                                key="Expected Revenue"
                                            ></app-dashboard-info>
                                        </h5>
                                        <p-skeleton *ngIf="!data?.revenue"  width="11ch" height="2em"></p-skeleton>
                                    </div>
                                    <h6>Expected Revenue</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="p-col-12 p-md-4 dashboard-info-container">
            <app-dashboard-info
                key="Logistics"
            ></app-dashboard-info>
            <div class="p-grid">
                <div class="p-col-12 p-pb-0">
                    <div class="card statistics">
                        <div class="card-header">
                            <div class="card-title">
                                <h6>Logistics Summary {{ dashboardService.getPeriodStr() }}</h6>
                            </div>
                        </div>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-6">
                                <div class="statistic-item">
                                    <div class="item-title">
                                        <h5 *ngIf="data.travel">
                                            {{ data.travel.distanceBooked | distance : dashboardService.units }}
                                        </h5>
                                        <p-skeleton *ngIf="!data.travel"  width="6ch" height="2em"></p-skeleton>
                                    </div>
                                    <h6>Distance Booked</h6>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-6">
                                <div class="statistic-item">
                                    <div class="item-title">
                                        <h5 *ngIf="data.travel">
                                            {{ data.travel.distanceCompleted | distance : dashboardService.units }}
                                        </h5>
                                        <p-skeleton *ngIf="!data.travel"  width="6ch" height="2em"></p-skeleton>
                                    </div>
                                    <h6>Distance Completed</h6>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-6">
                                <div class="statistic-item">
                                    <div class="item-title">
                                        <h5 *ngIf="data.travel">
                                            {{ data.travel.timeBooked | secondsToDuration }}
                                        </h5>
                                        <p-skeleton *ngIf="!data.travel"  width="6ch" height="2em"></p-skeleton>
                                    </div>
                                    <h6>Travel Time Booked</h6>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-6">
                                <div class="statistic-item">
                                    <div class="item-title">
                                        <h5 *ngIf="data.travel">
                                            {{ data.travel.timeCompleted | secondsToDuration }}
                                        </h5>
                                        <p-skeleton *ngIf="!data.travel"  width="6ch" height="2em"></p-skeleton>
                                    </div>
                                    <h6>Travel Time Completed</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-col-12 p-md-4">
            <div class="p-grid">
                <div class="p-col-12 p-pb-0">
                    <div class="card statistics conversion">
                        <div class="card-header">
                            <div class="card-title">
                                <h6>Conversion Metrics {{ dashboardService.getPeriodStr() }}</h6>
                                <!-- <p class="subtitle">All Franchises</p> -->
                            </div>
                            <!-- <p class="subtitle">August</p> -->
                        </div>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-4 p-lg-6">
                                <div class="statistic-item">
                                    <div class="item-title">
                                        <h5 *ngIf="data.cscConversion">{{ (100 * data.cscConversion.totalJobsWithCompleteRevenueGeneratingEvents / data.cscConversion.totalCSCJobs || 0) | number : '1.2-2'  }}%

                                            <app-dashboard-info class="block"
                                                key="CSC conversion"
                                            ></app-dashboard-info>


                                        </h5>
                                        <p-skeleton *ngIf="!data.cscConversion"  width="6ch" height="2em"></p-skeleton>
                                    </div>
                                    <h6>CSC conversion</h6>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-lg-6">

                                <div class="statistic-item">
                                    <div class="item-title">
                                        <h5 *ngIf="data.obeConversion">{{ (100 * data.obeConversion.totalConvertedJobs / data.obeConversion.totalOBEJobs || 0) | number : '1.2-2'  }}%
                                            <app-dashboard-info  class="block"
                                                key="OBE conversion"
                                            ></app-dashboard-info>

                                        </h5>
                                        <p-skeleton *ngIf="!data.obeConversion"  width="6ch" height="2em"></p-skeleton>
                                    </div>
                                    <h6>OBE conversion</h6>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-4 p-lg-6">
                                <div class="statistic-item">
                                    <div class="item-title">
                                        <h5 *ngIf="data.oseConversion">{{ (100 * data.oseConversion.totalJobsWithCompleteRevenueGeneratingEvents / data.oseConversion.totalJobsWithEstimates || 0) | number : '1.2-2'  }}%

                                            <app-dashboard-info class="block"
                                                key="OSE conversion"
                                            ></app-dashboard-info>
                                        </h5>
                                        <p-skeleton *ngIf="!data.oseConversion"  width="6ch" height="2em"></p-skeleton>
                                    </div>
                                    <h6>OSE conversion</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="p-col-12 p-md-2 dashboard-info-container"
            *hasPermission="['frontend.needs-attention']"
            >
            <div class="card">
                <div class="card-header space-between">
                    <div class="card-title">
                        <h6>Needs Attention</h6>
                    </div>
                    <a routerLink="/needs-attention" class="bold">View More</a>
                </div>
                <app-attention-items-summary
                    summaryStyle="chips"
                    ></app-attention-items-summary>
            </div>
        </div>

        <div class="p-col-12 p-md-5 dashboard-info-container">
            <app-dashboard-info key="Events Created"></app-dashboard-info>
            <app-dashboard-event-type-summary
                [data]="data.createdEvents"
                [currency]="data.currency"
                title="Events Created {{ dashboardService.getPeriodStr() }}"
                ></app-dashboard-event-type-summary>
        </div>

        <div class="p-col-12 p-md-5 dashboard-info-container">
            <app-dashboard-info key="Events Happening"></app-dashboard-info>
            <app-dashboard-event-type-summary
                [data]="data.happeningEvents"
                [currency]="data.currency"
                title="Events Happening {{ dashboardService.getPeriodStr() }}"
                ></app-dashboard-event-type-summary>
        </div>
    </div>
</div>
