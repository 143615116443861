

<div class="header">
    <h4 class="text">
        <i class="pi pi-chart-line large-icon"></i>
        Scheduled
        <app-object-state
            [object]="sr"
            objectType="report"
            [colorful]="true"
            ></app-object-state>
    </h4>
    <div class="actions">
        <button
            pButton
            pRipple
            label="Actions"
            [disabled]="!sr"
            (click)="menu.toggle($event)"
            icon="pi pi-angle-down"
        ></button>

        <p-menu
            #menu
            [popup]="true"
            appendTo="body"
            [model]="actions"
        ></p-menu>
    </div>
</div>

<h5 *ngIf="!loading; else loadingBar">
    <a routerLink="/reports/type/{{sr?.reportType?.id}}">
        {{sr?.name || "Report Type"}}
    </a>
</h5>

<!-- Recently run reports will go here eventually... -->

<!-- <div class="tag-container" *ngIf="!loading; else loadingBar">
    <p-tag
        *ngFor="let tag of job.tags"
        [style]="{'background-color': tag.color}"
        [colorForBackground]="{elementSelector: 'span', backgroundColor: tag.color}"
    >
        {{tag | formatTag}}
    </p-tag>
</div> -->

<h5>Overview</h5>
<div class="card">
    <p *ngIf="!loading; else loadingBar">
        Created: {{ sr?.createdAt | freyaDate }}
    </p>
    <p *ngIf="!loading; else loadingBar">
        Interval: {{ timeToString(sr?.time, true) }}
    </p>
    <p *ngIf="!loading; else loadingBar">
        Last Run:
        <span *ngIf="sr?.lastRun"
            [pTooltip]="sr.lastRun * 1000 | appropriateTimeMeasurement"
        >{{ sr?.lastRun | freyaDate }}</span>
        <span *ngIf="!sr?.lastRun">Not yet run</span>
    </p>
    <p *ngIf="!loading; else loadingBar"
    >
        Next Run:
        <span *ngIf="sr?.nextRun"
            [pTooltip]="sr.nextRun * 1000 | appropriateTimeMeasurement"
        >{{ sr?.nextRun | freyaDate }}</span>
        <span *ngIf="!sr?.nextRun">Will not run again</span>
    </p>
</div>


<h5>Period & Variables</h5>
<div class="card">

    <div class="p-p-2" *ngIf="loading">
        <p-skeleton width="100%" height="8rem"></p-skeleton>
    </div>
    <div class="p-p-2" *ngIf="!loading && vars && !vars.length">
        No variables
    </div>

    <p
        *ngFor="let reportVar of vars"
    >
        <strong>{{ reportVar.key }}</strong>:
        <ng-container [ngSwitch]="reportVar.format">
            <span *ngSwitchCase="'datetime'">{{ reportVar.value | freyaDate: 'MMM dd, YYYY HH:mm' }}</span>
            <span *ngSwitchCase="'object'">{{ reportVar.value | json }}</span>
            <span *ngSwitchDefault>{{ reportVar.value }}</span>
        </ng-container>
    </p>
</div>

<h5>Notifications</h5>
<div class="card">

    <div class="p-p-2" *ngIf="loading">
        <p-skeleton width="100%" height="8rem"></p-skeleton>
    </div>

    <ng-container *ngIf="!loading">
        <div>
            Users: {{ sr?.notifyUsers?.length }}
            <ul *ngIf="notifyUsers?.length">
                <li *ngFor="let user of notifyUsers">
                    <a
                        (click)="detailsHelper.open('users', user)"
                        class="clickable"
                    >
                        {{ user.email }}
                    </a>
                </li>
            </ul>
        </div>
        <div>
            Roles: {{ sr?.notifyRoles?.length }}
            <ul *ngIf="notifyRoles?.length">
                <li *ngFor="let role of notifyRoles">
                    <a
                        class="clickable"
                        (click)="detailsHelper.open('role', role)"
                    >
                    {{ role.name }}
                    </a>
                </li>
            </ul>
        </div>
        <div>
            Webhooks: {{ sr?.webhooks?.length === 0 ? 'None' : '' }}
            <ul *ngIf="sr?.webhooks?.length">
                <li *ngFor="let webhook of sr.webhooks">
                    {{ webhook }}
                </li>
            </ul>
        </div>
    </ng-container>

    
</div>

<ng-template #loadingBar>
    <div class="p-p-2">
        <p-skeleton width="100%" height="16px"></p-skeleton>
    </div>
</ng-template>
