import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { InjectionToken, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { ApolloLink, FetchResult, InMemoryCache, Observable, Operation, split } from '@apollo/client/core';
import { ErrorHandler, onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { APOLLO_OPTIONS, Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { print } from 'graphql';
import { Client, createClient } from 'graphql-ws';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';

import { PlusAuthenticationService } from './plus-auth.service';

function checkIfAuthenticationError(errors: any[]) {
  return errors.find((e) => {
    if (e.extensions && e.extensions.code === 'UNAUTHENTICATED') {
      return true;
    }
  }) !== undefined;
}

export interface PlusConnectionOptions {
  graphql: string;
  ws: string;
  websocketsEnabled: boolean;
}

export const PLUS_CONNECTION_OPTIONS = new InjectionToken<PlusConnectionOptions>('plus.connection-options');

export function createApollo(
  httpLink: HttpLink,
  injector: Injector,
  plusConnectionOptions: PlusConnectionOptions,
  graphqlModule: GraphQLModule,
) {

  const authLink = new ApolloLink((op, forward) => {
    let promiseResolve: () => void;
    const prom = new Promise<void>((resolve, reject) => {
      promiseResolve = resolve;
    });
    function resolveInFlightPromise() {
      if (promiseResolve) {
        // resolve at the end of the event queue
        setTimeout(() => {
          promiseResolve();
          const index = graphqlModule.inFlightPromises.indexOf(prom);
          if (index >= 0) {
            graphqlModule.inFlightPromises.splice(index, 1);
          }
        }, 1);
      }
    }

    const authService = injector.get(PlusAuthenticationService);

    const isAuthOp = [
      'authenticate',
      'deauthenticate',
    ].includes(op?.operationName);

    const authPromise = authService.checkAuthStatus(!isAuthOp, !isAuthOp);

    graphqlModule.inFlightPromises.push(prom);

    return graphqlModule.waitForReady()
      // wait for the auth observable to resolve before we proceed with the query
      .flatMap(() => new Observable((observer) => {
        authPromise.then((res) => {
          if (!isAuthOp && res === 'deauthenticating'){
            observer.error(res);
            return;
          }

          observer.next(res);
          observer.complete();
        });

        return () => {};
      }))
      .flatMap(
        () => forward(op).map((response) => {
          if (response && authService.connectionStatus.value !== 'connected') {
            authService.connectionStatus.next('connected');
          }

          if (isAuthOp) {
            resolveInFlightPromise();
            return response;
          }

          // Do not set zone if we are in the middle of a zone change
          if (graphqlModule.newZoneLoading) {
            resolveInFlightPromise();
            return response;
          }

          const context = op.getContext();
          const {
            response: { headers }
          } = context;

          let desiredZone = graphqlModule.zone.value;
          let desiredRole = graphqlModule.role.value;
          const opContext = op.getContext();

          opContext.zone = resolveZoneFromContext(opContext);

          if (opContext.zone) {
            desiredZone = opContext.zone;
            desiredRole = opContext.role;
          }

          // get user from header
          if (headers) {

            // TODO: initial login has zone set incorrectly for branding query
            const resolvedZone = headers.get('x-zone') || undefined;
            const resolvedRole = headers.get('x-role') || undefined;
            const backendResolvedDifferentZone = graphqlModule.zone.value === desiredZone && desiredZone !== resolvedZone;
            const backendResolvedDifferentRole = graphqlModule.role.value === desiredRole && desiredRole !== resolvedRole;

            if ((resolvedZone && !graphqlModule.zone.value) || (resolvedZone && backendResolvedDifferentZone)) {
              graphqlModule.zone.next(resolvedZone);
            }
            if (resolvedRole && !graphqlModule.role.value || (resolvedRole && backendResolvedDifferentRole)) {
              graphqlModule.role.next(resolvedRole);
            }

            const user = headers.get('x-user') || undefined;
            const deauthenticated = headers.get('x-deauthenticated');

            if (deauthenticated && op.operationName !== 'deauthenticated') {
              authService.onDeauthenticated(true).then(() => {
                location.reload();
              });
            } else if (!user && authService.user) {
              authService.notifySessionExpired();
              authService.onDeauthenticated(true).then(() => {
                location.reload();
              });
            }
          }

          // setTimeout(() => {
          resolveInFlightPromise();
          // }, 250);
          return response;

        })
      );
    // .map
  });

  const handleError: ErrorHandler = ({
    graphQLErrors,
    networkError,
    operation,
    forward,
  }) => {
    if (
      networkError &&
      networkError instanceof HttpErrorResponse &&
      networkError.error &&
      networkError.error.errors &&
      checkIfAuthenticationError(networkError.error.errors)
    ) {
      const authService = injector.get(PlusAuthenticationService);
      // If we are logged in, deauthenticate
      // since this is an authentication error
      if (authService.jwtPayload) {
        authService.onDeauthenticated(true)
        .then(() => {
          // reload the page after we've been deauthenticated to reload initial queries
          location.reload();
        });
      }
    } else if (
        // "graphQLErrors is an alias for networkError.result.errors if the property exists."
        networkError &&
        networkError instanceof HttpErrorResponse &&
        networkError.error?.errors?.length
      ) {
        // console.error(error, operation);
        const errors = networkError.error.errors;
        if (environment.breakAtGraphqlErrors) {
          // eslint-disable-next-line no-debugger
          debugger;
        }

        console.error(`[GQL Error] ${ operation.operationName }:`, operation, errors, networkError);
        // throw new Error(`Encountered ${ errors.length } Graphql Errors`);
    } else if (networkError instanceof HttpErrorResponse) {
      // Various Connection Errors

      // Client errors
      let isClientError = false;
      if (networkError.error?.errors) {
        for (const err of networkError.error.errors) {
          if (err.extensions.code === 'BAD_USER_INPUT') {
            console.error('[Error]: Bad Input Supplied to Function');
            isClientError = true;
          } else if (err.extensions.code === 'GRAPHQL_VALIDATION_FAILED') {
            console.error('[Error]: Graphql Schema is Invalid');
            isClientError = true;
          } else {
            console.error(networkError);
          }
        }
      }


      const status = networkError.status;
      // The Server is unreachable
      if (!isClientError && ((status >= 500 && status < 600) || status === 0)) {
        const authService = injector.get(PlusAuthenticationService);
        authService.connectionStatus.next('disconnected');
        authService.pollConnection();
      }
    }
  };

  const errorLink = onError(handleError);

  let finalHttpLink = authLink.concat(errorLink.concat(httpLink.create({
    uri: (op) => {

      let zone = graphqlModule.zone.value;
      let role = graphqlModule.role.value;
      // console.log({zone, role});

      const opContext = op.getContext();

      opContext.zone = resolveZoneFromContext(opContext);

      if (opContext.zone) {
        zone = opContext.zone;
        role = opContext.role;
      }

      let uri = plusConnectionOptions.graphql;
      const query = new URLSearchParams({
        n: op.operationName.slice(0, 32),
      });

      if (zone && role) {
        query.set('context', `${zone}:${role}`);
      } else if (zone) {
        query.set('zone', zone);
      }
      uri += `?${ query.toString() }`;

      return uri;
    },
    withCredentials: true,
  })));

  const supportsWebSockets = 'WebSocket' in window || 'MozWebSocket' in window;
  if (plusConnectionOptions.ws && supportsWebSockets && plusConnectionOptions.websocketsEnabled) {

    const wsProtocol = location.protocol === 'http:' ? 'ws:' : 'wss:';

    const wsUrl = `${ wsProtocol }//${ location.host }/api/subscriptions`;

    console.log(`Connecting to WS:`, wsUrl);

    if (!graphqlModule.wsClient) {

      graphqlModule.wsClient = createClient({
        url: wsUrl,
        // eslint-disable-next-line arrow-body-style
        connectionParams: () => {
          return {
          };
        },
      });
    }

    const wsLink = new GraphQLWsLink(graphqlModule.wsClient);

    finalHttpLink = split(
      ({ query }) => {
        const { kind, operation }: any = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      finalHttpLink,
    );
  }

  return {
    link: finalHttpLink,
    cache: makeCache(),
  };
}

export function makeCache() {
  return new InMemoryCache({
    resultCaching: true,
    // fragmentMatcher: new IntrospectionFragmentMatcher({ introspectionQueryResultData }),
    typePolicies: {
      Query: {
        fields: {
          jobs: {
            // Merge the existing jobs array if incoming does not have it
            merge(existing, incoming) {
              return {
                ...existing,
                ...incoming,
                jobs: incoming.jobs ? incoming.jobs : existing.jobs,
              };
            },
          },
          users: {
            // Merge the existing users array if incoming does not have it
            merge(existing, incoming) {
              return {
                ...existing,
                ...incoming,
                users: incoming.users ? incoming.users : existing.users,
              };
            },

          }
        },
      },
    }
  });
}

function resolveZoneFromContext(opContext: any): string | undefined {
  if (!opContext) { return undefined; }

  if (opContext.zone) {
    return opContext.zone;
  }

  if (opContext['x-zone']) {
    return opContext['x-zone'];
  }

  if (opContext.headers && opContext.headers['x-zone']) {
    return opContext.headers['x-zone'];
  }

  return undefined;
}



export class PlusApollo extends Apollo { }

export class GraphQLWsLink extends ApolloLink {
  constructor(private client: Client) {
    super();
  }

  public request(operation: Operation): Observable<FetchResult> {
    // eslint-disable-next-line arrow-body-style
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        },
      );
    });
  }
}

// @dynamic
@NgModule({
  imports: [
    // ApolloModule,
    HttpClientModule,
    // HttpLinkModule
  ],
  exports: [
    // ApolloModule,
    HttpClientModule,
    // HttpLinkModule
  ],
  providers: [],
})
export class GraphQLModule {

  wsClient: Client;
  inFlightPromises: Promise<void>[] = [];

  readyResolve: () => void;
  readyWaiting: Promise<void>;

  /**
   * A behaviour subject of the currently contexted zone
   */
  public zone = new BehaviorSubject<string>(undefined);

  /**
   * A behaviour subject of the currently contexted zone
   */
  public role = new BehaviorSubject<string>(undefined);

  /**
   * String combination of zone:role used to context
   * into the backend
   */
  public get context() {
    return `${this.zone}:${this.role}`;
  }

  public newZoneLoading = false;

  constructor() {

  }

  public static forRoot(config?: PlusConnectionOptions): ModuleWithProviders<GraphQLModule> {
    return {
      ngModule: GraphQLModule,
      providers: [
        {
          provide: PLUS_CONNECTION_OPTIONS,
          useValue: config || {
            graphql: 'localhost:1337/api/graphql',
            ws: 'localhost:1337/api/subscriptions',
          }
        },
        {
          provide: APOLLO_OPTIONS,
          useFactory: createApollo,
          deps: [
            HttpLink,
            Injector,
            PLUS_CONNECTION_OPTIONS,
            GraphQLModule,
          ],
        },
        {
          provide: PlusApollo,
          useExisting: Apollo,
        }
      ]
    };
  }

  reinitWebsocket() {
    if (!this.wsClient) { return; }
    // re-initialize the websocket connection
    // this.wsClient.close(false, false);
    console.log(`Reinitializing the websocket connection`);
  }



  markWaiting() {
    this.markReady();

    this.readyWaiting = new Promise((resolve) => {
      this.readyResolve = resolve;
    });
  }

  async waitForInflightQueries(timeout = 5000) {
    console.log('Changing Zone');
    return new Promise<void>(async (resolve) => {
      const timeoutHandle = setTimeout(() => {
        this.inFlightPromises = [];
        resolve();
      }, timeout);
      console.warn(this.inFlightPromises);
      Promise.all(this.inFlightPromises)
        .catch()
        .then((vals) => {
          this.inFlightPromises = [];
          clearTimeout(timeoutHandle);
          resolve();
        });
    });

  }

  markReady() {
    if (this.readyResolve) {
      this.readyResolve();
      this.readyResolve = undefined;
      this.readyWaiting = undefined;
    }
  }

  waitForReady() {
    return new Observable<boolean>((observer) => {
      if (!this.readyWaiting) {
        observer.next(true);
        observer.complete();
        return;
      } else {
        this.readyWaiting.then(() => {
          observer.next(true);
          observer.complete();
        });
      }

    });

  }
}
