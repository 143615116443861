/* eslint-disable @typescript-eslint/naming-convention */

import {dayjs} from '@karve.it/core';

export function currentTimeSeconds() {
	// eslint-disable-next-line @typescript-eslint/no-magic-numbers
	return Math.round(Date.now() / 1000);
}

export const MS_ONE_SECOND = 1000;
export const MS_TEN_SECONDS = 10000;
export const MS_ONE_HOUR = 3600000;
export const MS_ONE_DAY = 86400000;
export const MS_ONE_WEEK = 604800000;
export const MS_ONE_MONTH = 2592000000;
export const MS_ONE_YEAR = 31536000000;

export const S_ONE_MINUTE = 60;
export const S_ONE_HOUR = 3600;
export const S_THREE_HOURS = 10800;
export const S_ONE_DAY = 86400;
export const S_ONE_WEEK = 604800;
export const S_FOUR_WEEKS = 2419200;
export const S_ONE_YEAR = 31536000;
export const S_TWO_YEARS = 63072000;
export const S_TEN_YEARS = 3.154e+8;

export const HOURS_TO_SECONDS = 3600;
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const UNIX_MS_S_THRESHOLD = currentTimeSeconds() * 10;

export type DateFormat = 'mm/dd/yy' | 'dd/mm/yy';
export type TimeFormat = 12 | 24;
export const dateTimeOnlyFormat = 'DD/MM/YYYY HH:mm';


// eslint-disable-next-line no-shadow
export enum MONTH_INDEX {
	JANUARY,
	FEBRURARY,
	MARCH,
	APRIL,
	MAY,
	JUNE,
	JULY,
	AUGUST,
	SEPTEMBER,
	OCTOBER,
	NOVEMBER,
	DECEMBER,
}

/**
 * Divides val by 1000 if val is greater than UNIX_MS_S_THRESHOLD
 *
 * @param val unix seconds or milliseconds
 * @returns integer
 */
export function ensureUnixSeconds(val?: number) {
	if (val === undefined || val === null) { return val; }
	if (val > UNIX_MS_S_THRESHOLD) {
		return Math.round(val / MS_ONE_SECOND);
	}
	return val;
}

export async function resolveMilliseconds(milliseconds: number) {
	return new Promise(async (resolve) => {
		setTimeout(resolve, milliseconds);
	});
}

export async function resolveNever() {
	return new Promise(async () => {});
}

export function timeInSpan(time: number, start: number, end: number) {
	return time >= start && time <= end;
}

export function dateToDateString(date: Date) {
	return `${ date.getFullYear() }/${ date.getMonth() + 1 }/${ date.getDate() }`;
}

export function getCurrentDateStringFromDayJs(){
	return dayjs().format('YYYY-MM-DD') + 'T00:00';
}

/**
 * Convert a date from the american format to a useable format for javascript
 *
 * @param date Date string in format 'MM/DD/YYYY'
 * @param includeTime If you want to initialize a date object this must be true otherwise the date is initalized in GMT
 * @returns
 */
export function convertCalendarFormatToStandard(date: string, includeTime = false): string{
	const [month, day, year] = date.split('/');
    const formattedDate = `${year}-${month}-${day}${includeTime ? 'T00:00' : ''}`;

	return formattedDate;
}

/**
 * Returns an array of numbers in the format
 * [year, month, day, hour, minute, second, millisecond]
 * which can be accepted by moment to parse a date without conflicting with
 * the moment default timezone
 *
 * @param date date object
 * @returns tuple of numbers
 */
export function dateToParts(date: Date): [ number, number, number, number, number, number, number ] {
	return [
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
		date.getHours(),
		date.getMinutes(),
		date.getSeconds(),
		date.getMilliseconds(),
	];
}

export function dateToUnixSeconds(date: Date) {
	return Math.floor(date.getTime() / 1000);
}
