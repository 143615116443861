<!-- app.component.html -->
<div class="inventory-header">
	<h5 class="no-margin">
		Inventory
	</h5>
	<div class="right-buttons-container">
		<button
			*ngIf="notesFromStorage && !notesFromStorageRetrieved"
			class="p-button-outlined p-m-1"
			pButton
			pRipple
			label="Retrieve Unsaved Notes"
			icon="pi pi-sync"
			(click)="retrieveUnsavedNotes()"
			[disabled]="!job || loading || (estimateHelper.jobLoading | async)"
		></button>
		<button
			*ngIf="notesFromStorageRetrieved"
			class="p-button-outlined p-m-1"
			pButton
			label="Discard notes"
			(click)="declineRestoredNotes()"
			icon="pi pi-trash"
			[disabled]="!job || loading || (estimateHelper.jobLoading | async)"
		></button>
		<button
			*ngIf="notesFromStorageRetrieved"
			class="p-m-1"
			pButton
			pRipple
			label="Accept notes"
			(click)="acceptRestoredNotes()"
			icon="pi pi-check"
			[disabled]="!job || loading || (estimateHelper.jobLoading | async)"
		></button>
		<button
			*ngIf="yemboHelper.yemboEnabled
				&& !(job?.metadata | metadata : 'yemboEmployeeLink')
				&& !readonly"
			pButton
			class="p-button-outlined p-m-1"
			label="Self Survey"
			(click)="openSurveyDialog()"
			[disabled]="!job || (job?.metadata | metadata : 'yemboMoveCreatorId') || yemboMoveCreated || (estimateHelper.jobLoading | async)"
			tooltipDisabled
		></button>
		<button
			*ngIf="yemboHelper.yemboEnabled
				&& !(job?.metadata | metadata : 'yemboEmployeeLink')
				&& !readonly"
			pButton
			class="p-button-outlined p-m-1"
			label="Book Smart Consult"
			(click)="goToSmartConsult()"
			[disabled]="!job || (job?.metadata | metadata : 'yemboMoveCreatorId') || yemboMoveCreated || (estimateHelper.jobLoading | async)"
		></button>
		<!--<button
			pButton
			label="Start Onsite"
			(click)="startOnsite()"
			[disabled]="!job || (job?.metadata | metadata : 'yemboMoveCreatorId') || yemboMoveCreated || (estimateHelper.jobLoading | async)"
		></button>-->
		<app-yembo-documents
			viewType="actions"
			[jobId]="job?.id"
			(selfSurveyTriggered)="openSurveyDialog()"
			(bookSmartConsultTriggered)="goToSmartConsult()"
			(yemboMoveCreatedEmitted)="onYemboMoveCreatedFromMenu()"
			[getInventoryButtonsDisabled]="
				!job
				|| (job?.metadata | metadata : 'yemboMoveCreatorId')
				|| yemboMoveCreated
				|| (estimateHelper.jobLoading | async)">
		</app-yembo-documents>
	</div>
	<div *ngIf="(job?.metadata | metadata : 'yemboEmployeeLink')">
		<a target="_blank" [href]="(job?.metadata | metadata : 'yemboEmployeeLink')">
			<i class="pi pi-external-link"></i>
				View Move In Yembo
		</a>
	</div>
</div>

<small *ngIf="readonly">(Readonly)</small>

  <textarea
  	*ngIf="!readonly; else readonlyNotes"
	style="white-space: pre-line;"
	pInputTextarea
	class="full-width"
	[(ngModel)]="inventoryNote"
	(focus)="inventoryUpdated=true"
	rows="16"
	placeholder="Add notes about the inventory ..."
    [disabled]="!job || loading || (estimateHelper.jobLoading | async) || notesFromStorageRetrieved"
></textarea>
<ng-template #readonlyNotes>
	<p *ngIf="!loading && inventoryNote" style="white-space: pre-line;" [class.notes-max-height]="readonlyNotesScroll">{{inventoryNote}}</p>
</ng-template>


<div *ngIf="loading; else showEmptyMessage" class="p-m-4">
	Loading...
</div>

<ng-template #showEmptyMessage >
	<div *ngIf="!inventory.rooms?.length; else showData" class="p-m-4">
		No Rooms have been added
	</div>
</ng-template>

<ng-template #showData>
	<div *ngFor="let room of inventory.rooms" class="room-card">
		<p-panel 
			[toggleable]="true" 
			[collapsed]="!roomsExpanded"
			expandIcon="pi pi-angle-down"
			collapseIcon="pi pi-angle-up"
			toggler="header"
			transitionOptions="200ms cubic-bezier(0.86, 0, 0.07, 1)"
			>
			<ng-template pTemplate="header">
				<div class="room-header clickable">
					<span class="p-mr-2" style="font-weight: bold">{{room.name}}</span>
					<span>Items: {{room.totalItems}}</span>
					<span>Weight: {{room.totalWeight}} lbs</span>
					<span>Volume: {{room.totalVolume}} ft³</span>
				</div>
			</ng-template>
			<p-table [value]="room.inventory" styleClass="p-datatable-striped" responsiveLayout="scroll">
				<ng-template pTemplate="header">
				  <tr class="item-row">
					<th class="name">Name</th>
					<th class="number-input">Quantity</th>
					<th class="number-input">Weight (lbs)<br> <small>Per Item</small></th>
					<th class="number-input">Volume (ft³)<br> <small>Per Item</small></th>
					<th>Attributes</th>
					<th *ngIf="!readonly"></th>
				  </tr>
				</ng-template>
				<ng-template pTemplate="body" let-item>
				  <tr class="item-row">
					<td class="name">
					  <input
					  	*ngIf="!readonly && !item.generated; else nameReadonly"
						class="full-width" pInputText
						[(ngModel)]="item.name"
						(blur)="inventoryUpdated = true"
					/>
					  <ng-template #nameReadonly>{{item.name}}</ng-template>
					</td>
					<td class="number-input">
					  <input *ngIf="!readonly && !item.generated; else quantityReadonly" class="full-width" pInputText type="number" [(ngModel)]="item.quantity" (blur)="calculateRoomTotals()"/>
					  <ng-template #quantityReadonly>{{item.quantity}}</ng-template>
					</td>
					<td class="number-input">
						<input *ngIf="!readonly && !item.generated; else weightReadonly" class="full-width" pInputText type="number" [(ngModel)]="item.weight" (blur)="calculateRoomTotals()"/>
						<ng-template #weightReadonly>{{item.weight}}</ng-template>
					</td>
					<td class="number-input">
					  <input *ngIf="!readonly && !item.generated; else volumeReadonly" class="full-width" pInputText type="number" [(ngModel)]="item.volume" (blur)="calculateRoomTotals()"/>
					  <ng-template #volumeReadonly>{{item.volume}}</ng-template>
					</td>
					<td>
						<div class="attribute-chips" *ngFor="let attribute of inventoryItemAttributesOptions | keyvalue">
							<p-chip
								*ngIf="item[attribute.key]"
								class="p-m-1"
								[label]="attribute.value" 
								[removable]="!readonly"
								(onRemove)="item[attribute.key] = false; inventoryUpdated=true">
							</p-chip>
						</div>
						<p-chip
							*ngIf="item.generated"
							class="p-m-1"
							label="External" 
							[removable]="false"
							pTooltip="This item was generated from an external source and cannot be edited"
						>
						</p-chip>
						<button *ngIf="!readonly && !item.generated" pButton icon="pi pi-plus" (click)="op.toggle($event)"></button>
						<p-overlayPanel #op>
							<b>Add attributes by clicking them</b>
							<hr>
							<ng-template pTemplate>
								<div *ngFor="let attribute of inventoryItemAttributesOptions | keyvalue" >
									<p-chip
										*ngIf="!item[attribute.key]"
										class="clickable p-m-1 attribute-chips"
										[label]="attribute.value" 
										(click)="item[attribute.key] = true; inventoryUpdated=true">
									</p-chip>
								</div>
							</ng-template>
						</p-overlayPanel>
					</td>
					<td *ngIf="!readonly" style="text-align: right;">
						<button pButton icon="pi pi-trash" class="p-button-danger" (click)="removeItemFromRoom(room, item)"></button>
					</td>
				  </tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
					  <td colspan="5" style="text-align: center;">
						No items have been added to this room
					  </td>
					</tr>
				</ng-template>
				<ng-template pTemplate="summary">
					<tr *ngIf="!readonly" [formGroup]="itemForm" class="room-footer">
							<p-autoComplete  
								[suggestions]="filteredItemOptions" 
								placeholder="+ Add item ..."
								formControlName="name"
								[dropdown]="true"
								label="reviewerName"
								class="room-item-autocomplete p-m-1"
								#itemSelect
								[delay]="100"
								[completeOnFocus]="true"
								(completeMethod)="searchItemOptions($event, room);"
								(onSelect)="addItemToRoom(room, $event, itemSelect);" 
								appendTo="body"
							>
								<ng-template pTemplate="item" let-item>
									<div *ngIf="item.value !== 'VIEW'">
										<span *ngIf="item?.reviewerName; else textOnly">{{item.reviewerName}} <small>{{item.weight | number }} lbs, {{item.volume | number}}ft³</small></span>
										<ng-template #textOnly>{{itemForm.value.name}} (Custom)</ng-template>
									</div>
									<div *ngIf="item.value === 'VIEW'">
										View Complete List
									</div>
								</ng-template>
							</p-autoComplete>
							<div class="room-buttons">
								<button
									pButton
									class="p-m-1"
									label="Duplicate Room"
									icon="pi pi-copy"
									class="p-button"
									(click)="duplicateRoom(room)"
								></button>
								<button
									pButton
									class="p-m-1"
									label="Delete Room"
									icon="pi pi-trash"
									class="p-button-danger"
									(click)="removeRoom(room)"
								></button>
							</div>
					  </tr>
				</ng-template>
			  </p-table>
		  </p-panel>
		  <br>
	</div>
</ng-template>

<hr>

<div class="inventory-footer">
    <div [formGroup]="roomForm" class="autocomplete" *ngIf="!readonly">
      <p-autoComplete
	  	formControlName="name" 
		[suggestions]="filteredRoomOptions" 
		placeholder="+ Add room ..." 
		[dropdown]="true"
		[delay]="100"
		[completeOnFocus]="true"
		(completeMethod)="searchRoomOptions($event)"
		(onSelect)="addRoom()"
		appendTo="body"
		></p-autoComplete>
    </div>
	<div *ngIf="!loading && inventory" class="inventory-stats" >
		<div>
			Total Items
			<br>
			<b>{{inventory.totalItems}}</b>
		</div>
		<div>
			Total Weight
			<br>
			<b>{{inventory.totalWeight}} lbs</b>
		</div>
		<div>
			Total Volume
			<br>
			<b>{{inventory.totalVolume}} ft³</b>
		</div>
	</div>
  </div>


  <p-dialog [modal]="true" [(visible)]="showSurveyDialog">
    <ng-template pTemplate="header">
        <h4>Send Self Survey Link</h4>
    </ng-template>

    <div>
        <h6>Confirm before sending:</h6>
        <p>Email: {{customerEmail || 'None'}}</p>
        <p>Phone: {{({phone: customerPhone} | phone) || 'None'}}</p>
    </div>

    <p-footer>
        <button pButton
            (click)="createSelfSurvey('both')"
            icon="pi pi-arrow-right"
			[disabled]="yemboMoveCreated"
            label="Send Invite"
        ></button>
    </p-footer>
</p-dialog>


<p-dialog [modal]="true" [(visible)]="showItemDialog">
    <ng-template pTemplate="header">
        <h4>Inventory Items</h4>
    </ng-template>

    <div *ngFor="let item of defaultItemOptions">
		<span>{{item.reviewerName}}</span>
    </div>
</p-dialog>
