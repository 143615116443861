import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SubSink } from 'subsink';

import { AttentionItemsService } from '../attention-items.service';

@Component({
  selector: 'app-attention-items-summary',
  templateUrl: './attention-items-summary.component.html',
  styleUrls: ['./attention-items-summary.component.scss']
})
export class AttentionItemsSummaryComponent implements OnInit, OnDestroy {

  @Input() summaryStyle: 'dashboard' | 'chips' = 'dashboard';

  constructor(
    public attentionItemsService: AttentionItemsService,
  ) { }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

}
