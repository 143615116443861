import { Injectable, } from '@angular/core';
import { safeParseJSON } from '../js';

export interface InventoryNote {
    jobId: string;
    inventoryNotes: string;
  }

export type InventoryNotes = Record<string, string>;

@Injectable({
    providedIn: 'root'
  })
  export class UnsavedInventoryNotesService {
    getAllUnsavedNotes(): InventoryNotes {
        const notesJSON = localStorage.getItem('unsaved-inventory-notes');

        if (notesJSON) {
          return safeParseJSON(notesJSON, {});
        }
        return {};
    }

    getUnsavedNotesForJob(jobId: string): string {
      const allNotes: InventoryNotes = this.getAllUnsavedNotes();

      const note = allNotes[jobId];

      return note || '';
    }


    setUnsavedNotes(input: { jobId: string; note: string }): void {

      const inventoryNotes: InventoryNotes = this.getAllUnsavedNotes();

      inventoryNotes[input.jobId] = input.note;

      const updatedInventoryNotesJSON = JSON.stringify(inventoryNotes);

      localStorage.setItem('unsaved-inventory-notes', updatedInventoryNotesJSON);
    }

    removeNoteFromStorage(jobId: string): void {
      const inventoryNotes: InventoryNotes = this.getAllUnsavedNotes();

      delete inventoryNotes[jobId];

      const updatedInventoryNotesJSON = JSON.stringify(inventoryNotes);

      localStorage.setItem('unsaved-inventory-notes', updatedInventoryNotesJSON);
    }

  }