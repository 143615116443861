import { Component, OnInit, ViewChild } from '@angular/core';
import { QueryRef } from 'apollo-angular';

import { WatchQueryOptionsAlone } from 'apollo-angular/types';
import { SubSink } from 'subsink';

import { ConfigValueInput, GetConfigValuesGQL, GetConfigValuesQuery, GetConfigValuesQueryVariables, SetConfigValuesGQL } from '../../../generated/graphql.generated';
import { DEFAULT_HOW_HEARD_DROPDOWN_OPTIONS } from '../../global.constants';
import { safeParseJSON } from '../../js';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { PermissionService } from '../../services/permission.service';
import { EditData, EditableListComponent } from '../../shared/editable-list/editable-list.component';

@Component({
  selector: 'app-how-heard-options',
  templateUrl: './how-heard-options.component.html',
  styleUrls: ['./how-heard-options.component.scss']
})
export class HowHeardOptionsComponent implements OnInit {

  @ViewChild(EditableListComponent) editableListRef: EditableListComponent;

  subs = new SubSink();

  howHeardConfigQR: QueryRef<GetConfigValuesQuery, GetConfigValuesQueryVariables>;

  howHeardOptions: string[] = [];

  canEdit: boolean;

  constructor(
    private getConfigValues: GetConfigValuesGQL,
    private setConfigValues: SetConfigValuesGQL,
    private localNotify: FreyaNotificationsService,
    private permissions: PermissionService,
  ) { }

  ngOnInit(): void {
    this.retrieveHowHeardConfig();
    this.checkPermissions();
  }

  async checkPermissions() {
    this.subs.sink = this.permissions.watch().subscribe(() => {
      this.canEdit = this.permissions.checkPermission('configs.set');
    });
  }

  retrieveHowHeardConfig() {
    if (this.howHeardConfigQR) {
      this.howHeardConfigQR.refetch();
      return;
    }

    const opts: WatchQueryOptionsAlone<GetConfigValuesQueryVariables, any> = { fetchPolicy: 'cache-and-network' };

    this.howHeardConfigQR = this.getConfigValues.watch({ keys: [ 'jobs.howHeardOptions' ] }, opts);

    this.subs.sink = this.howHeardConfigQR.valueChanges.subscribe((res) => {
      if (res.loading) { return; }

      if (!res.data.getConfigValues.length) {
        this.howHeardOptions = DEFAULT_HOW_HEARD_DROPDOWN_OPTIONS;
        return;
      }

      const retrievedHowHeardOptions = safeParseJSON(res.data.getConfigValues[0].value) as string[];

      this.howHeardOptions = retrievedHowHeardOptions ? retrievedHowHeardOptions : DEFAULT_HOW_HEARD_DROPDOWN_OPTIONS;
    });

  }

  mutateHowHeard(editData: EditData) {

    const { items, editType } = editData;
    const configInput: ConfigValueInput = {
      key: 'jobs.howHeardOptions',
      value: JSON.stringify(items),
    };

    this.setConfigValues.mutate({ configs: [ configInput ]})
      .subscribe(() => {

        switch (editType) {
          case 'delete':
            this.editableListRef.closeDeleteDialog();
            this.localNotify.success('Item(s) deleted');
            break;
          case 'add':
            this.editableListRef.closeAddDialog();
            this.localNotify.success('Item added');
            break;
          case 'edit':
            this.editableListRef.closeEditDialog();
            this.localNotify.success('Item edited');
            break;
        }

        this.retrieveHowHeardConfig();
      });
  }

}
