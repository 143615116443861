import { Component, OnInit, ViewChild } from '@angular/core';
import {QueryRef} from 'apollo-angular';

import { ConfigValue, ConfigValueInput, GetConfigValuesGQL, GetConfigValuesQuery, GetConfigValuesQueryVariables, SetConfigValuesGQL } from 'graphql.generated';
import { SubSink } from 'subsink';

import { safeParseJSON } from '../js';
import { BrandingService } from '../services/branding.service';
import { FreyaNotificationsService } from '../services/freya-notifications.service';

import { MutateTransactionTypeComponent } from '../shared/mutate-transaction-type/mutate-transaction-type.component';

export interface ParsedTransactionType {
  key: string;
  name: string;
  provider: string;
  enabled: boolean;
  modified: boolean;
}

@Component({
  selector: 'app-payment-types',
  templateUrl: './payment-types.component.html',
  styleUrls: ['./payment-types.component.scss']
})
export class PaymentTypesComponent implements OnInit {
  @ViewChild('mutateTransactionType') mutateTransactionTypeRef: MutateTransactionTypeComponent;

  subs = new SubSink();

  paymentTypeRows: ParsedTransactionType[];

  paymentTypesQueryRef: QueryRef<GetConfigValuesQuery, GetConfigValuesQueryVariables>;

  loading: boolean;

  // Used for discard changes functionality
  defaultValuesLookup: { [transactionTypeKey: string]: ParsedTransactionType } = {};

  constructor(
    private branding: BrandingService,
    private getConfigGQL: GetConfigValuesGQL,
    private setConfigGQL: SetConfigValuesGQL,
    private notify: FreyaNotificationsService
  ) { }

  ngOnInit(): void {
    this.retrievePaymentTypes();
  }

  retrievePaymentTypes() {
    if (this.paymentTypesQueryRef) {
      this.paymentTypesQueryRef.refetch();
      return;
    }

    this.paymentTypesQueryRef = this.getConfigGQL.watch({ keys: 'payment-type.*'}, { fetchPolicy: 'cache-and-network' });

    this.subs.sink = this.paymentTypesQueryRef.valueChanges
      .subscribe((res) => {

        this.loading = res.loading;

        if (this.loading) { return; }

        this.paymentTypeRows = res.data.getConfigValues
          .map((t) => this.parseTransactionType(t))
          .filter(Boolean);

      });
  }

  parseTransactionType(transactionType: ConfigValue): ParsedTransactionType {
    const parsedValue = safeParseJSON(transactionType.value);
    if (!parsedValue) { return; };
    return {
      key: transactionType.key,
      name: parsedValue.name,
      provider: parsedValue.provider,
      enabled: parsedValue.enabled,
      modified: false
    };
  }

  openCreateTransactionTypeDialog() {
    this.mutateTransactionTypeRef.mutateType = 'create';
    this.mutateTransactionTypeRef.openDialog();
  }

  saveTransactionType(transactionType: ParsedTransactionType) {
    const { name, provider, enabled } = transactionType;
    const configInput: ConfigValueInput = {
      key: transactionType.key,
      value: JSON.stringify({ name, provider, enabled })
    };

    this.subs.sink = this.setConfigGQL
    .mutate({ configs: [configInput]})
    .subscribe(() => {

      transactionType.modified = false;

      // Update default value;
      const defaultValue = this.defaultValuesLookup[transactionType.key];
      Object.assign(defaultValue, transactionType);

      this.notify.success('Changes saved.');
    });
  }

  markTransactionTypeAsModified(transactionType: ParsedTransactionType) {
    transactionType.modified = true;
  }

  discardChangesToTransactionType(transactionType: ParsedTransactionType) {
    if (!transactionType.modified) { return; };
    const defaultValue = this.defaultValuesLookup[transactionType.key];
    Object.assign(transactionType, defaultValue);
  }

}
