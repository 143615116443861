import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PlusApollo } from '@karve.it/core';
import { RuleService } from '@karve.it/features';
import { describeRule } from '@karve.it/rule-checker';
import { ListRulesQueryVariables, RulePartsFragment } from 'graphql.generated';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { SubSink } from 'subsink';

import { DetailsHelperService } from '../../services/details-helper.service';
import { MutateRuleComponent } from '../../shared/mutate-rule/mutate-rule.component';

import { DescribedRule } from './rules.constants';

export type RuleMode = 'Ordered' | 'Assorted';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit, OnDestroy {

  @Input() zone?: string;
  @Input() mode: RuleMode = 'Ordered';

  @ViewChild('mutate') mutateRef: MutateRuleComponent;
  @ViewChild('rulesTable') rulesTable: Table;

  rules?: DescribedRule[];
  totalRecords?: number;
  loading = true;
  selectAll = false;
  selectedRules: DescribedRule[];

  watchQuery: ReturnType<typeof RuleService.prototype.watchRules>;

  skip = 0;
  limit = 10;

  private subs = new SubSink();

  constructor(
    private ruleService: RuleService,
    private dhs: DetailsHelperService,
  ) { }

  ngOnInit(): void {

    this.subs.sink = this.dhs.getObjectUpdates('Rules').subscribe(() => {
      this.loadRules();
    });
  }

  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }

  getVariables() {
    return {
      filter: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        overridePriority_EXISTS: this.mode === 'Ordered',
      },
      limit: this.limit,
      skip: this.skip,
      sort: 'overridePriority:DESC;createdAt:DESC',
    } as ListRulesQueryVariables;
  }

  parseRules(rules: RulePartsFragment[]) {
    return rules.map((r) => {
      const out: DescribedRule = {
        ...r,
        ...describeRule(r as any),
        condition: JSON.parse(r.condition),
      };
      return out;
    });
  }

  setMode(mode: RuleMode) {
    this.mode = mode;
    return this.reset();

  }
  reset() {
    this.loading = true;
    this.rules = [];
    this.totalRecords = undefined;
    this.loadRules();
  }

  loadRules(ev?: LazyLoadEvent) {
    this.loading = true;
    if (ev) {
      this.skip = ev.first;
      this.limit = ev.rows;
    }

    if (!this.watchQuery) {
      this.watchQuery = this.ruleService.watchRules(this.getVariables(), {
        zone: this.zone,
        fetchPolicy: 'cache-and-network',
      });

      this.subs.sink = this.watchQuery.valueChanges.subscribe((res) => {
        if (!res.data) { return; }
        this.totalRecords = res.data.rules.total;
        this.rules = this.parseRules(res.data.rules.rules);
        this.loading = false;
      });
    } else {
      this.watchQuery.refetch(this.getVariables());
    }
  }

  selectRule(rule: RulePartsFragment) {
    this.dhs.detailsItem.next({ type: 'rules', item: rule });
  }

  openCreate(){
    this.mutateRef.rule = undefined;
    this.mutateRef.mutateType = 'create';

    const lastRule = this.rules[0];
    const lastOrder = (lastRule?.overridePriority || 0) + 1;

    this.mutateRef.openDialog(
      this.zone,
      this.mode,
      this.mode === 'Ordered' ? lastOrder : undefined,
    );
  }

}
