import { MenuItem } from 'primeng/api';

import { prettyProperty } from './generic.util';

/**
 * Various conditions for why a menuItem can be disabled, add to this object as needed.
 * When declaring objects of this property specify them in the order of relevance.
 * ie. objectDeleted be declared before corporate because the object being deleted is more revelant to the user.
 */
export interface DisabledWhen {
    objectDeleted?: boolean;
    objectArchived?: boolean;
    inRootOrCorporateZone?: boolean;
    eventLocked?: boolean;
    eventCancelled?: boolean;
    eventUnscheduled?: boolean;
    eventInvoiced?: boolean;
    documentGenerating?: boolean;
    invoiceOutdated?: boolean;
    invoiceVoided?: boolean;
    invoiceFinalized?: boolean;
    invoicePaid?: boolean;
    invoiceExportedToQuickbooks?: boolean;
    invoiceDraft?: boolean;
    jobClosed?: boolean;
};

/**
 * Allows specifying custom tooltips for disabled when properties
 */
export type DisabledWhenTooltips = {
    [ K in keyof DisabledWhen ]?: string;
};


/**
 * The default tooltips for the disabledwhen values if none are provided
 */
export const DEFAULT_DISABLED_TOOLTIPS: DisabledWhenTooltips = {
    inRootOrCorporateZone: 'Action not allowed in the corporate zone',
    objectDeleted: 'Action not allowed on deleted object'
};

export interface KarveMenuItem extends MenuItem {
    // The conditions to check if this should be disabled
    disabledWhen?: DisabledWhen;

    // Overrides the default items type
    items?: KarveMenuItem[];
}

/**
 * Mutate categories and sets their visibility status based on the visibility of
 * their children
 */
export function parseMenuItemCategoriesVisible(
    categories: MenuItem[],
) {
    for (const category of categories) {
        if (!category.items) { continue; }
        const hasVisibleItem = category.items.find((i) => i.visible !== false);

        category.visible = Boolean(hasVisibleItem);
    }

    return categories;
}

/**
 * Loops through an menu items disabled properties and returns true if any of them needs to be disabled
 *
 * @param action The MenuItem to check
 * @param disabledWhen The consitions in the component that called this to check if they should be disabled
 * @param tooltips OPTIONAL, any values provided will override default tooltip value for the matching key.
 */
export function setMenuItemDisabled(action: KarveMenuItem, disabledWhen: DisabledWhen, tooltips?: DisabledWhenTooltips){
    for (const key of Object.keys(disabledWhen)){
        const value = disabledWhen[key];
        if (value && action?.disabledWhen && action?.disabledWhen[key]){
          action.disabled = true;

          const tooltip = tooltips?.[key] || DEFAULT_DISABLED_TOOLTIPS[key] || `Action Disabled: ${prettyProperty(key)}`;

          action.tooltipOptions = {
            tooltipPosition: 'left',
            tooltipLabel: tooltip
          };
          return;
        }
    }

    action.disabled = false;
    action.tooltipOptions = undefined;

    return false;
}
