<div class="auth-container">
    <div class="auth-header"></div>
    <div class="auth-body">
        <img
            class="app-logo"
            [src]="brandingService.watchIcon(256, 'light') | async"
            [routerLink]="[ '/auth', 'login' ]"
            alt="Application Logo"
            width="256px"
            height="256px"
        />

        <div *ngIf="token && !tokenLoading">
            <p-messages
                [(value)]="signupMessages"
                [enableService]="false"
            ></p-messages>
            <form
                class="auth-form"
                [formGroup]="signUpForm"
                (submit)="signup()"
            >
                <div class="input-container">
                    <div *appVar="signUpForm.get('firstName') as control">
                        <input
                            type="text"
                            class="login-input"
                            id="auth_first_name"
                            placeholder="First Name"
                            formControlName="firstName"
                            [class.p-invalid-input]="!control.valid && control.touched"
                            pInputText
                        >
                        <app-validation-messages
                            [control]="control"
                            [requireTouched]="true"
                        ></app-validation-messages>
                    </div>
                    <div *appVar="signUpForm.get('lastName') as control">
                        <input
                            type="text"
                            class="login-input"
                            id="auth_last_name"
                            placeholder="Last Name"
                            formControlName="lastName"
                            [class.p-invalid-input]="!control.valid && control.touched"
                            pInputText
                        >
                        <app-validation-messages
                            [control]="control"
                            [requireTouched]="true"
                        ></app-validation-messages>
                    </div>
                    <div *appVar="signUpForm.get('email') as control">
                        <input
                            type="email"
                            class="login-input"
                            id="auth_email_name"
                            placeholder="Email"
                            formControlName="email"
                            [class.p-invalid-input]="!control.valid && control.touched"
                            pInputText
                        >
                        <app-validation-messages
                            [control]="control"
                            [requireTouched]="true"
                        ></app-validation-messages>
                    </div>
                    <div *appVar="signUpForm.get('password') as control">
                        <input
                            pPassword
                            type="password"
                            id="password"
                            [feedback]="false"
                            formControlName="password"
                            placeholder="Password"
                            [class.p-invalid-input]="!control.valid && control.touched"
                        />
                        <app-validation-messages
                            [control]="control"
                            fieldName="Password"
                        >
                        </app-validation-messages>
                    </div>
                    <div *appVar="signUpForm.get('passwordConfirm') as control">
                        <input
                            pPassword
                            type="password"
                            id="password-confirm"
                            [feedback]="false"
                            formControlName="passwordConfirm"
                            placeholder="Confirm Password"
                            [class.p-invalid-input]="!control.valid && control.touched"
                        />
                        <app-validation-messages
                            [control]="control"
                            fieldName="Password Confirm"
                        >
                        </app-validation-messages>
                        <small class="field-error-message" *ngIf="!passwordsValid() && control.touched">
                            Passwords must match
                        </small>
                    </div>

                    <div class="auth-btn-set">
                        <button
                            pButton
                            pRipple
                            label="Sign Up"
                            id="auth-signup-button"
                            class="auth-btn p-button-lg p-button-primary signup-submit-button"
                            pRipple
                            type="submit"
                            [disabled]="!signUpForm.valid || !passwordsValid() || formSubmitted || !passwordAuthMethod"
                        >
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div [hidden]="token || tokenLoading">
            <div class="sign-up-error">
                <h2>Sign-up link expired</h2>
                <p>It looks like your sign up link has already been used or has expired.</p>
                <p><a [routerLink]="[ '/auth', 'login' ]">Go to login</a></p>
                
            </div>
        </div>

    </div>
    <app-auth-footer supportMessage="I'm having trouble signing up"></app-auth-footer>
</div>

<div
    *ngIf="tokenLoading"
    class="loading-cover"
>
    <i
        class="pi pi-spin pi-spinner"
        style="font-size: 4rem"
    ></i>
</div>