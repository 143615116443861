import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DomoreRegistrationService, PlusAuthenticationService, TokenService } from '@karve.it/core';

import { Message } from 'primeng/api';
import { FREYA_ROUTES } from 'src/app/global.constants';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { environment } from '../../../environments/environment';

import { AuthMethod, AuthMethodType, ListAuthMethodsGQL } from '../../../generated/graphql.generated';

import { BrandingService } from '../../services/branding.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss', '../auth.styles.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {

  subs = new SubSink();

  loginPath: string = FREYA_ROUTES.login;

  // Whether the token should be removed from the URL for security purposes
  removeTokenFromURL = environment.production;

  // The token loaded from the url, reset to undefined on error
  token: string;
  tokenLoading = true;

  signUpForm = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
    lastName: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
    email: new UntypedFormControl({value: '', disabled: true}, [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(environment.passwordMinLength),
    ]),
    passwordConfirm: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(environment.passwordMinLength),
    ]),
  });

  formSubmitted = false;

  authMethods: AuthMethod[] = [];
  passwordAuthMethod: AuthMethod;

  // Messages inside this component, more agency than notifications
  signupMessages: Message[] = [];

  constructor(
    public router: Router,
    private plusRegistration: DomoreRegistrationService,
    private plusAuth: PlusAuthenticationService,
    private route: ActivatedRoute,
    private tokenService: TokenService,
    private localNotify: FreyaNotificationsService,
    private freyaHelper: FreyaHelperService,
    public brandingService: BrandingService,
    private authMethodsGQL: ListAuthMethodsGQL,
  ) { }

  ngOnInit(): void {

    this.subs.sink = this.authMethodsGQL.fetch({}).subscribe((res) => {
      if (res.data.authMethods) {
        this.setAuthMethods(res.data.authMethods);
      }
    }, (err) => {
      console.error(`Error retrieving auth methods`, err);
      this.setAuthMethods([]);
    });

    this.subs.sink = this.route.queryParamMap.subscribe((params) => {
      if (params.get('token')) {
        this.token = params.get('token');

        if (this.removeTokenFromURL) {
          this.freyaHelper.setPageUrl('/auth/signup', true);
        }

        this.subs.sink = this.tokenService.validateToken({
          token: this.token,
          tokenType: 'Invite'
        }).subscribe((res) => {
          if (!res.data.validateToken) { return; }

          if (!res.data.validateToken.isValid) {
            this.setPageForInvalidToken();
            return;
          }

          const metadata = res.data.validateToken.metadata;

          this.signUpForm.setValue({
            firstName: metadata?.givenName, // .find((md) => md.key === 'givenName')?.value,
            lastName: metadata?.familyName,// .find((md) => md.key === 'familyName')?.value,
            email: metadata?.email, //
            password: '',
            passwordConfirm: '',
          });

          this.tokenLoading = false;

        }, (err) => {
          console.error(err);
          this.setPageForInvalidToken();
        });
      } else {
        this.tokenLoading = false;
        this.freyaHelper.setPageUrl('/auth/signup', true);
      }
    });
  }

  setAuthMethods(authMethods: AuthMethod[]) {
    this.authMethods = authMethods;
    console.log(authMethods);
    this.passwordAuthMethod = authMethods
      .find((a) => a.authMethodType === AuthMethodType.Password);

    // find password auth method. If it does not exist, show an error.
    if (!this.passwordAuthMethod) {
      this.signupMessages.push({
        severity: 'error',
        summary: 'Cannot sign up',
        detail: 'Password authentication is not available.',
      });
      return;
    }

    const passwordValidators = [
      Validators.required,
      Validators.minLength(this.passwordAuthMethod.password_minLength || 7),
    ];

    const passwordControl = this.signUpForm.get('password');
    passwordControl.clearValidators();
    passwordControl.setValidators(passwordValidators);
    const passwordConfirmControl = this.signUpForm.get('passwordConfirm');
    passwordConfirmControl.clearValidators();
    passwordConfirmControl.setValidators(passwordValidators);
  }

  setPageForInvalidToken(){
    this.token = undefined;
    this.localNotify.warning('Invite has expired or already been used');
    this.tokenLoading = false;
    this.freyaHelper.setPageUrl('/auth/signup', true);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  signup() {
    this.formSubmitted = true;
    const password = this.signUpForm.controls.password.value;

    this.subs.sink = this.plusRegistration.redeemInviteToken(
      {
        ...this.signUpForm.value,
        token: this.token,
        password,
      }).subscribe((redeemRes) => {
        if (redeemRes.data?.redeemInviteToken) {
          this.afterSignupSuccess(
            this.signUpForm.controls.email.value,
            password,
          );
        } else {
          this.signupMessages = [{ severity: 'error', detail: 'Failed to Signup' }];
        }
      }, (err) => {
        this.signupMessages = [{ severity: 'error', detail: err }];
        this.formSubmitted = false;
      });
  }

  afterSignupSuccess(email: string, password: string) {
    this.subs.sink = this.plusAuth.authenticate({
        email,
        password,
      }).subscribe(async (authRes) => {
        if (authRes) {
          this.router.navigate(['/']);
        }
    });
  }

  passwordsValid() {
    const password = this.signUpForm.get('password').value;
    const passwordConfirm = this.signUpForm.get('passwordConfirm').value;

    if (!password || !passwordConfirm) { return false; }
    return password === passwordConfirm;
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

}
