
import { EventEmitter } from '@angular/core';
import { NgxGpAutocompleteOptions } from '@angular-magic/ngx-gp-autocomplete/lib/ngx-gp-autocomplete-options';
import { Charge } from '@karve.it/interfaces/charges';
import { UpdateJobInput } from 'graphql.generated';

import { JobEventStatus, JobEventType } from '../global.constants';

export interface EstimateInfo {
  customerInfo: CustomerStepInfo;
  jobInfo: JobInfo;
  inventoryInfo: InventoryInfo;
  breakdownInfo: BreakdownInfo;
  bookingInfo: any; // Change to Type
  discount?: number;
  needsFinancing?: boolean;
  preferredDate?: string;
  preferredTime?: string;
  flexibility?: string;
  notes?: string;
  estimatedTotal?: number;
  resolvedZone?: string; // ID of the zone that we have resolved as the main one.

}

export interface InventoryInfo {
  inventory?: string;
  needFinance?: boolean;
  protectionPlan?: string;
  coffeeOrder?: string;
}

export interface BreakdownInfo {
  charges: Charge[];
  estimatedTotal: number;
}

export interface CustomerStepInfo {
  // Customer Info
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  // If they already exist then boom here you go
  userId?: string;

  // Customer History Info
  usedUsBefore?: boolean;
  usedOtherBefore?: boolean;
  howDidTheyHearAboutUs: string;
  // required event types requested by the customer
  requiredEvents?: string[];
}

export interface JobInfo {
  start: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    place_id: string;
    address: string;
    unit: string;
    bedrooms: string;
    bathrooms: number;
    sqft: number;

    partialMove: boolean;
    moreThan10Items: boolean;

    dwellingType: string;
    stairs: string;
    elevators: string;
    elevatorsBookable: boolean;
    parkingInformation: string;

    garages: GarageInfo[];
    sheds: ShedInfo[];
    storageUnits: StorageUnitInfo[];

    areaCode: string;
    country: string;
    city: string;
    jurisdiction: string;
  };

  end: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    place_id: string;
    address: string;
    unit: string;
    sqft: number;

    dwellingType: string;
    stairs: string;
    elevators: string;
    elevatorsBookable: boolean;
    parkingInformation: string;

    areaCode: string;
    country: string;
    city: string;
    jurisdiction: string;
  };
}

export type ExtraType = 'shed' | 'garage' | 'storage';

export interface GarageInfo {
  sqft: number;
  // Eg. 2 Car Garage
  xCarGarage: number;
}

export interface ShedInfo {
  sqft: number;
}

export interface StorageUnitInfo {
  sqft: number;
  indoor: boolean;
  private: boolean;
  accessAndSecurityInfo: string;
}

export interface Extra<D = ShedInfo | GarageInfo | StorageUnitInfo> {
  type: ExtraType;
  details: D;
}

export function extraIsGarage(extra: Extra): extra is Extra<GarageInfo> {
  return extra.type === 'garage';
}

export function extraIsShed(extra: Extra): extra is Extra<ShedInfo> {
  return extra.type === 'shed';
}

export function extraIsStorageUnit(extra: Extra): extra is Extra<StorageUnitInfo> {
  return extra.type === 'storage';
}


  // Options for Address AutoCompletes
export const googleLocationOptions: NgxGpAutocompleteOptions = {
    componentRestrictions: {
      // @ts-ignore
      country: ['ca', 'us'],
    },
    // location: {lat: 41.5, lng: -83.5} as LatLngLiteral,
    // radius: 7500
    // bounds: {north: 41.75, south: 41.5, east: -83.4, west: -83.75} as LatLngBoundsLiteral,
    // strictBounds: true
};

export interface ModifiedField {
  name: string;
  value: any;
  discardChanges?: boolean;
}

export interface EstimateUpdatedEvent {
  // property name which applies to rules
  property?: string;
  // value of the update
  value?: any;
}
export interface EventStatusChange {
  eventType: JobEventType;
  // the status to set.
  status?: JobEventStatus;
  // remove tag if there is no event associated
  remove?: boolean;
  // force set the status even if a following status for that event has already been created
  force?: boolean;
}

export interface EstimateUpdated {

  estimateUpdated: EventEmitter<EstimateUpdatedEvent>;

  getRuleData?(): any;

}

export interface EstimatingSaveInfo {
  metadata?: { [key: string]: string };
  input: UpdateJobInput;
  tagIds?: string[];
  removeTagIds?: string[];
  promises: Promise<boolean>[];
}

  /**
   * 0 if not saving
   * 1 when the job is in the process of saving
   * 2 when job is retrieving new values after a save
   */
// eslint-disable-next-line no-shadow
export enum JobSavingStage {
  NOT_SAVING = 0,
  SAVING = 1,
  RETRIEVING_VALUES = 2
}
