import { Injectable } from '@angular/core';
import { CalendarApi } from '@fullcalendar/core';
import {dayjs} from '@karve.it/core';
import { capitalize } from 'lodash';

import { DialogService } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';

import { Asset, BaseProductFragment, BaseUserFragment, CalendarEventForScheduleFragment, CalendarEventLocation, FullAvailabilityTemplateFragment, FullCalendarEventFragment } from '../../generated/graphql.generated';
import { AvailabilityEventTypes } from '../availability/availability.util';
import { RemoveAvailabilityComponent } from '../availability/remove-availability/remove-availability.component';
import { COLOR_MAP, isColorName } from '../colors';

import { EventLocationTypes, eventTypeInfoMap, JOB_ROLE_MAP, locationNameMap, SM_BREAKPOINT } from '../global.constants';
import { strToTitleCase } from '../js';
import { RESOURCE_AREA_WIDTH } from '../schedules/schedule.constants';
import { EVENT_STATUS_ICON_MAP } from '../schedules/schedule.util';
import { FCEventHolder } from '../shared/event-location/calendarevent.util';
import { PRODUCT_LABOR_CATEGORY } from '../shared/product-categories/product-categories';
import { ensureUnixSeconds, S_ONE_HOUR, timeInSpan } from '../time';
import { firstInitialLastName, formatUserName, lastNameFirstName } from '../users/users.utils';
import { getTextColorForBackground } from '../utilities/color.util';
import { getActualTravelTime, getFirstLocation, getLocationAreaCode } from '../utilities/locations.util';

import { DetailsHelperService } from './details-helper.service';

import { EstimateHelperService } from './estimate-helper.service';
import { FreyaHelperService } from './freya-helper.service';

export interface BookingInformation{
  date: Date;
  assetId: string;
  time: number;
}

export interface EventColorInfo {
  backgroundColor: string;
  textColor: string;
  travelTimeColor: string;
  leftDivColor?: string;
  leftDivTextColor?: string;
  leftDivColorProduct?: BaseProductFragment;
  zoneBackgroundColor?: string;
  zoneTextColor?: string;
  endAreaColor?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FullCalendarHelperService {

  // Used to set the date of the calendar
  changeCalendarDate = new Subject<Date>();

  availabilityClicked = new Subject();

  constructor(
    private estimateHelper: EstimateHelperService,
    private freyaHelper: FreyaHelperService,
    private detailsHelper: DetailsHelperService,
    private dialogService: DialogService,
  ) { }

  createFreyaAvailability(hidden, child = false){
     // CREATE CONTAINER ELEMENT
     const containerEl = document.createElement('div');
     containerEl.classList.add('freya-event-container');

    // CREATE AND APPEND EVENT ELEMENT
    const eventEl = document.createElement('div');
    eventEl.classList.add('freya-calendar-event');
    eventEl.style.width = '100%';
    containerEl.append(eventEl);

    eventEl.classList.add('freya-availability-event');

    if (child){
      eventEl.style.backgroundImage = 'none';
    }

    if (hidden){
      containerEl.classList.add('freya-event-hidden');
      eventEl.classList.add('freya-event-hidden');
    }

    return containerEl;
  }

  createFreyaEvent(
    info,
    calendarEvent: FullCalendarEventFragment,
    calendar: CalendarApi,
    hidden = false,
    editable = false,
    hasBeenEdited = false,
    disabled = false,
  ) {
    // If events were moved to this day they will not have a mtaching calendar event in the list
    if (!calendarEvent && info?.event._def?.extendedProps?.loaded === false){
      calendarEvent = info?.event._def?.extendedProps.event;
    }

    // Make sure the calenar event exists
    if (!calendarEvent) {
      // eslint-disable-next-line no-underscore-dangle
      calendar.getEventById(info.event._def.groupId)?.remove();
      return;
    }

    const isMonthView = calendar.view.type === 'dayGridMonth';

    // Get the accurate widths for the travel times
    const startTravelTime =
    getActualTravelTime(calendarEvent.locations.find((l) => l.type === EventLocationTypes.dockStart) as CalendarEventLocation);

    const secondLastLocation = calendarEvent.locations.find((l) => l.type === EventLocationTypes.end)
      || calendarEvent.locations.find((l) => l.type === EventLocationTypes.start);

    const endTravelTime = getActualTravelTime(secondLastLocation as CalendarEventLocation);

    // Use the data from the event so it remains accurate when editing the length
    const eventDuration = ensureUnixSeconds(info.event.end.getTime()) - ensureUnixSeconds(info.event.start.getTime());

    const startTravelWidth = (startTravelTime) / eventDuration * 100;

    const endTravelWidth = (endTravelTime) / eventDuration * 100;

    const eventWidth = 100 - (startTravelWidth + endTravelWidth);


    const {
      leftDivColor,
      backgroundColor,
      textColor,
      travelTimeColor,
      zoneBackgroundColor,
      endAreaColor,
    } = this.determineColorsFromEvent(calendarEvent);

    // CREATE CONTAINER ELEMENT
    const containerEl = document.createElement('div');
    containerEl.classList.add('freya-event-container');

    if (hasBeenEdited){
      containerEl.classList.add('freya-event-edited');
    }

    if(editable){
      containerEl.classList.add('freya-event-draggable');
    }

    if (disabled) {
      containerEl.classList.add('freya-event-disabled');
    }

    if (!isMonthView){
      // CREATE START TRAVEL TIME
      const startTravelDiv = document.createElement('div');
      startTravelDiv.style.width = `${startTravelWidth}%`;
      startTravelDiv.style.backgroundColor = travelTimeColor;
      startTravelDiv.classList.add('freya-travel-time-start');
      containerEl.append(startTravelDiv);
    }

    // CREATE EVENT ELEMENT
    const eventEl = document.createElement('div');
    eventEl.classList.add('freya-calendar-event');
    eventEl.style.width = `${isMonthView ? 100 : eventWidth}%`;
    eventEl.style.backgroundColor = backgroundColor;
    containerEl.append(eventEl);

    if (!isMonthView){
      // CREATE END TRAVEL TIME
      const endTravelDiv = document.createElement('div');
      endTravelDiv.style.width = `${endTravelWidth}%`;
      endTravelDiv.style.backgroundColor = travelTimeColor;
      endTravelDiv.classList.add('freya-travel-time-end');
      if (editable){
        endTravelDiv.classList.add('freya-event-length-editable');
      }
      containerEl.append(endTravelDiv);
    }

    // CREATE AREA BAR
    const areaBarElement = document.createElement('div');
    areaBarElement.classList.add('event-area-bar');
    if (isMonthView){
      areaBarElement.style.marginTop = '0px';
    }

    areaBarElement.style.background = `linear-gradient(45deg, ${zoneBackgroundColor} 40%, ${endAreaColor} 60%)`;
    containerEl.append(areaBarElement);

    if(hidden) {
      containerEl.classList.add('freya-event-hidden');
      eventEl.classList.add('freya-event-hidden');
      areaBarElement.classList.add('freya-event-hidden');
    }

    // Add Month Styling if month view
    if (calendar.view.type === 'dayGridMonth') {
      containerEl.classList.add('freya-month-event');
    }

    // SET CREW SIZE COLOR
    const crewSizeColor = leftDivColor || backgroundColor;
    const eventTypeInfoDiv = document.createElement('div');
    eventTypeInfoDiv.classList.add('event-type-info');
    eventTypeInfoDiv.style.backgroundColor = crewSizeColor;
    eventTypeInfoDiv.style.border = `1.5px solid ${travelTimeColor}`;


    // SET THE STATUS ICON
    const eventIconsDiv = document.createElement('div');
    eventEl.append(eventIconsDiv);
    eventIconsDiv.append(eventTypeInfoDiv);

    const statusContainerEl = document.createElement('div');
    statusContainerEl.classList.add('status');

    const statusIconEl = document.createElement('i');
    statusContainerEl.append(statusIconEl);

    statusIconEl.style.backgroundColor = backgroundColor;
    statusIconEl.style.color = textColor;

    statusIconEl.classList.add('pi', this.determineIconFromEvent(calendarEvent as any));
    eventIconsDiv.append(statusContainerEl);

    // SET THE TITLE
    const titleEl = document.createElement('span');
    titleEl.classList.add('title', 'info');
    titleEl.innerText = this.resolveTitle(calendarEvent);

    // SET THE ZIP/POSTAL CODE
    const startPostalCodeEl = document.createElement('span');
    startPostalCodeEl.classList.add('postal', 'info');
    const loc = getFirstLocation(calendarEvent);
    if (loc?.location) {
      startPostalCodeEl.innerText = getLocationAreaCode(loc.location);
    } else {
      startPostalCodeEl.innerText = 'No Area';
    }

    const endPostalCodeEl = document.createElement('span');
    endPostalCodeEl.classList.add('postal', 'info');
    const endLocation = getFirstLocation(calendarEvent, EventLocationTypes.end, true);
    if (endLocation?.location) {
      endPostalCodeEl.innerText = `- ${getLocationAreaCode(endLocation.location)}`;
    }

    // SET THE MAIN SECTION
    const mainContainerEl = document.createElement('div');
    mainContainerEl.append(titleEl, startPostalCodeEl, endPostalCodeEl);
    eventEl.append(mainContainerEl);

    return containerEl;
  }

  resolveTitle(calendarEvent: FullCalendarEventFragment) {

    const customer = calendarEvent?.job?.users?.find((a) => a.role === JOB_ROLE_MAP.customerRole);

    if (!customer) {
      return calendarEvent.title;
    }

    return customer.user.company || lastNameFirstName(customer.user);
  }

  createBookedOffEvent(calendarEvent, viewType){
     // CREATE CONTAINER ELEMENT
     const containerEl = document.createElement('div');
     containerEl.classList.add('freya-event-container');
     containerEl.style.borderRadius = '4px';

     if (viewType==='dayGridMonth'){
      containerEl.classList.add('freya-event-hidden');
     }

     containerEl.style.backgroundColor = 'var(--light-availability-color)';

     const contentContainer = document.createElement('div');
     contentContainer.classList.add('freya-calendar-event');

     // CREATE THE TITLE EL
     const titleEl = document.createElement('span');
     titleEl.classList.add('title', 'info');
     titleEl.innerText = calendarEvent.title;

     const descriptionEl = document.createElement('span');
     descriptionEl.classList.add('postal', 'info');
     descriptionEl.innerText = calendarEvent.description;

    // SET THE MAIN SECTION
    const mainContainerEl = document.createElement('div');
    mainContainerEl.append(titleEl, descriptionEl);
    contentContainer.append(mainContainerEl);
    containerEl.append(contentContainer);

    return containerEl;
  }

  determineColorsFromEvent(
    ce?: CalendarEventForScheduleFragment,
  ): EventColorInfo {
    let eventColorInfo: EventColorInfo = {
      backgroundColor: COLOR_MAP.blue.color,
      textColor: COLOR_MAP.blue.textColor,
      travelTimeColor: COLOR_MAP.blue.lightColor,
    };
    if (!ce) {
      return eventColorInfo;
    }
    const eventTypeInfo = eventTypeInfoMap[ce.type];
    if (eventTypeInfo && (isColorName(eventTypeInfo.backgroundColor))) {
      const color = COLOR_MAP[eventTypeInfo.backgroundColor];
      eventColorInfo.backgroundColor = color.color;
      eventColorInfo.textColor = color.textColor || '#000000';
      eventColorInfo.travelTimeColor = color.lightColor;
    } else if (eventTypeInfo?.backgroundColor) {

    // TODO: validate when we pull this from the server
    eventColorInfo.backgroundColor = eventTypeInfo.backgroundColor;
    eventColorInfo.textColor = eventTypeInfo.textColor || '#000000';
    }

    const products = ce.charges?.map((c) => c.product) || [];
    // todo: sort?
    for (const product of products) {
      const productColorInfo = this.determineColorInfoFromProduct(product);
      if (productColorInfo) {
        eventColorInfo = {
          ...eventColorInfo,
          ...productColorInfo,
        };
        break;
      }
    }

    // Get Colors from service area
    const startLocation = ce.locations.find((l) => l.type === 'start');
    if (startLocation?.location?.serviceArea) {
      eventColorInfo.zoneBackgroundColor = startLocation?.location?.serviceArea?.color;
      eventColorInfo.zoneTextColor = '#000000';
    } else if (ce.zones?.nodes?.length) {
      const firstZoneWithcolor = ce.zones.nodes?.find((z) => z.color);
      if (firstZoneWithcolor) {
        eventColorInfo.zoneBackgroundColor = firstZoneWithcolor.color;
        eventColorInfo.zoneTextColor = '#000000';
      } else {
        eventColorInfo.zoneBackgroundColor = 'black';
        eventColorInfo.zoneTextColor = 'white';
      }
    } else {
      // Use black to signify it doesn't match any zones
      eventColorInfo.zoneBackgroundColor = 'black';
    }

    const endLocation = ce.locations.find((l) => l.type === 'end');
    if(!endLocation?.location?.serviceArea) {
      eventColorInfo.endAreaColor = eventColorInfo.zoneBackgroundColor;
    } else if (endLocation?.location?.serviceArea){
      eventColorInfo.endAreaColor = endLocation?.location?.serviceArea?.color;
    } else {
      // Use black to signify it doesn't match any zones
      eventColorInfo.endAreaColor = 'black';
    }

    return eventColorInfo;
  }

  determineColorInfoFromProduct(
    product: BaseProductFragment,
  ) {
    if (!product) { return undefined; }

    if (product.category !== PRODUCT_LABOR_CATEGORY) { return; }

    const metaColor = product?.metadata?.color;

    if (isColorName(metaColor) && COLOR_MAP[metaColor]) {
      const color = COLOR_MAP[metaColor];
      return {
        leftDivColor: color.color,
        leftDivTextColor: color.textColor,
        leftDivColorProduct: product,
      };
    }
    return undefined;
  }

  /**
   * Returns the correct icon class based on event status
   *
   * @param event Calendar event
   * @returns The class for the icon based on the event status
   */
  determineIconFromEvent(event: FullCalendarEventFragment): string{
    const icon = EVENT_STATUS_ICON_MAP.find((esi) => esi.status === event.status);

    if (!icon){
      return 'pi-bars';
    }

    return icon.icon;
  }

  /**
   * Builds an returns the Hover overlay for events on the calendar
   *
   * @param info The info parameter from the mouse enter event
   * @param event The calendar event for the event
   * @returns The Element
   */
  createEventHover(info, event: FullCalendarEventFragment){
    const hover = document.createElement('div'); // The Container
      hover.className = 'schedule-hover-container';
      const hoverStart = document.createElement('div');
      hoverStart.className = 'schedule-hover';

      const {
        leftDivColorProduct,
        leftDivColor,
        leftDivTextColor,
        backgroundColor,
        textColor,
        zoneBackgroundColor,
        zoneTextColor,
      } = this.determineColorsFromEvent(event);

      const baseTime = 'h:mm a';
      const dateTime = 'MMM D h:mm a';

      appendEventTypeOrTitle();

      if (event) {
        const {
          eventStart,
          eventEnd
        } = this.freyaHelper.getCalendarEventStartandEnd(event);

        // Job Info Section
        const jobInfoDiv = document.createElement('div');
        jobInfoDiv.classList.add('schedule-hover');
        jobInfoDiv.append(
          createJobCode(),
          createTime(eventStart, eventEnd),
          createStatusElement(this.determineIconFromEvent(event as any))
        );
        if (event.attendees) {
          jobInfoDiv.append(createAttendees());
        }

        jobInfoDiv.style.border = `2px solid ${backgroundColor}`;

        // Product / Crew Section
        const crewInfoDiv = document.createElement('div');
        crewInfoDiv.classList.add('schedule-hover');
        const productInfoDiv = createProduct();
        if (productInfoDiv) {
          crewInfoDiv.append(productInfoDiv);
        }

        crewInfoDiv.style.border = `2px solid ${leftDivColor}`;

        // Area and Locations Section
        const areaInfoDiv = document.createElement('div');
        areaInfoDiv.classList.add('schedule-hover');
        areaInfoDiv.append(createArea());
        if (event.locations) {
          areaInfoDiv.append(createLocations(this.estimateHelper.getLocationArrivalAndDepartureTimes(event)));
        }

        areaInfoDiv.style.border = `2px solid ${zoneBackgroundColor}`;

        hoverStart.append(jobInfoDiv, crewInfoDiv, areaInfoDiv);
      }

      function appendEventTypeOrTitle() {
        const hoverTitle = document.createElement('span');
        hoverTitle.innerText = info.event?.title || event?.title || info.title;
        hoverTitle.classList.add('hover-title');
        if (backgroundColor && event) {
          hoverTitle.style.backgroundColor = backgroundColor;
          hoverTitle.style.color = textColor;
        }
        hoverStart.append(hoverTitle);
      }

      function createStatusElement(icon){
        const statusDiv = document.createElement('div');
        statusDiv.style.textAlign = 'center';
        const statusTextEl = document.createElement('span');
        statusTextEl.style.marginLeft = '4px';
        statusTextEl.innerText = capitalize(event.status);
        const statusIconEl = document.createElement('i');
        statusDiv.append(statusIconEl, statusTextEl);

        statusIconEl.classList.add('pi', icon);

        return statusDiv;
      }

      function createProduct() {
        if (leftDivColorProduct && leftDivColor && leftDivTextColor) {

          const productDiv = document.createElement('span');
          productDiv.className = 'schedule-hover-product-color';
          productDiv.innerText = leftDivColorProduct.name;
          productDiv.style.backgroundColor = leftDivColor;
          productDiv.style.color = leftDivTextColor;

          return productDiv;
        }
      }

      function createArea(){
        const color = zoneBackgroundColor || '#FFFFFF';

        const areaBarEl = document.createElement('span');
        areaBarEl.innerText = event.locations?.find((l) => l.type === 'start')?.location?.serviceArea?.name
          || event.zones?.nodes[0] ? event.zones.nodes[0].name : 'Locations';
        areaBarEl.style.backgroundColor = color;
        areaBarEl.style.color = zoneTextColor;
        areaBarEl.className = 'schedule-hover-product-color';

        return areaBarEl;
      }

      function createJobCode() {
        if (event?.job) {
          const jobCodeDom = document.createElement('span');
          jobCodeDom.className = 'schedule-hover-jobcode';
          jobCodeDom.innerText = event.job?.code;

          // if (zoneBackgroundColor) {
            // jobCodeDom.style.backgroundColor = zoneBackgroundColor;
            // jobCodeDom.style.color = zoneTextColor;
          // }

          return jobCodeDom;
        }
      }

      function createTime(eventStart: number, eventEnd: number) {
        const calendarStart = dayjs(info.view.currentStart);
        const calendarEnd = dayjs(info.view.currentEnd);
        /* 
          We can set dayjs.tz() to the branding timezone because we use a common instance of dayjs across 
          our project, and the timezoneHelper service sets the default timezone when the branding timezone changes.
          That said, if we use dayjs() without .tz() it will use the browser's timezone.
          We didn't use day.tz(time, timezone) because this would require us to access timezone service and createTime is
          an event called by Full Calendar component.
        */
        const start = dayjs.tz(eventStart * 1000); 
        const end = dayjs.tz(eventEnd * 1000);
        let startFormat = baseTime;
        let endFormat = baseTime;

        if (!timeInSpan(start.unix(), calendarStart.unix(), calendarEnd.unix())) {
          startFormat = dateTime;
        }
        if (!timeInSpan(end.unix(), calendarStart.unix(), calendarEnd.unix())) {
          endFormat = dateTime;
        }

        const showEnd = (end.unix() - start.unix()) > S_ONE_HOUR;

        const timeDom = document.createElement('span');
        let innerText = `${ start.format(startFormat) }`;
        if (showEnd) {
          innerText += ` to ${ end.format(endFormat) }`;
        }
        timeDom.innerText = innerText;

        return timeDom;
      }

      function createAttendees(){
        const table = document.createElement('table');
        table.className = 'schedule-hover-attendees';
        const thead = table.createTHead();
        const headerRow = thead.insertRow();
        for (const headerText of [ 'Name', 'Role' ]) {
          const headercell = headerRow.insertCell();
          headercell.innerText = headerText;
        }
        const tableBody = table.createTBody();

        const customer = event?.job?.users?.find((a) => a.role === JOB_ROLE_MAP.customerRole);
        const attendees = customer ? [ customer, ...event.attendees] : event.attendees;
        for (const attendee of attendees) {
          const row = tableBody.insertRow();

          const nameCell = row.insertCell();


          nameCell.innerText = formatUserName(attendee.user);

          const roleCell = row.insertCell();
          roleCell.innerText = strToTitleCase(attendee.role);
        }

        return table;
      }

      function createLocations(ceLocations){
        const table = document.createElement('table');
          table.className = 'schedule-hover-locations';
          const thead = table.createTHead();
          const headerRow = thead.insertRow();
          for (const headerText of [ 'Location' ]) {
            const headercell1 = headerRow.insertCell();
            headercell1.innerText = headerText;
          }
          const headercell2 = headerRow.insertCell();
          headercell2.innerText = 'Arrival / Departure';
          headercell2.colSpan = 2;

          const tableBody = table.createTBody();
          // let lastTime = undefined;
          for (const location of ceLocations) {
            const row = tableBody.insertRow();

            const locationType = locationNameMap[location.type] || location.type;
            const arrival = dayjs.tz(location.arrivalTime * 1000);
            const departure = dayjs.tz(location.departureTime * 1000);

            const nameCell = row.insertCell();
            nameCell.innerText = strToTitleCase(locationType);

            const arrivalCell = row.insertCell();
            arrivalCell.innerText = arrival.format(baseTime);
            if (arrival.unix() === departure.unix()) {
              arrivalCell.colSpan = 2;

            } else {
              const departureCell = row.insertCell();
              departureCell.innerText = departure.format(baseTime);
            }

            if (location.travelToNextDestination > 30) {
              const nextDestinationRow = tableBody.insertRow();
              const nextDestinationCell = nextDestinationRow.insertCell();
              nextDestinationCell.className = 'travel-to-next-destination';
              nextDestinationCell.colSpan = 3;

              const duration = dayjs.duration(location.travelToNextDestination, 'seconds');

              nextDestinationCell.innerText = `${ duration.humanize() } to next destination`;
            }
          }

          return table;
      }

      hoverStart.classList.add('hover-start');

      hover.append(hoverStart);

      hover.classList.add('freya-fc-hover');
      hover.classList.add('p-overlaypanel');

      hover.style.setProperty('top', `${window.scrollY + info.el.getBoundingClientRect().top + 30}px`);
      hover.style.setProperty('left', `${window.scrollX + info.el.getBoundingClientRect().left}px`);
      // TODO: set max width
      // hover.styl4

      const body = document.body;

      body.appendChild(hover);

      return info.el;
  }

  /**
   * Checks an event for conflict against a list of events
   *
   * @param events The list of events for the given time span
   * @param eventToCheck The event we are checking conflicts for
   * @param editedEvents The local version of the edited events that have not yet been saved
   * @returns True if the event conflicts
   */
  conflictsWithEvent(events: FCEventHolder[], eventToCheck: FCEventHolder, editedEvents: FCEventHolder[]): boolean{
    const start = eventToCheck.start;
    const end = eventToCheck.end;

    for (let event of events){
      // Ignore itself when chcking for conflicts
      if (event.id === eventToCheck.id){
        continue;
      }

      // If the event has been edited, check for conflicts with new version
      const editedVersion = editedEvents.find((edit) => edit.id === event.id);

      if (editedVersion){
        event = editedVersion;
      }

      // If events are not on the same asset ignore conflicts
      if (!event.assetIds.find((a) => eventToCheck.assetIds.includes(a))){
        continue;
      }

      // If the event spans the entirity of the block
      if (event.start <= start && event.end >= end){
          return true;
      }

      // If the event starts during the block and is longer than it
      if ((event.start >= start && event.start <= end) && event.end >= end){
          return true;
      }

      // If the event overlaps the start and ends in the middle of the block
      if (event.start <= start && (event.end <= end && event.end >= start)){
          return true;
      }

      // If the event is in the middle of the block
      if (event.start >= start && event.end <= end){
          return true;
      }
  }
  }


  // RESOURCES
  /**
   * Generate the Resource Label for the timeline view
   *
   * @param info The ResourceLabelContentArg object
   * @param assets List of to search
   * @returns HTML for resource label
   */
 generateResourceLabel(info, assets: Asset[]){
    // Get Values from resource
    const title = info.resource._resource.title;
    let assetId = info.resource.id;
    if (assetId.startsWith('A-')){
      assetId = assetId.replace('A-', '');
    }
    const template = info.resource?.extendedProps?.template;

    // Create and Style the element
    const spanEl = document.createElement('span');
    spanEl.innerHTML = title;

    // Add assetId attribute to element
    spanEl.setAttribute('assetId', assetId);

    const matchingAsset = assets?.find((a) => a.id === assetId);

    // If there is a matching asset (i.e., event is not unassigned), make it a link
    if (matchingAsset) {
      spanEl.classList.add('freya-link-button');

      // Setup Event Listener
      spanEl.onclick = (event) => {
        this.detailsHelper.open('asset', {id: matchingAsset.id});
      };
    } else if (template) {
      spanEl.classList.add('freya-link-button');

      // Setup Event Listener
      spanEl.onclick = (event) => {
        this.detailsHelper.open('availability-template', template);
      };
      const buttonEl = document.createElement('span');

      // Add button to element
      const iconEl = document.createElement('i');
      iconEl.classList.add('pi', 'pi-trash');
      buttonEl.append(iconEl);

      buttonEl.classList.add('freya-remove-availability-button');

      if (this.freyaHelper.inRootOrCorporateZone){
        buttonEl.style.display = 'none';
      }

      buttonEl.onclick = (event) => {
        this.dialogService.open(RemoveAvailabilityComponent, {
          header: 'Remove Availability Template',
          data: {
            input: {
              asset: info.resource.extendedProps.parent,
              template,
              type: info.resource.extendedProps.type,
              date: info.resource.extendedProps.date,
            }
          },
          styleClass: 'remove-availability',
          width: '50vw',
        });
      };

      return {domNodes: [buttonEl, spanEl]};

    }

    return { domNodes: [spanEl] };
}

  getResourceAreaWidth() {
    if (window.innerWidth > SM_BREAKPOINT) {
      return RESOURCE_AREA_WIDTH.md;
    }
    return RESOURCE_AREA_WIDTH.sm;
  }

  /**
   * Create the element that is shown in the template row on the availability page
   *
   * @param template Availability Template
   * @param type Type of the application eg. 'Normal' or 'Override'
   * @param overriden True if this template has been overriden by another template
   * @returns Element to show on calendar
   */
  createAvailabilityTemplateEvent(template: FullAvailabilityTemplateFragment, type: string, overriden: boolean){
    const container = document.createElement('div');
    container.classList.add('freya-availability-block');
    container.classList.add('freya-availability-template');

    // Change the color to grey if overriden
    if (overriden && type !== AvailabilityEventTypes.override) {
      container.style.backgroundColor = 'var(--light-availability-color)';
    }

    const templateNameEl = document.createElement('div');
    templateNameEl.classList.add('name');
    templateNameEl.style.whiteSpace = 'nowrap';
    templateNameEl.style.fontSize = '11px';
    templateNameEl.innerText = `${template.name} - ${type}${type !== AvailabilityEventTypes.override && overriden ? ' (Overriden)' : ''}`;

    const datesEl = document.createElement('div');
    datesEl.style.whiteSpace = 'nowrap';
    datesEl.style.fontSize = '11px';

    datesEl.innerHTML = `<b>Start:</b> ${template.startDate} <b>End:</b> ${template.endDate || 'Never'}`;

    const areasEl = document.createElement('div');
    areasEl.style.whiteSpace = 'nowrap';
    areasEl.style.marginTop = '2px';

    for (const zone of template?.zones){
      const zoneSpan = document.createElement('span');
      zoneSpan.style.marginRight = '4px';
      zoneSpan.style.borderRadius = '4px';
      zoneSpan.style.padding = '2px';
      zoneSpan.style.backgroundColor = `${zone.color || '#81D4FA'}`;
      zoneSpan.style.color = `${getTextColorForBackground(zone.color || '#81D4FA')}`;
      zoneSpan.style.fontSize = '11px';
      zoneSpan.innerText = zone.name;
      areasEl.append(zoneSpan);
    }

    container.append(templateNameEl, datesEl, areasEl);

    return container;
  }
}
