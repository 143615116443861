/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {ApolloQueryResult} from '@apollo/client/core';

import { isNumber } from 'lodash';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';

import { environment } from '../../environments/environment';
import { BaseLocationFragment, CalculateDistanceGQL, CalculateDistanceOutput, CalculateDistanceQuery, Coordinates, Location } from '../../generated/graphql.generated';

import { DistanceUnit, JobEventType, METERS_PER_FOOT, METERS_PER_KM, METERS_PER_MILE } from '../global.constants';
import { safeParseJSON } from '../js';
import { FreyaHelperService } from '../services/freya-helper.service';

export interface DistanceLocation {
  address: string;
  city: string;
  country?: string;
  type?: string;
  areaCode?: string;
  coordinates?: Coordinates;
  id: string;
  jurisdiction?: string;
  place_id?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DistanceService {

  dockUpdated = new Subject<{ coordinates?: Coordinates }>();

  KEYS_AFFECTING_EVENT_LENGTH = ['start_end']; // All measurements that affect the duration of an event

  distancesUpdated = new Subject<void>();
  distanceSub: Subscription;
  // country: string = environment.defaultCountry;

  distancesCalculated = false;
  units = environment.defaultUnits;
  locations: {
    [ type: string ]: DistanceLocation;
  } = {};

  distances: {
    [ key: string ]: CalculateDistanceOutput & {
      calculating?: boolean;
      cacheKey?: string;
      route: DistanceLocation[];
      // set to true to force a recalculate for the next calculation
      clearCache?: boolean;
    };
  } = {};

  constructor(
    private freyaHelper: FreyaHelperService,
    private calculateDistancesGQL: CalculateDistanceGQL,
  ) { }

  reset() {
    if (this.distanceSub) {
      this.distanceSub.unsubscribe();
    }
    this.distancesCalculated = false;
    this.locations = {};
    this.distances = {};
    this.distancesUpdated.next();
  }

  /**
   * Get distances between two event location types
   *
   * @param startEventType
   * @param endEventType
   * @param locationDistanceTypeMap
   */
  getDistanceBetweenLocationTypes(
    startEventType: string,
    endEventType: string,

    locationDistanceTypeMap = {
      basestart: 'dock',
      baseend: 'dock',
      start: 'start',
      end: 'end',
    },
  ) {

    const from = locationDistanceTypeMap[startEventType] || startEventType;
    const to = locationDistanceTypeMap[endEventType] || endEventType;
    const distanceKey = `${ from }_${ to }`;
    const reversedDistanceKey = `${ to }_${ from }`;
    return this.distances[distanceKey] || this.distances[reversedDistanceKey];
  }

  getDistancesForLocation(locationType: string) {
      // find distances which use this location by cacheKey
    const distances = Object.entries(this.distances).filter(([ _dKey, _distance]) => {
      const route: DistanceLocation[] = safeParseJSON(_distance.cacheKey, []);
      for (const routeLocation of route) {
        if (routeLocation.type === locationType) { return true; }
      }

      return false;
    });

    return distances;
  }

  /**
   * Sets locations and, if they already exist / are already calculated,
   * sets the cache key to clear that location.
   *
   * You will need to then run calculateDistances afterwards
   *
   * @param locations
   */
  setLocations(locations: { [ key: string ]: DistanceLocation }) {
    for (const [ key, loc ] of Object.entries(locations)) {
      if (loc) {
        loc.type = key;
      }
      const address = loc?.address?.length ? loc.address : undefined;
      const changed = address && (!this.locations[key] || JSON.stringify(loc) !== JSON.stringify(this.locations[key]));

      if (changed || !address) {
        this.getDistancesForLocation(key).forEach(([ _dKey, distance ]) => {
          distance.clearCache = true;
        });
      }

      if (loc && address?.length) {
        this.locations[key] = loc;
        // console.log(`${ key } location set to`, loc);
      } else {
        delete this.locations[key];
        // console.log(`${ key } location unset`);
      }

      if (key === 'dock' && loc) {
        this.dockUpdated.next(loc);
      }
    }

  }

  /**
   * If your zone is updated and you need to update the dock location
   * because the HQ is different
   */
  async updateDock() {
    const dockLocation = await this.getDockLocation();
    this.setLocations({
      dock: dockLocation,
    });
  }

  async getDockLocation() {

    const dock = await this.freyaHelper.getDockLocation();
    if (!dock) { return undefined; }

    // Notify any components using the dockLocation that it has changed
    if (dock.addressLineOne !== this.locations?.dock?.address) {
      this.dockUpdated.next(dock);
    }

    const DOCK = {
      // currently toledo address
      id: dock.id,
      address: dock.addressLineOne,
      city: dock.city,
      type: 'dock',
    };

    return DOCK;
  }

  getRoutesToCalculate() {
    const {
      start,
      end,
      dock,
    } = this.locations;

    const routes: { [ type: string ]: DistanceLocation[] } = {
      start_end: [ start, end ],
      dock_start_end_dock: [ dock, start, end, dock ],
      dock_start: [ dock, start ],
      start_dock: [ start, dock ],
      end_dock: [ end, dock ],
    };

      // remove routes where a location is undefined
    for (const key in routes) {
      if (routes[key].indexOf(undefined) >= 0) {
        delete routes[key];
      }

    }

    return routes;
  }


  transformDistance(
    // distance in meters or calendar event location
    value: number,
    // units to display
    unitSystem: DistanceUnit = 'metric',
    decimalPlaces = 2,
    // in meters
    smallUnitThreshold = 500,
  ): string {

    if(!value && !isNumber(value)) { return 'N/A'; }

    const smallUnits = value < smallUnitThreshold;
    const unit = smallUnits
      ? ( unitSystem === 'metric' ? 'm' : 'ft' )
      : ( unitSystem === 'metric' ? 'km' : 'mi' );

    const convertedValue =  smallUnits
      ? ( unitSystem === 'metric' ? value : value / METERS_PER_FOOT )
      : ( unitSystem === 'metric' ? value / METERS_PER_KM : value / METERS_PER_MILE );

    return `${ convertedValue.toFixed(decimalPlaces) }${ unit }`;
  }

  async calculateDistances() {
    const units = await this.freyaHelper.getUnits();
    this.units = units;
    // this.determineCountry();
    // const units = this.getDistanceUnits();
    this.distancesCalculated = false;

    const routesToCalculate = this.getRoutesToCalculate();

    if (this.distanceSub) {
      this.distanceSub.unsubscribe();
    }

    const distanceObservableMap: {
      [ key in keyof typeof routesToCalculate ]?: Observable<ApolloQueryResult<CalculateDistanceQuery>>;
    } = {};

    for (const [ key, route ] of Object.entries(routesToCalculate)) {
      let valid = route.length > 1;
      for (const location of route) {
        if (!location.id) {
          valid = false;
        }
      }
      // Location is not valid, skip this calculation
      if (!valid) {
        delete this.distances[key];
        continue;
      }

      // Continue if location is already calculated or calculating
      const cacheKey = JSON.stringify(route);
      const existing = this.distances[key];
      if (existing && existing.cacheKey === cacheKey && !existing.calculating && !existing.clearCache) {
        continue;
      }

      this.distances[key] = {
        calculating: true,
        cacheKey,
        route,
      };

      distanceObservableMap[key] = this.calculateDistancesGQL.fetch({
        units,
        locationIds: route.map((l) => l.id),
      });
    }

    // exit if no distances need to be calculated
    if (Object.entries(distanceObservableMap).length === 0) {
      this.distancesCalculated = true;
      this.distancesUpdated.next();
      return;
    }


    this.distanceSub = forkJoin(distanceObservableMap).subscribe((distances) => {
      this.distanceSub = undefined;
      for (const [ key, val ] of Object.entries(distances)) {
        const existing = this.distances[key];
        this.distances[key] = {
          ...val.data?.calculateDistance,
          cacheKey: existing?.cacheKey,
          route: existing?.route,
        };
      }
      console.log(`Distances calculated:`, this.distances);
      this.distancesCalculated = true;

      this.distancesUpdated.next();
    });

    return this.distanceSub;
  }

  convertLocationToDistanceLocation(location: BaseLocationFragment): DistanceLocation{
    return {
      ...location,
      address: location?.addressLineOne,
    } as DistanceLocation;
  }

  convertFormToDistanceLocation(form: UntypedFormGroup): DistanceLocation{
    const value = form?.getRawValue();
    if (!value) { return undefined; }
    return {
      ...value,
    } as DistanceLocation;
  }

  /**
   * Converts a DistanceLocation object into a URL-escaped string
   */
  encodeDistanceLocation(location: DistanceLocation): string {
    if (!location) { return; }
    const address = location.address? encodeURIComponent(location.address): '';
    const city = location.city? encodeURIComponent(location.city) : '';
    const country = location.country ? encodeURIComponent(location.country) : '';
    return [address, city, country].join(',');
  }

  /**
   * The total time worth of drives that affect the length of the calendarEvent
   */
  totalTimeAffectingEvent(type: JobEventType){
    let timeToAdd = 0;

    if (type !== 'moving'){ // Other types shouldn't be affected by traveling
      return timeToAdd;
    }

    for (const key of this.KEYS_AFFECTING_EVENT_LENGTH){
      if(!this.distances[key])  { continue; }
      timeToAdd += this.distances[key].estimatedTime;
    }

    return timeToAdd;
  }
}
