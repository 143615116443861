import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { BaseReportTypeFragment, CreateExportTokenGQL, Token } from '../../../generated/graphql.generated';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { PeriodOptions, periodOptions } from '../reports.constants';

@Component({
  selector: 'app-export-to-sheets-dialog',
  templateUrl: './export-to-sheets-dialog.component.html',
  styleUrls: ['./export-to-sheets-dialog.component.scss']
})
export class ExportToSheetsDialogComponent implements OnInit {

  exportFormula: string;
  token: Token;
  reportType: BaseReportTypeFragment;

  showOptions = false;
  hideSummary = false;
  hideData = false;
  showSummaryAboveHeaders = false;
  showMetadata = true;

  periodOptions = periodOptions;
  period: PeriodOptions = 'Last 24 hours';

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private clipboard: Clipboard,
    private createExportTokenGQL: CreateExportTokenGQL,
    private localNotify: FreyaNotificationsService,
  ) { }

  ngOnInit(): void {

    this.exportFormula = undefined;
    try {
      this.reportType = this.config.data?.reportType;
      this.createExportFormula();
    } catch (err) {
      this.exportFormula = 'Error generating formula. Please submit a bug report.';
      console.error(err);

    }
  }


  createExportFormula() {

    if (!this.exportFormula) {
      this.updateExportFormula();

      this.createExportTokenGQL.mutate({
        reportTypeId: this.reportType.id,
      }).subscribe((res) => {
        this.token = res?.data?.createExportToken;

        this.updateExportFormula();
      });
    }
  }

  updateExportFormula() {
    if (!this.token) {
      this.exportFormula = 'Generating...';
      return;
    }

    const url = new URL(`${ location.protocol }//${ location.hostname }`);

    url.pathname = `/api/export/${ this.token.token }`;

    url.searchParams.set('showMetadata', this.showMetadata ? 'true' : 'false');
    url.searchParams.set('hideSummary', this.hideSummary ? 'true' : 'false');
    url.searchParams.set('hideData', this.hideData ? 'true' : 'false');
    url.searchParams.set('showSummaryAboveHeaders', this.showSummaryAboveHeaders ? 'true' : 'false');

    url.searchParams.set('vars.period', this.period);

    this.exportFormula = `=IMPORTDATA("${ url.toString() }")`;

  }

  copyFormula() {
    if (!this.exportFormula || this.exportFormula[0] !== '=') {
      return;
    }
    this.clipboard.copy(this.exportFormula);

    this.localNotify.addToast.next({
      severity: 'info',
      summary: 'Formula copied to clipboard',
      detail: 'Paste this formula into google sheets and it will update every hour',
    });
  }

}
