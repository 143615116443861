import { Component, OnInit } from '@angular/core';
import {QueryRef} from 'apollo-angular';

import { SubSink } from 'subsink';

import { GetConfigValuesGQL, GetConfigValuesQuery, GetConfigValuesQueryVariables, SetConfigValuesGQL } from '../../generated/graphql.generated';
import { FreyaNotificationsService } from '../services/freya-notifications.service';

@Component({
  selector: 'app-quickbooks',
  templateUrl: './quickbooks.component.html',
  styleUrls: ['./quickbooks.component.scss']
})
export class QuickbooksComponent implements OnInit {

  isEnabled = false;

  configValuesQueryRef: QueryRef<GetConfigValuesQuery, GetConfigValuesQueryVariables>;

  subs = new SubSink();

  constructor(
    private getConfigValuesGQL: GetConfigValuesGQL,
    private setConfigValuesGQL: SetConfigValuesGQL,
    private notify: FreyaNotificationsService,
  ) { }

  ngOnInit(): void {
    this.configValuesQueryRef = this.getConfigValuesGQL.watch({ keys: [ 'quickbooks.enabled' ] });

    this.subs.sink = this.configValuesQueryRef.valueChanges
      .subscribe((res) => {
        if (res.loading) { return; }

        if (!res.data.getConfigValues.length) { return; }

        let parsedValue: boolean;

        try {
          parsedValue = JSON.parse(res.data.getConfigValues[0].value);
        } catch(e) {
          console.log('Could not parse act-on.enabled config value');
        }

        this.isEnabled = parsedValue || false;
      });
  }

  saveIsEnabled() {
    const value = JSON.stringify(this.isEnabled);

    this.setConfigValuesGQL.mutate({configs: [{
      key: 'quickbooks.enabled',
      value,
    }]}).subscribe(() => {
      this.configValuesQueryRef.refetch();
      const verb = value === 'true' ? 'enabled' : 'disabled';
      this.notify.success(`Quickbooks ${verb}`);
    });
  }

}
