import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {QueryRef} from 'apollo-angular';

import { SubSink } from 'subsink';

import { GetConfigValuesGQL, GetConfigValuesQuery, GetConfigValuesQueryVariables, IsAuthenticatedToActOnGQL, IsAuthenticatedToActOnQuery, IsAuthenticatedToActOnQueryVariables, LogInToActOnGQL, SetConfigValuesGQL } from '../../generated/graphql.generated';
import { FreyaNotificationsService } from '../services/freya-notifications.service';

@Component({
  selector: 'app-act-on',
  templateUrl: './act-on.component.html',
  styleUrls: ['./act-on.component.scss']
})
export class ActOnComponent implements OnInit, OnDestroy {

  logInForm = new UntypedFormGroup(
    {
      email: new UntypedFormControl('', [ Validators.required, Validators.email ]),
      password: new UntypedFormControl(''),
    });

  subs = new SubSink();

  isAuthenticatedQuery: QueryRef<IsAuthenticatedToActOnQuery, IsAuthenticatedToActOnQueryVariables>;

  isAuthenticated = false;

  isEnabled = false;

  configValuesQueryRef: QueryRef<GetConfigValuesQuery, GetConfigValuesQueryVariables>;

  constructor(
    private isAuthenticatedToActOnGQL: IsAuthenticatedToActOnGQL,
    private logInToActOnGQL: LogInToActOnGQL,
    private notify: FreyaNotificationsService,
    private getConfigValuesGQL: GetConfigValuesGQL,
    private setConfigValuesGQL: SetConfigValuesGQL,
  ) { }

  ngOnInit(): void {
    this.configValuesQueryRef = this.getConfigValuesGQL.watch({ keys: [ 'act-on.enabled' ] });

    this.subs.sink = this.configValuesQueryRef.valueChanges
      .subscribe((res) => {
        if (res.loading) { return; }

        if (!res.data.getConfigValues.length) { return; }

        let parsedValue: boolean;

        try {
          parsedValue = JSON.parse(res.data.getConfigValues[0].value);
        } catch(e) {
          console.log('Could not parse act-on.enabled config value');
        }

        this.isEnabled = parsedValue || false;

        this.setFormDisabled(this.isEnabled);

        this.retrieveIsAuthenticated();
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  retrieveIsAuthenticated() {
    if (this.isAuthenticatedQuery) {
      this.isAuthenticatedQuery.refetch();
      return;
    }

    this.isAuthenticatedQuery = this.isAuthenticatedToActOnGQL.watch();

    this.subs.sink = this.isAuthenticatedQuery.valueChanges
      .subscribe((res) => {
        if (res.loading) { return; }
        this.isAuthenticated = res.data.isAuthenticatedToActOn;
      });
  }

  logIn() {
    const { email: username, password } = this.logInForm.value;
    this.logInToActOnGQL.mutate({ username, password })
      .subscribe(() => {
        this.retrieveIsAuthenticated();
      }, (err) => {
        this.notify.apolloError('Log in failed', err);
      });
  }

  saveIsEnabled() {
    const value = JSON.stringify(this.isEnabled);

    this.setConfigValuesGQL.mutate({configs: [{
      key: 'act-on.enabled',
      value,
    }]}).subscribe(() => {
      this.configValuesQueryRef.refetch();
      const verb = value === 'true' ? 'enabled' : 'disabled';
      this.notify.success(`Act-On ${verb}`);
    });
  }

  setFormDisabled(enable: boolean) {
    if (enable) {
      this.logInForm.enable();
    } else {
      this.logInForm.disable();
    }
  }
}

