import { Component, OnDestroy, OnInit } from '@angular/core';
import { describeRule, Rule } from '@karve.it/rule-checker';
import { Message } from 'primeng/api';
import { SubSink } from 'subsink';

import { ConfigValueTrigger } from '../../../generated/graphql.generated';

import { RuleCheckerService } from '../../shared/rule-checker.service';

interface MessageContent {
  trigger: ConfigValueTrigger;
  rules: Rule[];
}

interface MessageContentMap {
  [id: string]: MessageContent;
}

@Component({
  selector: 'app-rules-display',
  templateUrl: './rules-display.component.html',
  styleUrls: ['./rules-display.component.scss']
})
export class RulesDisplayComponent implements OnInit, OnDestroy {

  msgs: Message[] = [];
  subs = new SubSink();

  constructor(
    public ruleChecker: RuleCheckerService,
  ) { }

  ngOnInit(): void {
    this.msgs = [];
    this.formatMessages();
    this.subs.sink = this.ruleChecker.triggersUpdated
      .subscribe((rules: Rule[]) => {
        this.formatMessages(rules);
      });
  }

  formatMessages(rules?: Rule[]) {
    if (!rules) { rules = this.ruleChecker.rulesTriggered || []; }

    const messageContentMap: MessageContentMap = {};

    for (const rule of rules) {
      if (rule.trigger?.value?.triggerMsgLevel === 'none') { continue; }
      messageContentMap[rule.trigger.id] = messageContentMap[rule.trigger.id] || {
        trigger: rule.trigger,
        rules: [],
      };

      messageContentMap[rule.trigger.id].rules.push(rule);
    }

    if (this.ruleChecker.rulesRecentlyUpdated) {
      this.msgs.push({
        id: 'reload',
        severity: 'warn',
        summary: 'Reload the page to apply new rules',
        detail: 'Rules were recently updated and the page needs to reload to apply those changes to this estimate.',
      });
    }

    this.msgs = Object.entries(messageContentMap).map(([ triggerId, messageContent ]) => {
      const existingMessage = this.msgs.find((m) => m.id === triggerId);

      if (existingMessage && JSON.stringify(messageContent) === JSON.stringify(existingMessage.data)) {
        return existingMessage;
      }

      const ruleMessages: string[] = [];
      for (const rule of messageContent.rules) {
        const description = describeRule(rule as any);
        ruleMessages.push(description.when);
      }

      const tgr = messageContent.trigger.value;

      return {
        severity: tgr.triggerMsgLevel || 'warn',
        summary: tgr.triggerPrettyName || messageContent.trigger?.key || 'trigger',
        detail: ruleMessages.length ? ruleMessages.join(' / ') : 'No conditions',
        data: messageContent,
        id: triggerId,
      };

    });

    // console.log(`Rules displayed`, this.msgs);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
