import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, merge, of, timer } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Directive({
  selector: '[appLongClick]'
})
export class LongClickDirective implements OnInit, OnDestroy {

  @Input() threshold = 500;

  @Output() appLongClick = new EventEmitter();

  // Map to true to turn on timer
  mouseDown = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'mousedown')
    .pipe(
      filter(event => event.button === 0),
      map(() => true),
    );

  // Map to false to reset timer
  mouseUp = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'mouseup')
    .pipe(
      filter(event => event.button === 0),
        map(() => false),
    );

  // Handle touch devices
  touchStart = fromEvent(this.elementRef.nativeElement, 'touchstart').pipe(map(() => true));

  touchEnd = fromEvent(this.elementRef.nativeElement, 'touchend').pipe(map(() => false));

  subs = new SubSink();

  constructor(
    private elementRef: ElementRef,
  ) { }

  ngOnInit() {
    this.subs.sink = merge(
      this.mouseDown,
      this.mouseUp,
      this.touchStart,
      this.touchEnd,
    )
      .pipe(
        switchMap(
          restartTimer => restartTimer ?
            // switch to new timer observable to restart timer
            timer(this.threshold).pipe(switchMap(() => of(true))) :
            // switch to false to cancel timer without starting a new one
            of(false),
        ),
        // Emit longClick event when timer emits true
        filter(value => value === true),
      ).subscribe(() => this.appLongClick.emit());
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
