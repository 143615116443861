<div class="p-grid statistics" *ngIf="summaryStyle === 'dashboard'">
  <div
    *ngFor="let summary of (attentionItemsService.summaries$ | async)"
    class="p-col-12 p-md-4"
    >
    <div class="statistic-item">
      <h5 class="item-title">
        {{ summary.items }}
      </h5>
      <h6>
        {{ summary.attentionExplanation | titlecase }}
      </h6>
    </div>
  </div>
</div>
<div *ngIf="summaryStyle === 'chips'">
  <p-chip
    *ngFor="let summary of (attentionItemsService.summaries$ | async)"
    class="selectable"
    styleClass="p-m-1"
    [routerLink]="[ '/needs-attention' ]"
    [queryParams]="{ attentionExplanation: summary.attentionExplanation }"
    >
    <div class="p-chip-text">
      {{ summary.label | titlecase }}:
      {{ summary.items }}
    </div>
  </p-chip>
</div>
