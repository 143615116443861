import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import {QueryRef} from 'apollo-angular';

import { valueFromAST } from 'graphql';
import { cloneDeep } from 'lodash';
import { SubSink } from 'subsink';

import { ListBaseAssetsQueryVariables, ListBaseAssetsQuery, BaseAssetFragment, ListBaseAssetsGQL } from '../../../generated/graphql.generated';
import { PlusAuthenticationService } from '../../core/public-api';
import { baseAssetFragment } from '../../fragments/assets.fragments';

export interface AssetKeyValue  {
  key: string;
  value: BaseAssetFragment;
}

@Component({
  selector: 'app-asset-select',
  templateUrl: './asset-select.component.html',
  styleUrls: ['./asset-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: AssetSelectComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: AssetSelectComponent,
    }
  ]
})
export class AssetSelectComponent implements OnInit, OnDestroy {

  @Output() assetsSelected = new EventEmitter();

  // Pass this to override current zone
  @Input() filter: ListBaseAssetsQueryVariables['filter'];

  subs = new SubSink();

  // Users Variables
  assetsQueryRef: QueryRef<ListBaseAssetsQuery>;
  assetSuggestions: AssetKeyValue[] = [];

  // FORM CONTROL VARIABLES
  selectedAssets: BaseAssetFragment[] = [];
  touched = false;
  disabled = false;

  constructor(
    private listBaseAssetsGQL: ListBaseAssetsGQL,
  ) { }

  ngOnInit(): void {
    this.getAssets();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getAssets(){
    this.assetsQueryRef = this.listBaseAssetsGQL.watch(
      { filter: this.filter, limit: -1 },
      { fetchPolicy: 'cache-and-network' },
    );

    this.subs.sink = this.assetsQueryRef.valueChanges.subscribe((res) => {
      if (res.loading) { return; }
      this.assetSuggestions = res.data.assets.assets.map((a) => ({key: `${a.name} (${a.type})`, value: a}));

      // Force equivalent objects to show as selected if passed in
      this.selectedAssets = this.selectedAssets.map((a) => this.assetSuggestions.find((as) => as.value.id === a.id).value);
    });
  }

  emitSelect(){
    this.assetsSelected.emit(this.selectedAssets);
  }

  // FORM CONTROL FUNCTIONS
  onChange = (user) => {};

  onTouched = () => {
    this.touched = true;
  };

  writeValue(assets: BaseAssetFragment[]) {
    this.selectedAssets = cloneDeep(assets);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

}
