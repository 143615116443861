<div class="p-grid">
    <div
        id="left-side"
        class="p-md-6"
    >
    <div class="flex p-jc-between p-ai-center">
        <h5 class="p-m-0">Locations</h5>
        <button
		*hasPermission="['locations.create']"
        pButton
        label="Add Location"
        icon="pi pi-plus"
        (click)="showAdditionalLocationDialog = true"
        [disabled]="!addableLocationTypes?.length"
    ></button>
    </div>
        <p-tabView [(activeIndex)]="activeLocationIndex">
            <p-tabPanel
                *ngFor="let location of jobLocations; let i = index"
                [selected]="i === 0"
            >
            <ng-template pTemplate="header">
                {{location.locationType | titlecase}}
            </ng-template>
                <app-add-job-location
                    #locationComponent
                    [job]="job" 
                    [type]="location?.locationType"
                    [fieldNamespace]="location?.fieldNamespace"
                    [enableAdditionalFields]="location?.enableAdditionalFields"
                    [removable]="location?.removable"
                    (locationRemoved)="removeLocation($event)"
                ></app-add-job-location>
            </p-tabPanel>
            <!-- <p-tabPanel header="Starting Location">
                <app-add-job-location 
                    [job]="job" 
                    [type]="'start'"
                    [fieldNamespace]="'startLocation'"
                    #startLocationRef
                ></app-add-job-location>
            </p-tabPanel>
            <p-tabPanel header="Ending Location">
                <app-add-job-location
                    [job]="job"
                    [type]="'end'"
                    [fieldNamespace]="'endLocation'"
                    #endLocationRef
                ></app-add-job-location>
            </p-tabPanel>
            <p-tabPanel header="Additional Location">
                <app-add-job-location
                    [job]="job"
                    [type]="'additional'"
                    [fieldNamespace]="'endLocation'"
                    [enableAdditionalFields]="false"
                    #endLocationRef
                ></app-add-job-location>
            </p-tabPanel> -->
        </p-tabView>

        <div
            class="p-grid"
            [formGroup]="additionalFieldsForm"
        >
            <div class="p-col-12"><h5 class="p-m-0">Move Size</h5></div>
            <div class="p-col-6">
                <label>Partial Move</label>
                <br />
                <p-inputSwitch
                    formControlName="partialMove"
                    ruleProperty="starting_location.partialMove"
                ></p-inputSwitch>
            </div>
            <div class="p-col-6">
                <label>More than 10 items</label>
                <br />
                <p-inputSwitch
                    formControlName="moreThan10Items"
                    ruleProperty="starting_location.moreThan10Items"
                ></p-inputSwitch>
            </div>
            <br>
            <div class="p-col-12">
                <div class="p-grid p-align-center">
                    <div class="p-col">
                        <h5 class="no-margin">Extras</h5>
                    </div>
                    <div class="p-col">
                        <button
                            *hasPermission="['fields.setValue']"
                            pButton
                            pRipple
                            label="Add Extra"
                            [disabled]="estimateHelper.jobLoading | async"
                            (click)="menu.toggle($event)"
                            icon="pi pi-angle-down"
                            data-cy="add-extra-button"
                        ></button>
                        <p-menu
                            #menu
                            [popup]="true"
                            [model]="addExtraActions"
                        ></p-menu>
                    </div>
                </div>
                <p></p>
                <p-table [value]="extras">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Type</th>
                            <th>Sqft</th>
                            <th [attr.colspan]="'3'">Additional Info</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template
                        pTemplate="body"
                        let-extra
                    >
                        <tr>
                            <td>{{extra.type | titlecase}}</td>
                            <td>{{extra.details.sqft}} sqft.</td>
                            <td [attr.colspan]="'3'">
                                <span
                                    class="p-m-1"
                                    *ngIf="extra.details.xCarGarage"
                                >
                                    {{extra.details.xCarGarage}} Car
                                </span>
                                <span
                                    class="p-m-1"
                                    *ngIf="extra.details.private !== undefined"
                                >
                                    {{extra.details.private ? 'Private' : 'Public'}},
                                </span>
                                <span
                                    class="p-m-1"
                                    *ngIf="extra.details.indoor !== undefined"
                                >
                                    {{extra.details.indoor ? 'Indoor' : 'Outdoor'}}
                                </span>
                            </td>
                            <td>
                                <button
                                    pButton
                                    class="p-m-1"
                                    (click)="openEditExtra(extra, extra.type)"
                                    icon="pi pi-pencil"
                                ></button>
                                <button
                                    pButton
                                    class="p-m-1 p-button-danger"
                                    (click)="removeExtra(extra)"
                                    icon="pi pi-trash"
                                ></button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td [attr.colspan]="'6'">
                                No Extras Added
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <br>
            </div>
        </div>
    </div>

    <div
        id="right-side"
        class="p-md-6"
    >
        <app-distances
            [showMap]="true"
            *ngIf="googleHelper.loaded | async"
        ></app-distances>
    </div>
</div>

<p-dialog
    header="Add Garage"
    [(visible)]="showGarageModal"
    [modal]="true"
    (onHide)="extrasEditIndex = undefined"
    [draggable]="false"
    [resizable]="false"
    [breakpoints]="{'992px': '62vw', '510px': '93vw'}"
    [style]="{width: '50vw'}"
>
    <div class="p-inputgroup">
        <input
            type="number"
            placeholder="Sqft."
            pInputText
            [(ngModel)]="garageInfo.sqft"
        />
        <span class="p-inputgroup-addon">Sqft.</span>
    </div>
    <br>
    <div class="p-inputgroup">
        <input
            type="number"
            placeholder="Eg. 2"
            pInputText
            [(ngModel)]="garageInfo.xCarGarage"
        />
        <span class="p-inputgroup-addon">Car Garage</span>
    </div>
    <ng-template pTemplate="footer">
        <button
            pButton
            *ngIf="!(extrasEditIndex >= 0)"
            type="button"
            [disabled]="!garageInfo.sqft && !garageInfo.xCarGarage"
            class="p-button-success"
            icon="pi pi-plus"
            label="Add Garage"
            (click)="addExtra('garage')"
        ></button>
        <button
            *ngIf="extrasEditIndex >= 0"
            pButton
            type="button"
            [disabled]="!garageInfo.sqft && !garageInfo.xCarGarage"
            class="p-button-success"
            icon="pi pi-pencil"
            label="Edit Garage"
            (click)="editExtra('garage')"
        ></button>
    </ng-template>
</p-dialog>

<p-dialog
    header="Add Shed"
    [(visible)]="showShedModal"
    (onHide)="extrasEditIndex = undefined"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [breakpoints]="{'992px': '62vw', '510px': '93vw'}"
    [style]="{width: '50vw'}"
>
    <div class="p-inputgroup">
        <input
            type="number"
            placeholder="Sqft."
            pInputText
            [(ngModel)]="shedInfo.sqft"
        />
        <span class="p-inputgroup-addon">Sqft.</span>
    </div>
    <ng-template pTemplate="footer">
        <button
            *ngIf="!(extrasEditIndex >= 0)"
            pButton
            type="button"
            [disabled]="!shedInfo.sqft"
            class="p-button-success"
            icon="pi pi-plus"
            label="Add Shed"
            (click)="addExtra('shed')"
        ></button>
        <button
            *ngIf="extrasEditIndex >= 0"
            pButton
            type="button"
            [disabled]="!shedInfo.sqft"
            class="p-button-success"
            icon="pi pi-pencil"
            label="Edit Shed"
            (click)="editExtra('shed')"
        ></button>
    </ng-template>
</p-dialog>

<p-dialog
    header="Add Storage Unit"
    [(visible)]="showStorageUnitModal"
    (onHide)="extrasEditIndex = undefined"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [breakpoints]="{'992px': '62vw', '510px': '93vw'}"
    [style]="{width: '50vw'}"
>
    <div class="p-inputgroup">
        <input
            type="number"
            placeholder="Sqft."
            pInputText
            [(ngModel)]="storageUnitInfo.sqft"
        />
        <span class="p-inputgroup-addon">Sqft.</span>
    </div>
    <br>
    <p-checkbox
        class="p-col-12"
        [(ngModel)]="storageUnitInfo.indoor"
        binary="true"
        label="Is the Unit Inside?"
    ></p-checkbox>
    <p-checkbox
        class="p-col-12"
        [(ngModel)]="storageUnitInfo.private"
        binary="true"
        label="Is the Unit Private?"
    ></p-checkbox>
    <div class="p-col-12">
        <textarea
            [rows]="3"
            [cols]="50"
            [(ngModel)]="storageUnitInfo.accessAndSecurityInfo"
            placeholder="Security and Access information for the Storage Unit"
            pInputTextarea
        ></textarea>
    </div>
    <ng-template pTemplate="footer">
        <button
            pButton
            *ngIf="!(extrasEditIndex >= 0)"
            type="button"
            class="p-button-success"
            icon="pi pi-plus"
            [disabled]="!storageUnitInfo.sqft"
            label="Add Storage Unit"
            (click)="addExtra('storage')"
        ></button>
        <button
            *ngIf="extrasEditIndex >= 0"
            pButton
            type="button"
            [disabled]="!storageUnitInfo.sqft"
            class="p-button-success"
            icon="pi pi-pencil"
            label="Edit Storage Unit"
            (click)="editExtra('storage')"
        ></button>
    </ng-template>
</p-dialog>

<p-dialog header="Add Location Type" [(visible)]="showAdditionalLocationDialog">
    <p-dropdown
        #locationTypeDropdown
        [options]="addableLocationTypes"
        appendTo="body"
    ></p-dropdown>

    <ng-template pTemplate="footer">
        <button 
            pButton
            label="Cancel"
            icon="pi pi-times"
            (click)="showAdditionalLocationDialog = false">
        </button>
        <button
            pButton
            label="Add Location Type"
            icon="pi pi-plus"
            (click)="addLocationType(locationTypeDropdown.value)"
        ></button>
    </ng-template>
</p-dialog>

<!-- <app-add-location
    #addLocation
    (onHide)="attemptedAutocomplete = false"
    (locationInputBlur)="onLocationInputBlur($event)"
    (locationAdded)="setValueEqualToManual($event)"
></app-add-location> -->
