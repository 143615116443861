<div class="event-card">
    <!-- <div class="p-grid p-align-center sticky-header">
        <div class="p-col p-d-flex p-jc-between p-ai-center">
            <h6
                class="no-margin p-m-2"
                style="display: inline-block;"
            >Event Details</h6>
        </div>
        <div class="p-col p-d-flex p-jc-between p-ai-center">
            <h6 class="no-margin">Locations</h6>
            <span>
                <span [pTooltip]="readonly && readonlyTooltip" tooltipPosition="left">
                    <button
                        pButton
                        icon="pi pi-pencil"
                        [disabled]="!event || readonly"
                        (click)="openMutateEvent()"
                        class="p-mr-1"
                        ></button>
                </span>
                <button
                    pButton
                    icon="pi pi-print"
                    (click)="printButtonClicked.next(null)"
                    ></button>
            </span>
        </div>
    </div> -->
    <div class="p-grid">
            <div class="p-col-12" *ngIf="!event?.start">
                <p>This event has not been scheduled</p>
            </div>
            <div class="p-col-12">
                <span
                    *hasPermission="['calendarEvents.edit']"
                    [pTooltip]="(readonly && readonlyTooltip) || ((event | eventHasInvoice) && 'This event was invoiced')"
                    tooltipPosition="bottom"
                    >
                    <p-splitButton
                        *ngIf="event.status !== 'cancelled' && event?.start"
                        [disabled]="!event || readonly || event | eventHasInvoice"
                        label="{{nextStatus ? 'Mark ' + nextStatus : ''}}"
                        icon="pi pi-angle-double-up"
                        styleClass="p-m-1"
                        [model]="items"
                        (onClick)="promoteToNextStatus()"
                        ></p-splitButton>
                    <p-button
                        *ngIf="event.status === 'cancelled'"
                        [disabled]="!event || readonly || event | eventHasInvoice"
                        label="Undo Cancel"
                        icon="pi pi-undo"
                        styleClass="p-m-1"
                        (onClick)="eventHelper.uncancelEvent(event)"
                        ></p-button>
                </span>
                    <button
                        pButton
                        style="display: inline-block"
                        label="Details"
                        icon="pi pi-calendar-times"
                        class="p-m-1"
                        (click)="openEventDetails()"
                    ></button>
                    <ng-container *hasPermission="['calendarEvents.edit']">
                        <span
                            *ngIf="!event?.start"
                            [pTooltip]="readonly && readonlyTooltip"
                            tooltipPosition="bottom"
                            >
                            <button
                                pButton
                                label="Book Now"
                                class="p-m-1"
                                [disabled]="readonly"
                                (click)="goToBooking()"
                                ></button>
                        </span>
                    </ng-container>
                    <ng-container *hasPermission="['transactions.create']">
                        <span
                            *ngIf="event | eventHasInvoice : false"
                            [pTooltip]="event && !event?.total ? 'No charges for event' : (readonly && readonlyTooltip)"
                            tooltipPosition="bottom"
                            >
                            <button
                                pButton
                                icon="pi pi-wallet"
                                [label]="payableAmount ? 'Pay ' + ( payableAmount / 100 | currency ) : 'Pay'"
                                class="p-m-1"
                                [disabled]="!payableAmount || readonly"
                                (click)="openMutateTransaction()"
                                ></button>
                        </span>
                    </ng-container>
                    <ng-container *hasPermission="['invoice.create']">
                        <span
                            *ngIf="!(event | eventHasInvoice : false)"
                            [pTooltip]="event.status !== 'completed' && 'Event must be marked completed before it can be invoiced or paid'"
                            >
                            <button
                                pButton
                                icon="pi pi-file"
                                label="Invoice"
                                class="p-button-success p-m-1"
                                [disabled]="event.status !== 'completed'"
                                (click)="openCreateInvoiceDialog()"
                                ></button>
                        </span>
                    </ng-container>
            </div>
            <div *ngIf="event?.start" class="p-col">
                <h6>Date</h6>
                <p
                class="freya-link-button"
                (click)="goToEventOnSchedule(event)"
                >{{ event.start | freyaDate }}</p>

                <div class="p-mt-2">
                    <app-object-state
                        *ngIf="event?.status === 'cancelled'"
                        [object]="event"
                        objectType="event"
                        [colorful]="true"
                        ></app-object-state>
                </div>

                <h6>Start / End</h6>
                <p>{{ event | eventDuration }}</p>

                <h6>Start / End (Dock to Dock)</h6>
                <p>{{ event | eventDuration: true }}</p>

                <h6>Assets</h6>
                <p *ngFor="let a of event.assets">{{ a.name }}</p>

                <!-- <h6>Crewsize</h6>
                    <p>{{crewSize || 'Not Set'}}</p> -->

            </div>

        <div *ngIf="event?.start" class="p-col">
            <div *ngFor="let location of event.locations; let i = index">
                <app-event-location
                    [readonly]="readonly"
                    [calendarEventType]="event.type"
                    [eventLocation]="location"
                    [event]="event"
                    [arrivalTimeList]="this.arrivalTimeList[i]"
                    ></app-event-location>
                <br />
            </div>
        </div>
        <div *ngIf="!event" class="p-col">N/A</div>
    </div>
</div>
