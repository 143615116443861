import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppMainComponent } from 'src/app/app.main.component';

import { ResponsiveHelperService } from '../../services/responsive-helper.service';

@Component({
  selector: 'app-mini-steps',
  templateUrl: './mini-steps.component.html',
  styleUrls: ['./mini-steps.component.scss']
})
export class MiniStepsComponent implements OnChanges {
  @Input() model: MenuItem[];
  @Input() activeIndex: number;
  @Input() autoScrollOnChange = true;
  @Output() activeIndexChange = new EventEmitter<number>();

  activeStep: MenuItem;

  constructor(
    private appMain: AppMainComponent,
    private responsiveHelper: ResponsiveHelperService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('activeIndex')) {
      this.setActiveStep(this.activeIndex);
    }
  }

  setActiveIndex(step: MenuItem) {
    this.activeIndex = this.model.indexOf(step);
    this.activeIndexChange.next(this.activeIndex);
  }

  setActiveStep(index: number) {
    this.activeStep = this.model[index];

    if (this.autoScrollOnChange && !this.responsiveHelper.isLargeScreen) {
      this.appMain.scrollToTop();
    }
  }

  goToPrev() {
    if (this.activeIndex === 0) { return; };
    this.activeIndex = this.activeIndex - 1;
    this.activeIndexChange.next(this.activeIndex);
  }

  goToNext() {
    if (this.activeIndex === this.model.length - 1) { return; };
    this.activeIndex = this.activeIndex + 1;
    this.activeIndexChange.next(this.activeIndex);
  }

}
