<div>
  <div class="header-with-button">
    <h4>
      <i class="pi pi-file large-icon"></i> Invoice
      <app-object-state
        *ngIf="invoice"
        [object]="invoice"
        objectType="invoice"
        [colorful]="true"
        ></app-object-state>
    </h4>
    <div>
      <button
        pButton
        pRipple
        label="Actions"
        icon="pi pi-angle-down"
        (click)="invoiceActionsMenu.toggle($event)"
        ></button>
      <p-menu
        #invoiceActionsMenu
        [popup]="true"
        appendTo="body"
        [model]="invoiceActions"
        ></p-menu>
    </div>
  </div>
  <p-messages
    *ngIf="([ artifact ] | attributeFilter : { includesAny: [ 'outdated' ] })?.length > 0"
    severity="warn"
    styleClass="p-mb-2"
    >
    <ng-template pTemplate>
      <div>
        <span *ngIf="!invoice.sentAt && !invoice.openedAt">
          Financials may have changed since the attached document was generated.
        </span>
      <ng-container *ngIf="invoice.sentAt || invoice.openedAt">
        <span>
        Financials may have changed since the attached document was generated, but the document cannot be updated as it already has been finalized.
        </span>
        <br>
        <br>
        <span>
        If you need a document with the latest financials, please void this invoice and create a new one.
        </span>
      </ng-container>
        <div class="p-text-center p-mt-2">
          <p-button
            *ngIf="!invoice.sentAt && !invoice.openedAt"
            label="Update Document"
            [icon]="documentUpdating ? 'pi pi-spin pi-spinner' : 'pi pi-refresh'"
            styleClass="p-button-secondary"
            (onClick)="updateDocument()"
            [disabled]="documentUpdating"
            ></p-button>
        </div>
      </div>
    </ng-template>
  </p-messages>
  <div class="card" *ngIf="invoice; else loadingCard">
    <p>Document:
      <span *ngIf="showDownloadInvoiceLink" class="freya-link-button" (click)="downloadDocument()">
        {{ artifact?.name }}
      </span>
      <span *ngIf="!showDownloadInvoiceLink">{{ artifact?.name }}</span>
    </p>
    <p>Invoice Date: {{ invoice.invoiceDate | freyaDate }}</p>
    <p>Date Sent:
      <span *ngIf="showSendInvoiceLink" class="freya-link-button" (click)="sendInvoice()">Not Sent</span>
      <span *ngIf="!showSendInvoiceLink">{{ invoice.sentAt ? (invoice.sentAt | freyaDate ) : 'Not Sent' }}</span>
    </p>
    <p>Date Opened:
      <span *ngIf="showOpenInvoiceLink" class="freya-link-button" (click)="confirmOpenInvoice('customer')">Not Opened</span>
      <span *ngIf="!showOpenInvoiceLink">{{ invoice.openedAt ? (invoice.openedAt | freyaDate) : 'Not Opened' }}</span>
    </p>
    <p *ngIf="invoice.metadata?.quickbooksId">
      <a [href]="viewOnQuickbooksUrl + invoice.metadata.quickbooksId" target="_blank">
        View on Quickbooks <i class="pi pi-external-link"></i>
      </a>
    </p>

  </div>

  <h5>Events</h5>
  <div class="card events"  *ngIf="invoice; else loadingCard">
    <ng-container *ngIf="invoice?.events?.length">

      <div *ngFor="let event of invoice.events"
        class="ce-event clickable"
        (click)="detailsHelper.open('calendar-event', event)"

        >
        <div>
          <span class="ce-title">{{ event.title }}</span>
          <span class="ce-status">{{ event.status | titlecase }}</span>
        </div>
        <div
          pTooltip="With dock travel times: {{ event | eventDuration : true : false }}"
          tooltipPosition="left"
          class="ce-time"
          >
          <span *ngIf="event?.start"
            >
            {{ event | eventDuration: false : true : false }}
          </span>
            <span>
              {{ event | eventDuration: false : false : false }}
            </span>
        </div>
      </div>
    </ng-container>

    <p *ngIf="invoice && !invoice?.events?.length" class="no-events">
    No Events
    </p>
  </div>

  <h5>Transactions</h5>
  <div class="card" *ngIf="invoice; else loadingCard">
    <p *ngFor="let t of invoice?.transactions">
    <p-tag [severity]="t.deletedAt ? 'danger' : (t.stage | severity : 'paid':'pending':'cancelled')">
      {{t.deletedAt ? 'Deleted' : t.stage | titlecase}}
    </p-tag>
    <a 
      (click)="detailsHelper.open('transaction', {id: t.id})"
      class="clickable"
      >
      {{t.type | titlecase}} {{t.amount / 100 | currency : t.currency}}
    </a>
    </p>
    <p *ngIf="invoice && !invoice?.transactions?.length" class="no-events">
      No Transactions
    </p>
  </div>

  <h5>Financials</h5>
  <div class="card">
    <app-financials-table
      [loading]="!invoice"
      [item]="invoice"
      [showPaid]="true"
      >
    </app-financials-table>
  </div>
</div>
<ng-template #loadingCard>
  <div class="card">
    <div class="p-p-2" *ngFor="let i of [ 0, 1, 2, 3 ]">
      <p-skeleton
        width="100%"
        height="16px"
        ></p-skeleton>
    </div>
  </div>
</ng-template>
