<div [formGroup]="baseLocationForm">
	<div class="p-grid location-form">
		<div class="p-col-9">
			<div class="p-inputgroup">
				<input
					*ngIf="googleHelper.loaded | async"
					#googleAutocomplete="ngx-places"
					ngx-gp-autocomplete
					class="location-input full-width"
					[options]="options"
					formControlName="address"
					pInputText
					autocomplete="off"
					(onAddressChange)="handleAddressChange($event, 'start')"
					(blur)="onLocationInputBlur()"
					data-cy="start-location-text-field"
					#locationInput
					/>
				<button type="button" pButton pRipple (click)="openManualLocation()" icon="pi pi-map-marker"
					pTooltip="Add a location without using the autocomplete"
					[disabled]="disabled$ | async"></button>
			</div>

			<small>You must use autocomplete to set the location.</small>
		</div>
		<div class="p-md-3">
			<span class="p-float-label">
				<input class="full-width" ruleProperty="starting_location.unit" pInputText formControlName="unit"
					data-cy="start-unit-text-field"/>
				<label for="unit">Unit #</label>
			</span>
			<app-validation-messages
					[control]="baseLocationForm.get('unit')"
					[requireTouched]="true"
			></app-validation-messages>
		</div>
	</div>
</div>

<div *ngIf="enableAdditionalFields" class="p-grid p-mt-1" [formGroup]="additionalFieldsForm">
	<div class="p-md-6">
		<span class="p-float-label">
			<p-dropdown class="full-width-dropdown" ruleProperty="starting_location.dwellingType"
				[options]="DWELLING_DROPDOWN_OPTIONS" formControlName="dwellingType"
				data-cy="start-dwelling-type-dropdown-field"
				></p-dropdown>
			<label for="dwellingType">Dwelling Type</label>
		</span>
	</div>
	<div class="p-md-6">
		<div class="p-inputgroup">
			<span class="p-float-label">
				<input type="number" pInputText ruleProperty="starting_location.sqft" formControlName="sqft"
					data-cy="start-sqft-number-field" />
				<label for="sqft">Sqft.</label>
			</span>

			<span class="p-inputgroup-addon">Sqft.</span>
		</div>
	</div>
	<div class="p-md-6">
		<span class="p-float-label">
			<p-dropdown class="full-width-dropdown" [options]="ELEVATOR_DROPDOWN_OPTIONS"
				ruleProperty="starting_location.elevators" formControlName="elevators"
				data-cy="start-elevators-dropdown-field"
				></p-dropdown>
			<label for="elevators">Elevators</label>
		</span>
	</div>
	<div class="p-md-6">
		<label>Can elevators be booked</label>
		<br />
		<p-inputSwitch formControlName="elevatorsBookable" ruleProperty="starting_location.elevatorsBookable"
			></p-inputSwitch>
	</div>
	<div class="p-md-6">
		<span class="p-float-label">
			<p-dropdown class="full-width-dropdown" [options]="STAIRS_DROPDOWN_OPTIONS"
				ruleProperty="starting_location.stairs" optionLabel="name" optionValue="value" formControlName="stairs"
				data-cy="start-stairs-dropdown-field"
				></p-dropdown>
			<label for="stairs">Stairs</label>
		</span>
	</div>
	<div class="p-md-6">
		<span class="p-float-label">
			<p-dropdown class="full-width-dropdown" ruleProperty="starting_location.bedrooms"
				[options]="BEDROOM_DROPDOWN_OPTIONS" optionLabel="name" optionValue="value" formControlName="bedrooms"
				data-cy="start-bedrooms-dropdown-field"
				></p-dropdown>
			<label for="bedrooms">Number of Bedrooms</label>
		</span>
	</div>

	<div class="p-col-12">
		<span class="p-float-label">
			<textarea [rows]="3" [cols]="50" formControlName="parkingInformation" pInputTextarea
				[style]="{width: '100%', maxWidth: '372px'}" data-cy="start-parking-info-textarea-field"
				></textarea>
			<label for="parkingInformation">Parking Information</label>
		</span>
	</div>
</div>

<div *ngIf="removable">
	<button 
		*hasPermission="['jobs.update']"
		pButton 
		class="p-button-danger" 
		label="Remove Location" 
		icon="pi pi-trash"
		(click)="removeLocation()"
	></button>
	<br>
	<br>
</div>

<app-add-location #addLocation [formType]="type" (didHide)="attemptedAutocomplete = false"
	(locationInputBlur)="onLocationInputBlur()" (locationAdded)="setValueEqualToManual($event)"></app-add-location>
