import { taxesQuery } from '@karve.it/interfaces/taxes';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ChargesComponent } from '../charges/charges.component';

export type SourceType = 'local';
export type UpdateObjectAction = 'create' | 'update' | 'delete' | 'added' | 'removed' | boolean;


export interface ObjectUpdate {
  id: string;
  type: UpdatedType;
  // action is update type (e.g., create, update, delete, added, removed,boolean)
  action: UpdateObjectAction;
  // update is object that contains updated value
  update?: any;
  source?: SourceType;
  // history?: History;
}

export const updatedType = [
  'Discounts',
  'Products',
  'Prices',
  'Assets',
  'AvailabilityConfig',
  'AvailabilityTemplate',
  'Zones',
  'Jobs',
  'User',
  'Locations',
  'Artifacts',
  'Comments',
  'Transactions',
  'Rules',
  'Discounts',
  'Events',
  'Taxes',
  'Charges',
  'Fields',
  'Tags',
  'Configs',
  'Report',
  'ReportType',
  'ScheduledReport',
  'Franchises',
  'Invoice',
  'DynamicReport'
] as const;

export type UpdatedType = typeof updatedType[number];


/**
 * Check all subject's type to the given type(s)
 * it will return a subject that is matching the given type.
 */
export function objectTypeFilter(inputType: UpdatedType | UpdatedType[]) {
  let inputTypes: UpdatedType[] = [];
  if (typeof inputType === 'string') {
    inputTypes = [inputType];
  } else {
    inputTypes = inputType;
  }
  return filter((objectupdate: ObjectUpdate) => Boolean(inputTypes.find((type) => type === objectupdate.type)));
}

