<div
    class="conditionBlock"
    [class.evenBlock]="blockLevel % 2 === 0"
    [class.oddBlock]="blockLevel % 2 === 1"
>
    <div
        *ngFor="let con of conditions; let first = first"
    >
        <ng-container *ngIf="!checkIfConditionIsBasic(con) && con.value">

            <ng-container *ngIf="!first && con.condition !== 'OR' && con.condition !== 'XOR' && con.condition !== 'AND'">
                AND
            </ng-container>
            {{ con.condition }}

            <app-mutate-rule-condition-block
                [form]="form"
                [conditions]="con.value"
                [blockLevel]="blockLevel + 1"
                (removeBlock)="removeCondition(con)"
                (blockUpdated)="onBlockUpdated()"
            ></app-mutate-rule-condition-block>
        </ng-container>
        <ng-container *ngIf="checkIfConditionIsBasic(con)">
            <ng-container *ngIf="!first">
                <br />AND <br />
            </ng-container>
            <p-dropdown
                appendTo="body"
                placeholder="Select property"
                [ngModel]="con.property"
                [ngModelOptions]="{standalone: true}" 
                (onChange)="conditionInfoUpdated($event, con, 'property')"
                [options]="propOptions || getConditionalPropertyOptions()"
            ></p-dropdown>
            <p-dropdown
                *ngIf="con.property"
                appendTo="body"
                placeholder="Select condition"
                [ngModel]="con.condition"
                [ngModelOptions]="{standalone: true}" 
                (onChange)="conditionInfoUpdated($event, con, 'condition')"
                [options]="getAllowedConditionsForProperty(con)"
            ></p-dropdown>
            <p-dropdown
                appendTo="body"
                placeholder="Select type"
                [ngModel]="con.type"
                [ngModelOptions]="{standalone: true}" 
                (onChange)="conditionInfoUpdated($event, con, 'type')"
                [options]="getAllowedTypesForConditionOptions(con)"
                [hidden]="getAllowedTypesForCondition(con).length <= 1"
                *ngIf="con.condition"
            ></p-dropdown>
            <ng-container
                *ngIf="con.condition"
                [ngSwitch]="con.type"
            >
                <p-inputNumber
                    mode="decimal"
                    [minFractionDigits]="1"
                    [maxFractionDigits]="3"
                    *ngSwitchCase="'number'"
                    [ngModel]="con.value"
                    [ngModelOptions]="{standalone: true}" 
                    (onInput)="conditionInfoUpdated($event, con, 'value')"
                
                ></p-inputNumber>

                <input type="text" pInputText
                    *ngSwitchCase="'string'"
                    [ngModel]="con.value"
                    [ngModelOptions]="{standalone: true}" 
                    (ngModelChange)="conditionInfoUpdated($event, con, 'value')"
                />
                <p-dropdown
                    *ngSwitchCase="'boolean'"
                    appendTo="body"
                    placeholder="Select value"
                    [ngModel]="con.value"
                    [ngModelOptions]="{standalone: true}" 
                    (onChange)="conditionInfoUpdated($event, con, 'value')"
                    [options]="booleanOptions"
                ></p-dropdown>
                <div *ngSwitchCase="'array-num'">Arrays not yet supported.</div>
                <div *ngSwitchCase="'array-str'">Arrays not yet supported.</div>
                <div *ngSwitchCase="'array-bool'">Arrays not yet supported.</div>

            </ng-container>
            <button pButton pRipple type="button"
                icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text"
                (click)="removeCondition(con)"
            
            ></button>
        </ng-container>
    </div>
    <br />
    <p-splitButton
        label="Add Condition"
        (onClick)="addCondition()"
        [model]="addConditionItems"
        styleClass="p-button-info"
        appendTo="body"
    ></p-splitButton>

    <button pButton pRipple type="button"
        icon="pi pi-times"
        class="p-button-rounded p-button-danger p-button-text"
        (click)="removeBlock.next()"
        *ngIf="blockLevel > 0"
    ></button>

</div>