import {Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {FetchPolicy} from '@apollo/client/core';

import { filter, map, switchMap } from 'rxjs/operators';

import { AttentionItem, AttentionItemsSummaryGQL, AttentionItemsTotalGQL, GetConfigValuesGQL, Job, ZoneDir } from '../../generated/graphql.generated';
import { safeParseJSON } from '../js';
import { BrandingService } from '../services/branding.service';
import { DetailsHelperService } from '../services/details-helper.service';
import { DocumentHelperService } from '../services/document-helper.service';
import { EventHelperService } from '../services/event-helper.service';
import { FreyaHelperService } from '../services/freya-helper.service';
import { FreyaNotificationsService } from '../services/freya-notifications.service';

export interface AttentionConfig {
  action: string;
  attentionExplanation: string;
  label: string;
  description?: string;
  score: number;
}

@Injectable({
  providedIn: 'root'
})
export class AttentionItemsService {

  configs$ = this.branding.currentZone()
    .pipe(switchMap(() => this.getConfigValuesGQL.fetch({ keys: [ 'attention.*' ] })
      .pipe(
        filter((res) => Boolean(res.data?.getConfigValues)),
        map((res) => res.data.getConfigValues.map((c) => safeParseJSON(c.value) as AttentionConfig)),
      )
    ));

  summaries$ = this.configs$
  .pipe(
    switchMap((configs) => this.attentionItemsSummaryGQL
      .watch({ filter: { zoneDir: ZoneDir.Lte }}).valueChanges
        .pipe(
          filter((res) => Boolean(res?.data?.attentionItemsSummary?.length)),
          map((res) => res.data.attentionItemsSummary.map((s) => {

            const config = configs.find((c) => c.attentionExplanation === s.attentionExplanation);

            return {
              attentionExplanation: config?.attentionExplanation,
              label: config?.label,
              items: s.items,
            };
          })),
        )));


  constructor(
    private freyaHelper: FreyaHelperService,
    private localNotify: FreyaNotificationsService,
    private router: Router,
    private eventHelperService: EventHelperService,
    private detailsHelper: DetailsHelperService,
    private getConfigValuesGQL: GetConfigValuesGQL,
    private branding: BrandingService,
    private attentionItemsSummaryGQL: AttentionItemsSummaryGQL,
    private attentionItemsTotalGQL: AttentionItemsTotalGQL,
    private documentHelper: DocumentHelperService,
  ) { }

  getConfigs() {
    const attentionConfigs$ = this.getConfigValuesGQL.fetch({ keys: [ 'attention.*' ] })
      .pipe(
        filter((res) => Boolean(res.data?.getConfigValues)),
        map((res) => res.data.getConfigValues.map((c) => safeParseJSON(c.value))),
      );

    return this.branding.currentZone().pipe(switchMap(() => attentionConfigs$));
  }

  async performItemAction(item: AttentionItem, afterCb?: () => void) {

    switch(item.action) {
      case 'process-job':
        if (item.__typename === 'Job') {
          this.router.navigate([ '/estimating', item.id ]);
        } else {
          this.localNotify.error('Action not implemented for this type of item');
        }
        break;
      case 'generate-invoice':
        if (item.__typename === 'Job') {
          this.documentHelper.openCreateInvoiceDialog(item.id);
        } else {
          this.localNotify.error('Action not implemented for this type of item');
        }
        break;
      case 'complete-event':
        if (item.__typename === 'CalendarEvent') {
          await this.eventHelperService.updateEventStatus(item.id, 'completed')
            .catch(() => this.localNotify.error('Could not update event status'));

          if (!afterCb) { return; };

          afterCb();
        } else {
          this.localNotify.error('Action not implemented for this type of item');
        }
        break;
      case 'book-event':
        if (item.__typename === 'Job') {
          this.goToBooking(item);
        } else {
          this.localNotify.error('Action not implemented for this type of item');
        }
        break;
      default:
        this.localNotify.error('Action not implemented');
    }
  };

  goToBooking(job: Job) {
    const uncompletedEvents = job.events
      .filter((e) => e.status !== 'completed')
      .sort((a, b) => a.start - b.start);

    const [ first ] = uncompletedEvents;

    const queryParams = {
      step: 'booking',
      eventType: first.type,
    };

    this.router.navigate([ 'estimating', job.id ], { queryParams });
  }

  openItem(item: AttentionItem) {
    this.detailsHelper.open(this.getDetailsItemType(item), { id: item.id });
  }

  fetchTotal(fetchPolicy: FetchPolicy) {
    return this.attentionItemsTotalGQL.fetch({}, { fetchPolicy })
      .pipe(
        filter(result => !result.loading),
        map(result => result.data.attentionItems.total)
      );
  }

  getDetailsItemType(item: AttentionItem) {
    switch (item.__typename) {
      case 'Job':
        return 'job';
      case 'CalendarEvent':
        return 'calendar-event';
      case 'User':
       return 'users';
    }
  }

}
