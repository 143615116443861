import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ZoneInput } from '@karve.it/interfaces/zones';
import { CANADA, countries, regex, USA } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { environment } from '../../../environments/environment';
import { BaseZoneWithAreasFragment, CreateLocationsGQL, CreateZonesGQL, ListZonesWithAreasGQL, RemoveLocationsGQL, UpdateZonesGQL, UpdateZonesMutationVariables } from '../../../generated/graphql.generated';
import { BrandingService } from '../../services/branding.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { GoogleHelperService } from '../../services/google-helper.service';

import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

@Component({
  selector: 'app-mutate-area',
  templateUrl: './mutate-area.component.html',
  styleUrls: ['./mutate-area.component.scss']
})
export class MutateAreaComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('name') nameRef: TemplateRef<any>;
  @ViewChild('areaCodes') codesRef: TemplateRef<any>;
  @ViewChild('color') colorRef: TemplateRef<any>;
  @ViewChild('country') countryRef: TemplateRef<any>;
  @ViewChild('description') descriptionRef: TemplateRef<any>;

  @Input() mutateType: 'update' | 'create';
  @Input() area: BaseZoneWithAreasFragment;

  zoneQueryRef: ReturnType<typeof this.listZonesWithAreasGQL.watch>;

  steps: MutateObjectElement[];

  country: string;

  subs = new SubSink();

  showColorTags = true;

  areaForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(2)]),
    areaCodes: new UntypedFormControl([], []),
    color: new UntypedFormControl('#81D4FA', []),
    country: new UntypedFormControl(environment.defaultCountry, [Validators.required]),
    description: new UntypedFormControl('', [])
  });
  areaFormValues = this.areaForm.value;

  countries = countries;

  constructor(
    private localNotify: FreyaNotificationsService,
    private createZonesGQL: CreateZonesGQL,
    private updateZonesGQL: UpdateZonesGQL,
    private listZonesWithAreasGQL: ListZonesWithAreasGQL,
    private createLocationsGQL: CreateLocationsGQL,
    private detailHelper: DetailsHelperService,
    private cd: ChangeDetectorRef,
    private brandingSvc: BrandingService,
    private freyaHelper: FreyaHelperService,
    private googleHelperSvc: GoogleHelperService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterContentChecked(){
    this.cd.detectChanges();
  }

  openDialog() {
    if (this.mutateType === 'create') {
      this.areaForm.reset(this.areaFormValues);
      this.retrieveCountry();
    } else if (this.mutateType === 'update') {
      this.assignDefaultValues();
      this.retrieveCountry();
    }

    this.steps = [
      {name: 'Name', ref: this.nameRef, control: 'name', type: 'text'},
      {name: 'Description', ref: this.descriptionRef, control: 'description', type: 'text'},
      {name: 'Area Codes', ref: this.codesRef, control: 'areaCodes', type: 'array'},
      {name: 'Color', ref: this.colorRef, control: 'color', type: 'text'},
    ];

    // If the country isn't set make sure they have the option
    if (!this.country) {
      this.steps.push({name: 'Country', ref: this.countryRef, control: 'country', type: 'text'});
    }

    this.mutateRef.steps = this.steps;
    this.mutateRef.mutateType = this.mutateType;

    this.mutateRef.openDialog();
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      this.createArea();
    } else if (this.mutateType === 'update') {

      if (!this.areaForm.dirty) {
        this.localNotify.success('Area already up to date');
        this.mutateRef.closeDialog();
        return;
      }

      this.editZone();
    }
  }

  createArea(){
    const createZoneInput = {
      attributes: ['area'],
      name: this.areaForm.controls.name.value,
      type: 'area',
      color: this.areaForm.controls.color.value,
      description: this.areaForm.controls.description.value,
      contextable: true,
    } as ZoneInput;

    this.subs.sink = this.createZonesGQL.mutate({
      parent: this.brandingSvc.currentZone().value.id,
      zones: [createZoneInput],
    }).subscribe((res) => {
      if (this.areaForm.controls.areaCodes.value.length > 0) {
        const locations = this.areaForm.controls.areaCodes.value.map((code) => (
          {
            areaCode: code,
            attributes: ['ZoneArea', this.areaForm.controls.name.value],
            public: false,
            name: `${code} - ${this.areaForm.controls.name.value}`,
            addressLineOne: code,
            country: this.areaForm.controls.country.value,
          }
        ));

        this.createLocationsGQL.mutate({input: {locations}}, {
          context: {
            zone: res.data.createZones[0].id,
          },
        }).subscribe((createRes) => {
          this.detailHelper.pushUpdate({
            id:res.data.createZones[0].id,
            type:'Zones',
            action:'create',
          });
          this.mutateRef.closeDialog();
          this.localNotify.addToast.next({severity: 'success', summary: 'Area created'});
        }, (createErr) => {
          this.mutateRef.loading=false;
          this.localNotify.apolloError(`Failed to add codes to area`,createErr);
        });

      } else {
        this.mutateRef.closeDialog();
        this.localNotify.addToast.next({severity: 'success', summary: 'Area created'});
        this.detailHelper.pushUpdate({
          id:res.data.createZones[0].id,
          type:'Zones',
          action:'create'
      });

      }
    }, (err) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Failed to create area`,err);
    });
  }

  async retrieveCountry(){

    const country = await this.freyaHelper.getCountry();

    this.country = country.country;
    this.areaForm.controls.country.setValue(this.country);

  }

  retrieveZone(){
    if (!this.zoneQueryRef) {
      this.zoneQueryRef = this.listZonesWithAreasGQL.watch({filter: {ids: [this.area.id]}}, { fetchPolicy: 'network-only' });
    } else {
      this.zoneQueryRef.refetch({filter: {ids: [this.area.id]}});
    }

    this.subs.sink = this.zoneQueryRef.valueChanges.subscribe((res) => {
      if (res.networkStatus === 7) {
        this.area = res.data.zones.nodes[0];
        this.detailHelper.detailsItem.next({type: 'area', item: this.area});
      }
    });
  }

  assignDefaultValues(){
    this.areaForm.setValue({
      name: this.area.name,
      areaCodes: this.area.areas.map((l) => l.areaCode),
      color: this.area.color,
      country: this.country ? this.country : environment.defaultCountry,
      description: this.area.description,
    });
  }

  // Edit the Zone Properties
  editZone(){
    const editZoneInput: UpdateZonesMutationVariables = {
      ids: [this.area.id],
      edit: {
        name: this.areaForm.value.name,
        color: this.areaForm.value.color,
        description: this.areaForm.value.description,
        attributes: this.area.attributes,
      }
    };

    if (this.areaForm.controls.areaCodes.dirty) {

      const locationsToAdd = this.areaForm.value.areaCodes
        .filter((ac) => !this.area.areas.find((a) => a.areaCode === ac));

      editZoneInput.edit.addAreaCodes = {
        areaCodes: locationsToAdd,
        country: this.areaForm.controls.country.value,
      };

      const locationsToRemove = this.area.areas
        .filter((a) => !this.areaForm.value.areaCodes.find((ac) => ac === a.areaCode))
        .map((l) => l.id);

      editZoneInput.edit.removeAreaCodes = locationsToRemove;
    }

    this.updateZonesGQL.mutate(editZoneInput).subscribe((res) => {
      this.mutateRef.closeDialog();
      this.localNotify.addToast.next({severity: 'success', summary: 'Area updated'});
      this.retrieveZone();
    }, (err) => {
      this.mutateRef.loading=false;
      this.localNotify.apolloError(`Area update failed`,err);
    });
  }

  validateAreaCode(event){
    const target = event.originalEvent.target;
    const value = event.value.replace(/[\-" "]/g, '');

    let codeValid: boolean;
    if (this.areaForm.value.country === CANADA){
      const re = new RegExp(regex.areaCodeCanada);
      codeValid = re.test(value);
    } else if (this.areaForm.value.country === USA){
      const re = new RegExp(regex.areaCodeUSA);
      codeValid = re.test(value);
    }

    // Remove the value from being automatically added to the control
    const valueAfterRemove = this.areaForm.controls.areaCodes.value.filter((ac) => ac !== event.value);
    const control = this.areaForm.controls.areaCodes;
    control.setValue(valueAfterRemove);
    if(!codeValid){
      setTimeout(() => {
        target.value = value;
        control.setErrors({pattern: true});
      }, 100);
    } else {
      control.setValue([...control.value, value]);
    }
  }

  capitalizeText(input){
    input.value = input.value.toUpperCase();
  }

  clearAreaInput(event) {
    event.target.value = '';
    this.areaForm.controls.areaCodes.setErrors(null);
  }

  changeTagColors(){
    this.showColorTags = false;
    setTimeout(() => {
      this.showColorTags = true;
    }, 0.01);
  }
}
