<div [formGroup]="bookOffForm">
    <label>Date</label>
    <p-calendar
        #dateSelect
        dateFormat="DD, MM, d"
        appendTo="body"
        selectionMode="single"
        formControlName="date"
        [style]="{ width: '100%' }"
    ></p-calendar>

    <label>Time</label>
    <p-dropdown
        [style]="{ width: '100%' }"
        [options]="bookOffOptions"
        formControlName="timeBlock"
        (onChange)="setCustomEnabled($event)"
        appendTo="body"
    >
        <ng-template let-item pTemplate="item">
            <div>
                <span>{{ item.label }}</span>
                <br />
                <small *ngIf="!item.custom"
                    >{{ item.start | secondsToTime }} -
                    {{ item.end | secondsToTime }}</small
                >
            </div>
        </ng-template>
    </p-dropdown>
    <div
        *ngIf="bookOffForm.value?.timeBlock?.custom"
        [formGroup]="customTimeForm"
    >
        <br />
        <span>Start</span>
        <p-calendar
            formControlName="start"
            [showTime]="true"
            [timeOnly]="true"
            [showSeconds]="false"
        ></p-calendar>
        <span>End</span>
        <p-calendar
            formControlName="end"
            [showTime]="true"
            [timeOnly]="true"
            [showSeconds]="false"
        ></p-calendar>
    </div>

    <label>Assets</label>
    <app-asset-select formControlName="assets"></app-asset-select>

    <label>Description</label>
    <textarea
        class="full-width"
        pInputTextarea
        formControlName="description"
        placeholder="Optional: Description for why this asset is booked off"
    ></textarea>
</div>

<div class="footer">
    <button
        pButton
        icon="pi pi-times"
        label="Cancel"
        class="p-button-text"
        (click)="closeDialog()"
    ></button>
    <button
        pButton
        icon="pi pi-check"
        label="Book Off"
        class="p-button-success"
        (click)="bookOff()"
    ></button>
</div>
