<div class="p-d-flex p-ai-center">
	<div style="flex: 1;">
		<p-panel
			[toggleable]="true"
			[collapsed]="true"
		>
			<ng-template pTemplate="header">
				<div class="full-width">
					<div class="p-grid p-mt-0">
						<div class="p-col-4">
							<span class="p-mr-2">
								{{document.artifact?.metadata | metadata : 'docTypeName' || document.artifact?.name | titlecase}}
							</span>
						</div>
						<div class="p-col-8">
							<b *ngIf="isSignable"><span class="success-text ">
								Signable
							</span></b>
							<b *ngIf="!isSignable"><span class="success-text ">
								Not signable
							</span></b>
						</div>
					</div>
				</div>
			</ng-template>
				<p class="break-text">Name: {{document.artifact?.name}}</p>
				<p>Size: {{document.artifact?.fileSize | fileSize}}</p>
				<label>Message</label>
				<br>
				<textarea
						pInputTextarea
						[(ngModel)]="message"
						[disabled]="document.status !== 'draft'"
						[placeholder]="document.status === 'draft' ? 'Add a message (optional)' : 'No Custom Message Sent'"
						style="width: 100%; resize: vertical;"
						rows="4"
						cols="20"
				></textarea>
		</p-panel>
	</div>
	<div class="p-p-2" style="width: 10.5rem;">
		<p-splitButton
			[label]="document.status === 'pending' ? 'Pending' :
		                          document.status === 'sent' ? 'Resend' : 'Send'"
			[icon]="document.status === 'pending' ? 'pi pi-spinner pi-spin' : 'pi pi-send'"
			styleClass="p-button-success full-width"
			(onClick)="sendDocument()"
			[disabled]="document.status === 'pending'"
			[model]="actions"
			appendTo="body"
		></p-splitButton>
	</div>
</div>
<p class="created-date">Created
	<app-time-since [unixTime]="document.artifact?.createdAt"></app-time-since>
</p>
