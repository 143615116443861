import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';

import { RulesComponent } from '../rules/rules.component';
import { Category } from '../system-rules/system-rules.component';

@Component({
  selector: 'app-rules-franchise',
  templateUrl: './rules-franchise.component.html',
  styleUrls: ['./rules-franchise.component.scss']
})
export class RulesFranchiseComponent implements AfterViewInit {

  @ViewChild('appRules') appRules: RulesComponent;

  constructor(
    public freyaHelper: FreyaHelperService
  ) { }

  ngAfterViewInit(): void {
    // this.appRules.openCreate();

  }

}
