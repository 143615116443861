import { FIELD_TYPE } from '@karve.it/interfaces/fields';

import { BaseFieldFragment } from '../../generated/graphql.generated';

/**
 * Check the field for a value
 *
 * @returns True if the field has a value
 */
export function hasValue(field: BaseFieldFragment): boolean{
    return field?.values[0] !== undefined && field?.values[0] !== null;
}

interface NamespaceAndKey{
    namespace: string;
    key: string;
}

/**
 * Splits the name of the field into a namespace and key
 *
 * @returns namespace and key of the field
 */
export function splitName(field: BaseFieldFragment): NamespaceAndKey {
    const [namespace, key] = field.name.split('.');

    return {
        namespace,
        key
    };
}


/**
 * Gets the first value of a field if one exists
 *
 * @returns The value of the field or undefined
 */
export function getFieldValue(field: BaseFieldFragment): any {
    if (!hasValue(field)){
        return undefined;
    }

    if (field.type === FIELD_TYPE.OBJECT){
        try{
            return JSON.parse(field.values[0].value);
        } catch {
            console.warn(`Could not parse value for field: ${field.name}`);
            return;
        }
    }

    return field.values[0].value;
}

/**
 * Gets the value of a field by name
 *
 * @param fields data from the fields query
 * @param name name of the field to get the value for
 * @returns value of the field or undefined
 */
export function getFieldValueByName(fields: BaseFieldFragment[], name: string): any {
    const field = fields.find((f) => f.name === name);

    if (!field){
        return undefined;
    }

    return getFieldValue(field);
}
