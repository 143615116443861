import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { interval } from 'rxjs';
import { SubSink } from 'subsink';

import { ResponsiveHelperService } from '../../services/responsive-helper.service';
import { MS_TEN_SECONDS } from '../../time';


@Component({
  selector: 'app-time-since',
  templateUrl: './time-since.component.html',
  styleUrls: ['./time-since.component.scss']
})
export class TimeSinceComponent implements OnInit, OnDestroy {

  @Input() unixTime = 1622237441;

  @Input() refreshInterval = MS_TEN_SECONDS;

  refreshing = false;

  currentUnixTime = 0;

  subs = new SubSink();

  constructor(
    public responsiveHelper: ResponsiveHelperService
  ) { }

  ngOnInit(): void {
    this.currentUnixTime = Math.trunc(Date.now() / 1000);
    this.subs.sink = interval(this.refreshInterval)
      .subscribe(() => this.refresh());
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  refresh() {
    this.refreshing = true;
    setTimeout(() => {
      this.refreshing = false;
      this.currentUnixTime = Math.trunc(Date.now() / 1000);
    }, 0);
  }

}
