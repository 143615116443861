import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RuleService } from '@karve.it/features';
import { ConfigValueTrigger, describeConditionObject, describeRule, parseTriggers } from '@karve.it/rule-checker';
import { cloneDeep } from 'lodash';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { BaseZoneFragment, CreateRuleInput, ListZonesWithChildrenGQL, RulePartsFragment, UpdateRuleInput } from '../../../generated/graphql.generated';

import { advancedConditions, conditionalInfo, ConditionArray, conditionArrayValidator, DescribedRule, determineValueTypeOption, formDetails, forms, getPropertyInfo } from '../../franchise/rules/rules.constants';
import { BrandingService } from '../../services/branding.service';
import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';
import { RuleCheckerService } from '../rule-checker.service';

@Component({
  selector: 'app-mutate-rule',
  templateUrl: './mutate-rule.component.html',
  styleUrls: ['./mutate-rule.component.scss']
})
export class MutateRuleComponent implements OnInit, OnDestroy {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;
  @ViewChild('delete') deleteRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('given') given: TemplateRef<any>;
  @ViewChild('when')  when:  TemplateRef<any>;
  @ViewChild('then')  then:  TemplateRef<any>;
  @ViewChild('overridePriority') overridePriority:  TemplateRef<any>;
  @ViewChild('attributes') attributes:  TemplateRef<any>;
  @ViewChild('metadata') metadata:  TemplateRef<any>;

  @Input() mutateType: 'update' | 'create' | 'duplicate' = 'create';
  @Input() rule: DescribedRule;

  triggers: ConfigValueTrigger[];
  triggerOptions: { label: string; value: string }[];

  zone?: string;

  steps: MutateObjectElement[];

  subs = new SubSink();

  formOptions = forms;

  formPropertyOptions = [];
  showFormProperties = false;

  ruleForm = new UntypedFormGroup({
    form: new FormControl('estimating', [Validators.required]),
    property: new FormControl('', []),
    conditions: new UntypedFormControl(
      [{ property: '', condition: '', type: 'string' }] as ConditionArray,
      [ conditionArrayValidator ],
    ),
    trigger: new UntypedFormControl(undefined, [ Validators.required ]),
    attributes: new UntypedFormControl([], []),
    overridePriority: new FormControl(100, []),
    overridePriorityType: new FormControl('Assorted', []),
    metadata: new UntypedFormControl({}, []),
  });

  ruleFormValues = cloneDeep(this.ruleForm.value);

  // Zone Variables
  zones: BaseZoneFragment[] = [];
  zoneQueryRef: ReturnType<ListZonesWithChildrenGQL['watch']>;

  showDialog = false;

  constructor(
    private ruleService: RuleService,
    private localNotify: FreyaNotificationsService,
    private brandingSvc: BrandingService,
    private detailsHelper: DetailsHelperService,
    private ruleCheckerService: RuleCheckerService,
    private listZonesWithChildrenGQL: ListZonesWithChildrenGQL,
  ) { }

  ngOnInit(): void {
    this.retrieveZoneInfo();
    this.retrieveTriggers();
    this.formUpdated();
    this.checkPriorityType();

    this.subs.sink = this.ruleForm.valueChanges.subscribe(() => {
      this.checkPriorityType();
    });
  }

  checkPriorityType() {

    if (this.ruleForm.value.overridePriorityType === 'Ordered') {
      this.ruleForm.get('overridePriority').enable({ emitEvent: false });
    } else {
      this.ruleForm.get('overridePriority').disable({ emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  mutateObject() {
    if (this.mutateType === 'create' || this.mutateType === 'duplicate') {
      this.subs.sink = this.createRule();
    } else if (this.mutateType === 'update') {
      this.subs.sink = this.updateRule();
    }
  }

  formUpdated() {
    const empty = { label: 'Entire Form', value: '' };
    const details = this.getFormDetails();
    if (!details) {
      this.formPropertyOptions = [ empty ];
    }

    this.formPropertyOptions = [
      empty,
      ...details.properties.map((p) => ({
        label: p.label || p.name,
        value: p.name,
      })),
    ];

    this.showFormProperties = details.showFormProperties || false;
  }

  getFormDetails() {
    const val = this.ruleForm.get('form').value;
    if (!val) { return undefined; }
    const details = formDetails.find((f) => f.form === val);
    if (!details) { return undefined; }
    return details;
  }

  retrieveZoneInfo() {
    this.zoneQueryRef = this.listZonesWithChildrenGQL.watch({ filter: {
      ids: [ this.brandingSvc.currentZone().value.id,
    ] } });

    this.subs.sink = this.zoneQueryRef.valueChanges.subscribe((res) => {
      if (res.networkStatus === 7) {
        if (res.data.zones.nodes.length < 1) { return; }
        this.zones = [
          ...res.data.zones.nodes,
          ...res.data.zones.nodes[0].children.nodes
        ];
      }
    });
  }

  retrieveTriggers() {
    this.subs.sink = this.ruleService.getRuleTriggers({ zone: this.zone }).subscribe((res) => {
      this.triggers = parseTriggers(res.data.getConfigValues || []);
      this.updateTriggerOptions();
    });
  }

  updateTriggerOptions() {
    this.triggerOptions = this.triggers.map((t) => ({
      label: t.value.triggerPrettyName,
      value: t.id,
    }));
  }

  formatConditionValue(conditions?: ConditionArray) {
    if (!conditions) {
      conditions = this.ruleForm.get('conditions').value;
    }

    if (!conditions) { return {}; }

    // isValid?

    const obj = {};
    for (const condition of conditions) {
      const isBlock = advancedConditions.includes(condition.condition);
      if (isBlock) {
        obj[condition.condition] = this.formatConditionValue(condition.value);
      } else {
        obj[condition.property + condition.condition] = condition.value;
      }

    }

    return obj;
  }

  conditionStrToArray(obj: any) {
    const arr: ConditionArray = [];
    const conditions = Object.keys(conditionalInfo);

    // eslint-disable-next-line guard-for-in
    for (const key in obj) {
      const value = obj[key];
      if (advancedConditions.includes(key)) {
        arr.push({
          condition: key,
          value: this.conditionStrToArray(value),
        });
      } else {
        const typeOption = determineValueTypeOption(value);
        if (!typeOption) { continue; }

        const condition = conditions.find((f) => key.slice(-f.length) === f);
        if (!condition) { continue; }
        const property = key.slice(0, -condition.length);

        arr.push({
          condition,
          property,
          type: typeOption.value,
          value,
        });
      }
    }

    return arr;

  }

  generateGiven() {

    const val = this.ruleForm.value;
    const form = formDetails.find((f) => f.form === val?.form);
    let given = form?.formLabel || val?.form;
    if (val?.property) {
      const property = getPropertyInfo(val.form, val.property);
      given += ` on ${ property.label }`;
    } else {
      given += ` on entire form`;
    }

    return given;
  }

  openDialog(
    zone?: string,
    setOverridePriorityType?: 'Ordered' | 'Assorted',
    setOverridePriority?: number,
  ) {
    this.zone = zone;

    const baseSteps: MutateObjectElement[] = [
      { name: 'Given', ref: this.given, control: 'form', type: 'func', reviewFunc: (val) => this.generateGiven() || val },
      { name: 'When', ref: this.when, control: 'conditions', type: 'func', reviewFunc: (val) => {

        const obj = this.formatConditionValue();

        return describeConditionObject(obj);
      }},
      { name: 'Then', ref: this.then, control: 'trigger', type: 'func', reviewFunc: (val) => {
        if (!this.triggers) { return ''; }
        if (!val || val === '') { return ''; }
        const trigger = this.triggers.find((t) => t.id === val);
        if (!trigger) { return val; }
        if (!trigger?.value?.triggerPrettyName) { return trigger.key; }
        return trigger.value.triggerPrettyName;
      }},
      { name: 'Override Priority', ref: this.overridePriority, control: 'overridePriority', type: 'func', reviewFunc: (val) => {
        const type = this.ruleForm.get('overridePriorityType').value;
        if (type === 'Ordered') {
          return val;
        } else {
          return type;
        }
      } },
      { name: 'Attributes', ref: this.attributes, control: 'attributes', type: 'array' },
      { name: 'Metadata', ref: this.metadata, control: 'metadata', type: 'func',
        reviewFunc: (val) => Object.keys(val).map((v) => `${v}: ${val[v]}`).join(', ')
      },
    ];

    let duplicate = false;
    this.ruleForm.reset(cloneDeep(this.ruleFormValues));
    if (this.mutateType === 'create') {
      this.steps = baseSteps;
    } else if (this.mutateType === 'duplicate') {
      this.steps = baseSteps;
      this.setFormValues();
      this.rule = undefined;
      this.mutateType = 'create';
      duplicate = true;
    } else {
      this.steps = baseSteps;
      this.setFormValues();
    }

    if (setOverridePriorityType) {
      this.ruleForm.get('overridePriorityType').setValue(setOverridePriorityType);
    }

    if (setOverridePriority !== undefined) {
      this.ruleForm.get('overridePriority').setValue(setOverridePriority);
    }

    this.mutateRef.mutateType = this.mutateType;
    this.mutateRef.steps = this.steps;
    this.mutateRef.openDialog();
    if (duplicate) {
      this.mutateRef.viewReview();
    }
  }

  openDelete() {
    this.deleteRef.openDialog();
  }

  setFormValues() {
    const conditions = this.conditionStrToArray(this.rule.condition);
    console.log(this.rule.property);

    this.ruleForm.reset({
      ...this.rule,
      property: this.rule.property || '',
      trigger: this.rule?.trigger?.id,
      conditions,
      overridePriority: this.rule.overridePriority,
      overridePriorityType: this.rule.overridePriority === null ? 'Assorted' : 'Ordered',
    });

    if (!this.rule.metadata) {
      this.ruleForm.get('metadata').setValue({});
    }
  }

  openRuleFromFragment(fragmentRule: RulePartsFragment) {
    const describedRule: DescribedRule = {
      ...fragmentRule,
      ...describeRule(fragmentRule as any),
      condition: JSON.parse(fragmentRule.condition),
    };

    this.detailsHelper.detailsItem.next({ type: 'rules', item: describedRule });
  }

  getOverridePriority() {
    const val = this.ruleForm.value;

    if (val.overridePriorityType === 'Ordered') {
      return val.overridePriority || 0;
    }

    return null;
  }

  createRule() {

    const val = this.ruleForm.value;
    const condition = JSON.stringify(this.formatConditionValue());
    let property = val.property;
    if (property === '') {
      property = undefined;
    }

    const createRuleInput = {
      properties: {
        condition,
        form: val.form,
        property,
        overridePriority: this.getOverridePriority(),
        attributes: val.attributes,
      },
      relationships: {
        trigger: val.trigger,
        metadata: val.metadata,
      }
    } as CreateRuleInput;

    return this.ruleService.createRules({ rules: [ createRuleInput ] }, { zone: this.zone }).subscribe((res) => {
      this.mutateRef.closeDialog();
      this.localNotify.addToast.next({ severity: 'success', summary: 'Created rule' });
      this.detailsHelper.pushUpdate({
        id:res.data.createRules[0].id,
        type:'Rules',
        action:'create',
      });
      const [ rule ] = res.data?.createRules;
      if (rule) {
        this.openRuleFromFragment(rule);
      }
      this.ruleCheckerService.rulesRecentlyUpdated = true;
    }, (err) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Failed to create rule`,err.message);
    });
  }

  updateRule() {
    const val = this.ruleForm.value;
    const condition = JSON.stringify(this.formatConditionValue());

    const updateRuleInput = {
      id: this.rule.id,
      properties: {
        condition,
        form: val.form,
        overridePriority: this.getOverridePriority(),
        attributes: val.attributes,
      },
      relationships: {
        trigger: val.trigger,
        // todo: metadata on inputs
        metadata: val.metadata,
      }
    } as UpdateRuleInput;

    if (val.property && val.property.length >= 1) {
      updateRuleInput.properties.property = val.property;
    }

    return this.ruleService.updateRules({rules: [updateRuleInput]}, { zone: this.zone }).subscribe((res) => {
      this.mutateRef.closeDialog();
      this.localNotify.addToast.next({ severity: 'success', summary: 'Updated rule' });
      this.detailsHelper.pushUpdate({
        id:this.rule.id,
        type:'Rules',
        action:'update',
      });
      const [ rule ] = res.data?.updateRules;
      if (rule) {
        this.openRuleFromFragment(rule);
      }
      this.ruleCheckerService.rulesRecentlyUpdated = true;
    }, (err) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Failed to update rule`,err.message);

    });
  }

  deleteRule() {
    this.subs.sink = this.ruleService.deleteRules({ ruleIds: [ this.rule.id ] }, { zone: this.zone }).subscribe((res) => {
      this.localNotify.addToast.next({ severity: 'success', summary: 'Deleted rule' });
      this.detailsHelper.pushUpdate({
        id:this.rule.id,
        type:'Rules',
        action:'delete'
      });
      if (this.detailsHelper.detailsItem.value?.item?.id === this.rule.id) {
        this.detailsHelper.detailsItem.next(null);
      }
      this.ruleCheckerService.rulesRecentlyUpdated = true;

    }, (err) => {
      this.localNotify.addToast.next({ severity: 'error', summary: 'Failed to delete rule' });
    });
  }

  onConditionBlockUpdated() {
    this.ruleForm.setValue(this.ruleForm.getRawValue());
  }

  hasEmptyMetadata() {
    const val = this.ruleForm.get('metadata').value;

    return Boolean(val.find((v) => !v.key || !v.key.length));
  }

  // updateMetadata(meta: { key: string; value: string }, prop: 'key' | 'value', val: string) {
  //   meta[prop] = val;

  //   // if (!this.hasEmptyMetadata()) {
  //   //   this.ruleForm.get('metadata').value.push({ key: '', value: '' });
  //   // }
  // }

  addProperty(newProperty: string){
    const metadataControl = this.ruleForm.get('metadata');

    const value = {
      ...metadataControl.value,
      [newProperty]: '',
    };

    metadataControl.setValue(value);
  }

  // getMetadata() {
  //   const val = this.ruleForm.get('metadata').value.filter((m) => m && m.key && m.key.length);

  //   if (!val || !val.length) { return undefined; }

  //   const obj = {};
  //   for (const meta of val) {
  //     obj[meta.key] = meta.value;
  //   }

  //   return obj;
  // }

}
