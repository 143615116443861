<div *ngIf="transaction?.amount">
    <div class="header-with-button p-ai-start">
        <h4>
            <i class="pi pi-credit-card large-icon"></i> Transaction
            <app-object-state
                [object]="transaction"
                objectType="transaction"
                [colorful]="true"
            ></app-object-state>
        </h4>
        <div>
            <span [pTooltip]="allActionsDisabled ? allActionsDisabledTooltip : undefined">
                <button
                    [disabled] = "allActionsDisabled"
                    pButton
                    pRipple
                    label="Actions"
                    (click)="menu.toggle($event)"
                    icon="pi pi-angle-down"
                    ></button>
            </span>
            <p-menu
                #menu
                [popup]="true"
                appendTo="body"
                [model]="transactionActions"
            ></p-menu>
        </div>
    </div>

    <div class="card">
        <p>Status: {{ (transaction.deletedAt ? 'deleted' : transaction.stage) | titlecase }}</p>
        <p>Amount: {{transaction.amount / 100 | currency : transaction.currency}}</p>
        <p>Time: {{transaction.createdAt | freyaDate : 'h:mm a, MMM d, y'}}</p>
        <p>Paid at: {{ transaction?.paidAt ? (transaction.paidAt | freyaDate : 'h:mm a, MMM d, y') : 'Not paid' }}</p>
        <p>Type: {{transaction.type | titlecase}}</p>
        <p>Payment Type: {{transaction.paymentType.name}}</p>
    </div>

    <h5>Invoice</h5>
    <div class="card">
        <div *ngIf="transaction; else loadingBar">
            <span *ngIf="transaction?.invoice" class="freya-link-button" (click)="openInvoiceDetails(transaction.invoice.id)">
                {{ transaction.invoice | invoiceName : (transaction.invoice?.job?.users | jobCustomer) : transaction?.invoice?.job?.code}}
            </span>
            <span *ngIf="!transaction?.invoice">No Active Invoices</span>
        </div>
    </div>
    <h5>User</h5>
    <div class="card">
        <h5 *ngIf="user; else elseBlock"> 
            <a routerLink="/user/{{user.id}}">
                {{user.givenName}} {{user.familyName}}
            </a>
        </h5>
        <p *ngIf="user; else elseBlock">{{user.email}}</p>
        <p *ngIf="user; else elseBlock">{{user | phone}}</p>
    </div>
</div>

<ng-template #elseBlock>
    <p-skeleton
        width="100%"
        height="24px"
    ></p-skeleton>
</ng-template>

<app-mutate-transaction #mutate mutateType='update' [transaction]="transaction"></app-mutate-transaction>
