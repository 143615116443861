
<div class="card">
        <div class="flex p-jc-between p-ai-center">
            <h5 class="p-m-0">Booking</h5>
            <app-add-event-button
                *hasPermission="['calendarEvents.create']"
                [job]="job"
                [disabled]="estimateHelper.jobLoading | async"
            ></app-add-event-button>
        </div>
    <p-tabView 
        *ngIf="job?.events?.length"
        styleClass="events-tab" 
        [activeIndex]="job.events | getObjectById : activeEventId : true"
        >
        <p-tabPanel *ngFor="let event of job?.events">
            <ng-template pTemplate="header">
                <div (click)="setActiveEventId(job.events.indexOf(event))" class="fully-clickable">
                    <span>{{event.title}} ({{event.status | titlecase}})</span>
                    <br>
                    <small>{{event.start ? (event.start | freyaDate : 'h:mm a, MMM d') : 'Unscheduled'}}</small>
                </div>
            </ng-template>
            <app-event-booking
                [job]="job"
                [event]="event"
            ></app-event-booking>
        </p-tabPanel>
    </p-tabView>
    <div *ngIf="!job?.events?.length">
        No Events have been added to this Job
    </div>
</div>
<app-schedules
    #schedule
    [showHeader]="false"
    [area]="job?.zone?.type === 'area' && job?.zone?.id"
    scheduleForEstimator="true"
    [currentJobId]="job?.id"
></app-schedules>
<!-- <div class="p-grid">
    <div class="p-col-4">
        <div class="card event-card">
            <div class="p-grid">
                <div class="booking-nav p-col-4">
                    <span
                        *ngFor="let type of eventTypes"
                        class="booking-nav-item"
                        [class.active-booking-nav-item]="type === activeEventType"
                        (click)="setActiveEventType(type)"
                    >{{type | titlecase | rename:'estimating':'OSEstimate':false}}</span>
                </div>
                <div
                    #bookingOptions
                    class="p-col-8 event-container"
                >
                    <div [hidden]="activeEventType !== 'estimating'">
                        <app-event-booking
                            [job]="job"
                            [activeEventType]="activeEventType"
                            [assetTypes]="['Estimator']"
                            [eventType]="'estimating'"
                            [event]="estimatingEvent"
                        ></app-event-booking>
                    </div>
                    <div [hidden]="activeEventType !== 'moving'">
                        <app-event-booking
                            [job]="job"
                            [activeEventType]="activeEventType"
                            [assetTypes]="['Truck']"
                            [eventType]="'moving'"
                            [event]="movingEvent"
                        ></app-event-booking>
                    </div>
                    <div [hidden]="activeEventType !== 'packing'">
                        <app-event-booking
                            [job]="job"
                            [activeEventType]="activeEventType"
                            [assetTypes]="['Truck']"
                            [eventType]="'packing'"
                            [event]="packingEvent"
                        ></app-event-booking>
                    </div>
                    <div [hidden]="activeEventType !== 'delivery'">
                        <app-event-booking
                            [job]="job"
                            [activeEventType]="activeEventType"
                            [assetTypes]="['Delivery']"
                            [eventType]="'delivery'"
                            [event]="deliveryEvent"
                        ></app-event-booking>
                    </div>
                    <div [hidden]="activeEventType !== 'unpacking'">
                        <app-event-booking
                            [job]="job"
                            [activeEventType]="activeEventType"
                            [assetTypes]="['Truck']"
                            [eventType]="'unpacking'"
                            [event]="unpackingEvent"
                        ></app-event-booking>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-col-8">
        <app-schedules
            *ngIf="job"
            #schedule
            [showHeader]="false"
            (initialized)="setActiveEventType(activeEventType)"
            [height]="'410px'"
            [dynamicHeight]="false"
            [area]="job.zone.id"
        ></app-schedules>
        <div *ngIf="!job">
            <p-message severity="warn" text="Enter Starting Address to View Schedules and Start Booking"></p-message>  
        </div>
    </div>
</div> -->
<div class="card">
    <div class="p-grid">
        <div class="p-col">
            <h6>Preferred Date</h6>
            <p>{{preferencesForm.value.preferredDate}}</p>
        </div>
        <div class="p-col">
            <h6>Preferred Time</h6>
            <p>{{preferencesForm.value.preferredTime}}</p>
        </div>
        <div class="p-col">
            <h6>Flexibility</h6>
            <p>{{preferencesForm.value.flexibility}}</p>
        </div>
    </div>
</div>
<br>
