<div class="card">
    <div class="p-grid">
        <div class="p-col">
            <h4 data-cy="franchises-card-header">Marketing</h4>
        </div>
        <div class="p-col">
        </div>
    </div>
</div>
<div class="p-grid">
    <div class="p-col">
        <app-how-heard-options></app-how-heard-options>
    </div>
</div>
