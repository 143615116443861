
<div class="rule-tabber">

    <button (click)="setMode('Ordered')" 
        [class.selected]="mode === 'Ordered'"
    >
        Ordered Rules
        <small>Only the rule with the highest override priority will be triggered
        </small>
    
    </button>
    <button (click)="setMode('Assorted')"
        [class.selected]="mode === 'Assorted'"
    >
        Assorted Rules

        <small>
            Multiple assorted rules can be triggered at once
        </small>
    </button>
</div>

<!-- <span class="p-buttonset">
    <button pButton pRipple label="Ordered Rules" (click)="setMode('Ordered')" icon="pi pi-check"></button>
    <button pButton pRipple label="Assorted Rules" (click)="setMode('Assorted')" icon="pi pi-check"></button>
</span> -->

<p-table #rulesTable
    [value]="rules"
    [lazy]="true"
    (onLazyLoad)="loadRules($event)"
    dataKey="id"
    [paginator]="true"
    [(first)]="skip"
    [rows]="limit"
    [totalRecords]="totalRecords"
    [rowsPerPageOptions]="[10,25,50]"
    [loading]="loading"
    [globalFilterFields]="['form', 'property', 'id']"
    breakpoint="620px"
>
    <ng-template pTemplate="header">
        <tr>
            <th>Given</th>
            <th>When</th>
            <th>Then</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rule>
        <tr class="selectable-row" (click)="selectRule(rule)">
            <td>
                <span class="p-column-title">Given:</span>
                {{ rule.given }}
            </td>
            <td class="when-cell">
                <span class="p-column-title">When:</span>
                <span class="value">
                    {{ rule.when }}
                </span>
            </td>
            <td>
                <span class="p-column-title">Then:</span>
                <span class="value">
                    {{ rule.then }}
                </span>
            </td>
        </tr>
    </ng-template>
</p-table>

<app-mutate-rule #mutate></app-mutate-rule>
