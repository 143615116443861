<span
    *ngIf="!refreshing"
    class="link-look"
    pTooltip="{{unixTime | freyaDate : 'medium'}}"
    tooltipStyleClass="tooltip-smalltext"
    tooltipPosition="bottom"
    [tooltipEvent]="responsiveHelper.isSmallScreen ? 'focus' : 'hover'"
>
{{(unixTime * 1000) | appropriateTimeMeasurement : true}}
</span>
