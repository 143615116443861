import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LocationService } from '@karve.it/features';
import { ListLocationsOutput, Location, stringifyLocation } from '@karve.it/interfaces/locations';
import {QueryRef} from 'apollo-angular';

import { clone } from 'lodash';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { PermissionService } from 'src/app/services/permission.service';
import { DeleteObjectComponent } from 'src/app/shared/delete-object/delete-object.component';
import { MutateJobComponent } from 'src/app/shared/mutate-job/mutate-job.component';
import { MutateLocationComponent } from 'src/app/shared/mutate-location/mutate-location.component';
import { parseMenuItemCategoriesVisible } from 'src/app/utilities/menu-item.util';
import { SubSink } from 'subsink';

import { DetailsHelperService } from '../../services/details-helper.service';


@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.scss']
})
export class LocationDetailsComponent implements OnInit, OnDestroy {

  @ViewChild('mutate') mutateRef: MutateLocationComponent;
  @ViewChild('delete') deleteRef: DeleteObjectComponent;

  @Input() location: Location;

  subs = new SubSink();

  locationQueryRef: QueryRef<ListLocationsOutput>;


  editLocationAction = {
    label: 'Edit',
    icon: 'pi pi-pencil',
    visible: false,
    command: () => {
      this.mutateRef.location = this.location;
      this.mutateRef.mutateType = 'update';
      this.mutateRef.openDialog();
    }
  };

  deleteLocationAction = {
    label: 'Delete',
    icon: 'pi pi-trash',
    visible: false,
    command: () => {
      this.deleteRef.openDialog();
    }
  };


  locationActions = [{
    label: 'Location Actions',
    items: [
      this.editLocationAction,
      this.deleteLocationAction

    ],
    visible: false,
  }];


  stringifyLocation = stringifyLocation;

  constructor(
    public detailsHelper: DetailsHelperService,
    private locationService: LocationService,
    private localNotify: FreyaNotificationsService,
    private permissionHandler: PermissionService,
  ) { }

  ngOnInit(): void {
    this.initializePermissions();
    this.subs.sink = this.detailsHelper.getObjectUpdates('Locations').subscribe((res) => {
      this.refetchLocation();
    });
  }

  initializePermissions() {
    this.subs.sink = this.permissionHandler.watchPermissions(
      ['locations.edit', 'locations.delete'])
      .subscribe((permisisons) => {
        this.editLocationAction.visible = permisisons[0];
        this.deleteLocationAction.visible = permisisons[1];

        const [ actions ] = this.locationActions;
        actions.visible = actions.items.some((i) => i.visible);

        parseMenuItemCategoriesVisible(this.locationActions);
        this.locationActions = clone(this.locationActions);
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  refetchLocation() {
    if (!this.locationQueryRef) {
      this.locationQueryRef = this.locationService.watchLocations({ filter: { ids: [this.location.id] } }, {});

      this.subs.sink = this.locationQueryRef.valueChanges.subscribe((res) => {
        // Location has been deleted
        if (this.location && res.data.locations.locations.length === 0) {
          this.detailsHelper.detailsItem.next(null);
        }
        if (res.networkStatus === 7) {
          this.location = res.data.locations.locations[0];
        }
      });
    } else {
      this.locationQueryRef.refetch();
    }
  }

  deleteLocation() {
    this.subs.sink = this.locationService.RemoveLocations({ ids: [this.location.id] }).subscribe((res) => {
      this.localNotify.addToast.next({ severity: 'success', summary: 'Location deleted' });
      this.detailsHelper.pushUpdate({
        id: this.location.id,
        type: 'Locations',
        action: 'delete',
      }, true);
    }, (err) => {
      this.localNotify.addToast.next({ severity: 'error', summary: 'Failed to delete location' });
    });
  }

}
