<div class="p-steps p-component">
    <ul role="tablist">
        <li *ngIf="activeIndex !== 0" role="tab" class="p-element p-steps-item">
            <a role="presentation" class="p-menuitem-link" (click)="goToPrev()">
                <span class="p-steps-number">
                    {{ activeIndex }}
                </span>
                <div class="p-steps-title">
                    {{ model[activeIndex - 1].label }}
                </div>
            </a>
        </li>
        <li *ngIf="activeIndex === 0" class="p-element p-steps-item p-disabled">
            <a role="presentation" class="p-menuitem-link">
                <span class="p-steps-number">
                    <i class="pi pi-chevron-left"></i>
                </span>
                <div class="p-steps-title"></div>
            </a>
        </li>
        <li role="tab" class="p-element p-steps-item p-highlight current-step">
            <a role="presentation" class="p-menuitem-link">
                <span class="p-steps-number">
                    {{ activeIndex + 1 }}
                </span>
            </a>
            <p-dropdown
                [options]="model"
                [(ngModel)]="activeStep"
                (onChange)="setActiveIndex($event.value)"
            >
            <ng-template let-step pTemplate="item">
                {{ ( model | indexOf : step ) + 1 }}. {{ step.label }}
            </ng-template>
            </p-dropdown>
        </li>
        <li *ngIf="activeIndex !== model.length - 1" role="tab" class="p-element p-steps-item">
            <a role="presentation" class="p-menuitem-link" (click)="goToNext()">
                <span class="p-steps-number">
                    {{ activeIndex + 2 }}
                </span>
                <div class="p-steps-title">
                    {{ model[activeIndex + 1].label }}
                </div>
            </a>
        </li>
        <li *ngIf="activeIndex === model.length - 1" role="tab" class="p-element p-steps-item p-disabled">
            <a role="presentation" class="p-menuitem-link">
                <span class="p-steps-number">
                    <i class="pi pi-chevron-right"></i>
                </span>
                <div class="p-steps-title"></div>
            </a>
        </li>
    </ul>
<!-- 
    <div>
        <span>
            <button
                pButton
                [disabled]="activeIndex === 0"
                (click)="goToPrev()"
                type="button"
                icon="pi pi-chevron-left"
                class="p-button-rounded"
            ></button>
        </span>
        <span>
            <span *ngIf="activeIndex !== 0">
               {{ activeIndex }}. 
            </span>
        </span>
    </div>



    <div>
        <span>
            <button
                pButton
                [disabled]="activeIndex === model.length - 1"
                (click)="goToNext()"
                type="button"
                icon="pi pi-chevron-right"
                class="p-button-rounded"
            ></button>
        </span>
        <span>
            <span *ngIf="activeIndex !== model.length - 1" >
                {{ activeIndex + 2 }}. {{ model[activeIndex + 1].label }}
            </span>
        </span>
    </div> -->

</div>
