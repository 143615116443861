import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from '@karve.it/core';
import {QueryRef} from 'apollo-angular';


import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { ResponsiveHelperService } from 'src/app/services/responsive-helper.service';
import { MutateTransactionComponent } from 'src/app/shared/mutate-transaction/mutate-transaction.component';
import { SendDocumentComponentInput, SendDocumentsComponent } from 'src/app/shared/send-documents/send-documents.component';
import { SubSink } from 'subsink';

import { FullTransactionFragment, TransactionsGQL, TransactionsQuery, TransactionsQueryVariables, TransactionStage, User } from '../../../generated/graphql.generated';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { FreyaMutateService } from '../../services/freya-mutate.service';
import { PermissionService } from '../../services/permission.service';


@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss']
})
export class TransactionDetailsComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('mutate') mutateRef: MutateTransactionComponent;

  @Input() transaction: FullTransactionFragment;

  user: User;

  transactionActions: MenuItem[];

  editAction: MenuItem;

  sendReceiptAction: MenuItem;

  deleteTransactionAction: MenuItem;

  allActionsDisabled = false;

  allActionsDisabledTooltip: string;

  transactionsQueryRef: QueryRef<TransactionsQuery>;

  subs = new SubSink();

  disableIfArchived = [ 'edit', 'delete' ];

  constructor(
    private detailsHelper: DetailsHelperService,
    private userService: UserService,
    private dialogService: DialogService,
    private transactionsGQL: TransactionsGQL,
    private responsiveHelper: ResponsiveHelperService,
    private freyaHelper: FreyaHelperService,
    private freyaMutate: FreyaMutateService,
    private permissionHandler: PermissionService,
  ) { }

  ngOnInit(): void {
    this.setActions();
    this.initializePermissions();

    // Refetch transaction on transaction and artifacts updates (e.g., receipt sent)
    this.subs.sink = this.detailsHelper
      .getObjectUpdates([ 'Artifacts', 'Transactions' ])
      .subscribe(() => this.retrieveTransaction());
  }

  ngOnChanges() {
    if (!this.transaction?.amount){
      this.retrieveTransaction();
      return;
    }

    this.retrieveUser();
    this.setDisabledActions();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  retrieveUser() {
    this.subs.sink = this.userService.listUsersV2({ userIds: [this.transaction.customer.id] }, {}).subscribe((res) => {
      // @ts-ignore
      this.user = res.data.usersv2.users[0];
      this.transaction.customer = this.user;
    });
  }

  isEditable(): boolean {
    if (!this.transaction) {
      return false;
    };
    return this.transaction.stage === TransactionStage.Pending;
  }

  setActions() {
    this.editAction = {
      id: 'edit',
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => {
        this.mutateRef.transaction = this.transaction;
        this.mutateRef.mutateType = 'update';
        this.mutateRef.openDialog();
      }
    };

    this.sendReceiptAction = {
      id: 'send',
      label: 'Send Receipt',
      icon: 'pi pi-send',
      command: () => this.openSendDocuments()
    };

    this.deleteTransactionAction = {
      id: 'delete',
      label: 'Delete Transaction',
      icon: 'pi pi-trash',
      command: () => {
        this.freyaMutate.openDeleteObject({
          objectId: this.transaction.id,
          objectName: `this ${this.transaction.type}`,
          objectType: 'transaction',
          customTitle: 'Delete Transaction',
          afterDelete: () => {

            if (this.transaction?.invoice) {
              this.detailsHelper.pushUpdate({
                type: 'Invoice',
                action: 'update',
                id: this.transaction.invoice.id,
              });
            }

          },
        });
      },
    };

    this.transactionActions = [{
      label: 'Transaction Actions',
      items: [
        this.editAction,
        this.sendReceiptAction,
        this.deleteTransactionAction,
      ]
    }];
  }

  initializePermissions() {
    this.subs.sink = this.permissionHandler.watchPermissions(['transactions.update', 'transactions.delete']).subscribe((res) => {
      this.editAction.visible = res[0];
      this.deleteTransactionAction.visible = res[1];
      this.setDisabledActions();
    });
  }

  setDisabledActions() {
    if (!this.transactionActions) { return; }

    const [ actionsMenu ] = this.transactionActions;
    const { items: actions } = actionsMenu;

    let allActionsDisabled = true;

    for (const action of actions) {

      if (!action.visible) {
        continue;
      }

      if (this.transaction.deletedAt) {
        this.freyaHelper.disableAction(action, 'transaction was deleted');
        continue;
      }

      if (this.disableIfArchived.includes(action.id) && this.transaction.archivedAt) {
        this.freyaHelper.disableAction(action, 'job is archived');
        continue;
      }

      if (action.id === 'edit' && this.transaction.stage !== TransactionStage.Pending) {
        this.freyaHelper.disableAction(action, 'cannot edit cancelled or completed transactions');
        continue;
      }

      if (action.id === 'send') {
        const transactionUnpaid = this.transaction.stage !== TransactionStage.Paid;

        if (transactionUnpaid) {
          this.freyaHelper.disableAction(action, 'transaction is unpaid');
          continue;
        }

        const receiptSent = Boolean(this.transaction?.metadata?.artifactReceiptId);

        if (receiptSent) {
          this.freyaHelper.disableAction(action, 'receipt already sent');
          continue;
        }
      }

      this.freyaHelper.enableAction(action);
      allActionsDisabled = false;
    }

    this.allActionsDisabled = allActionsDisabled;
    if (this.allActionsDisabled) {
      this.allActionsDisabledTooltip = this.transaction.deletedAt ? 'Transaction was deleted' :
        this.transaction.archivedAt ? 'Transaction is archived' : 'No actions available';
    } else {
      this.allActionsDisabledTooltip = undefined;
    }
  }

  openSendDocuments() {
    this.dialogService.open(SendDocumentsComponent,{
      header: 'Send Documents',
      data: {
        input: {
          jobId: this.transaction.job.id,
          preselectTemplateKey: 'standard-documents.receipt',
          transactions: [this.transaction]
        } as SendDocumentComponentInput,
      },
      width: this.responsiveHelper.dialogWidth,
    });
  }

  retrieveTransaction() {
    if (!this.transaction) { return; };

    if (this.transactionsQueryRef) {
      this.transactionsQueryRef.resetLastResults();
      this.transactionsQueryRef.refetch();
      return;
    };

    const transactionInput: TransactionsQueryVariables = {
      filter: {
        transactionIds: [this.transaction.id]
      }
    };

    this.transactionsQueryRef = this.transactionsGQL
    .watch(transactionInput, {  });

    this.subs.sink = this.transactionsQueryRef.valueChanges
    .subscribe((res) => {
      if (res.loading) { return; }

      this.transaction = res.data.transactions.transactions[0];
      this.retrieveUser();
      this.setDisabledActions();
    });
  }

  openInvoiceDetails(invoiceId: string) {
    this.detailsHelper.detailsItem.next({ type: 'invoice', item: { id: invoiceId } });
  }

}
