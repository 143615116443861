import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {dayjs} from '@karve.it/core';

import { CalendarEventForScheduleFragment, CreateCalendarEventGQL, CreateCalendarEventMutationVariables, EditCalendarEventByIdGQL } from '../../../generated/graphql.generated';
import { BOOK_OFF_EVENT_TYPE } from '../../global.constants';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { TimezoneHelperService } from '../../services/timezone-helper.service';
import { currentTimeSeconds, DateFormat, TimeFormat } from '../../time';
import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';
import { getGreaterThanValidator } from '../range-validators';

@Component({
  selector: 'app-mutate-book-off',
  templateUrl: './mutate-book-off.component.html',
  styleUrls: ['./mutate-book-off.component.scss']
})
export class MutateBookOffComponent implements OnInit {

  @ViewChild(MutateObjectComponent) mutateRef: MutateObjectComponent;

  @ViewChild('duration') durationRef: TemplateRef<any>;
  @ViewChild('description') descriptionRef: TemplateRef<any>;
  @ViewChild('durationReview') durationReview: TemplateRef<any>;

  @Input() mutateType: 'update' | 'create';
  @Input() bookOffEvent: CalendarEventForScheduleFragment;
  @Input() assetId: string;

  dateFormat: DateFormat = 'mm/dd/yy';
  timeFormat: TimeFormat = 12;

  steps: MutateObjectElement[];

  bookOffForm = new UntypedFormGroup({
    description: new UntypedFormControl(''),
    duration: new UntypedFormGroup({
      start: new UntypedFormControl(undefined, [ Validators.required ]),
      end: new UntypedFormControl(undefined, [ Validators.required ]),
    }, [ getGreaterThanValidator('start', 'end', { optionalMax: false, dataType: 'dateString' }) ]),
    }, );

  constructor(
    private timeZoneHelper: TimezoneHelperService,
    private createCalendarEventGQL: CreateCalendarEventGQL,
    private localNotify: FreyaNotificationsService,
    private detailsHelper: DetailsHelperService,
    private editCalendarEventByIdGQL: EditCalendarEventByIdGQL,
  ) { }

  ngOnInit(): void {
  }

  openDialog() {

    this.steps = [
      {
        name: 'Duration',
        ref: this.durationRef,
        control: 'duration',
        type: 'complex',
        reviewRef: this.durationReview,
      },
      {
        name: 'Description',
        ref: this.descriptionRef,
        control: 'description',
        type: 'text',
      },
    ];

    if (this.mutateType === 'create') {
      this.setDefaultCreateValues();
    } else if (this.mutateType === 'update') {
      this.setFormValues();
    }

    this.mutateRef.mutateType = this.mutateType;
    this.mutateRef.steps = this.steps;

    this.mutateRef.openDialog();
  }

  setDefaultCreateValues() {

    const startSecs = currentTimeSeconds();
    const dayJsStart = this.getDayJsTime(startSecs).set('minutes', 0);
    const dayJsEnd = dayJsStart.clone().add(1, 'hour');
    this.bookOffForm.reset({
      duration: {
        start: dayJsStart.format(this.dayJsFormat),
        end: dayJsEnd.format(this.dayJsFormat),
      },
      description: '',
    });

  }

  setFormValues() {
    this.bookOffForm.patchValue({
      duration: {
        start: this.formatEventTime(this.bookOffEvent.start),
        end: this.formatEventTime(this.bookOffEvent.end),
      },
      description: this.bookOffEvent.description,
    });

    this.bookOffForm.markAsPristine();
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      this.bookOffAsset();
    } else if (this.mutateType === 'update') {
      this.updateBookOffEvent();
    }
  }

  bookOffAsset() {

    if (!this.assetId) {
      throw new Error('Unable to book off asset: no asset ID found');
    }

    const val = this.bookOffForm.value;

    const createEventInput: CreateCalendarEventMutationVariables = {
      calendarEvents: [
        {
          title: 'Booked Off',
          description: val.description,
          type: BOOK_OFF_EVENT_TYPE,
          start: this.formatFormTime(val.duration.start),
          end: this.formatFormTime(val.duration.end),
          status: 'confirmed',
          assetIds: [ this.assetId ],
          attributes: [ 'disable-notifications'],
        }
      ]
    };

    this.createCalendarEventGQL.mutate(createEventInput)
      .subscribe((res) => {

        this.localNotify.success('Asset Booked Off');

        this.detailsHelper.pushUpdate({
          id: res.data.createCalendarEvent.events[0].id,
          type: 'Events',
          action: 'create',
          update: {
            type: 'book-off'
          }
        });

        this.mutateRef.closeDialog();

      }, (err) => {

        this.localNotify.apolloError('Failed to book off asset', err);

        this.mutateRef.loading = false;
      });
  }

  formatFormTime(time: string) {
    return dayjs.tz(time, this.dayJsFormat, this.timeZoneHelper.getCurrentTimezone()).unix();
  }

  getDayJsTime(time: number) {
    const timezone = this.timeZoneHelper.getCurrentTimezone();
    return dayjs.tz(time * 1000, timezone);
  }

  formatEventTime(time: number) {
    return this.getDayJsTime(time).format(this.dayJsFormat);
  }

  updateBookOffEvent() {
    const val = this.bookOffForm.value;

    this.editCalendarEventByIdGQL.mutate({ id: this.bookOffEvent.id, edit: {
      description: val.description,
      start: this.formatFormTime(val.duration.start),
      end: this.formatFormTime(val.duration.end),
    }}).subscribe(() => {

        this.localNotify.success('Asset Booked Off');

        this.detailsHelper.pushUpdate({
          id: this.bookOffEvent.id,
          type: 'Events',
          action: 'update',
          update: {
            type: 'book-off'
          }
        });

        this.mutateRef.closeDialog();
    }, (err) => {

        this.localNotify.apolloError('Failed to update book off', err);

        this.mutateRef.loading = false;
    } );
  }

  get dayJsDateFormat() {
    switch (this.dateFormat) {
      case 'dd/mm/yy':
        return 'DD/MM/YYYY';
      case 'mm/dd/yy':
      default:
        return 'MM/DD/YYYY';
    }
  }

  get dayJsTimeFormat() {
    switch (this.timeFormat) {
      case 24:
        return `HH:mm`;
      case 12:
      default:
        return `hh:mm A`;
    }
  }

  get dayJsFormat() {
    return `${ this.dayJsDateFormat } ${ this.dayJsTimeFormat }`;
  }

}
