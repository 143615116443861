
<div class="card">
    <div class="p-grid">
        <div class="p-col">
            <h4 data-cy="rules-card-header">System Rules</h4>
            <small>Rules for your business unit.
            </small>
        </div>
        <div class="p-col">
            <span [pTooltip]="freyaHelper.inRootOrCorporateZone ? 'You cannot add rules to your current zone': undefined">
                <button
                    type="button"
                    pButton
                    *hasPermission="['rules.create']"
                    icon="pi pi-plus"
                    label="Add"
                    (click)="appRules.openCreate()"
                    [disabled]="freyaHelper.inRootOrCorporateZone"
                    data-cy="add-rules-button"
                ></button>
            </span>
        </div>
    </div>
</div>

<div class="card rules">
    <app-rules #appRules></app-rules>
</div>
