import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { forkJoin } from 'rxjs';
import { SubSink } from 'subsink';

import { AvailableZonesGQL, BaseZoneFragment, EditCalendarEventGQL, EditCalendarEventInput, EditCalendarEventMutationVariables, FullCalendarEventFragment, UpdateJobGQL, UpdateJobInput, UpdateJobMutationVariables } from '../../../generated/graphql.generated';
import { SHARED_TO_ATTRIBUTE_PREFIX } from '../../global.constants';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';

@Component({
  selector: 'app-share-event-zone',
  templateUrl: './share-event-zone.component.html',
  styleUrls: ['./share-event-zone.component.scss']
})
export class ShareEventZoneComponent implements OnInit {

  @Input() event: FullCalendarEventFragment;

  subs = new SubSink();

  availableZones: BaseZoneFragment[];

  shareEventForm = new UntypedFormGroup({
    zones: new UntypedFormControl(undefined, Validators.required),
  });

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private editCalendarEventGQL: EditCalendarEventGQL,
    private localNotify: FreyaNotificationsService,
    private availableZonesGQL: AvailableZonesGQL,
    private detailsHelper: DetailsHelperService,
    private updateJobGQL: UpdateJobGQL,
  ) { }

  ngOnInit(): void {
    this.event = this.config.data.event;

    this.getAvailableZones();
  }

  closeDialog(){
    this.ref.close();
  }

  shareEvent(){
    const input: EditCalendarEventMutationVariables = {
      ids: [this.event.id],
      edit: {
        setSharedZones: this.shareEventForm.value.zones.map((z) => z.id)
      }
    };

    const jobInput: UpdateJobMutationVariables = {
      updateJobs: [{
        jobId: this.event?.job?.id || this.config?.data?.jobId,
        setSharedZones: this.shareEventForm.value.zones.map((z) => z.id)
      }],
    };

    forkJoin([this.editCalendarEventGQL.mutate(input), this.updateJobGQL.mutate(jobInput)]).subscribe((res) => {
      this.localNotify.success('Event shared');
      this.detailsHelper.pushUpdate({
        action: 'update',
        id: this.event.id,
        type: 'Events'
      });
    }, (err) => {
      this.localNotify.apolloError('Failed to share event', err);
    });

    this.closeDialog();
  }

  getAvailableZones(){
    this.subs.sink = this.availableZonesGQL.fetch({limit: -1}).subscribe((res) => {
      this.availableZones = res.data.availableZones.filter((z) => z.type === 'franchise');

      // Default the value based on the current shared zones
      const sharedZones = this.availableZones.filter((z) => this.event.sharedZones.includes(z.id));
      this.shareEventForm.controls.zones.setValue(sharedZones);
    });
  }


}
