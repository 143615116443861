import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { SubSink } from 'subsink';

import { CreateZonesGQL, GetConfigValuesGQL, ZoneInput } from '../../../generated/graphql.generated';
import { AvailableZonesService } from '../../services/available-zones.service';
import { BrandingService } from '../../services/branding.service';
import { DetailsHelperService } from '../../services/details-helper.service';

import { MutateType } from '../../services/freya-mutate.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { FreyaMutateComponent } from '../mutate-charges/mutate-charges.component';
import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

@Component({
  selector: 'app-mutate-business-unit',
  templateUrl: './mutate-business-unit.component.html',
  styleUrls: ['./mutate-business-unit.component.scss']
})
export class MutateBusinessUnitComponent implements OnInit, OnDestroy, FreyaMutateComponent {

    @ViewChild('mutate') mutateRef: MutateObjectComponent;

    @ViewChild('name') nameRef: TemplateRef<any>;
    @ViewChild('unitType') unitTypeRef: TemplateRef<any>;
    @ViewChild('id') idRef: TemplateRef<any>;
    @ViewChild('zoneCode') zoneCodeRef: TemplateRef<any>;
    @ViewChild('timezone') timezoneRef: TemplateRef<any>;
    @ViewChild('language') languageRef: TemplateRef<any>;
    @ViewChild('country') countryRef: TemplateRef<any>;
    @ViewChild('hqAddress') hqAddressRef: TemplateRef<any>;
    @ViewChild('email') emailRef: TemplateRef<any>;
    @ViewChild('phone') phoneRef: TemplateRef<any>;

    @Input() mutateType: MutateType;

    unitTypes = [
        {
            label: 'Franchise',
            value: 'franchise',
        },
        {
            label: 'Business Group',
            value: 'business-group',
        }
    ];

    businessUnitForm = new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        unitType: new UntypedFormControl('franchise', Validators.required),
        id: new UntypedFormControl('', Validators.required),
        zoneCode: new UntypedFormControl('', Validators.required),
        timezone: new UntypedFormControl(''),
        language: new UntypedFormControl(''),
        country: new UntypedFormControl(''),
        hqAddress: new UntypedFormControl(''),
        email: new UntypedFormControl(''),
        phone: new UntypedFormControl(''),
    });

    defaultFormValues = cloneDeep(this.businessUnitForm.value);

    steps: MutateObjectElement[];

    subs = new SubSink();

    constructor(
        private createZonesGQL: CreateZonesGQL,
        private brandingService: BrandingService,
        private detailsHelper: DetailsHelperService,
        private localNotify: FreyaNotificationsService,
        private availableZonesService: AvailableZonesService,
        private getConfigValuesGQL: GetConfigValuesGQL,
    ) { }

    ngOnInit(): void {
        this.subs.sink = this.getConfigValuesGQL.watch({ keys: [
          'system-details.timezone',
          'system-details.language',
          'system-details.country',
        ] }, { fetchPolicy: 'cache-and-network' }).valueChanges
            .subscribe((res) => {

                if (res.loading) { return; }

                const timezone = res.data.getConfigValues.find((c) => c.key === 'system-details.timezone')?.value;

                const language = res.data.getConfigValues.find((c) => c.key === 'system-details.language')?.value;

                const country = res.data.getConfigValues.find((c) => c.key === 'system-details.country')?.value;

                // Default timezone, language and country to current zone system defaults
                this.defaultFormValues = {
                    ...this.defaultFormValues,
                    timezone,
                    language,
                    country,
                };
            });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    openDialog() {

        this.steps = [
            {
                name: 'Name',
                type: 'text',
                control: 'name',
                ref: this.nameRef,
            },
            {
                name: 'Business Unit Type',
                type: 'text',
                control: 'unitType',
                ref: this.unitTypeRef,
            },
            {
                name: 'ID',
                type: 'text',
                control: 'id',
                ref: this.idRef,
            },
            {
                name: 'Zone Code',
                type: 'text',
                control: 'zoneCode',
                ref: this.zoneCodeRef,
            },
            {
                name: 'Timezone',
                type: 'text',
                control: 'timezone',
                ref: this.timezoneRef,
            },
            {
                name: 'Language',
                type: 'text',
                control: 'language',
                ref: this.languageRef,
            },
            {
                name: 'Country',
                type: 'text',
                control: 'country',
                ref: this.countryRef,
            },
            {
                name: 'HQ Address',
                type: 'text',
                control: 'hqAddress',
                ref: this.hqAddressRef,
            },
            {
                name: 'Email',
                type: 'text',
                control: 'email',
                ref: this.emailRef,
            },
            {
                name: 'Phone',
                type: 'text',
                control: 'phone',
                ref: this.phoneRef,
            },
        ];

        this.mutateRef.steps = this.steps;

        this.businessUnitForm.reset(this.defaultFormValues);

        this.mutateRef.openDialog();
    }

    mutateObject() {
        if (this.mutateType === 'create') {
            this.createBusinessUnit();
        } else {
            throw new Error('Update business unit not implemented');
        }
    }

    createBusinessUnit() {

        const val = this.businessUnitForm.value;

        const configs = [
            {
                key: 'system-details.timezone',
                value: val.timezone,
            },
            {
                key: 'system-details.language',
                value: val.language,
            },
            {
                key: 'system-details.country',
                value: val.country,
            },
            {
                key: 'franchise-info.email',
                value: val.email,
            },
            {
                key: 'franchise-info.phone',
                value: val.phone,
            },
        ];

        const zoneInput: ZoneInput = {
            name: val.name,
            id: val.id,
            type: val.unitType,
            attributes: [ val.unitType ],
            contextable: true,
            runMigrations: true,
            configs,
        };

        // Do not set unless user entered value, otherwise input will fail AJV validation
        if (val.hqAddress) {
            zoneInput.zoneHQ = val.hqAddress;
        }

        const unitTypeLabel = this.unitTypes.find((ut) => ut.value === val.unitType)?.label;

        this.createZonesGQL.mutate({
            parent: this.brandingService.currentZone().value.id,
            zones: zoneInput,
        }).subscribe((res) => {

          this.detailsHelper.pushUpdate({
            id:res.data.createZones[0].id,
            type:'Zones',
            action:'create',
          });

          this.mutateRef.closeDialog();

          this.localNotify.addToast.next({severity: 'success', summary: `${ unitTypeLabel } created`});

          if (this.availableZonesService.watchQuery) {
            // Refetch to update the select-zones component, which is reading zones off this service
            this.availableZonesService.watchQuery.refetch();
          }
        }, (createErr) => {
          this.mutateRef.loading=false;
          this.localNotify.apolloError(`Failed to create ${ unitTypeLabel?.toLowerCase() }`,createErr);
        });
    }

    setFormValues() {
        throw new Error('Method not implemented.');
    }

}
